import {Grid, TextField} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
    card_description,
    card_title,
    ipGreen,
    ReportHeaderCell,
    ReportValueCell,
    ipRed,
    HeaderCell, IPTableRow, ValueCell, color_filters
} from "../../Styles/styles";
import TableBody from "@material-ui/core/TableBody";
import React from "react";
import {superScript} from "../../Utils/compliance_utils";
import {dollarsWithCommas, percentage, pos_neg_dollar_card_description} from "../../Utils/num_utils";
import InputAdornment from "@material-ui/core/InputAdornment";
import Chip from "@material-ui/core/Chip";
import {msci_abc_rating} from "../Builder/Data/builder_journey";

export const TransitionImpact = (benchmark, headers, transition_data) => {

    const impact_data_copy = [...transition_data['impact_data']]
    const impact_data = impact_data_copy.sort((a, b) => parseFloat(b.pct) - parseFloat(a.pct))

    return (
        <div style={{height: "100%", position: "relative", color: "black",  fontDisplay: "swap"}}>
            <div style={{height: "390px"}}>
                <div style={{
                    position: "absolute",
                    top: "54px",
                    right: "60px",
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "black"
                }}>5
                </div>
                <Grid container justifyContent="flex-start" style={{
                    position: "absolute", top: "40px", left: "60px",
                    fontSize: "14px", color: "#c2c2c1", fontWeight: "600", width: "800px"
                }}>
                    <p style={{marginRight: "25px", color: "#009494"}}>Portfolio</p>
                    {headers.map(title => {
                        return(
                            <p style={{marginRight: "25px"}}>{title}</p>
                        )
                    })}
                </Grid>
                <div style={{
                    position: "absolute",
                    top: "140px",
                    left: "60px",
                    fontSize: "14px",
                    borderBottom: "1px solid black",
                    width: "755px",
                    fontWeight: "600"
                }}>
                    TOP 10 ESG LAGGARDS EXCLUDED {superScript(8)}
                </div>
                <div style={{position: "absolute", top: "160px", left: "60px", fontSize: "13px", width: "755px"}}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <ReportHeaderCell>Company Name</ReportHeaderCell>
                                    <ReportHeaderCell align="left">Ticker</ReportHeaderCell>
                                    <ReportHeaderCell align="right">Input Weight</ReportHeaderCell>
                                    <ReportHeaderCell align="center">MSCI ESG Rating {superScript(9)}</ReportHeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {transition_data["esg_laggards"].map((row) => (
                                    <TableRow>
                                        <ReportValueCell>{row.company_name.toUpperCase()}</ReportValueCell>
                                        <ReportValueCell align="left">{row.ticker}</ReportValueCell>
                                        <ReportValueCell
                                            align="right">{row.input_weight}</ReportValueCell>
                                        <ReportValueCell
                                            align="center" style={{color: ipRed}}><Chip
                                            style={{backgroundColor: "#ffe9ef", color: ipRed, fontSize: "10px", width: "50px"}}
                                            label={msci_abc_rating(parseFloat(row.value))}/></ReportValueCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            <div style={{
                position: "absolute", top: "640px", marginLeft: "60px", marginRight: "60px",
                width: "755px", fontSize: "14px", fontWeight: "600", borderBottom: "1px solid black"
            }}>
                IMPACT VS. INPUT PORTFOLIO<span
                style={{fontWeight: "500", fontSize: "12px"}}> (POTENTIAL ESG IMPACT OF INDEX PRO PORTFOLIO VERSUS INPUT PORTFOLIO) {superScript(10)}</span>
            </div>
            <Grid container direction="row" justifyContent="space-between" style={{
                position: "absolute", top: "680px", marginLeft: "60px", marginRight: "60px",
                width: "755px", fontSize: "16px", fontWeight: "500"
            }}>
                {impact_data.slice(0, 3).map((item, i) => (
                    <div style={{
                        backgroundColor: "#f6f7f8",
                        width: "241px",
                        height: "180px",
                        color: "black",
                        textAlign: "center",
                    }}>
                        <img src={`https://res.cloudinary.com/indexpro/image/upload/Impact Icons/${item.title}.svg`}
                             height="50px"
                             style={{marginTop: "40px", textAlign: "center", maxWidth: "95px", filter: color_filters[i]}}/>
                        <p style={{fontSize: "12px", textAlign: "center", marginBottom: "0px"}}>{item.title}</p>
                        <p style={{
                            fontSize: "12px",
                            textAlign: "center",
                            fontWeight: "500",
                            marginTop: "0px",
                            marginLeft: "10px",
                            marginRight: "10px",
                            color: "#555555"
                        }}>{item.pct} improvement in MSCI {item.title} score</p>
                    </div>
                ))}
            </Grid>
            <Grid container direction="row" justifyContent="space-between" style={{
                position: "absolute", top: "876px", marginLeft: "60px", marginRight: "60px",
                width: "755px", fontSize: "16px", fontWeight: "500"
            }}>
                {impact_data.slice(3, 5).map((item, i) => (
                    <div style={{
                        backgroundColor: "#f6f7f8",
                        width: "370px",
                        height: "180px",
                        color: "black",
                        textAlign: "center",
                    }}>
                        <img src={`https://res.cloudinary.com/indexpro/image/upload/Impact Icons/${item.title}.svg`} height="50px"
                             style={{marginTop: "40px", textAlign: "center", maxWidth: "95px", filter: color_filters[i + 3]}}/>
                        <p style={{fontSize: "12px", textAlign: "center", marginBottom: "0px"}}>{item.title}</p>
                        <p style={{
                            fontSize: "12px",
                            textAlign: "center",
                            fontWeight: "500",
                            marginTop: "0px",
                            marginLeft: "10px",
                            marginRight: "10px",
                            color: "#555555"
                        }}>{item.pct} improvement in MSCI {item.title} score</p>
                    </div>
                ))}
            </Grid>
            <div style={{position: "absolute", top: "800px", left: "60px", width: "755px"}}>
            </div>
        </div>
    )
}