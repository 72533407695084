import {Grid, Paper} from "@material-ui/core";
import {big_paper, card_subtitle, card_title, HeaderCell, ipRed, IPTableRow, ValueCell} from "../../Styles/styles";
import React from "react";
import {useAccountTransitionExclusionByID} from "../Hooks/Data/Accounts/accounts";
import {useParams} from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import {msci_abc_rating_to_color, msci_abc_rating} from "../Builder/Data/builder_journey";
import Chip from "@material-ui/core/Chip";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import {dollarsWithCommas, percentage} from "../../Utils/num_utils";

function AccountTransitionESG(props) {

    const {account_id} = useParams();

    return (
        <div className="ESG-Laggards">
            <Paper style={Object.assign({}, big_paper, {paddingBottom: "20px"})} elevation={0}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <div>
                        <p style={card_title}>ESG Laggards Excluded</p>
                        <p style={card_subtitle}>Top 10 current holdings that violate ESG guidelines</p>
                    </div>
                </Grid>
                {props.account["transition_data"] ? (
                    <div>
                        {props.account ? (
                            <div>
                                <Grid container direction="row">
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <HeaderCell>Company Name</HeaderCell>
                                                        <HeaderCell align="left">Ticker</HeaderCell>
                                                        <HeaderCell align="right">Input Weight</HeaderCell>
                                                        <HeaderCell align="center">MSCI ESG Rating</HeaderCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {props.account["transition_data"]["esg_laggards"].map((row) => (
                                                        <IPTableRow>
                                                            <ValueCell>{row.company_name}</ValueCell>
                                                            <ValueCell align="left">{row.ticker}</ValueCell>
                                                            <ValueCell
                                                                align="right">{row.input_weight}</ValueCell>
                                                            <ValueCell
                                                                align="center" style={{color: ipRed}}><Chip
                                                                style={{backgroundColor: "#ffe9ef", color: ipRed, fontSize: "13px", width: "80px"}}
                                                                label={msci_abc_rating(parseFloat(row.value))}/></ValueCell>
                                                        </IPTableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                </Grid>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                ) : (
                    <></>
                )
                }
            </Paper>
        </div>
    )
}

export default AccountTransitionESG