import React from "react";

export const TaxSelectionsTitlePage = () => {
    return (
        <div style={{height: "100%", position: "relative", fontDisplay: "swap"}}>
            <img
                src="https://res.cloudinary.com/indexpro/image/upload/v1652901841/Proposal/Tax%20Selections%20Title%20Page.jpg"
                width="100%" height="100%"/>
            <div style={{
                position: "absolute",
                top: "300px",
                left: "60px",
                fontSize: "84px",
                fontWeight: "700",
                color: "white",
                lineHeight: 1
            }}>
                TAXES
            </div>
            <div style={{
                position: "absolute",
                bottom: "80px",
                left: "60px",
                fontSize: "18px",
                color: "white",
                width: "300px"
            }}>
                “In this world nothing can be said to be certain, except death and taxes.”
            </div>
            <div style={{position: "absolute", bottom: "40px", left: "60px", fontSize: "18px", color: "white"}}>
                Benjamin Franklin
            </div>
        </div>
    )
}