import React from "react";
import {withStyles} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import TreeItem from "@material-ui/lab/TreeItem";
import Slider from "@material-ui/core/Slider";
import Badge from "@material-ui/core/Badge";

const hex2rgba = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
};

export const ipLogo = "https://res.cloudinary.com/indexpro/image/upload/Logo/Index%20Pro%20Logo%20Black.svg"
export const ipLogoIcon = "https://res.cloudinary.com/indexpro/image/upload/Logo/Index%20Pro%20Icon%20Black.svg"
export const ipLogoWhite = "https://res.cloudinary.com/indexpro/image/upload/Logo/Index%20Pro%20Logo%20White.svg"
export const textFontSize = "14px"
export const themeColor = "#1967d2"
export const themeBackground = "#F8F9FA"
export const boldWeight = "600"
export const ipGreen = "#1bb157"
export const ipRed = "#E52C2C"
export const ipYellow = "#FFB500"
export const fabRed = "#D62728"

//Overrides
export const ChromeAutoFillOverride = {WebkitBoxShadow: `0 0 0 1000px ${themeBackground} inset`};

//Text
export const simple_text = {
    fontSize: textFontSize
}

export const simple_text_bold = {
    fontSize: textFontSize,
    fontWeight: boldWeight
}

//App Bar
export const app_bar_style = {
    backgroundColor: "white",
    borderBottom: "1px solid #dadce0",
    paddingTop: "7px"
}

//Tabs
export const tab_label = {
    fontSize: textFontSize,
    color: "#000",
    margin: "0px",
    textTransform: "none",
}

//Buttons
export const bordered_button = {
    fontSize: textFontSize,
    backgroundColor: themeBackground,
    boxShadow: "none",
    border: `1px solid ${themeColor}`,
    color: themeColor,
    textTransform: 'none',
    whiteSpace: "nowrap"
}

export const form_button = {
    fontSize: textFontSize,
    backgroundColor: themeBackground,
    boxShadow: "none",
    border: `1px solid ${themeColor}`,
    color: themeColor,
    textTransform: 'none',
    whiteSpace: "nowrap",
    width: "150px",
    padding: "10px"
}

export const transparent_button = {
    fontSize: textFontSize,
    color: themeColor,
    textTransform: "none"
}

export const noborder_button = {
    fontSize: "14px",
    backgroundColor: themeBackground,
    boxShadow: "none",
    color: themeColor,
    textTransform: "none"
}

//Cards
export const control_paper_left = {
    height: "100%",
    width: "400px",
    float: "left",
    marginLeft: "222px",
    position: "relative",
    border: "1px solid #dadce0",
    borderRadius: "6px"
}

export const builder_paper_right = {
    height: "100%",
    width: "824px",
    float: "right",
    marginRight: "-200px",
    border: "1px solid #dadce0",
    borderRadius: "6px"
}

export const paper_right = {
    height: "100%",
    width: "622px",
    float: "right",
    border: "1px solid #dadce0",
    borderRadius: "6px"
}

export const paper_left = {
    height: "100%",
    width: "622px",
    float: "left",
    border: "1px solid #dadce0",
    borderRadius: "6px"
}

export const card_title = {
    marginLeft: "20px",
    fontSize: "17px",
    fontWeight: "600",
    marginBottom: "0px"
}

export const card_subtitle = {
    marginLeft: "20px",
    fontSize: "12px",
    color: "#B5B4B9",
    marginTop: "5px"
}

export const card_description = {
    marginLeft: "20px",
    fontSize: "16px",
    color: "#9A9A9A",
    marginTop: "5px"
}

export const card_subtitle_black = {
    fontSize: "13px",
    fontWeight: "600"
}
export const card_subtitle_grey = {
    marginLeft: "20px",
    fontSize: "13px",
    color: "#B5B4B9",
    marginTop: "30px",
    marginRight: "20px"
}

export const card_value = {
    marginLeft: "20px",
    fontSize: "17px",
    fontWeight: "500",
    color: "181C32",
    marginBottom: "0px"
}

export const big_paper = {
    height: "100%",
    width: "1278px",
    margin: "auto",
    paddingTop: "5px",
    paddingBottom: "50px",
    border: "1px solid #dadce0",
    borderRadius: "6px"
}

export const big_paper_pdf = {
    height: "100%",
    width: "90%",
    margin: "auto",
    paddingTop: "5px",
    paddingBottom: "50px",
    border: "1px solid #dadce0",
    borderRadius: "6px"
}

export const onboarding_paper = {
    height: "100%",
    width: "1278px",
    margin: "auto",
    boxShadow: "none",
    border: "1px solid #dadce0",
    borderRadius: "6px"
}

export const account_preferences_icon = {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginLeft: '20px',
    marginTop: '20px'
}


export const account_onboarding_completed_line = {
    width: "2px",
    backgroundColor: "transparent",
    height: "80px",
    marginLeft: "10px",
    marginTop: "-50px",
    marginBottom: "-50px",
    borderRight: "3px dotted #34C36B"
}

export const account_onboarding_progress_line = {
    width: "2px",
    backgroundColor: "transparent",
    height: "80px",
    marginLeft: "10px",
    marginTop: "-50px",
    marginBottom: "-50px",
    borderRight: "3px dotted #B5B4B9"
}

export const account_onboarding_title = {
    marginLeft: "20px", fontSize: "16px", fontWeight: "600",
    marginTop: "0px", paddingTop: "20px"
}

export const account_onboarding_time = {
    marginLeft: "20px",
    fontSize: "12px",
    color: "#B5B4B9",
    marginBottom: "0px",
    paddingTop: "45px",
}


//chips
export const chip_style_green = {
    backgroundColor: "#d9f9ef",
    color: ipGreen,
    fontSize: "11px",
    marginTop: "5px",
    marginRight: "5px",
}

export const chip_style_red = {
    backgroundColor: "#ffe9ef",
    color: ipRed,
    fontSize: "11px",
    marginTop: "5px",
    marginRight: "5px",
}


//Table Cells
export const HeaderCell = withStyles((theme) => ({
    head: {
        fontSize: textFontSize,
        color: "#000",
        fontWeight: boldWeight,
        paddingBottom: "2px"
    },
}))(TableCell);

export const IPTableRow = withStyles((theme) => ({
    root: {
        "&:last-child th, &:last-child td": {
            borderBottom: 0
        }
    }
}))(TableRow);

export const SpecialCell = withStyles((theme) => ({
    body: {
        paddingTop: "8px",
        paddingBottom: "8px"

    },
}))(TableCell);

export const ValueCell = withStyles((theme) => ({
    body: {
        fontSize: textFontSize,
        color: "#000",
        paddingTop: "8px",
        paddingBottom: "8px"
    },
}))(TableCell);


export const ReportHeaderCell = withStyles((theme) => ({
    head: {
        fontSize: "11px",
        color: "#000",
        fontWeight: "600",
        paddingBottom: "1px",
        paddingLeft: "0px",
        borderBottom: "1px solid #e0e0e0"
    },
}))(TableCell);



export const ReportValueCell = withStyles((theme) => ({
    body: {
        fontSize: "11px",
        color: "#000",
        paddingTop: "3px",
        paddingBottom: "3px",
        paddingLeft: "0px",
    },
}))(TableCell);

export const OnboardingAccordionSummary = withStyles((theme) => ({
    root: {
        '&:hover': {
            border: `1px solid ${themeColor}`,
            borderRadius: "6px",
            color: themeColor
        },
    },
    expanded: {
        '&:hover': {
            border: "0px",
        }
    }
}))(AccordionSummary);


//tree item
export const IPTreeItem = withStyles((theme) => ({
    label: {
        fontSize: "14px"
    }
}))(TreeItem);

export const TrackingSlider = withStyles({
    root: {
        color: themeColor,
        width: 400,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#FFF',
        border: '1px solid #AFAFAF',
        marginTop: -8,
        marginLeft: -12,
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
    mark: {
        height: 0,
        width: 0,
    },
    markLabel: {
        color: "black",
        paddingTop: "6px"
    }
})(Slider);

export const StyledBadge = withStyles((theme) => ({
    badge: {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}))(Badge);

//Asset Graph $ Amount Style
export const AssetGraphLabel = {
    fontSize: "17px",
    fontWeight: boldWeight,
    marginTop: "0px",
    marginRight: "20px"
}


export const color_filters = [
    "invert(71%) sepia(26%) saturate(3583%) hue-rotate(10deg) brightness(101%) contrast(91%)",
    "invert(36%) sepia(90%) saturate(694%) hue-rotate(136deg) brightness(93%) contrast(101%)",
    "invert(52%) sepia(97%) saturate(467%) hue-rotate(180deg) brightness(93%) contrast(91%)",
    "invert(17%) sepia(78%) saturate(2811%) hue-rotate(196deg) brightness(93%) contrast(94%)",
    "invert(12%) sepia(89%) saturate(686%) hue-rotate(174deg) brightness(99%) contrast(99%)"
]

//icons
export const dashboard_icon = "https://res.cloudinary.com/indexpro/image/upload/App%20Bar%20Icons/Dashboard.svg"
export const accounts_icon = "https://res.cloudinary.com/indexpro/image/upload/App%20Bar%20Icons/Accounts.svg"
export const strategies_icon = "https://res.cloudinary.com/indexpro/image/upload/App%20Bar%20Icons/Strategies.svg"
export const builder_icon = "https://res.cloudinary.com/indexpro/image/upload/App%20Bar%20Icons/Builder.svg"

export const manage_your_plan_icon = "https://res.cloudinary.com/indexpro/image/upload/Onboarding%20Icons/Manage%20your%20Plan.svg"
export const get_to_know_index_pro_icon = "https://res.cloudinary.com/indexpro/image/upload/Onboarding%20Icons/Get%20to%20Know%20Index%20Pro.svg"

export const default_firm_photo = "https://res.cloudinary.com/indexpro/image/upload/Misc/Default%20Firm%20Photo.jpg"

export const benchmark_icon = "https://res.cloudinary.com/indexpro/image/upload/Strategy%20Preferences%20Icons/Benchmark.svg"
export const values_icon = "https://res.cloudinary.com/indexpro/image/upload/Strategy%20Preferences%20Icons/Values.svg"
export const exclusions_icon = "https://res.cloudinary.com/indexpro/image/upload/Strategy%20Preferences%20Icons/Exclusions.svg"
export const security_exclusion_icon = "https://res.cloudinary.com/indexpro/image/upload/Strategy%20Preferences%20Icons/Security%20Exclusions.svg"
export const revenue_icon = "https://res.cloudinary.com/indexpro/image/upload/Strategy%20Preferences%20Icons/Revenue.svg"
export const themes_icon = "https://res.cloudinary.com/indexpro/image/upload/Strategy%20Preferences%20Icons/Themes.svg"
export const tax_icon = "https://res.cloudinary.com/indexpro/image/upload/Strategy%20Preferences%20Icons/Tax.svg"
export const alpha_icon = "https://res.cloudinary.com/indexpro/image/upload/Strategy%20Preferences%20Icons/Alpha.svg"
export const risk_icon = "https://res.cloudinary.com/indexpro/image/upload/Strategy%20Preferences%20Icons/Risk.svg"

export const timeout_icon = "https://res.cloudinary.com/indexpro/image/upload/Misc/Timeout.svg"
export const transition_icon = "https://res.cloudinary.com/indexpro/image/upload/Misc/Right%20Transition.svg"

