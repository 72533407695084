import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import {transparent_button, ChromeAutoFillOverride, ipLogoIcon, ipLogo} from "../Styles/styles"
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {useHistory} from 'react-router-dom'
import {Auth, API, Storage, Hub} from 'aws-amplify'
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {DataStore} from '@aws-amplify/datastore';
import CircularProgress from '@material-ui/core/CircularProgress';
import "./SignIn.css"
import {Mixpanel} from "../Utils/Mixpanel";

//form styles
const formStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundColor:
        theme.palette.background.default,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: "40px"
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        width: "250px"
    },
}));


function SignIn() {

    const history = useHistory();

    async function checkUserState() {
        try {
            await Auth.currentAuthenticatedUser()
            Mixpanel.identify(JSON.parse(localStorage.getItem("signed-in-user")).id);
            Mixpanel.track('Login');
            Mixpanel.people.set({
                $email: JSON.parse(localStorage.getItem("signed-in-user")).attributes.email,
                $name: JSON.parse(localStorage.getItem("signed-in-user")).attributes.name
            });
            history.push('/participant')
        } catch (e) {
            await DataStore.clear()
            console.error(e)
        }
    }

    useEffect( () => {
        checkUserState();
    }, [])

    //styles + history prop
    const classes = formStyles();

    //password visible state
    const [showPassword, setShowPassword] = React.useState(0)

    //step state
    const [step, setStep] = useState(0);

    //user state
    const [user, setUser] = useState({username: '', password: '', authCode: '', new_password: ''});

    //error state
    const [error, setError] = useState('');

    //show password handle
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    //on change method
    const handleInputChange = (event, keyName) => {
        event.persist();
        setUser((user) => {
            return {...user, [keyName]: event.target.value}
        })
    }

    //login state
    const [loggedInState, setLoggedInState] = React.useState()
    const [loggedInButton, setLoggedInButton] = React.useState()

    const datastoreListener = Hub.listen("datastore", async hubData => {
        const  { event, data } = hubData.payload;
        if (event === "ready") {
            history.push('/participant')
        }
    })

    const signInFirm = async (uid) => {
        //await Auth.signIn('indexpro-admin@' + user.username.split('@')[1], uid);
        //setLoggedInState("Logging In")
        //await DataStore.clear();
        //await DataStore.start();
        try {
            await Auth.signIn('indexpro-admin@' + user.username.split('@')[1], uid);
            setLoggedInState("Logging In")
            await DataStore.clear();
            await DataStore.start();
        } catch(e) {
            await Auth.signOut()
            setError('Please contact support')
            setLoggedInState(null)
            setLoggedInButton(null)
        }
    }

    //sign in
    const signIn = async () => {
        try {
            await Auth.currentAuthenticatedUser()
            Mixpanel.identify(JSON.parse(localStorage.getItem("signed-in-user")).id);
            Mixpanel.track('Login');
            Mixpanel.people.set({
                $email: JSON.parse(localStorage.getItem("signed-in-user")).attributes.email,
                $name: JSON.parse(localStorage.getItem("signed-in-user")).attributes.name
            });
            return history.push('/participant')
        } catch(e) {
                console.error(e)
        }
        try {
            setLoggedInButton("Logging In")
            if (user.username.split("@")[0] === 'indexpro-admin') {
                await Auth.signIn(user.username, user.password);
                localStorage.setItem('signed-in-user', JSON.stringify(await Auth.currentUserInfo()))
                setLoggedInState("Logging In")
                await DataStore.clear();
                await DataStore.start();
            }
            else {
                await Auth.signIn(user.username, user.password);
                localStorage.setItem('signed-in-user', JSON.stringify(await Auth.currentUserInfo()))
                const result = await Storage.get(`uid.txt`, {
                    level: "private",
                    download: true,
                    cacheControl: 'no-cache'
                });
                result.Body.text().then(i => {
                    signInFirm(i)
                });
            }
            Mixpanel.identify(JSON.parse(localStorage.getItem("signed-in-user")).id);
            Mixpanel.track('Login');
            Mixpanel.people.set({
                $email: JSON.parse(localStorage.getItem("signed-in-user")).attributes.email,
                $name: JSON.parse(localStorage.getItem("signed-in-user")).attributes.name
            });
            datastoreListener();
        } catch (error) {
            setLoggedInButton()
            setError(error.message)
            console.error('error', error);
        }
    }

    //forgotPassword
    const forgotPassword = async () => {
        try {
            if(user.username.startsWith('indexpro-admin@')) {
                console.error('Error Sending Forgot Password')
            } else {
                await Auth.forgotPassword(user.username)
                setStep(2)
            }
        } catch (error) {
            console.error('Error Sending Forgot Password: ', error);
        }
    }

    //forgotPasswordSubmit
    const forgotPasswordSubmit = async () => {
        try {
            await Auth.forgotPasswordSubmit(user.username, user.authCode.trim(), user.new_password)
            setError('')
            setStep(0)
        } catch (error) {
            setError(error.message)
            console.error('Error Submitting Forgot Password: ', error);
        }
    }

    if (loggedInState === "Logging In") {
        return (
            <>
                <div className="fade-in-text" style={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", minHeight: "100vh"}}>
                    <img
                        src={ipLogoIcon}
                        width="150" height="150"/>
                </div>
            </>
        )
    }

    return (
        <div>
            {step === 0 &&
            <Grid container component="main" className={classes.root}>
                <Grid item xs={0} sm={4} md={5} className={classes.image}/>
                <Grid item xs={12} sm={8} md={7} component={Paper} elevation={1} square>
                    <div className={classes.paper}>
                        <Grid container direction="column" justifyContent="flex-start">
                            <img
                                src={ipLogo}
                                alt="logo"
                                width="160" height="44"/>
                            <p style={{fontSize: "50px", marginBottom: "50px", marginTop: "200px"}}>
                                Welcome.
                            </p>
                        </Grid>
                        {error !== '' && (
                            <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                                <Alert severity="error" style={{width: "450px", marginBottom: "25px"}}>{error}</Alert>
                            </Grid>
                        )}
                        <form className={classes.form} noValidate>
                            <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    label="Email Address"
                                    autoComplete="email"
                                    type="email"
                                    id="email"
                                    autoFocus
                                    style={{
                                        backgroundColor: "#F5F8FA",
                                        width: "450px"
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            signIn()
                                        }
                                    }}
                                    onChange={(e) => handleInputChange(e, 'username')}
                                    inputProps={{style: ChromeAutoFillOverride}}

                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    id="password"
                                    type={showPassword ? 'text' : 'password'}
                                    label="Password"
                                    autoComplete="current-password"
                                    style={{
                                        backgroundColor: "#F5F8FA",
                                        width: "450px"
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            signIn()
                                        }
                                    }}
                                    onChange={(e) => handleInputChange(e, 'password')}
                                    inputProps={{style: ChromeAutoFillOverride}}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPassword ? <Visibility/> : <VisibilityOff/>}
                                                </IconButton>
                                            </InputAdornment>
                                    }}
                                />
                                <Button style={transparent_button} onClick={() => setStep(1)}>
                                    Forgot your password?</Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    id="signin"
                                    className={classes.submit}
                                    style={{marginTop: "50px", textTransform: "none", padding: "0px"}}
                                    onClick={() => signIn()}
                                >
                            <span>
                                <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
                                    {loggedInButton === 'Logging In' ? (
                                        <div style={{paddingTop: "5px"}}><CircularProgress style={{color: "white"}}/>
                                        </div>
                                    ) : (
                                        <><p>Log In</p> <ArrowForwardIcon
                                            style={{marginLeft: "10px", fontSize: "20px"}}/></>
                                    )}
                                </Grid>
                            </span>
                                </Button>
                                <Button style={transparent_button} onClick={() => history.push('/signup')}>
                                    Don't have an Account?</Button>
                            </Grid>
                        </form>

                    </div>
                </Grid>
            </Grid>
            } {step === 1 &&
        <Grid container component="main" className={classes.root}>
            <Grid item xs={false} sm={4} md={5} className={classes.image}/>
            <Grid item xs={12} sm={8} md={7} component={Paper} elevation={1} square>
                <div className={classes.paper}>
                    <Grid container direction="column" justifyContent="flex-start">
                        <img
                            src={ipLogo}
                            alt="logo"
                            width="160" height="44"/>
                        <p style={{fontSize: "50px", marginBottom: "0px", marginTop: "200px"}}>
                            Forgot your password?
                        </p>
                        <p style={{fontSize: "15px", marginBottom: "50px"}}>
                            Provide your email address and we'll send you a verification code.
                        </p>
                    </Grid>
                    <form className={classes.form} noValidate>
                        <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                label="Email Address"
                                type="email"
                                autoFocus
                                value={user.username}
                                style={{
                                    backgroundColor: "#F5F8FA",
                                    width: "450px"
                                }}
                                inputProps={{style: ChromeAutoFillOverride}}
                                onChange={(e) => handleInputChange(e, 'username')}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                style={{marginTop: "50px", textTransform: "none", padding: "0px"}}
                                onClick={() => forgotPassword()}
                            >
                                    <span>
                                        <Grid container direction="row" justifyContent="space-evenly"
                                              alignItems="center">
                                            <p>Send Code</p> <ArrowForwardIcon
                                            style={{marginLeft: "10px", fontSize: "20px"}}/>
                                        </Grid>
                                    </span>
                            </Button>
                            <Button style={transparent_button} onClick={() => {setStep(0); setError('')}}>
                                Back to Sign in</Button>
                        </Grid>
                    </form>

                </div>
            </Grid>
        </Grid>
        } {step === 2 &&
        <Grid container component="main" className={classes.root}>
            <Grid item xs={false} sm={4} md={5} className={classes.image}/>
            <Grid item xs={12} sm={8} md={7} component={Paper} elevation={1} square>
                <div className={classes.paper}>
                    <Grid container direction="column" justifyContent="flex-start">
                        <img
                            src={ipLogo}
                            alt="logo"
                            width="160" height="44"/>
                        <p style={{fontSize: "50px", marginBottom: "0px", marginTop: "200px"}}>
                            Reset Password
                        </p>
                        <p style={{fontSize: "15px", marginBottom: "50px"}}>
                            We sent you a code via email to reset your account.
                        </p>
                    </Grid>
                    {error !== '' && (
                        <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                            <Alert severity="error" style={{width: "450px", marginBottom: "25px"}}>{error}</Alert>
                        </Grid>
                    )}
                    <form className={classes.form} noValidate>
                        <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                            <TextField
                                variant="outlined"
                                margin="normal"
                                label="Code"
                                type="text"
                                autoFocus
                                autoComplete="off"
                                value={user.authCode}
                                style={{
                                    backgroundColor: "#F5F8FA",
                                    width: "450px"
                                }}
                                inputProps={{style: ChromeAutoFillOverride}}
                                onChange={(e) => handleInputChange(e, 'authCode')}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                type="password"
                                value={user.new_password}
                                label="New Password"
                                style={{
                                    backgroundColor: "#F5F8FA",
                                    width: "450px"
                                }}
                                inputProps={{style: ChromeAutoFillOverride}}
                                onChange={(e) => handleInputChange(e, 'new_password')}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                style={{marginTop: "50px", textTransform: "none", padding: "0px"}}
                                onClick={() => forgotPasswordSubmit()}
                            >
                                    <span>
                                        <Grid container direction="row" justifyContent="space-evenly"
                                              alignItems="center">
                                            <p>Reset Password</p> <ArrowForwardIcon
                                            style={{marginLeft: "10px", fontSize: "20px"}}/>
                                        </Grid>
                                    </span>
                            </Button>
                            <Button style={transparent_button} onClick={() => {setStep(0); setError('')}}>
                                Already on Index Pro? Sign in
                            </Button>
                        </Grid>
                    </form>

                </div>
            </Grid>
        </Grid>
        }
        </div>
    );
}

export default SignIn