import React, {useEffect, useState} from "react";
import {Grid, Typography, Paper, Button, alpha} from "@material-ui/core";
import {observer} from "mobx-react-lite";
import {
    control_paper_left,
    big_paper,
    card_title,
    card_subtitle,
    bordered_button,
    card_subtitle_black, themeColor, simple_text_bold, simple_text, noborder_button, transparent_button,
    ipRed, themeBackground, ipLogo, TrackingSlider
} from "../../Styles/styles";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Chart from "react-apexcharts";
import {Autocomplete} from "@material-ui/lab";
import {useAllocationModel, useBenchmarkCode, useESGValueData} from "../Hooks/Data/Builder/builder";
import {redux} from "../../Utils/data_utils";

const marks = [
    {
        value: 0.5,
        label: '0.5%',
    },
    {
        value: 1,
        label: '1.0%',
    },
    {
        value: 1.5,
        label: '1.5%',
    },
    {
        value: 2,
        label: '2.0%',
    },
    {
        value: 2.5,
        label: '2.5%',
    },
    {
        value: 3,
        label: '3.0%',
    }
];

function PerformanceComp({builder_state}) {

    const tracking_error = builder_state.tracking_error
    const alpha_group = builder_state.alpha_group
    const rebalance_frequency = builder_state.rebalance_frequency
    const cash_position_size = builder_state.cash_position_size
    const sector_constraints = builder_state.sector_constraints
    const industry_constraints = builder_state.industry_constraints
    const max_position_count = builder_state.max_position_count
    const security_exclusions = builder_state.security_exclusions
    const allocation_model_id = builder_state.allocation_model_id
    const benchmark_name = builder_state.benchmark_name

    const allocation_model = useAllocationModel(allocation_model_id)
    const benchmark_code = useBenchmarkCode(benchmark_name, allocation_model_id)
    const esg_value_data = useESGValueData(benchmark_code, allocation_model_id, allocation_model)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    const handleAlphaGroupSelect = (name) => {
        if (name === alpha_group) {
            builder_state.setAlphaGroup(null)
        } else {
            builder_state.setAlphaGroup(name);
        }
    }

    const options = {
        chart: {
            fontFamily: "Poppins",
            height: 350,
            type: 'radialBar'
        },
        plotOptions: {
            radialBar: {
                startAngle: -90,
                endAngle: 90,
                hollow: {
                    margin: 0,
                    size: '65%',
                    position: 'front',
                },
                dataLabels: {
                    show: false,
                    name: {
                        show: false,
                    },
                    value: {
                        fontSize: "45px",
                        fontFamily: "Poppins",
                        show: true,
                    }
                }
            }
        },
        colors: [themeColor],
        labels: [''],
    }

    const get_alpha_group_data = (alpha_group) => {
        if (alpha_group === 'Private Label') {
            return {
                'value': 100, 'description': 'Bottom-up stock insights can make your values, thematic, ' +
                    'and tax choices even more effective. This selection will include Index Pro\'s ' +
                    'proprietary Alpha Signals in the portfolio optimization.'
            }
        } else {
            return {
                'value': 0, 'description': 'Bottom-up stock insights can make your values, thematic, ' +
                    'and tax choices even more effective. Selecting the Private Label alpha model will include Index Pro\'s ' +
                    'proprietary Alpha Signals in the portfolio optimization.'
            }
        }
    }

    const handleCashBlur = (e) => {
        if (parseFloat(e.target.value) < 0.25 || e.target.value === null || e.target.value.trim() === "") {
            builder_state.setCashPositionSize(0.25);
        }
    };

    const handleSectorBlur = (e) => {
        if (parseFloat(e.target.value) < 1 || e.target.value === null || e.target.value.trim() === "") {
            builder_state.setSectorConstraints(5);
        }
    };

    const handleIndustryBlur = (e) => {
        if (parseFloat(e.target.value) < 1 || e.target.value === null || e.target.value.trim() === "") {
            builder_state.setIndustryConstraints(5);
        }
    };

    return (
        <div>
            <Paper style={big_paper} elevation={0} square>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <div>
                        <p style={card_title}>Performance</p>
                        <p style={card_subtitle}>Choose a performance group</p>
                    </div>
                </Grid>

                <Grid container direction="row" justifyContent="space-between">
                    <div style={{paddingBottom: "20px", marginLeft: "20px"}}>
                        <p>IndexPro Alpha Model</p>
                        <Paper style={{
                            width: "600px",
                            height: "100%",
                            border: `1px solid ${alpha_group ? (themeColor) : ("#dadce0")}`,
                            marginTop: "0px",
                            background: `${alpha_group ? (themeBackground) : ("white")}`
                        }} elevation={0}>
                            <Grid container justifyContent="space-between" direction="row" style={{marginTop: "10px"}}>
                                <img src={ipLogo} style={{
                                    maxWidth: "300px",
                                    maxHeight: "50px",
                                    marginTop: "20px",
                                    marginRight: "20px",
                                    marginLeft: "20px"
                                }}/>
                            </Grid>
                            <Grid container direction="row" style={{marginLeft: "20px"}}>
                                {['Private Label'].map(i => {
                                    if (i === alpha_group) {
                                        return (
                                            <Paper style={{
                                                height: "80px",
                                                width: "150px",
                                                border: `1px solid ${themeColor}`,
                                                marginTop: "20px",
                                                marginRight: "20px",
                                                cursor: "pointer"
                                            }} elevation={0} onClick={() => handleAlphaGroupSelect(i)}>
                                                <div style={{
                                                    borderRadius: "50%",
                                                    height: "15px",
                                                    width: "15px",
                                                    position: "relative",
                                                    left: "125px",
                                                    top: "8px",
                                                }}><CheckCircleIcon style={{fontSize: "18px", color: themeColor}}/>
                                                </div>
                                                <p style={{
                                                    marginLeft: "20px",
                                                    marginTop: "5px",
                                                    fontWeight: "600",
                                                    marginBottom: "0px",
                                                }}>{i}</p>
                                            </Paper>
                                        )
                                    } else {
                                        return (
                                            <Paper style={{
                                                height: "80px",
                                                width: "150px",
                                                border: "1px solid #dadce0",
                                                marginTop: "20px",
                                                marginRight: "20px",
                                                cursor: "pointer",
                                            }} elevation={0} onClick={() => handleAlphaGroupSelect(i)}>
                                                <div style={{
                                                    borderRadius: "50%",
                                                    height: "15px",
                                                    width: "15px",
                                                    border: "1px solid #dadce0",
                                                    position: "relative",
                                                    left: "125px",
                                                    top: "8px"
                                                }}/>
                                                <p style={{
                                                    marginLeft: "20px",
                                                    marginTop: "5px",
                                                    fontWeight: "600",
                                                    marginBottom: "0px"
                                                }}>{i}</p>
                                            </Paper>
                                        )
                                    }
                                })}
                            </Grid>
                        </Paper>
                    </div>
                    <div style={{paddingBottom: "20px", marginLeft: "20px", marginRight: "20px", marginTop: "45px"}}>
                        <Paper style={{
                            width: "600px",
                            height: "100%",
                        }} elevation={0}>
                            <Chart
                                options={options}
                                series=
                                    {[get_alpha_group_data(alpha_group)['value']]}
                                type="radialBar" height="250px"/>
                            {[get_alpha_group_data(alpha_group)['description']]}
                        </Paper>
                    </div>
                </Grid>
            </Paper>
            <div style={{marginTop: "30px"}}/>
            <Paper style={big_paper} elevation={0} square>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <div>
                        <p style={card_title}>Risk</p>
                        <p style={card_subtitle}>Adjust risk settings</p>
                    </div>
                </Grid>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center"
                      style={{borderTop: "1px solid #CCCCCC", marginLeft: "20px", width: "1238px", marginTop: "20px"}}>
                    <div style={{marginTop: "20px"}}>
                        <p style={simple_text_bold}>Tracking Error</p>
                        <p style={{width: "300px"}}>Desired deviation from benchmark</p>
                    </div>
                    <div style={{marginTop: "20px", marginLeft: "25px"}}>
                        <TrackingSlider
                            defaultValue={tracking_error}
                            valueLabelDisplay="off"
                            marks={marks}
                            step={null}
                            min={0}
                            max={3}
                            onChange={(e, val) => builder_state.setTrackingError(val)}
                        />
                    </div>
                </Grid>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center"
                      style={{borderTop: "1px solid #CCCCCC", marginLeft: "20px", width: "1238px", marginTop: "20px"}}>
                    <div style={{marginTop: "20px"}}>
                        <p style={simple_text_bold}>Rebalance Frequency</p>
                        <p style={{width: "300px"}}>Default frequency of trading</p>
                    </div>
                    <RadioGroup row aria-label="position" name="position" defaultValue={rebalance_frequency}
                                style={{marginTop: "20px"}}
                                onChange={(e) => builder_state.setRebalanceFrequency(e.target.value)}>
                        <FormControlLabel
                            value="D"
                            control={<Radio color="primary"/>}
                            label={<p style={{fontSize: "14px", marginBottom: "0px"}}>Daily</p>}
                            labelPlacement="top"
                        />
                        <FormControlLabel
                            value="W"
                            control={<Radio color="primary"/>}
                            label={<p style={{fontSize: "14px", marginBottom: "0px"}}>Weekly</p>}
                            labelPlacement="top"
                        />
                        <FormControlLabel
                            value="M"
                            control={<Radio color="primary"/>}
                            label={<p style={{fontSize: "14px", marginBottom: "0px"}}>Monthly</p>}
                            labelPlacement="top"
                        />
                        <FormControlLabel
                            value="Q"
                            control={<Radio color="primary"/>}
                            label={<p style={{fontSize: "14px", marginBottom: "0px"}}>Quarterly</p>}
                            labelPlacement="top"
                        />
                    </RadioGroup>
                </Grid>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center"
                      style={{borderTop: "1px solid #CCCCCC", marginLeft: "20px", width: "1238px", marginTop: "20px"}}>
                    <div style={{marginTop: "20px"}}>
                        <p style={simple_text_bold}>Cash Position Size</p>
                        <p style={{width: "300px"}}>Minimum allocation to cash</p>
                    </div>
                    <div style={{marginTop: "20px", marginLeft: "20px"}}>
                        <TextField
                            style={{
                                backgroundColor: "#F5F8FA",
                                width: "80px",
                            }}

                            defaultValue={cash_position_size}
                            value={cash_position_size}
                            id="filled-start-adornment"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>
                            }}
                            variant="outlined"
                            onChange={(e) => builder_state.setCashPositionSize(e.target.value)}
                            onBlur={handleCashBlur}
                        />
                    </div>
                </Grid>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center"
                      style={{borderTop: "1px solid #CCCCCC", marginLeft: "20px", width: "1238px", marginTop: "20px"}}>
                    <div style={{marginTop: "20px"}}>
                        <p style={simple_text_bold}>Max Position Count</p>
                        <p style={{width: "300px"}}>Limit the max number of issuers</p>
                    </div>
                    <div style={{marginTop: "20px", marginLeft: "20px"}}>
                        <TextField
                            style={{
                                backgroundColor: "#F5F8FA",
                                width: "110px",
                            }}
                            defaultValue={max_position_count}
                            variant="outlined"
                            onChange={(e) => builder_state.setMaxPositionCount(e.target.value)}
                        />
                    </div>
                    {max_position_count < 100 && max_position_count !== '' ? (
                        <div style={{marginTop: "20px", marginLeft: "80px", color: ipRed, width: "600px"}}>
                            Specifying a maximum number of assets will likely conflict with tracking error
                            and tax harvesting objectives. We do not recommend setting this constraint.
                        </div>
                    ) : (
                        <></>
                    )}

                </Grid>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center"
                      style={{borderTop: "1px solid #CCCCCC", marginLeft: "20px", width: "1238px", marginTop: "20px"}}>
                    <div style={{marginTop: "20px"}}>
                        <p style={simple_text_bold}>Sector Constraints</p>
                        <p style={{width: "300px"}}>Limit on sector exposure relative to the benchmark</p>
                    </div>
                    <div style={{marginTop: "20px", marginLeft: "20px"}}>
                        <TextField
                            style={{
                                backgroundColor: "#F5F8FA",
                                width: "110px",
                            }}
                            defaultValue={sector_constraints}
                            value={sector_constraints}
                            id="filled-start-adornment"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                startAdornment: <InputAdornment position="start">+/-</InputAdornment>
                            }}
                            variant="outlined"
                            onChange={(e) => builder_state.setSectorConstraints(e.target.value)}
                            onBlur={handleSectorBlur}
                        />
                    </div>
                </Grid>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center"
                      style={{borderTop: "1px solid #CCCCCC", marginLeft: "20px", width: "1238px", marginTop: "20px"}}>
                    <div style={{marginTop: "20px"}}>
                        <p style={simple_text_bold}>Industry Constraints</p>
                        <p style={{width: "300px"}}>Limit on industry exposure relative to the benchmark</p>
                    </div>
                    <div style={{marginTop: "20px", marginLeft: "20px"}}>
                        <TextField
                            style={{
                                backgroundColor: "#F5F8FA",
                                width: "110px",
                            }}
                            defaultValue={industry_constraints}
                            value={industry_constraints}
                            id="filled-start-adornment"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                startAdornment: <InputAdornment position="start">+/-</InputAdornment>
                            }}
                            variant="outlined"
                            onChange={(e) => builder_state.setIndustryConstraints(e.target.value)}
                            onBlur={handleIndustryBlur}
                        />
                    </div>
                </Grid>
            </Paper>

            <div style={{marginTop: "30px"}}/>

            <Paper style={big_paper} elevation={0} square>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <div>
                        <p style={card_title}>Individual Security Exclusions</p>
                        <p style={card_subtitle}>Exclude individual securities</p>
                    </div>
                </Grid>
                <div style={{marginLeft: "20px", marginTop: "20px"}}>
                    <Autocomplete
                        autoHighlight
                        multiple
                        defaultValue={security_exclusions}
                        filterOptions={(options, {inputValue}) => options.filter(item => item.company_name.toLowerCase().includes(inputValue.toLowerCase())
                            || item.ticker.toLowerCase().includes(inputValue.toLowerCase()) ||
                            item.sedol.toLowerCase().includes(inputValue.toLowerCase()))}
                        options={[...new Map(redux(esg_value_data, ['company_name', 'ticker', 'weight', 'gics_1', 'sedol']).map((item) => [item["company_name"], item])).values()].sort((a, b) => b.weight - a.weight)}
                        getOptionLabel={(option) => option.company_name}
                        getOptionSelected={(option, value) => option.company_name === value.company_name}
                        renderOption={(option) => (
                            <Grid container direction="row" justifyContent="space-between"
                                  alignItems="center">
                                <Grid item xs={10}>
                                    {option.company_name}
                                    <p style={{
                                        fontSize: "10px",
                                        fontWeight: "600",
                                        marginTop: "0px",
                                        marginBottom: "0px"
                                    }}>{option.ticker} | {option.sedol}</p>
                                </Grid>
                                <Grid item xs={2}>
                                    {(option.weight * 100).toFixed(2)}%
                                </Grid>
                            </Grid>
                        )}
                        renderInput={(params) => <TextField {...params}
                                                            style={{zIndex: "0"}}
                                                            label="Search for company names, tickers, or sedols"
                                                            variant="outlined"/>}
                        style={{width: "420px"}}
                        onChange={(option, value) => builder_state.setSecurityExclusions(value)}
                    >
                    </Autocomplete>
                </div>
            </Paper>
        </div>
    )
}

export default observer(PerformanceComp);