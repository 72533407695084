import {Button, Grid, Paper, TextField, Tooltip} from "@material-ui/core";
import {
    big_paper,
    card_description,
    card_subtitle,
    card_title,
    bordered_button,
    themeBackground, ChromeAutoFillOverride
} from "../../Styles/styles";
import {percentage, pos_neg_dollar_card_description} from "../../Utils/num_utils";
import InputAdornment from "@material-ui/core/InputAdornment";
import Lottie from "react-lottie";
import React from "react";
import optimizing from "../../Styles/optimizing.json";
import Proposal from "../Proposal/proposal";
import Fab from "@material-ui/core/Fab";
import ClearIcon from "@material-ui/icons/Clear";
import {Alert} from "@material-ui/lab";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Mixpanel} from "../../Utils/Mixpanel";
import Modal from "@material-ui/core/Modal";
import {useCustomStrategies} from "../Hooks/Data/Strategies/strategies";
import {DataStore} from "@aws-amplify/datastore";
import {Account} from "../../models";

function AccountTransition(props) {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: optimizing,
    };

    const account_transition_data = props.account.transition_data

    const [longTerm, setLongTerm] = React.useState(15);
    const [shortTerm, setShortTerm] = React.useState(35);

    const customStrategyItems = useCustomStrategies();
    const [account_selected_strategy, setAccountStrategy] = React.useState(null)
    const [accountBudget, setAccountBudget] = React.useState(0)
    const [open, setOpen] = React.useState(false)
    const [openTaxBudget, setOpenTaxBudget] = React.useState(false)

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenTaxBudget = () => {
        setOpenTaxBudget(true);
    };
    const handleCloseTaxBudget = () => {
        setOpenTaxBudget(false);
    };

    const handleSaveStrategy = async () => {
        await DataStore.save(Account.copyOf(props.account, item => {
            item["custom_strategy_id"] = customStrategyItems.filter(x => x.strategy_name === account_selected_strategy)[0]["id"]
            item["transition_data"] = null
        }));
        handleClose();
    }

    const handleSaveTaxBudget = async () => {
        await DataStore.save(Account.copyOf(props.account, item => {
            const originalObj = item.transition
            item["transition"] = {
                ...originalObj,
                'tax_budget': (accountBudget === '' || accountBudget === null) ? (0) : parseFloat(accountBudget),
            };
            item["transition_data"] = null
        }));
        handleCloseTaxBudget();
    }

    return (
        <div className="Transition">
            <Paper style={Object.assign({}, big_paper, {paddingBottom: "20px"})} elevation={0}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <div>
                        <p style={card_title}>Transition Overview {'tax_budget' in props.account.transition ? ('(' + props.account.transition.tax_budget + '% Annual Capital Gains Budget)') : ('')}</p>
                        <p style={card_subtitle}>View a transition plan based on the current holdings to your
                            target portfolio</p>
                    </div>

                    <div>
                        {props.strategy && props.account.transition_data!==null ? (
                            <Grid container direction="row">
                                <Button style={bordered_button} onClick={handleOpen}>Change Strategy</Button>
                                <Button style={Object.assign({}, bordered_button, {marginLeft: "20px"})} onClick={handleOpenTaxBudget}>Change Tax Budget</Button>
                                <Proposal proposal_data={props.strategy.proposal_data} strategy_id={props.strategy.id} transition_data={account_transition_data} tax={{long_term:longTerm, short_term:shortTerm}}/>
                            </Grid>
                        ) : (
                            <></>
                        )}
                    </div>

                    {account_transition_data ? (
                        <>
                            <Grid container direction="row" justifyContent="space-between"
                                  alignItems="flex-start" style={{marginRight: "20px", marginTop: "20px"}}>
                                <Grid item>
                                    <Grid container direction="column">
                                        <div>
                                            <Tooltip title={<p style={{fontSize: "12px"}}>For transitions, max position
                                                count may be violated to achieve optimal tax objectives</p>}
                                                     placement="top-start" arrow>
                                                <p style={card_title}># of Positions</p>
                                            </Tooltip>
                                            <p style={card_description}>{account_transition_data['transition_overview']['input_num_positions']} ➝ {account_transition_data['transition_overview']['target_num_positions']}</p>
                                        </div>
                                        <div>
                                            <p style={card_title}>Tracking Error</p>
                                            <p style={card_description}>{account_transition_data['transition_overview']['input_tracking_error']} ➝ {account_transition_data['transition_overview']['target_tracking_error']}</p>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="column">
                                    <div>
                                        <p style={card_title}>Long Term Realized Gains</p>
                                        <Grid container direction="row">
                                            {pos_neg_dollar_card_description((account_transition_data['transition_overview']['realized_pl_lt']).toFixed(0))}
                                            <p style={card_description}>({percentage((account_transition_data['transition_overview']['realized_pl_lt_pct']).toFixed(2))})</p>
                                        </Grid>
                                    </div>
                                        <div>
                                            <p style={card_title}>Short Term Realized Gains</p>
                                            <Grid container direction="row">
                                                {pos_neg_dollar_card_description((account_transition_data['transition_overview']['realized_pl_st']).toFixed(0))}
                                                <p style={card_description}>({percentage((account_transition_data['transition_overview']['realized_pl_st_pct']).toFixed(2))})</p>
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="column">
                                    <div>
                                        <p style={card_title}>Long Term Tax Rate</p>
                                        <TextField
                                            style={{marginLeft: "20px", marginTop: "0px", width: "70px"}}
                                            type="number"
                                            value={longTerm}
                                            onChange={(e) => {
                                                e.preventDefault();
                                                var value = parseInt(e.target.value, 10);
                                                if (value > 100) value = 100;
                                                if (value < 0) value = 0;
                                                setLongTerm(value)
                                                if (e.target.value === '') setLongTerm(15);
                                            }
                                            }
                                            InputProps={{
                                                endAdornment: <InputAdornment
                                                    position="start">%</InputAdornment>,
                                                inputProps: {min: 0, max: 100}
                                            }}
                                        />
                                    </div>
                                        <div style={{marginTop: "12px"}}>
                                            <p style={card_title}>Short Term Tax Rate</p>
                                            <TextField
                                                style={{marginLeft: "20px", marginTop: "0px", width: "70px"}}
                                                type="number"
                                                value={shortTerm}
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    var value = parseInt(e.target.value, 10);
                                                    if (value > 100) value = 100;
                                                    if (value < 0) value = 0;
                                                    setShortTerm(value);
                                                    if (e.target.value === '') setShortTerm(35);
                                                }
                                                }
                                                InputProps={{
                                                    endAdornment: <InputAdornment
                                                        position="start">%</InputAdornment>,
                                                    inputProps: {min: 0, max: 100}
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="column">
                                        <div>
                                            <p style={card_title}>Long Term
                                                Taxes {account_transition_data['transition_overview']['realized_pl_lt'] >= 0 ? ("") : ("Harvested")}</p>
                                            <Grid container direction="row">
                                                {pos_neg_dollar_card_description((-1 * ((longTerm / 100) * account_transition_data['transition_overview']['realized_pl_lt'])).toFixed(0))}
                                                <p style={card_description}>({percentage((-1 * ((longTerm / 100) * account_transition_data['transition_overview']['realized_pl_lt_pct'])).toFixed(2))})</p>
                                            </Grid>
                                        </div>
                                        <div>
                                            <p style={card_title}>Short Term
                                                Taxes {account_transition_data['transition_overview']['realized_pl_st'] >= 0 ? ("") : ("Harvested")}</p>
                                            <Grid container direction="row">
                                                {pos_neg_dollar_card_description((-1 * ((shortTerm / 100) * account_transition_data['transition_overview']['realized_pl_st'])).toFixed(0))}
                                                <p style={card_description}>({percentage((-1 * ((shortTerm / 100) * account_transition_data['transition_overview']['realized_pl_st_pct'])).toFixed(2))})</p>
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        account_transition_data ? (
                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                <Lottie options={defaultOptions} height={250} width={250}/>
                            </Grid>
                        ) : (
                            <></>
                        )
                    )}
                </Grid>
            </Paper>
            <Modal open={open}>
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Paper elevation={0} style={{
                        width: "600px", height: "340px", marginTop: "300px"
                    }}>
                        <Grid container direction="row">
                            <Grid item xs={10} style={{paddingTop: "25px"}}>
                                <p style={card_title}>Change Strategy</p>
                            </Grid>
                            <Grid item xs={2} style={{paddingRight: "20px", paddingTop: "20px"}}>
                                <Fab onClick={handleClose} style={{float: "right"}}>
                                    <ClearIcon/>
                                </Fab>
                            </Grid>
                            <div style={{
                                width: "100%",
                                marginTop: "30px",
                                marginBottom: "10px",
                                borderBottom: "2px solid #dadce0"
                            }}/>
                            <Grid item xs={12} style={{marginLeft: "20px", marginRight: "20px"}}>
                                <Alert severity="info">Select a new strategy for your account or create one <a
                                    href="/participant/builder">here</a>!</Alert>
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={customStrategyItems.map(function (el) {
                                        return el.strategy_name;
                                    })}
                                    style={{
                                        width: "560px",
                                        marginLeft: "20px",
                                    }}
                                    margin="normal"
                                    onChange={(option) => setAccountStrategy(option.target.innerText)}
                                    value={account_selected_strategy}
                                    renderInput={(params) => <TextField
                                        style={{
                                            backgroundColor: themeBackground,
                                        }}
                                        margin="normal"

                                        {...params} label="Strategy Name" variant="outlined"
                                    />}
                                />
                            </Grid>
                            <Grid item xs={12} style={{marginLeft: "430px", marginTop: "10px"}}>
                                <Button style={Object.assign({}, bordered_button, {width: "150px"})}
                                        onClick={() => {
                                            Mixpanel.track('Changed Strategy on Account');
                                            window.Intercom('trackEvent', 'changed-strategy-on-account');
                                            handleSaveStrategy()
                                        }}
                                >Save Strategy</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </Modal>
            <Modal open={openTaxBudget}>
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Paper elevation={0} style={{
                        width: "400px", height: "330px", marginTop: "300px"
                    }}>
                        <Grid container direction="row">
                            <Grid item xs={10} style={{paddingTop: "25px"}}>
                                <p style={card_title}>Change Tax Budget</p>
                            </Grid>
                            <Grid item xs={2} style={{paddingRight: "20px", paddingTop: "20px"}}>
                                <Fab onClick={handleCloseTaxBudget} style={{float: "right"}}>
                                    <ClearIcon/>
                                </Fab>
                            </Grid>
                            <div style={{
                                width: "100%",
                                marginTop: "30px",
                                marginBottom: "10px",
                                borderBottom: "2px solid #dadce0"
                            }}/>
                            <Grid container justifyContent="center" direction="column"
                                  alignItems="center" style={{marginTop: "10px"}}>
                                <p>Yearly Long Term Capital Gains Budget (% MV)</p>
                                <TextField
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: <InputAdornment
                                            position="end">%</InputAdornment>
                                    }}
                                    type="number"
                                    autoFocus
                                    value={accountBudget}
                                    onChange={(e) => {
                                        let value = e.target.value;
                                        if (value < -5) value = -5;
                                        if (value > 100) value = 100;
                                        setAccountBudget(value);
                                    }}
                                    style={{
                                        backgroundColor: "#F5F8FA",
                                        width: "100px",
                                        marginTop: "10px",
                                        marginBottom: "10px"
                                    }}
                                    inputProps={{
                                        min: -5,
                                        max: 100,
                                        style: ChromeAutoFillOverride,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} style={{marginLeft: "125px", marginTop: "10px"}}>
                                <Button style={Object.assign({}, bordered_button, {width: "150px"})}
                                        onClick={() => {
                                            Mixpanel.track('Changed Tax Budget on Account');
                                            window.Intercom('trackEvent', 'changed-tax-budget-on-account');
                                            handleSaveTaxBudget()
                                        }}
                                >Save Tax Budget</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </Modal>
        </div>
    )
}

export default AccountTransition