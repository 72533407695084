import React, { useState, useEffect }  from 'react';
import { Redirect, Route } from "react-router-dom";
import { Auth } from 'aws-amplify';

const PrivateRoute = ({ children, ...rest }) => {
    const [signInUser, setSignInUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let getUser = async() => {
            try {
                let user = await Auth.currentAuthenticatedUser();
                await setSignInUser(user);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.error(error)
            }
        }
        getUser();
    },[]);

    if(isLoading) {
        return <></>
    }
    return (
        <Route {...rest} render={({ location }) => {
            return signInUser? children
                : <Redirect to={{
                    pathname: '/signin',
                    state: { from: location }
                }} />
        }} />
    )
}

export default PrivateRoute;