import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite"
import './strategies.css'
import {
    big_paper, card_subtitle, card_title,
    HeaderCell, themeColor, ValueCell,
    card_description, paper_left, paper_right,
    IPTableRow, ipGreen, ipRed,
    bordered_button, account_preferences_icon, card_subtitle_black,
    card_subtitle_grey, values_icon, exclusions_icon, revenue_icon,
    themes_icon, tax_icon, alpha_icon, risk_icon, color_filters, benchmark_icon, SpecialCell, security_exclusion_icon
} from "../../Styles/styles";
import {Button, Grid, Paper} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Chart from "react-apexcharts";
import {useHistory, useParams} from "react-router-dom";
import {useStrategyBenchmarkStyle} from "../Hooks/Styles/GraphStyles/graph-styles";
import Proposal from "../Proposal/proposal";
import Lottie from "react-lottie";
import optimizing from "../../Styles/optimizing.json"
import {DataStore} from "@aws-amplify/datastore";
import {CustomStrategies, Strategies} from "../../models";
import {Storage} from "aws-amplify";
import GetAppIcon from '@material-ui/icons/GetApp';
import {useStrategy} from "../Hooks/Data/Strategies/strategies";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Mixpanel} from "../../Utils/Mixpanel";
import {useAllocationModel} from "../Hooks/Data/Builder/builder";
import LinearProgress from '@material-ui/core/LinearProgress';
import {formatDatetimeToDate} from "../../Utils/date_utils";

//strategy info component
function StrategyInfo({builder_state}) {
    const history = useHistory();
    const {type,id} = useParams();
    const strategySelected = useStrategy(type, id)
    const allocation_model = useAllocationModel(strategySelected && type==='custom' ? (strategySelected.settings.allocation_model_id) : null)

    useEffect(() => {
        Mixpanel.track('Viewed Strategy', {'strategy_id': id});
    }, [])

    const strategy_chart_style = useStrategyBenchmarkStyle(
        strategySelected ?
            (
                strategySelected.data ? (
                    strategySelected.data['daily_returns']['datadate']
                ) : (
                    []
                )
            ) :
            (
                []
            )
    )

    const monthlyPerformance = (value) => {
        if (parseFloat(value) >= 0) {
            return (
                <ValueCell align="left">
                    <p style={{
                        padding: "0px",
                        margin: "0px",
                        color: ipGreen
                    }}>{value}</p>
                </ValueCell>)
        } else {
            return (
                <ValueCell align="left">
                    <p style={{
                        padding: "0px",
                        margin: "0px",
                        color: ipRed
                    }}>{value}</p>
                </ValueCell>
            )
        }
    }

    function downloadBlob(blob, filename) {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename || 'download';
        const clickHandler = () => {
            setTimeout(() => {
                URL.revokeObjectURL(url);
                a.removeEventListener('click', clickHandler);
            }, 150);
        };
        a.addEventListener('click', clickHandler, false);
        a.click();
        return a;
    }

    async function download(id, filename) {
        Mixpanel.track('Downloaded Strategy Holdings');
        const result = await Storage.get(`strategy_holdings/${id}.csv`, { download: true, level: "private", cacheControl: 'no-cache'});
        downloadBlob(result.Body, filename + '.csv');
    }

    async function downloadDebug(id, filename) {
        const result = await Storage.get(`strategy_debug/${id}.csv`, { download: true, level: "private", cacheControl: 'no-cache'});
        downloadBlob(result.Body, filename + '.csv');
    }

    const changeStrategySettings = () => {
        const settings = strategySelected.settings

        const core = settings['values'].filter(r => ['Environmental', 'Social', 'Governance'].includes(r))
        const custom = settings['values'].filter((el) => !core.includes(el));

        //benchmark
        builder_state.setBenchmarkName(settings['benchmark'])
        builder_state.setAllocationModelId(settings['allocation_model_id'])
        //values page
        core.map(i => builder_state.setCoreOptions(i))
        custom.map(i => builder_state.setCustomOptions(i))
        settings['themes'].map(i => builder_state.setThemes(i))
        settings['revenue_any_exclusions'].map(i => builder_state.setAnyThreshold(i))
        settings['revenue_5pct_exclusions'].map(i => builder_state.set5pctThreshold(i.split(' 5%')[0]))
        settings['custom_exclusions'].map(i => builder_state.setCustomExclusions(i))

        //tax
        builder_state.setTransitionManagement(settings['transition_management'])
        builder_state.setTaxPreference(settings['tax_preference'])
        builder_state.setApplyTaxPreferences(settings['tax'])

        //risk
        builder_state.setAlphaGroup(settings['alpha'])
        builder_state.setIndustryConstraints(settings['industry_constraints'])
        builder_state.setSectorConstraints(settings['sector_constraints'])
        builder_state.setMaxPositionCount(settings['max_position_count'])
        builder_state.setTrackingError(settings['tracking_error'])
        builder_state.setRebalanceFrequency(settings['rebalance_frequency'])
        builder_state.setCashPositionSize(settings['cash_position_size'])
        builder_state.setSecurityExclusions(settings['security_exclusions'])

        history.push(`/participant/builder`)
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: optimizing,
    };


    if (typeof strategySelected === "undefined" || strategySelected === null) {
        return (
            <div>
                Loading...
            </div>
        )
    } else if (strategySelected && type === 'custom') {
        const proposalData = strategySelected.proposal_data
        return (
            <div className="CustomStrategiesDescriptionContainer" style={{paddingBottom: "60px"}}>
                <div className="Strategy-Title">
                    <Grid direction="row" container alignItems="center" justifyContent="space-between"
                          style={{width: "1278px", margin: "0 auto"}}>
                        <a onClick={() => {history.push('/participant/strategies')}}
                           style={{textDecoration: "none", cursor: "pointer"}}>
                            <p style={{
                                paddingBottom: "0px",
                                color: themeColor,
                                fontSize: "18px",
                                margin: "0px"
                            }}>Strategies > <span style={{color: "black"}}>{strategySelected.strategy_name}</span>
                            </p>
                        </a>
                            <p style={{
                                paddingBottom: "0px",
                                fontSize: "15px",
                                fontWeight: "500",
                                margin: "0px"
                            }}>Last Updated: {formatDatetimeToDate(strategySelected.updatedAt)}</p>
                    </Grid>
                </div>
                <div className="Strategy-Overview">
                    <div>
                    <Paper style={Object.assign({}, big_paper, {paddingBottom: "20px"})} elevation={0}>
                        {!proposalData ? (
                            <>
                                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                    <div>
                                        <p style={card_title}>Optimization Running</p>
                                        <p style={card_subtitle}>We'll send you an email once your strategy is available</p>
                                    </div>
                                </Grid>
                                <Grid container direction="row" justifyContent="center" alignItems="center">
                                    <Lottie options={defaultOptions} height={250} width={250}/>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                    <div>
                                        <p style={card_title}>Overview</p>
                                        <p style={card_subtitle}>Custom Strategy Statistics</p>
                                    </div>
                                    <div>
                                        <Proposal proposal_data={proposalData} strategy_id={strategySelected.id} transition_data={null} tax={null}/>
                                    </div>
                                    <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{marginRight: "20px", marginTop: "20px"}}>
                                            <Grid item>
                                                <p style={card_title}>Benchmark</p>
                                                <p style={card_description}>{proposalData['benchmark']}</p>
                                            </Grid>
                                        <Grid item>
                                            <p style={card_title}># of Positions</p>
                                            <p style={card_description}>{proposalData['portfolio_details'][0]['pro']}</p>
                                        </Grid>
                                        <Grid item>
                                            <p style={card_title}>Tracking Error</p>
                                            <p style={card_description}>{proposalData['tracking_error']}</p>
                                        </Grid>
                                        <Grid item>
                                            <p style={card_title}>Largest Weight</p>
                                            <p style={card_description}>{proposalData['portfolio_details'][1]['pro']}</p>
                                        </Grid>
                                        <Grid item>
                                            <p style={card_title}>Top 10 Weight</p>
                                            <p style={card_description}>{proposalData['portfolio_details'][2]['pro']}</p>
                                        </Grid>
                                            <Grid item>
                                                <p style={card_title}>Median Market Cap</p>
                                                <p style={card_description}>{proposalData['portfolio_details'][3]['pro']}</p>
                                            </Grid>
                                    </Grid>
                                </Grid>

                            </>
                        )}
                    </Paper>
                    </div>
                </div>
                <div className="Strategy-Description">
                    <div>
                    <Paper style={Object.assign({}, big_paper, {paddingBottom: "20px"})} elevation={0}>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <div>
                                <p style={card_title}>Strategy Preferences</p>
                                <p style={card_subtitle}>Special Client Considerations</p>
                            </div>
                            <div>
                                <Button style={Object.assign({}, bordered_button, {marginRight: "20px"})}
                                        onClick={() => changeStrategySettings()}>
                                    Change
                                </Button>
                            </div>
                        </Grid>
                        <Grid container direction="row" alignItems="flex-start">
                            <Grid item xs={6}>
                                <div style={account_preferences_icon}>
                                    <img
                                        src={benchmark_icon}
                                        alt="dashboard"
                                        width="30" height="30"
                                        style={{marginRight: "10px"}}
                                    />
                                    <p style={card_subtitle_black}>Benchmark</p>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <p style={card_subtitle_grey}>{strategySelected.settings['benchmark']}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={account_preferences_icon}>
                                    <img
                                        src={values_icon}
                                        alt="dashboard"
                                        width="30" height="30"
                                        style={{marginRight: "10px"}}
                                    />
                                    <p style={card_subtitle_black}>Values</p>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <p style={card_subtitle_grey}>{strategySelected.settings['values'].join(", ")}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={account_preferences_icon}>
                                    <img
                                        src={exclusions_icon}
                                        alt="dashboard"
                                        width="30" height="30"
                                        style={{marginRight: "10px"}}
                                    />
                                    <p style={card_subtitle_black}>Exclusions</p>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <p style={card_subtitle_grey}>{strategySelected.settings['custom_exclusions'].join(", ")}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={account_preferences_icon}>
                                    <img
                                        src={security_exclusion_icon}
                                        alt="dashboard"
                                        width="30" height="30"
                                        style={{marginRight: "10px"}}
                                    />
                                    <p style={card_subtitle_black}>Security Exclusions</p>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <p style={card_subtitle_grey}>{strategySelected.settings['security_exclusions']  ? (strategySelected.settings['security_exclusions'].map(i => i.company_name).join(", ")) : ('')}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={account_preferences_icon}>
                                    <img
                                        src={revenue_icon}
                                        alt="dashboard"
                                        width="30" height="30"
                                        style={{marginRight: "10px"}}
                                    />
                                    <p style={card_subtitle_black}>Revenue</p>
                                </div>
                            </Grid>

                            <Grid item xs={6}>
                                <p style={card_subtitle_grey}>{strategySelected.settings
                                    ['revenue_any_exclusions'].concat(strategySelected.settings['revenue_5pct_exclusions']).join(", ")}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={account_preferences_icon}>
                                    <img
                                        src={themes_icon}
                                        alt="dashboard"
                                        width="30" height="30"
                                        style={{marginRight: "10px"}}
                                    />
                                    <p style={card_subtitle_black}>Themes</p>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <p style={card_subtitle_grey}>{strategySelected.settings['themes'].join(", ")}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={account_preferences_icon}>
                                    <img
                                        src={tax_icon}
                                        alt="dashboard"
                                        width="30" height="30"
                                        style={{marginRight: "10px"}}
                                    />
                                    <p style={card_subtitle_black}>Tax</p>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <p style={card_subtitle_grey}>{strategySelected.settings['tax_preference'] ? (strategySelected.settings['tax_preference']) : ('No Tax Strategy')}
                                </p>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={account_preferences_icon}>
                                    <img
                                        src={alpha_icon}
                                        alt="dashboard"
                                        width="30" height="30"
                                        style={{marginRight: "10px"}}
                                    />
                                    <p style={card_subtitle_black}>Alpha</p>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <p style={card_subtitle_grey}>{strategySelected.settings['alpha'] ? (strategySelected.settings['alpha']) : ('No Alpha')}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={account_preferences_icon}>
                                    <img
                                        src={risk_icon}
                                        alt="dashboard"
                                        width="30" height="30"
                                        style={{marginRight: "10px"}}
                                    />
                                    <p style={card_subtitle_black}>Risk</p>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <p style={card_subtitle_grey}>Target Tracking
                                    Error: {strategySelected.settings['tracking_error']}%
                                    , Max Position
                                    Count: {strategySelected.settings['max_position_count'] ? (strategySelected.settings['max_position_count']) : ('None')}
                                    , Rebalance
                                    Frequency: {strategySelected.settings['rebalance_frequency']}</p>
                            </Grid>
                        </Grid>
                    </Paper>
                    </div>
                    <div>
                    {allocation_model && proposalData ? (
                        <Paper style={Object.assign({}, big_paper, {paddingBottom: "20px", marginTop: "30px"})} elevation={0}>
                            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                <div>
                                    <p style={card_title}>{proposalData['benchmark']}</p>
                                    <p style={card_subtitle}>Custom Allocation Model Breakdown</p>
                                </div>
                            </Grid>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <HeaderCell>Name</HeaderCell>
                                            <HeaderCell align="left">Symbol</HeaderCell>
                                            <HeaderCell align="left">Allocation</HeaderCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {JSON.parse(allocation_model.model[0]).sort((a, b) => b.pct - a.pct).map((row) => (
                                            <IPTableRow>
                                                <ValueCell>{row.type==='INDEX' ? (row.name) : (row.name.fund_name)}</ValueCell>
                                                <ValueCell>{row.type==='INDEX' ? (null) : (row.name.ticker)}</ValueCell>
                                                <SpecialCell align="left">
                                                    <Grid container direction="row" alignItems="center">
                                                        <p>{row.pct + '%'}</p>
                                                        <div style={{height: "20px", width: `${row.pct*10}px`, maxWidth: "400px", opacity:"50%", backgroundColor: themeColor, marginLeft: "20px", borderTopRightRadius: "3px", borderBottomRightRadius: "3px"}}/>
                                                    </Grid>
                                                </SpecialCell>
                                            </IPTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    ) : (
                        <></>
                    )}
                    </div>
                </div>
                <div className="Holdings">
                    <Paper style={paper_right} elevation={0}>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <div>
                            <p style={card_title}>Holdings</p>
                            <p style={card_subtitle}>Top 10 Holdings</p>
                        </div>
                            {proposalData ? (
                                <div>
                                    {JSON.parse(localStorage.getItem("signed-in-user")).attributes.email.startsWith("indexpro-admin") ? (
                                        <Button style={Object.assign({}, bordered_button, {width: "180px", height: "40px", marginRight: "20px"})}
                                                startIcon={<GetAppIcon />} onClick={() => downloadDebug(strategySelected.id, strategySelected.strategy_name)}>
                                            Download Debug
                                        </Button>
                                    ) : (<></>)}
                                    <Button
                                        startIcon={<GetAppIcon />}
                                        style={Object.assign({}, bordered_button, {marginRight: "20px"})}
                                            onClick={() => download(strategySelected.id, strategySelected.strategy_name)}>
                                        Download Holdings
                                    </Button>
                                </div>
                            ) : (<></>)
                            }
                        </Grid>
                        {proposalData ? (
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <HeaderCell>Name</HeaderCell>
                                                <HeaderCell align="left">Symbol</HeaderCell>
                                                <HeaderCell align="right">Weight</HeaderCell>
                                                <HeaderCell align="right">Weight vs. Index</HeaderCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {proposalData['top_10_positions'].map((row) => (
                                                <IPTableRow key={row.ticker}>
                                                    <ValueCell>{row.company_name}</ValueCell>
                                                    <ValueCell>{row.ticker}</ValueCell>
                                                    <ValueCell align="right">{row.weight}</ValueCell>
                                                    {parseFloat(row.weight_index) >= 0 ? (
                                                        <ValueCell align="right">
                                                            <p style={{
                                                                padding: "0px",
                                                                margin: "0px",
                                                                color: ipGreen
                                                            }}>{row.weight_index}</p>
                                                        </ValueCell>) : (
                                                        <ValueCell align="right">
                                                            <p style={{
                                                                padding: "0px",
                                                                margin: "0px",
                                                                color: ipRed
                                                            }}>{row.weight_index}</p>
                                                        </ValueCell>
                                                    )
                                                    }
                                                </IPTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )
                            :
                            (<></>)
                        }
                    </Paper>
                </div>
                <div className="Sector-Breakdown">
                    <Paper style={paper_left} elevation={0}>
                        <div>
                            <p style={card_title}>Sector Breakdown</p>
                            <p style={card_subtitle}>Sector vs. Benchmark</p>
                        </div>
                        {proposalData ? (
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <HeaderCell>Sector</HeaderCell>
                                            <HeaderCell align="right">{strategySelected.strategy_name}</HeaderCell>
                                            <HeaderCell align="right">{strategySelected.benchmark}</HeaderCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {proposalData['sector_breakdown'].map((row) => (
                                            <IPTableRow key={row.sector}>
                                                <ValueCell
                                                    style={{textTransform: "capitalize"}}>{row.sector.toLowerCase()}</ValueCell>
                                                <ValueCell align="right">{row.pro}</ValueCell>
                                                <ValueCell align="right">{row.benchmark}</ValueCell>
                                            </IPTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (<></>)
                        }
                    </Paper>
                </div>
                <div className="Impact-Summary">
                    <Paper style={Object.assign({}, big_paper, {paddingBottom: "20px"})}
                           elevation={0}>
                        <div>
                            <p style={card_title}>Impact Summary</p>
                            <p style={card_subtitle}>Impact Summary</p>
                        </div>
                        {strategySelected["proposal_data"] ? (
                            <div>
                                {strategySelected ? (
                                    <div>
                                        {strategySelected.settings["values"].length === 0 ? (
                                            <Grid container direction="row" justifyContent="center">
                                                <p style={{fontWeight: "600"}}>No Values Selected for Impact Summary</p>
                                            </Grid>
                                        ) : (
                                            <Grid container direction="row" justifyContent="flex-start"
                                                  alignItems="center" style={{marginLeft: "20px", marginRight: "20px"}}>
                                                {strategySelected["proposal_data"]["headers"].filter(item => ['Environmental', 'Social', 'Governance'].includes(item)).map(i => (
                                                    strategySelected["proposal_data"][i.toLowerCase()]['impact_data'].map((item, x) =>
                                                        <>
                                                            <Grid item md={1} style={{marginTop: "20px"}}>
                                                                <img
                                                                    src={`https://res.cloudinary.com/indexpro/image/upload/Impact Icons/${item.title}.svg`}
                                                                    height="50px"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        maxWidth: "95px",
                                                                        filter: color_filters[x]
                                                                    }}/>
                                                            </Grid>
                                                            <Grid item md={5} style={{marginTop: "20px"}}>
                                                                <p style={{
                                                                    fontSize: "15px",
                                                                    marginBottom: "0px",
                                                                    fontWeight: "600"
                                                                }}>{item.title}</p>
                                                                <p style={{
                                                                    fontSize: "15px",
                                                                    fontWeight: "500",
                                                                    marginTop: "0px",
                                                                    color: "#555555"
                                                                }}>{item.pct} improvement in MSCI {item.title} score</p>
                                                            </Grid>
                                                        </>
                                                    ))
                                                )}
                                            </Grid>
                                        )}
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        ) : (
                            <></>
                        )
                        }
                    </Paper>
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <div className="StrategiesDescriptionContainer">
                    <div className="Strategy-Title">
                        <Grid direction="row" container alignItems="center" justifyContent="space-between"
                              style={{width: "1278px", margin: "0 auto"}}>
                            <a onClick={() => history.push('/participant/strategies')}
                               style={{textDecoration: "none", cursor: "pointer"}}>
                                <p style={{
                                    paddingBottom: "0px",
                                    color: themeColor,
                                    fontSize: "18px",
                                    margin: "0px"
                                }}>Strategies > <span style={{color: "black"}}>{strategySelected.strategy_name}</span>
                                </p></a>
                            <p style={{
                                paddingBottom: "0px",
                                fontSize: "15px",
                                fontWeight: "500",
                                margin: "0px"
                            }}>Last Updated: {strategySelected.data.as_of_date}</p>
                        </Grid>
                    </div>
                    <div className="Strategy-Description">
                        <Paper style={Object.assign({}, big_paper, {paddingBottom: "20px"})}
                               elevation={0}>
                            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                <div>
                                    <p style={card_title}>Strategy Description</p>
                                    <p style={card_description}>
                                        The {strategySelected.strategy_name} illustrates the effectiveness of the alpha
                                        foundation
                                        and aims to generate consistent alpha versus
                                        the {strategySelected.benchmark} Index with a {strategySelected.alpha_source}.
                                        Atom Investors LP (“Atom”) serves as the investment manager to Index Pro.
                                        Atom is registered with the US Securities and Exchange Commission as an
                                        investment adviser under the Investment
                                        Advisers Act of 1940 as amended.</p>
                                </div>
                            </Grid>
                        </Paper>
                    </div>
                    <div className="Strategy-Overview">
                        <Paper style={Object.assign({}, big_paper, {paddingBottom: "20px"})}
                               elevation={0}>
                            <p style={card_title}>Overview</p>
                            <div style={{marginTop: "20px"}}>
                                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        <p style={card_title}>Benchmark</p>
                                        <p style={card_description}>{strategySelected.benchmark}</p>
                                    </Grid>
                                    <Grid item>
                                        <p style={card_title}>Number of Positions</p>
                                        <p style={card_description}>{strategySelected.data.position_count}</p>
                                    </Grid>
                                    <Grid item>
                                        <p style={card_title}>YTD Performance</p>
                                        <p style={card_description}>{strategySelected.data.ytd_performance}</p>
                                    </Grid>
                                    <Grid item>
                                        <p style={card_title}>Tracking Error</p>
                                        <p style={card_description}>{strategySelected.data.tracking_error}</p>
                                    </Grid>
                                    <Grid item>
                                        <p style={card_title}>ESG Focus</p>
                                        {strategySelected.values ? (
                                            <p style={Object.assign({}, card_description, {marginRight: "100px"})}>{strategySelected.values} </p>
                                        ) : (
                                            <p style={Object.assign({}, card_description, {marginRight: "100px"})}>-</p>
                                        )
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                    </div>
                    <div className="Holdings">
                        <Paper style={paper_right} elevation={0}>
                            <div>
                                <p style={card_title}>Holdings</p>
                                <p style={card_subtitle}>Top 10 Holdings</p>
                            </div>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <HeaderCell>Name</HeaderCell>
                                            <HeaderCell align="left">Symbol</HeaderCell>
                                            <HeaderCell align="right">Weight</HeaderCell>
                                            <HeaderCell align="right">Weight vs. Index</HeaderCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {strategySelected.data.top_10_holdings.map((row) => (
                                            <IPTableRow key={row.symbol}>
                                                <ValueCell>{row.company_name}</ValueCell>
                                                <ValueCell>{row.symbol}</ValueCell>
                                                <ValueCell align="right">{row.pct_port}</ValueCell>
                                                {parseFloat(row.weight_index) >= 0 ? (
                                                    <ValueCell align="right">
                                                        <p style={{
                                                            padding: "0px",
                                                            margin: "0px",
                                                            color: ipGreen
                                                        }}>{row.weight_index}</p>
                                                    </ValueCell>) : (
                                                    <ValueCell align="right">
                                                        <p style={{
                                                            padding: "0px",
                                                            margin: "0px",
                                                            color: ipRed
                                                        }}>{row.weight_index}</p>
                                                    </ValueCell>
                                                )
                                                }

                                            </IPTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                    <div className="Sector-Breakdown">
                        <Paper style={paper_left} elevation={0}>
                            <div>
                                <p style={card_title}>Sector Breakdown</p>
                                <p style={card_subtitle}>Sector vs. Benchmark</p>
                            </div>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <HeaderCell>Sector</HeaderCell>
                                            <HeaderCell align="right">{strategySelected.strategy_name}</HeaderCell>
                                            <HeaderCell align="right">{strategySelected.benchmark}</HeaderCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {strategySelected.data.sector_breakdown.map((row) => (
                                            <IPTableRow key={row.sector}>
                                                <ValueCell>{row.sector}</ValueCell>
                                                <ValueCell align="right">{row.pct_port}</ValueCell>
                                                <ValueCell align="right">{row.pct_port_benchmark}</ValueCell>
                                            </IPTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                    <div className="Performance">
                        <Paper style={Object.assign({}, big_paper, {paddingBottom: "20px"})}
                               elevation={0}>
                            <div>
                                <p style={card_title}>Performance</p>
                                <p style={card_subtitle}>{strategySelected.strategy_name} vs. {strategySelected.benchmark}</p>
                            </div>
                            <Chart
                                options={strategy_chart_style}
                                series={[{
                                    name: strategySelected.strategy_name,
                                    data: strategySelected.data.daily_returns.daily_return
                                },
                                    {
                                        name: strategySelected.benchmark,
                                        data: strategySelected.data.daily_returns.daily_return_benchmark
                                    }]}
                                type="line" height="350px"/>
                        </Paper>
                    </div>
                    <div className="Performance-Monthly" style={{paddingBottom: "30px"}}>
                        <Paper style={Object.assign({}, big_paper, {paddingBottom: "20px"})}
                               elevation={0}>
                            <div>
                                <p style={card_title}>Performance</p>
                                <p style={card_subtitle}>Monthly Returns (%)</p>
                            </div>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <HeaderCell>{new Date().getFullYear().toString()}</HeaderCell>
                                            <HeaderCell align="left">Jan</HeaderCell>
                                            <HeaderCell align="left">Feb</HeaderCell>
                                            <HeaderCell align="left">Mar</HeaderCell>
                                            <HeaderCell align="left">Apr</HeaderCell>
                                            <HeaderCell align="left">May</HeaderCell>
                                            <HeaderCell align="left">Jun</HeaderCell>
                                            <HeaderCell align="left">Jul</HeaderCell>
                                            <HeaderCell align="left">Aug</HeaderCell>
                                            <HeaderCell align="left">Sep</HeaderCell>
                                            <HeaderCell align="left">Oct</HeaderCell>
                                            <HeaderCell align="left">Nov</HeaderCell>
                                            <HeaderCell align="left">Dec</HeaderCell>
                                            <HeaderCell align="left">YTD</HeaderCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {strategySelected.data.monthly_performance.map((row) => (
                                            <IPTableRow>
                                                <ValueCell>{row[new Date().getFullYear().toString()]}</ValueCell>
                                                {monthlyPerformance(row.Jan)}
                                                {monthlyPerformance(row.Feb)}
                                                {monthlyPerformance(row.Mar)}
                                                {monthlyPerformance(row.Apr)}
                                                {monthlyPerformance(row.May)}
                                                {monthlyPerformance(row.Jun)}
                                                {monthlyPerformance(row.Jul)}
                                                {monthlyPerformance(row.Aug)}
                                                {monthlyPerformance(row.Sep)}
                                                {monthlyPerformance(row.Oct)}
                                                {monthlyPerformance(row.Nov)}
                                                {monthlyPerformance(row.Dec)}
                                                {monthlyPerformance(row.YTD)}
                                            </IPTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper></div>
                </div>
            </div>

        )
    }

}

export default observer(StrategyInfo);