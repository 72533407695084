import {big_paper} from "../../Styles/styles";
import Paper from "@material-ui/core/Paper";
import {Divider, Grid} from "@material-ui/core";
import React from "react";

function HelpAllocationModel() {
    return (
        <div>
            <Paper style={big_paper} elevation={0}>
                <div style={{padding: "20px"}}>
                    <p style={{fontWeight: "600", fontSize: "18px"}}>Video Tutorial: Allocation Model</p>
                    <Grid item xs={12} style={{marginTop: "20px"}}>
                        <iframe
                            width="1240"
                            height="750"
                            src={`https://res.cloudinary.com/indexpro/video/upload/Onboarding%20Videos/Allocation%20Model.mp4`}
                            frameBorder="0"
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Product Tour"
                        />
                    </Grid>
                    <br/><br/>
                    <p style={{fontWeight: "600", fontSize: "18px"}}>Allocation Model Overview:</p>
                    <br/>
                    The <span style={{fontWeight: "600"}}>Allocation Model Builder</span> allows you to build custom strategies by combining indexes, mutual funds,
                    and ETFs. The sample strategy referenced in this guide is for informational purposes only and is not
                    intended for use, unless you independently determine its suitability for one of your clients.
                    Additionally, it shall not be construed as investment advice made by Index Pro. To learn how
                    to create a single index strategy, please click on the Builder help page in our help center.
                    <br/><br/>
                    <p style={{fontWeight: "600", fontSize: "18px"}}>Creating an Allocation Model:</p>
                    <br/>
                    <Grid container direction="row" >
                        <div style={{width: "600px", marginRight: "20px"}}>
                            The Allocation Model Builder tool lives inside the Builder tab on the Index Pro platform. Here are the simple steps to creating your custom allocation model:
                            <br/><br/>
                            <span style={{fontWeight: "600"}}>Step 1 | Create Allocation Model</span>
                            <br/><br/>
                            Click on Create Allocation Model to begin building your custom strategy
                            <br/><br/>
                        </div>
                        <div style={{width: "550px", marginLeft: "50px"}}>
                            <img src={"https://res.cloudinary.com/indexpro/image/upload/Help%20Section%20Images/Builder-Index-1.png"}
                                 alt="alloc-index" width="550px"/>
                        </div>
                    </Grid>
                    <br/>
                    <Divider/>
                    <br/><br/>
                    <Grid container direction="row" >
                        <div style={{width: "600px", marginRight: "20px"}}>
                            <span style={{fontWeight: "600"}}>Step 2 | Investment Options</span>
                            <br/><br/>
                            Select your <span style={{fontWeight: "600"}}>Indexes</span> or <span style={{fontWeight: "600"}}>Funds</span> to create your custom strategy.
                            <br/><br/>
                            1. Select your <span style={{fontWeight: "600"}}>Index(es)</span> from a list of S&P, FTSE Russell and MSCI Indexes. You can select one or more indexes to include in your strategy.
                        </div>
                        <div style={{width: "600px"}}>
                            <img src={"https://res.cloudinary.com/indexpro/image/upload/Help%20Section%20Images/AllocationModel-Index.png"}
                                 alt="alloc-index" width="550px"/>
                        </div>
                    </Grid>
                    <br/><br/><br/><br/>
                    <Grid container direction="row" >
                        <div style={{width: "400px", marginRight: "20px"}}>
                            2.	Select your Fund(s) from a list of over 25,000 ETFs and Mutual Funds.
                            <br/><br/>
                            <span style={{fontStyle: "italic"}}>Under each fund name you will notice information on the number
                                of positions and their AUM. The funds on this list are in the order of AUM, starting
                                with the largest. You can scroll through the choices or simply use the search field to
                                find the fund you would like to select.</span>
                            <br/><br/>
                            <span style={{fontStyle: "italic"}}>As you make your selections, you will notice the Selections column on the right-hand side populate.</span>
                        </div>
                        <div style={{width: "800px"}}>
                            <img src={"https://res.cloudinary.com/indexpro/image/upload/Help%20Section%20Images/AllocationModel-Fund.png"}
                                 alt="builder-core-options" width="800px"/>
                        </div>
                    </Grid>
                    <br/><br/>
                    <Divider/>
                    <br/><br/>
                    <Grid container direction="row" >
                        <div style={{width: "600px", marginRight: "20px"}}>
                            <span style={{fontWeight: "600"}}>Step 3 | Allocate</span>
                            <br/><br/>
                            Adjust the allocations of your selections to sum to 100%.
                            <br/><br/>
                            The Total Allocation percentage calculator will turn black once you have hit the
                            100% allocation mark. You will not be able to continue building your allocation
                            model if your selections do not add up to 100%.
                            <br/><br/>
                            <span style={{fontStyle: "italic"}}>The image is for illustration purposes only.
                                For the purpose of this guide, we have selected the first two indexes and the first fund from the lists. </span>
                        </div>
                        <div style={{width: "600px"}}>
                            <img src={"https://res.cloudinary.com/indexpro/image/upload/Help%20Section%20Images/AllocationModel-Allocation.png"}
                                 alt="alloc-alloc" width="550px"/>
                        </div>
                    </Grid>
                    <br/><br/>
                    <Divider/>
                    <br/><br/>
                    <Grid container direction="row" >
                        <div style={{width: "600px", marginRight: "20px"}}>
                            <span style={{fontWeight: "600"}}>Step 4 | Name & Create</span>
                            <br/><br/>
                            Name your allocation model and click on Create Allocation Model.
                            <br/><br/>
                            <span style={{fontStyle: "italic"}}>
                                Index Pro combines the data from the indexes, ETFs and mutual funds you have
                                selected to create your very own allocation model, the results of which can be
                                seen in real time on the left-hand side of your screen. You can see the total
                                number of positions in your allocation model, the median market cap, the largest
                                position weight, and the top 10 position weight. You can also see the composition
                                of your custom index, the sector breakdown and top holdings based on their weight
                                in your strategy.
                            </span>
                        </div>
                        <div style={{width: "600px"}}>
                            <img src={"https://res.cloudinary.com/indexpro/image/upload/Help%20Section%20Images/AllocationModel-Running.png"}
                                 alt="alloc-running" width="600px"/>
                        </div>
                    </Grid>
                    <br/><br/>
                    <Divider/>
                    <br/><br/>
                    <Grid container direction="row" >
                        <div style={{width: "600px", marginRight: "20px"}}>
                            <span style={{fontWeight: "600"}}>Step 5 | Back to the Builder</span>
                            <br/><br/>
                            Once you click on <span style={{fontWeight: "600"}}>Create Allocation Model</span>, you will return to the <span style={{fontWeight: "600"}}>Builder</span> page to
                            continue further building your allocation model by selecting values, themes, performance
                            & risk parameters, and tax preferences. Please refer to the Builder page help section,
                            starting with <span style={{fontWeight: "600"}}>Step 2</span>, to finish building your <span style={{fontWeight: "600"}}>Allocation Model</span>.
                        </div>
                        <div style={{width: "600px"}}>
                        </div>
                    </Grid>
                    <br/><br/>
                    <Divider/>
                    <br/><br/>
                    For more help with your <span style={{fontWeight: "600"}}>Allocation Model</span> please e-mail us at <a href={"mailto: indexpro@atomlp.com"}>indexpro@atomlp.com</a> or use the chat bubble on the bottom right of this page to chat with us.
                </div>
            </Paper>
        </div>
    )
}

export default HelpAllocationModel