import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import {ChromeAutoFillOverride, transparent_button, ipLogo, ipLogoIcon} from "../Styles/styles"
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {useHistory} from 'react-router-dom'
import {Auth, API} from 'aws-amplify'
import Alert from "@material-ui/lab/Alert";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Storage } from "@aws-amplify/storage"
import {Checkbox} from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {DataStore} from "@aws-amplify/datastore";


//styles
const formStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundColor:
        theme.palette.background.default,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: "40px"
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        width: "250px"
    },
}));


function SignUp() {

    async function clearUserState() {
        try {
            await Auth.currentAuthenticatedUser()
            //localStorage.clear();
            await Auth.signOut()
        } catch (e) {
            //localStorage.clear();
            console.error(e)
        }
    }

    useEffect( () => {
        clearUserState();
    }, [])

    //styles + history prop
    const classes = formStyles();
    const history = useHistory();

    //password visible state
    const [showPassword, setShowPassword] = React.useState(0)

    //logged in state
    const [loggedInState, setLoggedInState] = React.useState()
    const [loggedInButton, setLoggedInButton] = React.useState()

    //user state
    const [user, setUser] = useState({username: '', password: '', name: '', authCode: ''});

    //step state
    const [step, setStep] = useState(0);

    //error state
    const [error, setError] = useState('');

    //awk agreement
    const [agreement, setAgreement] = useState(false)

    //show password handle
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    //on change method
    const handleInputChange = (event, keyName) => {
        event.persist();
        setUser((user) => {
            return {...user, [keyName]: event.target.value}
        })
    }

    //signs up user
    const signUp = async () => {
        //todo: fix
        //if (user.username.split('@')[1] !== 'atomlp.com' || user.username.split('@')[0] !== 'indexpro-admin') {
        //    return setError('You are not authorized to create an account')
        //}
        try {
            await Auth.signUp({
                username: user.username,
                password: user.password,
                attributes: {email: user.username, name: user.name}
            });
            setStep(1)
            setError('')
        } catch (error) {
            setError(error.message)
            console.error('Error Signing Up: ', error);
        }
    }

    //confirms sign up with user's auth code
    const confirmSignUp = async () => {
        try {
            //confirm sign up
            await Auth.confirmSignUp(user.username, user.authCode.trim())
            //sign in
            setLoggedInButton("Logging In")
            await Auth.signIn(user.username, user.password)
            let credentials = await Auth.currentCredentials()
            let identityId = credentials['identityId']
            //write empty uid to s3
            await Storage.put('uid.txt', '$', {
                level: 'private',
                contentType: 'text/plain'
            });
            //trigger lambda w/ owner id
            await API.get('pythonapi', `/hello?id=${identityId}&email=${user.username}`);
            //sign out
            await Auth.signOut()
            //loading screen and redirect
            setLoggedInState("Logging In")
            setTimeout(() => {
                history.push('/signin');
            }, 1000);
            setError('')
        } catch (error) {
            setError(error.message)
            console.error('Error Confirming Sign Up: ', error);
        }
    }

    //confirms sign up with user's auth code
    const resendConfirmation = async () => {
        try {
            await Auth.resendSignUp(user.username)
            setError('')
        } catch (error) {
            setError(error.message)
            console.error('Error Resending Auth Code: ', error);
        }
    }

    if (loggedInState === "Logging In") {
        return (
            <>
                <div className="fade-in-text" style={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", minHeight: "100vh"}}>
                    <img
                        src={ipLogoIcon}
                        width="150" height="150"/>
                </div>
            </>
        )
    }

    return (
        <div>
            {step === 0 ? (
                <Grid container component="main" className={classes.root}>
                    <Grid item xs={false} sm={4} md={5} className={classes.image}/>
                    <Grid item xs={12} sm={8} md={7} component={Paper} elevation={1} square>
                        <div className={classes.paper}>
                            <Grid container direction="column" justifyContent="flex-start">
                                <img
                                    src={ipLogo}
                                    alt="logo"
                                    width="160" height="44"/>
                                <p style={{fontSize: "50px", marginBottom: "60px", marginTop: "200px"}}>
                                    Sign Up
                                </p>

                            </Grid>
                            {error !== '' && (
                                <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                                    <Alert severity="error" style={{width: "450px", marginBottom: "25px"}}>{error}</Alert>
                                </Grid>
                            )}

                            <form className={classes.form} noValidate>

                                <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        label="Full Name"
                                        type="text"
                                        autoFocus
                                        style={{
                                            backgroundColor: "#F5F8FA",
                                            width: "450px"
                                        }}
                                        inputProps={{ style: ChromeAutoFillOverride }}
                                        onChange={(e) => handleInputChange(e, 'name')}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        label="Email Address"
                                        autoComplete="email"
                                        type="email"
                                        autoFocus
                                        style={{
                                            backgroundColor: "#F5F8FA",
                                            width: "450px"
                                        }}
                                        inputProps={{ style: ChromeAutoFillOverride }}
                                        onChange={(e) => handleInputChange(e, 'username')}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        type={showPassword ? 'text' : 'password'}
                                        label="Password"
                                        autoComplete="current-password"
                                        style={{
                                            backgroundColor: "#F5F8FA",
                                            width: "450px"
                                        }}
                                        inputProps={{ style: ChromeAutoFillOverride }}
                                        onChange={(e) => handleInputChange(e, 'password')}
                                        InputProps={{endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword ? <Visibility/> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                        }}
                                    />
                                    <Button style={transparent_button} onClick={() => history.push('/signin')}>
                                        Already on Index Pro? Sign in
                                    </Button>
                                    <p style={{fontSize: "15px", marginTop: "40px"}}>To enter the Platform, please click the link to review the <a
                                        target="_blank" rel="noopener noreferrer" href="https://www.indexprogroup.com/platform-user-agreement">Platform User Agreement</a>.</p>

                                    <FormControlLabel
                                        control={<Checkbox checked={agreement} onChange={(e) => setAgreement(e.target.checked)} color="primary" name="agreement" />}
                                        label={<p style={{fontSize: "15px"}}>I acknowledge and agree to the terms of the Platform User Agreement.</p>}
                                    />

                                    <Button
                                        disabled={!agreement || user.name === ''}
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        style={{marginTop: "50px", textTransform: "none", padding: "0px"}}
                                        onClick={() => signUp()}
                                    >
                                    <span>
                                        <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
                                            <p>Create Account</p> <ArrowForwardIcon
                                            style={{fontSize: "20px"}}/>
                                        </Grid>
                                    </span>
                                    </Button>

                                </Grid>
                            </form>
                        </div>
                    </Grid>
                </Grid>

            ) : (
                <Grid container component="main" className={classes.root}>
                    <Grid item xs={false} sm={4} md={5} className={classes.image}/>
                    <Grid item xs={12} sm={8} md={7} component={Paper} elevation={1} square>
                        <div className={classes.paper}>
                            <Grid container direction="column" justifyContent="flex-start">
                                <img
                                    src={ipLogo}
                                    alt="logo"
                                    width="160" height="44"/>
                                <p style={{fontSize: "50px", marginBottom: "0px", marginTop: "200px"}}>
                                    Confirm Account
                                </p>
                                <p style={{fontSize: "15px", marginBottom: "50px"}}>
                                    We sent you a code via email to confirm your account.
                                </p>
                            </Grid>
                            {error !== '' && (
                                <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                                    <Alert severity="error" style={{width: "450px", marginBottom: "25px"}}>{error}</Alert>
                                </Grid>
                            )}
                            <form className={classes.form} noValidate>
                                <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        label="Code"
                                        type="text"
                                        autoFocus
                                        value={user.authCode}
                                        style={{
                                            backgroundColor: "#F5F8FA",
                                            width: "450px"
                                        }}
                                        inputProps={{ style: ChromeAutoFillOverride }}
                                        onChange={(e) => handleInputChange(e, 'authCode')}
                                    />
                                    <Button style={transparent_button} onClick={() => resendConfirmation()}>
                                        Resend confirmation code
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        className={classes.submit}
                                        style={{marginTop: "50px", textTransform: "none", padding: "0px"}}
                                        onClick={(e) => {e.preventDefault(); confirmSignUp()}}
                                    >
                                    <span>
                                        <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
                                        {loggedInButton === 'Logging In' ? (
                                            <div style={{paddingTop: "5px"}}><CircularProgress style={{color: "white"}}/>
                                            </div>
                                        ) : (
                                            <><p>Confirm</p> <ArrowForwardIcon
                                                style={{marginLeft: "10px", fontSize: "20px"}}/></>
                                        )}
                                        </Grid>
                                    </span>
                                    </Button>
                                    <Button style={transparent_button} onClick={() => history.push('/signin')}>
                                        Already on Index Pro? Sign in
                                    </Button>
                                </Grid>
                            </form>

                        </div>
                    </Grid>
                </Grid>
            )}

        </div>
    );
}

export default SignUp