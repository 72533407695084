import Paper from "@material-ui/core/Paper";
import {
    big_paper, bordered_button,
    card_subtitle,
    card_title,
    ChromeAutoFillOverride, default_firm_photo,
    ipLogo,
    simple_text,
    themeColor
} from "../../Styles/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {DropzoneArea} from "material-ui-dropzone";
import {TwitterPicker} from "react-color";
import {Editor} from "react-draft-wysiwyg";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import React, {useEffect} from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {ContentState, convertFromHTML, convertToRaw, EditorState} from "draft-js";
import {DataStore} from "@aws-amplify/datastore";
import {Firm} from "../../models";
import {Storage} from "@aws-amplify/storage";
import draftToHtml from "draftjs-to-html";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {useUserRole} from "../Hooks/Data/User/user";
import {adminOnlyActionWrapper} from "../../Utils/role_utils";

function FirmProfile() {

    const [editorStateFirm, setEditorStateFirm] = React.useState(EditorState.createEmpty());
    const user_role = useUserRole()

    const handleColorChange = (color, event) => {
        setFirmData((user) => {
            return {...user, ['firm_color']: color.hex}
        })
    }

    //photo state
    const [logoPhoto, setLogoPhoto] = React.useState();
    const [firmPhoto, setFirmPhoto] = React.useState();

    const [firmData, setFirmData] = React.useState({
        firm_color: null,
        firm_bio: null,
        firm_city: null,
        firm_website: null,
        firm_email: null,
        firm_logo: null,
        firm_photo: null
    })

    const [styleEdit, setStyleEdit] = React.useState(0)


    const handleFirmInputChange = (event, keyName) => {
        event.persist();
        setFirmData((user) => {
            return {...user, [keyName]: event.target.value}
        })
    }

    const handleFirmData = (val, keyName) => {
        setFirmData((user) => {
            return {...user, [keyName]: val}
        })
    }

    const fetchFirmData = async () => {
        const data = await DataStore.query(Firm);
        const user = JSON.parse(localStorage.getItem("signed-in-user"))
        if (data[0]) {
            await setFirmData(data[0])
            setEditorStateFirm(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(data[0]['firm_bio']))))
            try {
                const result = await Storage.list('firm_logo', {
                    level: 'private'
                });
                if (result.length === 0) {
                    return handleFirmData(null, 'firm_logo')
                }
                let key = result.filter(i => i.key.startsWith('firm_logo'))[0].key
                const photo = await Storage.get(key, {
                    level: 'private'
                });
                handleFirmData(photo, 'firm_logo')
            } catch (error) {
                console.error(error)
            }
            try {
                const result = await Storage.list('firm_photo', {
                    level: 'private'
                });
                if (result.length === 0) {
                    return handleFirmData(null, 'firm_photo')
                }
                let key = result.filter(i => i.key.startsWith('firm_photo'))[0].key
                const photo = await Storage.get(key, {
                    level: 'private'
                });
                handleFirmData(photo, 'firm_photo')
            } catch (error) {
                console.error(error)
            }
        } else {
            return true
        }

    };


    const saveFirmData = async () => {
        if (styleEdit) {
            setStyleEdit(!styleEdit)
            if (logoPhoto.length !== 0) {
                try {
                    await Storage.put(`firm_logo.${logoPhoto[0].path.split('.').pop()}`, logoPhoto[0], {
                        level: 'private', contentType: 'image/svg+xml'
                    });
                } catch (error) {
                    console.error(error)
                }
                const result = await Storage.list('firm_logo', {
                    level: 'private'
                });
                let key = result.filter(i => i.key.startsWith('firm_logo'))[0].key
                const photo = await Storage.get(key, {
                    level: 'private'
                });
                handleFirmData(photo, 'firm_logo')
            }
            if (firmPhoto.length !== 0) {
                try {
                    await Storage.put(`firm_photo.jpg`, firmPhoto[0], {
                        level: 'private'
                    });
                } catch (error) {
                    console.error(error)
                }
                const result = await Storage.list('firm_photo', {
                    level: 'private'
                });
                let key = result.filter(i => i.key.startsWith('firm_photo'))[0].key
                const photo = await Storage.get(key, {
                    level: 'private'
                });
                handleFirmData(photo, 'firm_photo')
            }
            try {
                const original = await DataStore.query(Firm); //think about passing in ID from cognito ID
                if (original[0]) {
                    handleFirmData(draftToHtml(convertToRaw(editorStateFirm.getCurrentContent())), 'firm_bio')
                    await DataStore.save(Firm.copyOf(original[0], item => {
                        item.firm_name = firmData.firm_name
                        item.firm_color = firmData.firm_color
                        item.firm_bio = draftToHtml(convertToRaw(editorStateFirm.getCurrentContent()))
                        item.firm_city = firmData.firm_city
                        item.firm_email = firmData.firm_email
                        item.firm_website = firmData.firm_website
                    }));
                } else {
                    await DataStore.save(
                        new Firm({
                            "firm_name": firmData.firm_name,
                            "firm_color": firmData.firm_color,
                            "firm_bio": draftToHtml(convertToRaw(editorStateFirm.getCurrentContent())),
                            "firm_city": firmData.firm_city,
                            "firm_email": firmData.firm_email,
                            "firm_website": firmData.firm_website
                        })
                    );
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            setStyleEdit(!styleEdit)
        }

    }

    //get user info
    useEffect(() => {
        fetchFirmData();
        const subscription = DataStore.observe(Firm).subscribe(fetchFirmData)
        return () => subscription.unsubscribe()
    }, []);


    const useStyles = makeStyles(() =>
        createStyles({
            fileDropzone: {
                "& .MuiDropzonePreviewList-removeButton": {
                    top: "25px",
                    left: "225px",
                },
            },
            firmDropzone: {
                "& .MuiDropzonePreviewList-removeButton": {
                    top: "25px",
                    left: "250px",
                },
            },
        })
    );

    const styles = useStyles();

    return (
        <div>
            <Paper style={big_paper} elevation={0}>
                {!styleEdit ? (
                    <div>
                        <Grid container direction="row" alignContent="flex-start" justifyContent="center">
                            <Grid item md={12}>
                                <p style={Object.assign({}, card_title, {
                                    paddingTop: '20px',
                                    paddingLeft: '20px',
                                })}>Firm Information</p>
                            </Grid>
                            <Grid item md={4}>
                                <p style={Object.assign({}, card_subtitle, {
                                    paddingTop: '20px',
                                    paddingLeft: '20px'
                                })}>FIRM NAME</p>
                                <p style={Object.assign({}, simple_text, {paddingLeft: '40px'})}>{firmData.firm_name}</p>
                            </Grid>
                            <Grid item md={4}>
                                <p style={Object.assign({}, card_subtitle, {
                                    paddingTop: '20px',
                                    paddingLeft: '20px'
                                })}>FIRM LOGO</p>
                                <div style={{marginLeft: "40px"}}>
                                    <img
                                        src={firmData.firm_logo === null ? (ipLogo) : (firmData.firm_logo)}
                                        width="180"/>
                                </div>
                            </Grid>
                            <Grid item md={4}>
                                <p style={Object.assign({}, card_subtitle, {
                                    paddingTop: '13px',
                                    paddingLeft: '20px'
                                })}>FIRM PHOTO</p>
                                <div style={{marginLeft: "40px"}}>
                                    <img
                                        src={firmData.firm_photo === null ? (default_firm_photo)
                                            : (firmData.firm_photo)} width="300" height="175"/>
                                </div>
                            </Grid>
                            <Grid item md={4}>
                                <p style={Object.assign({}, card_subtitle, {
                                    paddingTop: '20px',
                                    paddingLeft: '20px'
                                })}>FIRM COLOR</p>
                                <div style={{marginLeft: "40px"}}>
                                    <Grid container direction="row" alignItems="center"
                                          justifyContent="flex-start">
                                        <p style={Object.assign({}, simple_text, {marginTop: "5px"})}>{firmData.firm_color}</p>
                                        <div style={{
                                            width: "20px",
                                            height: "20px",
                                            backgroundColor: firmData.firm_color,
                                            borderRadius: "50%",
                                            marginLeft: "10px",
                                            marginTop: "-10px"
                                        }}/>
                                    </Grid>
                                </div>

                            </Grid>

                            <Grid item md={4}>
                                <p style={Object.assign({}, card_subtitle, {
                                    paddingTop: '20px',
                                    paddingLeft: '20px'
                                })}>FIRM EMAIL</p>
                                <p style={Object.assign({}, simple_text, {paddingLeft: '40px'})}>{firmData.firm_email}</p>
                            </Grid>
                            <Grid item md={4}>
                                <p style={Object.assign({}, card_subtitle, {
                                    paddingTop: '20px',
                                    paddingLeft: '20px'
                                })}>FIRM WEBSITE</p>
                                <p style={Object.assign({}, simple_text, {paddingLeft: '40px'})}>{firmData.firm_website}</p>
                            </Grid>
                            <Grid item md={12}>
                                <p style={Object.assign({}, card_subtitle, {
                                    paddingTop: '20px',
                                    paddingLeft: '20px'
                                })}>FIRM CITY</p>
                                <p style={Object.assign({}, simple_text, {paddingLeft: '40px'})}>{firmData.firm_city}</p>
                            </Grid>
                            <Grid item md={12}>
                                <p style={Object.assign({}, card_subtitle, {
                                    paddingTop: '20px',
                                    paddingLeft: '20px',
                                })}>FIRM BIO</p>
                                <p style={Object.assign({}, simple_text, {
                                    paddingLeft: '40px',
                                    paddingRight: "40px"
                                })}
                                   dangerouslySetInnerHTML={{__html: firmData.firm_bio}}/>
                            </Grid>
                        </Grid>
                    </div>) : (
                    <div>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                            <Grid item md={12}>
                                <p style={Object.assign({}, card_title, {
                                    paddingTop: '20px',
                                    paddingLeft: '20px',
                                    paddingBottom: '20px'
                                })}>Firm Information</p>
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    label="Firm Name"
                                    value={firmData.firm_name}
                                    type="text"
                                    autoFocus
                                    style={{
                                        backgroundColor: "#F5F8FA",
                                        width: "300px",
                                        marginLeft: "40px"
                                    }}
                                    inputProps={{style: ChromeAutoFillOverride}}
                                    onChange={(e) => handleFirmInputChange(e, 'firm_name')}
                                />
                            </Grid>

                            <Grid item style={{marginLeft: "40px", width: "300px"}}
                                  className={styles.firmDropzone}>
                                <p style={Object.assign({}, card_subtitle, {
                                    marginLeft: '0px'
                                })}>Firm Logo</p>
                                <DropzoneArea
                                    onChange={(e) => setLogoPhoto(e)}
                                    Icon={"null"}
                                    dropzoneText={<p style={{fontSize: "16px"}}>Drop your image here, or <span
                                        style={{color: themeColor}}>browse</span><br/>
                                        <span style={{fontSize: "12px"}}>Only SVG files are allowed</span></p>}
                                    filesLimit={1}
                                    acceptedFiles={["image/svg+xml"]}
                                    showAlerts={true}
                                    alertSnackbarProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'center'
                                        }
                                    }}
                                    getPreviewIcon={(file) => {
                                        if (file.file.type.split('/')[0] === 'image')
                                            return (
                                                <img style={{width: "200px", marginLeft: "50px"}}
                                                     src={file.data}/>
                                            );
                                    }}
                                />
                            </Grid>
                            <Grid item style={{marginLeft: "120px", width: "300px"}}
                                  className={styles.firmDropzone}>
                                <p style={Object.assign({}, card_subtitle, {
                                    marginLeft: '0px'
                                })}>Firm Photo</p>
                                <DropzoneArea
                                    onChange={(e) => setFirmPhoto(e)}
                                    Icon={"null"}
                                    acceptedFiles={['image/*']}
                                    dropzoneText={<p style={{fontSize: "16px"}}>Drop your image here, or <span
                                        style={{color: themeColor}}>browse</span><br/><span
                                        style={{fontSize: "12px"}}>PNG and JPG files are allowed</span></p>}
                                    filesLimit={1}
                                    showAlerts={true}
                                    alertSnackbarProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'center'
                                        }
                                    }}
                                    getPreviewIcon={(file) => {
                                        if (file.file.type.split('/')[0] === 'image')
                                            return (
                                                <img style={{width: "200px", marginLeft: "50px"}}
                                                     src={file.data}/>
                                            );
                                    }}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <p style={Object.assign({}, card_subtitle, {
                                    paddingLeft: '20px'
                                })}>FIRM COLOR</p>
                                <div style={{marginLeft: "40px"}}>
                                    <Grid container direction="row" alignItems="center"
                                          justifyContent="flex-start">
                                        <p style={Object.assign({}, simple_text, {marginTop: "5px"})}>{firmData.firm_color}</p>
                                        <div style={{
                                            width: "20px",
                                            height: "20px",
                                            backgroundColor: firmData.firm_color,
                                            borderRadius: "50%",
                                            marginLeft: "10px",
                                            marginTop: "-10px"
                                        }}/>
                                    </Grid>
                                    <TwitterPicker
                                        color={firmData.firm_color ? (firmData.firm_color) : (themeColor)}
                                        onChangeComplete={(c, e) => handleColorChange(c, e)}
                                        colors={['#1967d2', '#3f51b5', '#008b02', '#006400', '#c25400', '#820601', '#9300ff']}/>
                                    <div style={{marginTop: "10px", fontSize: "13px"}}>
                                        <a target="_blank" rel="noopener noreferrer"
                                           href="https://www.google.com/search?q=color+picker">Can't find your
                                            color?</a>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    label="Firm Email"
                                    value={firmData.firm_email}
                                    type="url"
                                    autoFocus
                                    style={{
                                        backgroundColor: "#F5F8FA",
                                        width: "300px",
                                        marginLeft: "40px"
                                    }}
                                    inputProps={{style: ChromeAutoFillOverride}}
                                    onChange={(e) => handleFirmInputChange(e, 'firm_email')}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    label="Firm City"
                                    value={firmData.firm_city}
                                    type="url"
                                    autoFocus
                                    style={{
                                        backgroundColor: "#F5F8FA",
                                        width: "300px",
                                        marginLeft: "40px"
                                    }}
                                    inputProps={{style: ChromeAutoFillOverride}}
                                    onChange={(e) => handleFirmInputChange(e, 'firm_city')}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    label="Firm Website"
                                    value={firmData.firm_website}
                                    type="url"
                                    autoFocus
                                    style={{
                                        backgroundColor: "#F5F8FA",
                                        width: "300px",
                                        marginLeft: "40px"
                                    }}
                                    inputProps={{style: ChromeAutoFillOverride}}
                                    onChange={(e) => handleFirmInputChange(e, 'firm_website')}
                                />
                            </Grid>
                            <Grid item md={12}
                                  style={{marginLeft: "40px", marginRight: "80px", marginTop: "40px"}}>
                                <p style={Object.assign({}, card_subtitle, {
                                    marginLeft: '0px'
                                })}>Firm Bio</p>
                                <div style={{
                                    border: "1px solid #e0e0e0",
                                    borderRadius: "5px",
                                    padding: "5px",
                                    backgroundColor: "#f8f9fa"
                                }}>
                                    <Editor
                                        editorState={editorStateFirm}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={(e) => setEditorStateFirm(e)}
                                        stripPastedStyles={true}
                                        toolbar={
                                            {
                                                options: ['inline', 'list'],
                                                inline: {
                                                    options: ['italic', 'underline', 'strikethrough', 'superscript', 'subscript']
                                                }
                                            }
                                        }
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                )
                }
                <Button style={Object.assign({}, bordered_button, {
                    marginTop: '20px',
                    marginLeft: '40px',
                })}
                        startIcon={!styleEdit ? (<EditIcon/>) : (<SaveAltIcon/>)} onClick={() => adminOnlyActionWrapper(user_role, saveFirmData)}>
                    {!styleEdit ? ('Edit') : ('Save')} </Button>
            </Paper>
        </div>
    )
}

export default FirmProfile