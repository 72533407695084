import {Button, Grid, Paper, TextField, Tooltip} from "@material-ui/core";
import {
    big_paper,
    bordered_button,
    card_description,
    card_subtitle,
    card_title, ChromeAutoFillOverride, themeBackground, themeColor,
    transition_icon
} from "../../Styles/styles";
import {userRoleActionWrapper} from "../../Utils/role_utils";
import {dollarsWithCommas, numWithCommas, pos_neg_dollar_card_description} from "../../Utils/num_utils";
import Lottie from "react-lottie";
import React from "react";
import {useUserRole} from "../Hooks/Data/User/user";
import optimizing from "../../Styles/optimizing.json";
import Fab from "@material-ui/core/Fab";
import ClearIcon from "@material-ui/icons/Clear";
import {DropzoneArea} from "material-ui-dropzone";
import DescriptionIcon from "@material-ui/icons/Description";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Alert, createFilterOptions} from "@material-ui/lab";
import InputAdornment from "@material-ui/core/InputAdornment";
import states from "../../data/states.json";
import Modal from "@material-ui/core/Modal";
import {useCustomStrategies, useCustomStrategyByID} from "../Hooks/Data/Strategies/strategies";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {Auth} from "aws-amplify";
import {DataStore} from "@aws-amplify/datastore";
import {Account} from "../../models";
import {Storage} from "@aws-amplify/storage";
import * as XLSX from "xlsx";
import {Mixpanel} from "../../Utils/Mixpanel";

function AccountTransitionPortfolio(props) {

    const user_role = useUserRole()

    const account_transition = props.account.transition
    const account_transition_data = props.account.transition_data

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: optimizing,
    };

    const [account_selected_strategy, setAccountStrategy] = React.useState(null)
    const [accountState, setAccountState] = React.useState(null)
    const [accountCash, setAccountCash] = React.useState(null)
    const [accountBudget, setAccountBudget] = React.useState(0)
    const customStrategyItems = useCustomStrategies();
    const [transitionPage, setTransitionPage] = React.useState(0)
    const [transitionOpen, setTransitionOpen] = React.useState(false)
    const [portfolioFile, setPortfolioFile] = React.useState(null);
    const [portfolioFileData, setPortfolioFileData] = React.useState([]);
    const [portfolioFileDataRows, setPortfolioFileDataRows] = React.useState([]);
    const [portfolioMappings, setPortfolioMappings] = React.useState([])
    const [headerRow, setHeaderRow] = React.useState(null)

    const mappingOptions = ['Security name', 'Security ticker', 'Purchase date', 'Number of shares',
        'Cost basis (per share)', 'Cost basis (total)', 'Market value']


    const handleTransitionOpen = () => {
        setTransitionPage(0)
        setTransitionOpen(true);
    };

    const addPortfolioMappings = (initial_field, new_field) => {
        let mapping_obj = {'initial_field': initial_field, 'new_field': new_field}
        setPortfolioMappings([...portfolioMappings.filter(item => (item.initial_field !== initial_field)), mapping_obj])
    }

    const allFieldsMapped = () => {
        return ['Security name', 'Security ticker', 'Number of shares', 'Market value'].every(elem => portfolioMappings.map(function (el) {
                return el.new_field
            }).includes(elem)) &&
            ['Cost basis (per share)', 'Cost basis (total)'].some(elem =>
                portfolioMappings.map(function (el) {
                    return el.new_field
                }).includes(elem))
    }

    const createTransitionReport = async () => {
        const admin_info = await Auth.currentUserInfo()
        const transition_data = {
            'mappings': portfolioMappings,
            'account_cash': accountCash,
            'tax_budget': (accountBudget === '' || accountBudget === null) ? (0) : parseFloat(accountBudget),
            'account_state': accountState,
        }
        await DataStore.save(Account.copyOf(props.account, item => {
            item["custom_strategy_id"] = customStrategyItems.filter(x => x.strategy_name === account_selected_strategy)[0]["id"]
            item['transition'] = transition_data
            item['transition_data'] = null
            item['admin_id'] = admin_info["id"]
        }));
        await Storage.put(`account_transitions/${props.account.id}.txt`, portfolioFileDataRows, {
            level: 'private',
        });
        Mixpanel.track('Created Transition Report');
        setTransitionOpen(false)
    }

    const createPortfolioData = (row) => {
        const workbook = XLSX.read(portfolioFileData, {type: "binary", cellDates: true});
        workbook.SheetNames.forEach(sheet => {
            let rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[sheet], {
                defval: "",
                range: parseInt(row) - 1,
                dateNF: 'yyyy-mm-dd'
            })
            setPortfolioFileDataRows(rowObject)

        })
    }


    const useStyles = makeStyles(() =>
        createStyles({
            fileDropzone: {
                "& .MuiDropzonePreviewList-removeButton": {
                    top: "25px",
                    left: "225px",
                },
            },
            firmDropzone: {
                "& .MuiDropzonePreviewList-removeButton": {
                    top: "25px",
                    left: "250px",
                },
            },
        })
    );

    const styles = useStyles();


    return (
        <div className="Portfolio">
            <Paper style={Object.assign({}, big_paper, {paddingBottom: "20px"})} elevation={0}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <div>
                        <p style={card_title}>Existing Portfolio Overview</p>
                        <p style={card_subtitle}>View an overview of your existing uploaded portfolio</p>
                    </div>
                    {account_transition !== null ? (
                        <div>
                            <Button style={Object.assign({}, bordered_button, {marginRight: "10px"})}
                                    onClick={() => userRoleActionWrapper(props.account.user_info, user_role, handleTransitionOpen)}>
                                Upload New Holdings
                            </Button>
                        </div>
                    ) : (
                        <div>
                            <Button style={Object.assign({}, bordered_button, {marginRight: "10px"})}
                                    onClick={() => userRoleActionWrapper(props.account.user_info, user_role, handleTransitionOpen)}>
                                Create Transition Report
                            </Button>
                        </div>
                    )}
                    {account_transition_data ? (
                        <>
                            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                <Grid container direction="row" justifyContent="space-between"
                                      alignItems="flex-start" style={{marginRight: "20px", marginTop: "20px"}}>
                                    <Grid item>
                                        <Grid container direction="column">
                                            <div>
                                                <p style={card_title}>Total Market Value</p>
                                                <p style={card_description}>{dollarsWithCommas(account_transition_data['transition_overview']['total_mv'])}</p>
                                            </div>
                                            <div>
                                                <Tooltip
                                                    title={<p style={{fontSize: "12px"}}>See excluded security
                                                        summary below</p>} placement="top-start" arrow><p
                                                    style={card_title}>Supported Market Value</p></Tooltip>
                                                <p style={card_description}>{dollarsWithCommas(account_transition_data['transition_overview']['supported_mv'])}</p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="column">
                                            <div>
                                                <p style={card_title}># of Positions</p>
                                                <p style={card_description}>{numWithCommas(account_transition_data['transition_overview']['input_num_positions'])}</p>
                                            </div>
                                            <div>
                                                <Tooltip title={<p style={{fontSize: "12px"}}>Tracking error of
                                                    existing portfolio vs. selected benchmark</p>}
                                                         placement="top-start" arrow><p
                                                    style={card_title}>Tracking Error</p></Tooltip>
                                                <p style={card_description}>{account_transition_data['transition_overview']['input_tracking_error']}</p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="column">
                                            <div>
                                                <p style={card_title}>Unrealized Gains</p>
                                                {pos_neg_dollar_card_description(account_transition_data['transition_overview']['unrealized_gains'])}
                                            </div>
                                            <div>
                                                <p style={card_title}>Unrealized Losses</p>
                                                {pos_neg_dollar_card_description(account_transition_data['transition_overview']['unrealized_losses'])}
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="column">
                                            <div>
                                                <p style={card_title}>Net Unrealized P&L</p>
                                                {pos_neg_dollar_card_description(account_transition_data['transition_overview']['unrealized_losses'] + account_transition_data['transition_overview']['unrealized_gains'])}
                                            </div>
                                            <div>
                                                <p style={card_title}>Cost Basis to Market Value</p>
                                                <p style={card_description}>{(account_transition_data['transition_overview']['cost_basis_to_mv'] * 100).toFixed(0) + '%'}</p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        account_transition ? (
                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                <Lottie options={defaultOptions} height={250} width={250}/>
                            </Grid>
                        ) : (
                            <></>
                        )
                    )}
                </Grid>
            </Paper>
            <Modal
                open={transitionOpen}
                style={{overflow: "scroll"}}
            >
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Paper elevation={0} style={{
                        width: "600px", height: "800px", marginTop: "250px"
                    }}>
                        <Grid container direction="row">
                            <Grid item xs={10} style={{paddingTop: "25px"}}>
                                <></>
                            </Grid>
                            <Grid item xs={2} style={{paddingRight: "20px", paddingTop: "20px"}}>
                                <Fab onClick={() => {
                                    setTransitionOpen(false);
                                    setTransitionPage(0)
                                }} style={{float: "right"}}>
                                    <ClearIcon/>
                                </Fab>
                            </Grid>
                            {(() => {
                                if (transitionPage === 0) {
                                    return (
                                        <>
                                            <Grid container justifyContent="center" style={{marginTop: "-20px"}}>
                                                <img src={transition_icon} height="80px"></img>
                                            </Grid>
                                            <Grid container justifyContent="center">
                                                <p style={Object.assign({}, card_title, {marginLeft: "0px"})}>Transition
                                                    Management</p>
                                            </Grid>
                                            <Grid container justifyContent="center"
                                                  style={{
                                                      marginLeft: "50px",
                                                      marginRight: "50px",
                                                      marginTop: "30px"
                                                  }}>
                                                <p style={Object.assign({}, card_subtitle, {
                                                    marginLeft: "0px",
                                                    fontSize: "14px",
                                                    textAlign: "center"
                                                })}>
                                                    Index Pro allows clients to conduct tax transition analyses to
                                                    help its
                                                    current and prospective accounts evaluate the potential benefits
                                                    of transitioning to a new
                                                    Index Pro portfolio.

                                                </p>

                                            </Grid>
                                            <Grid container justifyContent="center" style={{marginTop: "10px"}}>
                                                <p style={{
                                                    fontSize: "16px",
                                                    textAlign: "center",
                                                    fontWeight: "600",
                                                    marginBottom: "0px"
                                                }}>
                                                    Would you like Index Pro to create a transition report for you?
                                                </p>
                                            </Grid>
                                            <Grid container justifyContent="center" style={{marginTop: "60px"}}>
                                                <Button style={Object.assign({}, bordered_button, {
                                                    width: "250px",
                                                    marginRight: "20px",
                                                })} onClick={() => setTransitionOpen(false)}>
                                                    Cancel
                                                </Button>
                                                <Button style={Object.assign({}, bordered_button, {
                                                    width: "250px",
                                                    color: "white",
                                                    backgroundColor: themeColor
                                                })} onClick={() => setTransitionPage(1)}>
                                                    Continue
                                                </Button>
                                                <p style={{fontSize: "10px", marginTop: "300px"}}>
                                                    Index Pro does not provide tax advice. Please consult a tax professional.</p>
                                            </Grid>
                                        </>
                                    )
                                } else if (transitionPage === 1) {
                                    return (
                                        <>
                                            <Grid container justifyContent="center">
                                                <p style={Object.assign({}, card_title, {
                                                    marginLeft: "0px",
                                                    marginTop: "-5px"
                                                })}>
                                                    Upload Current Portfolio
                                                </p>
                                            </Grid>
                                            <Grid container justifyContent="center"
                                                  style={{
                                                      marginLeft: "50px",
                                                      marginRight: "50px",
                                                      marginTop: "10px"
                                                  }}>
                                                <p style={Object.assign({}, card_subtitle, {
                                                    marginLeft: "0px",
                                                    fontSize: "14px",
                                                    textAlign: "center"
                                                })}>
                                                    Upload a file with the following information:
                                                </p>
                                            </Grid>
                                            <Grid container direction="column" alignItems="flex-start"
                                                  style={{marginLeft: "50px"}}>
                                                <ul>
                                                    {['Security name', 'Security ticker', 'Purchase date', 'Number of shares',
                                                        'Cost basis (per share) or Cost basis (total)', 'Market value'].map(i => (
                                                        <li style={{margin: "3px"}}>{i}</li>
                                                    ))}
                                                </ul>
                                            </Grid>
                                            <div style={{marginLeft:"50px", marginRight:"50px"}}>
                                                <Alert severity="info">These fields are required. Empty or incorrect fields may result in partial or incorrect analysis.</Alert>
                                            </div>
                                            <Grid container direction="row" justifyContent="center"
                                                  alignItems="center"
                                                  style={{width: "300px", marginTop: "20px", marginLeft: "150px"}}
                                                  className={styles.fileDropzone}>
                                                <DropzoneArea
                                                    onChange={(file) => {
                                                        setPortfolioFile(file)
                                                    }}
                                                    onDrop={async ([file]) => {
                                                        var reader = new FileReader();
                                                        reader.onload = function (evt) {
                                                            const bstr = evt.target.result;
                                                            setPortfolioFileData(bstr)

                                                        };
                                                        reader.readAsText(file);
                                                    }}
                                                    Icon={"null"}
                                                    acceptedFiles={['text/csv']}
                                                    dropzoneText={<p style={{fontSize: "16px"}}>Drop your portfolio
                                                        here, or <span
                                                            style={{color: themeColor}}>browse</span><br/><span
                                                            style={{fontSize: "12px"}}>Only CSV files are allowed</span>
                                                    </p>}
                                                    filesLimit={1}
                                                    showAlerts={true}
                                                    alertSnackbarProps={{
                                                        anchorOrigin: {
                                                            vertical: 'bottom',
                                                            horizontal: 'center'
                                                        }
                                                    }}
                                                    getPreviewIcon={(file) => {
                                                        return (
                                                            <DescriptionIcon style={{
                                                                height: "150px",
                                                                width: "150px",
                                                                marginLeft: "75px"
                                                            }}/>
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                            <Grid container justifyContent="center" style={{marginTop: "40px"}}>
                                                <Button style={Object.assign({}, bordered_button, {
                                                    width: "250px",
                                                    marginRight: "20px",
                                                })} onClick={() => setTransitionPage(0)}>
                                                    Back
                                                </Button>
                                                <Button
                                                    disabled={portfolioFileData.length === 0}
                                                    style={Object.assign({}, bordered_button, {
                                                        width: "250px",
                                                        color: "white",
                                                        border: "",
                                                        backgroundColor: portfolioFileData.length === 0 ? ("grey") : (themeColor)
                                                    })} onClick={() => {
                                                    setTransitionPage(2);
                                                    createPortfolioData(1)
                                                }}>
                                                    Continue
                                                </Button>
                                            </Grid>
                                        </>
                                    )
                                } else if (transitionPage === 2) {
                                    return (
                                        <>
                                            <Grid container justifyContent="center" direction="column"
                                                  alignItems="center">
                                                <p style={Object.assign({}, card_title, {
                                                    marginLeft: "0px",
                                                    marginTop: "-5px"
                                                })}>
                                                    Field Mapping
                                                </p>
                                                <p style={Object.assign({}, card_subtitle, {
                                                    marginLeft: "0px",
                                                    fontSize: "14px",
                                                    textAlign: "center"
                                                })}>
                                                    Map your columns below
                                                </p>
                                            </Grid>
                                            <Grid container justifyContent="center">
                                                <TextField
                                                    label="Header Start Row"
                                                    type="number"
                                                    variant="outlined"
                                                    onChange={(e) => {
                                                        e.persist();
                                                        createPortfolioData(e.target.value)
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} style={{
                                                marginTop: "20px",
                                                marginLeft: "20px",
                                                overflowY: "scroll",
                                                height: "420px",
                                                width: "600px",
                                                overflowX: "hidden"
                                            }}>
                                                {Object.keys(portfolioFileDataRows[0]).map(
                                                    i => (

                                                        <Grid container direction="row" alignItems="center">
                                                            <TextField
                                                                variant="outlined"
                                                                disabled={true}
                                                                type="text"
                                                                value={i}
                                                                autoFocus
                                                                size="small"
                                                                style={{
                                                                    backgroundColor: "#F5F8FA",
                                                                    width: "255px",
                                                                    marginTop: "5px", marginBottom: "5px"
                                                                }}
                                                                inputProps={{style: ChromeAutoFillOverride}}
                                                            />
                                                            <ArrowRightAltIcon style={{
                                                                marginLeft: "10px",
                                                                marginRight: "10px",
                                                                color: "#dadce0"
                                                            }}/>
                                                            <Autocomplete
                                                                size="small"
                                                                value={portfolioMappings.filter(obj => {
                                                                    return obj.initial_field === i
                                                                }).length !== 0 ?
                                                                    (portfolioMappings.filter(obj => {
                                                                        return obj.initial_field === i
                                                                    })[0].new_field) : null}
                                                                onChange={(option, value) => addPortfolioMappings(i, value)}
                                                                options={mappingOptions.filter((el) => !portfolioMappings.map(function (el) {
                                                                    return el.new_field
                                                                }).includes(el))}
                                                                style={{
                                                                    backgroundColor: "#F5F8FA",
                                                                    width: "255px",
                                                                    marginTop: "5px", marginBottom: "5px"
                                                                }}
                                                                renderInput={(params) => <TextField {...params}
                                                                                                    variant="outlined"/>}
                                                            />
                                                        </Grid>
                                                    )
                                                )}
                                            </Grid>
                                            <Grid container justifyContent="center" style={{
                                                marginTop: "20px",
                                                marginLeft: "20px",
                                                marginRight: "20px"
                                            }}>
                                                {allFieldsMapped()
                                                    ? (
                                                        <Alert severity="success" style={{width: "100%"}}>All
                                                            Required Fields Mapped!</Alert>
                                                    ) : (
                                                        <Alert severity="error" style={{width: "100%"}}>Not All
                                                            Required Fields Mapped!</Alert>
                                                    )}
                                            </Grid>
                                            <Grid container justifyContent="center" style={{marginTop: "40px"}}>
                                                <Button style={Object.assign({}, bordered_button, {
                                                    width: "250px",
                                                    marginRight: "20px",
                                                })} onClick={() => setTransitionPage(1)}>
                                                    Back
                                                </Button>
                                                <Button
                                                    disabled={!allFieldsMapped()}
                                                    onClick={() => setTransitionPage(3)}
                                                    style={Object.assign({}, bordered_button, {
                                                        width: "250px",
                                                        color: "white",
                                                        border: "",
                                                        backgroundColor: allFieldsMapped() ? (themeColor) : ("grey")
                                                    })}>
                                                    Continue
                                                </Button>
                                            </Grid>
                                        </>
                                    )
                                } else if (transitionPage === 3) {
                                    return (
                                        <>
                                            <Grid container justifyContent="center" direction="column"
                                                  alignItems="center">
                                                <p style={Object.assign({}, card_title, {
                                                    marginLeft: "0px",
                                                    marginTop: "-5px"
                                                })}>
                                                    Additional Information
                                                </p>
                                            </Grid>
                                            <Grid container justifyContent="center" direction="column"
                                                  alignItems="center" style={{marginTop: "10px"}}>
                                                <p>What Strategy will this account be transitioned to?</p>
                                                <Autocomplete
                                                    options={customStrategyItems.map(function (el) {
                                                        return el.strategy_name;
                                                    })}
                                                    style={{
                                                        width: "400px",
                                                    }}
                                                    margin="normal"
                                                    onChange={(option) => setAccountStrategy(option.target.innerText)}
                                                    value={account_selected_strategy}
                                                    renderInput={(params) => <TextField
                                                        style={{
                                                            backgroundColor: themeBackground,
                                                        }}
                                                        margin="normal"

                                                        {...params} label="Strategy Name" variant="outlined"
                                                    />}
                                                />
                                            </Grid>
                                            <Grid container justifyContent="center" direction="column"
                                                  alignItems="center" style={{marginTop: "10px"}}>
                                                <p>Yearly Long Term Capital Gains Budget (% MV)</p>
                                                <TextField
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment: <InputAdornment
                                                            position="end">%</InputAdornment>
                                                    }}
                                                    type="number"
                                                    autoFocus
                                                    value={accountBudget}
                                                    onChange={(e) => {
                                                        let value = e.target.value;
                                                        if (value < -5) value = -5;
                                                        if (value > 100) value = 100;
                                                        setAccountBudget(value);
                                                    }}
                                                    style={{
                                                        backgroundColor: "#F5F8FA",
                                                        width: "100px",
                                                        marginTop: "10px",
                                                        marginBottom: "10px"
                                                    }}
                                                    inputProps={{
                                                        min: -5,
                                                        max: 100,
                                                        style: ChromeAutoFillOverride,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid container justifyContent="center" direction="column"
                                                  alignItems="center" style={{marginTop: "10px"}}>
                                                <p>Additional cash amount being provided, if any</p>
                                                <TextField
                                                    variant="outlined"
                                                    InputProps={{
                                                        startAdornment: <InputAdornment
                                                            position="start">$</InputAdornment>
                                                    }}
                                                    type="number"
                                                    autoFocus
                                                    value={accountCash}
                                                    onChange={(e) => {
                                                        e.persist();
                                                        setAccountCash(e.target.value)
                                                    }}
                                                    style={{
                                                        backgroundColor: "#F5F8FA",
                                                        width: "300px",
                                                        marginTop: "10px",
                                                        marginBottom: "10px"
                                                    }}
                                                    inputProps={{style: ChromeAutoFillOverride}}
                                                />
                                            </Grid>
                                            <Grid container justifyContent="center" style={{marginTop: "40px"}}>
                                                <Button style={Object.assign({}, bordered_button, {
                                                    width: "250px",
                                                    marginRight: "20px",
                                                })} onClick={() => setTransitionPage(2)}>
                                                    Back
                                                </Button>
                                                <Button
                                                    onClick={() => createTransitionReport()}
                                                    disabled={!(account_selected_strategy)}
                                                    style={Object.assign({}, bordered_button, {
                                                        width: "250px",
                                                        color: "white",
                                                        border: "",
                                                        backgroundColor: account_selected_strategy ? (themeColor) : ("grey")
                                                    })}>
                                                    Generate Transition Report
                                                </Button>
                                            </Grid>
                                        </>
                                    )
                                }
                            })()}
                        </Grid>
                    </Paper>
                </div>
            </Modal>
        </div>
    )
}

export default AccountTransitionPortfolio