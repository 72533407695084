import React, {useRef} from "react";
import {bordered_button, card_title, ipGreen, ipRed, themeColor, card_subtitle} from "../../Styles/styles";
import {Grid, Button, Paper, TextField} from "@material-ui/core";
import {CoverPage} from "./cover-page";
import {useReactToPrint} from "react-to-print";
import {PortfolioDetailsTitlePage} from "./portfolio-details-title-page";
import {PortfolioDetails} from "./portfolio-details";
import {Disclosure} from "./disclosure";
import {EnvironmentalSelectionsTitlePage} from "./environmental-selections-title-page";
import {SocialSelectionsTitlePage} from "./social-selections-title-page";
import {GovernanceSelectionsTitlePage} from "./governance-selections-title-page";
import {TaxSelectionsTitlePage} from "./tax-selections-title-page";
import {PerformanceSelectionsTitlePage} from "./performance-selections-title-page";
import {Performance} from "./performance";
import {SelectionImpact} from "./selection-impact";
import {About} from "./about";
import {useESGModels, useCustomExclusionModels, useThemeModels} from "../Hooks/Data/Builder/builder";
import {TaxImpact} from "./tax-selections";
import {Exclusions} from "./exclusion-selections";
import {Contents} from "./contents";
import CircularProgress from "@material-ui/core/CircularProgress";
import {ThemesSelectionsTitlePage} from "./themes-selections-title-page";
import GetAppIcon from "@material-ui/icons/GetApp";
import Modal from "@material-ui/core/Modal";
import Alert from "@material-ui/lab/Alert";
import {useUserName} from "../Hooks/Data/User/user";
import {Footnotes} from "./footnotes";
import ReactGA from "react-ga";
import html2pdf from "html2pdf.js";
import {Storage} from "@aws-amplify/storage";
import {TransitionDetails} from "./transition-details";
import {TransitionImpact} from "./transition-impact";
import {Mixpanel} from "../../Utils/Mixpanel";
import {TransitionDetailsTitlePage} from "./transition-details-title-page";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

function Proposal(props) {

    const TRACKING_ID = "UA-240852490-1";
    ReactGA.initialize(TRACKING_ID);

    const proposalData = props['proposal_data']
    const transitionData = props['transition_data']
    const tax = props['tax']

    const componentRef = useRef();

    const [loading, setLoading] = React.useState(null)

    const handlePrint = () => {
        handleClose()
        setLoading('loading')
        window.Intercom('trackEvent', 'downloaded-proposal');
        Mixpanel.track('Downloaded Proposal');
        printHTML();
        setTimeout(() => {
            print();
            setLoading(null)
        }, 4500);
    }

    const printHTML = useReactToPrint(
        {
            content: () => componentRef.current,
            copyStyles: true,
            print: async (printIframe) => {
                const document = printIframe.contentDocument;
                if (document) {
                    const html = document.getElementsByTagName('html')[0];
                    const date = new Date
                    try {
                        await Storage.put(`compliance/${date.getFullYear()}/${date.getMonth() + 1}/${props.strategy_id + '---' + date.toString()}.html`, html.outerHTML, {
                            level: 'private', contentType: 'image/html'
                        });
                    } catch (e) {
                        console.error(e)
                    }
                }
            }
        }
    )

    const print = useReactToPrint(
        {content: () => componentRef.current}
    )

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
            ReactGA.event({
                category: 'User',
                action: 'Downloaded a Proposal'
            });
            setOpen(true);
        }
    ;

    const handleClose = () => {
            setOpen(false);
        }
    ;

    const defaultName = useUserName()
    const [name, setName] = React.useState(null)

    const defaultProposalCover = "https://res.cloudinary.com/indexpro/image/upload/Proposal/Proposal%20Cover%20-%20New3.jpg"
    const mountainProposalCover = "https://res.cloudinary.com/indexpro/image/upload/Proposal/Proposal%20Cover%20Mountain.jpg"
    const windmillProposalCover = "https://res.cloudinary.com/indexpro/image/upload/Proposal/Proposal%20Cover%20Windmill.jpg"

    const [proposalCover, setProposalCover] = React.useState(defaultProposalCover)

    const handleClientNameChange = (event) => {
        event.persist();
        setName(event.target.value);
    }

    const page_numbers = Object.assign({}, proposalData['page_numbers'])
    if (transitionData) {
        Object.keys(page_numbers).forEach(function (key) {
            page_numbers[key] = (page_numbers[key] || 0) + 3;
        });
    }

    const ProposalPDF = () => {
        const environmental_models = useESGModels(proposalData['environmental'] ? (proposalData['environmental']['selections']) : ([]))
        const social_models = useESGModels(proposalData['social'] ? (proposalData['social']['selections']) : ([]))
        const governance_models = useESGModels(proposalData['governance'] ? (proposalData['governance']['selections']) : ([]))
        const theme_models = useThemeModels(proposalData['themes'] ? (proposalData['themes']['selections']) : ([]))
        const exclusion_models = useCustomExclusionModels(proposalData['exclusions'] ? (proposalData['exclusions']['custom_exclusions']) : ([]))
        const prepared_for = name ? (name) : (defaultName)
        return (
            <div ref={componentRef} id="proposal" className="customFontText">
                <style type="text/css" media="print">{"@page {size: portrait; margin: 0 0 0 0}"}</style>
                <style type="text/css" media="print">{"@media print {body {zoom: 100%;}}"}</style>
                <div style={{height: "1132px", width: "875px"}}>
                    {CoverPage(proposalData['benchmark'], prepared_for, proposalCover)}
                </div>
                <div style={{height: "1132px", width: "875px"}}>
                    {Contents(page_numbers, transitionData)}
                </div>
                {transitionData ? (
                    <>
                        <div style={{height: "1132px", width: "875px"}}>
                            {TransitionDetailsTitlePage()}
                        </div>
                        <div style={{height: "1132px", width: "875px"}}>
                            {TransitionDetails(proposalData['benchmark'], proposalData['headers'], transitionData, tax)}
                        </div>
                        <div style={{height: "1132px", width: "875px"}}>
                            {TransitionImpact(proposalData['benchmark'], proposalData['headers'], transitionData)}
                        </div>
                    </>
                ) : (
                    <></>
                )}
                <div style={{height: "1132px", width: "875px"}}>
                    {PortfolioDetailsTitlePage()}
                </div>
                <div style={{height: "1132px", width: "875px"}}>
                    {PortfolioDetails(proposalData['benchmark'],
                        proposalData['headers'],
                        proposalData['description'],
                        proposalData['portfolio_details'],
                        proposalData['sector_breakdown'],
                        proposalData['top_10_positions'], transitionData)}
                </div>
                {proposalData['headers'].includes('Environmental') ? (
                    <>
                        <div style={{height: "1132px", width: "875px"}}>
                            {EnvironmentalSelectionsTitlePage()}
                        </div>
                        <div style={{height: "1132px", width: "875px"}}>
                            {SelectionImpact(
                                'Environmental',
                                proposalData['headers'],
                                page_numbers['Environmental'],
                                proposalData['benchmark'],
                                environmental_models,
                                proposalData['environmental']['top5_exclusions'],
                                proposalData['environmental']['impact_data'], transitionData)}
                        </div>
                    </>
                ) : (<></>)}
                {proposalData['headers'].includes('Social') ? (
                    <>
                        <div style={{height: "1132px", width: "875px"}}>
                            {SocialSelectionsTitlePage()}
                        </div>
                        <div style={{height: "1132px", width: "875px"}}>
                            {SelectionImpact(
                                'Social',
                                proposalData['headers'],
                                page_numbers['Social'],
                                proposalData['benchmark'],
                                social_models,
                                proposalData['social']['top5_exclusions'],
                                proposalData['social']['impact_data'], transitionData)
                            }
                        </div>
                    </>
                ) : (<></>)}
                {proposalData['headers'].includes('Governance') ? (
                    <>
                        <div style={{height: "1132px", width: "875px"}}>
                            {GovernanceSelectionsTitlePage()}
                        </div>
                        <div style={{height: "1132px", width: "875px"}}>
                            {SelectionImpact(
                                'Governance',
                                proposalData['headers'],
                                page_numbers['Governance'],
                                proposalData['benchmark'],
                                governance_models,
                                proposalData['governance']['top5_exclusions'],
                                proposalData['governance']['impact_data'], transitionData)
                            }
                        </div>
                    </>
                ) : (<></>)}
                {proposalData['headers'].includes('Themes') ? (
                    <>
                        <div style={{height: "1132px", width: "875px"}}>
                            {ThemesSelectionsTitlePage()}
                        </div>
                        <div style={{height: "1132px", width: "875px"}}>
                            {SelectionImpact(
                                'Themes',
                                proposalData['headers'],
                                page_numbers['Themes'],
                                proposalData['benchmark'],
                                theme_models,
                                proposalData['themes']['top5_overweight'],
                                proposalData['themes']['impact_data'], transitionData)
                            }
                        </div>
                    </>
                ) : (<></>)}
                {proposalData['headers'].includes('Exclusions') ? (
                    <>
                        <div style={{height: "1132px", width: "875px"}}>
                            {Exclusions('Exclusions',
                                proposalData['headers'],
                                page_numbers['Exclusions'],
                                exclusion_models,
                                proposalData['exclusions']['revenue5'],
                                proposalData['exclusions']['revenueAny'],
                                proposalData['exclusions']['top5_exclusions'], transitionData)}
                        </div>
                    </>
                ) : (<></>)}
                <div style={{height: "1132px", width: "875px"}}>
                    {TaxSelectionsTitlePage()}
                </div>
                <div style={{height: "1132px", width: "875px"}}>
                    {TaxImpact('Taxes', proposalData['headers'], page_numbers['Taxes'], proposalData['tax_strategy'], proposalData['transition_management'], transitionData)}
                </div>
                {proposalData['headers'].includes('Performance') ? (
                    <>
                        <div style={{height: "1132px", width: "875px"}}>
                            {PerformanceSelectionsTitlePage()}
                        </div>
                        <div style={{height: "1132px", width: "875px"}}>
                            {Performance('Performance', proposalData['headers'], page_numbers['Performance'], page_numbers['Taxes'], proposalData['performance'], transitionData)}
                        </div>
                    </>
                ) : (<></>)}
                <div style={{height: "1132px", width: "875px"}}>
                    {About()}
                </div>
                <div style={{height: "1132px", width: "875px"}}>
                    {Footnotes(page_numbers, transitionData, proposalData['headers'])}
                </div>
                <div style={{height: "1132px", width: "875px"}}>
                    {Disclosure()}
                </div>
            </div>
        )
    };

    return (
        <div>
            <div style={{visibility: "hidden", height: "0px", width: "0px", overflowX: "hidden"}}>
                {ProposalPDF()}
            </div>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center"
                  style={{marginLeft: "20px", marginRight: "20px"}}>
                <Button style={Object.assign({}, bordered_button, {width: "180px", height: "40px"})}
                        onClick={handleOpen}
                        startIcon={loading !== 'loading' ? (<GetAppIcon/>) : (<></>)} id="downloadproposal">
                    {loading === 'loading' ? (
                        <CircularProgress style={{color: themeColor, padding: "5px"}}/>
                    ) : (
                        'Download Proposal'
                    )}

                </Button>
            </Grid>
            <Modal
                open={open}
                style={{overflow: "scroll"}}
            >
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Paper elevation={0} style={{
                        width: "600px", height: "600px", marginTop: "300px"
                    }}>
                        <Grid container direction="row">
                            <Grid item xs={10} style={{paddingTop: "25px", marginBottom: "10px"}}>
                                <p style={card_title}>Client Name</p>
                                <p style={card_subtitle}>Who is this proposal prepared for?</p>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    autoFocus
                                    required
                                    label="Client Name"
                                    defaultValue={defaultName}
                                    value={name}
                                    onChange={(e) => handleClientNameChange(e)}
                                    style={{
                                        backgroundColor: "#F5F8FA",
                                        width: "560px",
                                        marginLeft: "20px",
                                    }}
                                />
                            </Grid>
                            <p style={Object.assign({}, card_subtitle, {color: "black"})}>Select a cover photo for your
                                proposal</p>
                            <Grid item xs={12}>
                                <Grid container direction="row"
                                      style={{marginRight: "20px", marginLeft: "20px", marginTop: "5px"}}>
                                    <Card style={{
                                        marginRight: "40px",
                                        border: `4px solid ${proposalCover === defaultProposalCover
                                            ? (themeColor) : ("#dadce0")}`
                                    }} onClick={() =>
                                        setProposalCover(defaultProposalCover)}>
                                        <CardActionArea>
                                            <CardMedia
                                                image={defaultProposalCover}
                                                style={{height: "200px", width: "150px"}}
                                            />

                                        </CardActionArea>
                                    </Card>
                                    <Card style={{
                                        marginRight: "40px",
                                        border: `4px solid ${proposalCover ===
                                        mountainProposalCover ? (themeColor) : ("#dadce0")}`
                                    }} onClick={() =>
                                        setProposalCover(mountainProposalCover)}>
                                        <CardActionArea>
                                            <CardMedia
                                                image={mountainProposalCover}
                                                style={{height: "200px", width: "150px"}}
                                            />

                                        </CardActionArea>
                                    </Card>
                                    <Card style={{
                                        marginRight: "40px",
                                        border: `4px solid ${proposalCover ===
                                        windmillProposalCover ? (themeColor) : ("#dadce0")}`
                                    }} onClick={() =>
                                        setProposalCover(windmillProposalCover)}>
                                        <CardActionArea>
                                            <CardMedia
                                                image={windmillProposalCover}
                                                style={{height: "200px", width: "150px"}}
                                            />

                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{marginLeft: "380px", marginTop: "50px"}}>
                                <Button style={Object.assign({}, bordered_button, {width: "95px", marginRight: "10px"})}
                                        onClick={() => {
                                            handleClose()
                                        }}
                                >Cancel</Button>
                                <Button style={Object.assign({}, bordered_button, {
                                    width: "95px",
                                    color: "white",
                                    backgroundColor: themeColor
                                })}
                                        onClick={handlePrint} id="downloadproposal2"
                                >Download</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </Modal>
        </div>
    )


}

export default Proposal;
