import React, {useState, useRef, useEffect} from "react";
import {Grid, Paper, Button, TextField, BottomNavigation, BottomNavigationAction} from "@material-ui/core";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {observer} from "mobx-react-lite";
import {
    themeColor,
    card_title,
    card_subtitle,
    bordered_button,
    ipRed,
    paper_right,
    chip_style_red, chip_style_green, color_filters, ipGreen, ipYellow, themeBackground
} from "../../Styles/styles";
import Modal from "@material-ui/core/Modal";
import {useHistory} from "react-router-dom";
import {DataStore} from "@aws-amplify/datastore";
import {CustomStrategies} from "../../models";
import Alert from "@material-ui/lab/Alert";
import "./strategy-builder.css"
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
    useAllocationModel,
    useBenchmarkCode, useCustomExclusionData, useCustomValueData,
    useESGValueData, usePagination, useRevenueExclusionsData, useThemeData
} from "../Hooks/Data/Builder/builder";
import {
    builder_journey_value_exclusions,
    builder_journey_overweight, msci_abc_rating, msci_abc_rating_to_color, msci_colors_from_type
} from "./Data/builder_journey";
import {Auth} from "aws-amplify";
import BarChartOutlinedIcon from '@material-ui/icons/BarChartOutlined';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import {Autocomplete, createFilterOptions} from "@material-ui/lab";
import {redux} from "../../Utils/data_utils";
import ClearIcon from "@material-ui/icons/Clear";
import Fab from "@material-ui/core/Fab";
import {useUserRole} from "../Hooks/Data/User/user";
import {userRoleActionWrapper} from "../../Utils/role_utils";
import Pagination from '@material-ui/lab/Pagination';
import {useCustomStrategies} from "../Hooks/Data/Strategies/strategies";
import {Mixpanel} from "../../Utils/Mixpanel";

function BuilderJourney({builder_state}) {
    const history = useHistory();

    //button data
    const [next_data] = useState({
        0: 'Next / Values',
        1: 'Next / Themes',
        2: 'Next / Performance',
        3: 'Next / Taxes',
        4: 'Next / Summary',
        5: 'Save & Share'
    })

    const handleNextButton = () => {
        var new_tab = builder_state.tab + 1
        if (new_tab >= 5) {
            new_tab = 5
            handleOpen()
        }
        builder_state.setTab(new_tab)
    }

    //builder state
    const benchmark_name = builder_state.benchmark_name
    const allocation_model_id = builder_state.allocation_model_id
    const core_options = builder_state.core_options
    const custom_options = builder_state.custom_options
    const custom_exclusions = builder_state.custom_exclusions
    const revenue_threshold_exclusions5 = builder_state.revenue_threshold_exclusions5
    const revenue_threshold_exclusionsAny = builder_state.revenue_threshold_exclusionsAny
    const selected_themes = builder_state.themes
    const tax = builder_state.apply_tax_preferences
    const tax_preference = builder_state.tax_preference
    const transition_management = builder_state.transition_management
    const alpha_group = builder_state.alpha_group
    const tracking_error = builder_state.tracking_error
    const rebalance_frequency = builder_state.rebalance_frequency
    const cash_position_size = builder_state.cash_position_size
    const sector_constraints = builder_state.sector_constraints
    const industry_constraints = builder_state.industry_constraints
    const max_position_count = builder_state.max_position_count
    const security_exclusions = builder_state.security_exclusions


    const custom_strategies = useCustomStrategies()
    const custom_strategies_names = custom_strategies.map(i => i.strategy_name)

    useEffect(()=>{
        handlePositionBreakdownClose()
    },[benchmark_name])


    //esg exclusion data
    const allocation_model = useAllocationModel(allocation_model_id)
    const benchmark_code = useBenchmarkCode(benchmark_name, allocation_model_id)
    const esg_value_data = useESGValueData(benchmark_code, allocation_model_id, allocation_model)
    const custom_value_data = useCustomValueData()
    const theme_data = useThemeData()
    const exclusion_data = useCustomExclusionData()
    const revenue_data = useRevenueExclusionsData()

    const environmental_titles = custom_value_data.filter(i => i.label === 'Environmental').map(i => i.title)
    const social_titles = custom_value_data.filter(i => i.label === 'Social').map(i => i.title)
    const governance_titles = custom_value_data.filter(i => i.label === 'Governance').map(i => i.title)
    const theme_titles = theme_data.map(i => i.title)
    const exclusion_titles = exclusion_data.map(i => i.title).concat(revenue_data.map(i => i.title)).concat(revenue_data.map(i => i.title + ' 5%'))

    //selections, builder journey value data
    const selections_arr = Array.from(core_options).concat(Array.from(custom_options)).concat(Array.from(custom_exclusions)).concat(Array.from(revenue_threshold_exclusionsAny)).concat(Array.from(revenue_threshold_exclusions5))
    const selection_arr_no_rev = Array.from(core_options).concat(Array.from(custom_options))
    const value_exclusion_data = builder_journey_value_exclusions(selections_arr, esg_value_data)
    const value_overweight_data = builder_journey_overweight(selection_arr_no_rev, esg_value_data, selection_arr_no_rev.concat(Array.from(selected_themes)), security_exclusions)
    const [open, setOpen] = React.useState(false);
    const [openPositionBreakdown, setOpenPositionBreakdown] = React.useState(null);
    const [strategy_name, setStrategyName] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [mode, setMode] = React.useState(0);
    const [researchCompany, setResearchCompany] = React.useState(null);
    const user_role = useUserRole()

    let [page, setPage] = useState(1);
    let [pageOverweight, setPageOverweight] = useState(1);

    const handleChange = (e, p) => {
        setPage(p);
        _value_exclusion_data.jump(p);
    };

    const handleChangeOverweight = (e, p) => {
        setPageOverweight(p);
        _value_overweight_data.jump(p);
    };

    const _value_exclusion_data = usePagination(value_exclusion_data[1], 5)
    const _value_overweight_data = usePagination(value_overweight_data[1], 5)

    const researchCompanyHasData = () => {
        return esg_value_data.filter(i => i.company_name === researchCompany.company_name).length > 0;
    }

    const filtered_esg_data = (type) => {
        const filtered = esg_value_data.filter(i => i.company_name === researchCompany.company_name).filter(i => environmental_titles.concat(social_titles).concat(governance_titles).includes(i.map))
        switch (type) {
            case 'ESG Laggard':
                return filtered.filter(i => i.value < 2.86)
            case 'Average':
                return filtered.filter(i => i.value >= 2.86 & i.value < 7.15)
            case 'ESG Leader':
                return filtered.filter(i => i.value >= 7.15)
        }
    }

    const filtered_theme_data = () => {
        return esg_value_data.filter(i => i.company_name === researchCompany.company_name).
            filter(i => theme_titles.includes(i.map)).
            filter(i => i.value > 0).
            sort(function (a, b) {
                return b.value - a.value
            })
    }

    const filtered_exclusion_data = () => {
        return esg_value_data.filter(i => i.company_name === researchCompany.company_name).filter(i => exclusion_titles.includes(i.map)).filter(i => i.value === 0)
    }


    const handleOpen = () => {
            setOpen(true);
        }
    ;

    const handleClose = () => {
            setOpen(false);
        }
    ;

    const resetPagination = () => {
        setPage(1)
        setPageOverweight(1)
        _value_exclusion_data.jump(1)
        _value_overweight_data.jump(1)
    }

    const handlePositionBreakdownOpen = (event) => {
        setResearchCompany(null)
        setMode(0)
        resetPagination()
        Mixpanel.track('Opened Builder Journey');
        setOpenPositionBreakdown(event.currentTarget);
    };

    const handlePositionBreakdownClose = () => {
        setResearchCompany(null)
        setMode(0)
        resetPagination()
        setOpenPositionBreakdown(null);
    };


    const saveStrategy = async () => {
            const data = {
                benchmark: benchmark_name,
                allocation_model_id: allocation_model_id,
                values: Array.from(core_options).concat(Array.from(custom_options)),
                custom_exclusions: Array.from(custom_exclusions),
                revenue_5pct_exclusions: Array.from(revenue_threshold_exclusions5),
                revenue_any_exclusions: Array.from(revenue_threshold_exclusionsAny),
                themes: Array.from(selected_themes),
                tax: tax,
                tax_preference: tax_preference,
                transition_management: transition_management,
                alpha: alpha_group,
                tracking_error: tracking_error,
                rebalance_frequency: rebalance_frequency,
                cash_position_size: cash_position_size,
                sector_constraints: sector_constraints,
                industry_constraints: industry_constraints,
                max_position_count: max_position_count,
                security_exclusions: security_exclusions
            }
            {
                if (strategy_name && !custom_strategies_names.includes(strategy_name)) {
                    const admin_info = await Auth.currentUserInfo()
                    try {
                        await DataStore.save(
                            new CustomStrategies({
                                "strategy_name": strategy_name,
                                "strategy_id": null,
                                "values": Array.from(core_options).join(", "),
                                "benchmark": benchmark_name,
                                "settings": JSON.stringify(data),
                                "user_info": localStorage.getItem("signed-in-user"),
                                "admin_id": admin_info["id"],
                                "data": null
                            })
                        ).then(id => {
                            builder_state.reset();
                            Mixpanel.track('Created Strategy');
                            window.Intercom('trackEvent', 'created-strategy');
                            history.push('/participant/strategies');
                        });
                        setOpen(false);
                    } catch (error) {
                        console.log(error);
                    }
                } else if (custom_strategies_names.includes(strategy_name)) {
                    setError('Strategy Name Taken By Another Strategy')
                } else {
                    setError('Please Name Your Strategy')
                }
            }
        }
    ;


    const handleStrategyNameChange = (event) => {
        event.persist();
        setStrategyName(event.target.value);
    }


    const statusDiv = (company_name, label, weight, ticker, gics_1, type) => {
        return (
            <Grid container direction="row" className="hover-fund" alignContent="center" alignItems="center"
                  style={{paddingBottom: "8px", borderTop: "1px solid #dadce0", cursor: "pointer"}} onClick={() =>
            {setResearchCompany({'company_name':company_name, 'weight':weight, 'ticker':ticker, 'gics_1':gics_1});
            setMode(1)}}>
                <Grid container direction="row" style={{paddingTop: "6px"}}>
                    <Grid item xs={1} style={{marginLeft: "20px", marginTop: "15px"}}>
                        <Avatar style={{height: "50px", width: "50px"}}
                                src={"https://universal.hellopublic.com/companyLogos/" + ticker + "@2x.png?v=1b72d14c-4115-4bac-ab09-487a2ffb364e"}>{company_name[0]}</Avatar>
                    </Grid>
                    <Grid item xs={6}>
                        <p style={Object.assign({}, card_title, {
                            fontSize: "11px",
                            marginLeft: "25px"
                        })}>{company_name}</p>
                    </Grid>
                    <Grid item xs={4}>
                        <p style={Object.assign({}, card_title,
                            {fontSize: "14px", marginLeft: "170px", marginTop: "25px", color: type==='Underweight' ? (ipRed) : ("black")})}>{type==='Underweight' ? ("-") : ("")}{weight}</p>
                    </Grid>
                    <Grid item xs={12} style={{marginLeft: "90px", marginTop: "-35px"}}>
                        {label.splice(0, 3).map(item => {
                            return (
                                <Chip style={type==='Underweight' ? (chip_style_red) : (chip_style_green)} label={item}/>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const ESGResearch = (type) => {
        return (
            <Paper style={{width: "390px", paddingBottom: "10px"}}
                   elevation={0}>
                <Grid container direction="row" alignItems="center"
                      justifyContent="center"
                      style={{backgroundColor: msci_colors_from_type(type)}}>
                    <p style={{color: "white",fontSize: "17px",margin: "5px"}}>{type}</p>
                </Grid>
                {filtered_esg_data(type).map(i => (
                    <Grid container direction={"row"} alignItems={"center"}>
                        <Grid item md={2} style={{marginTop: "20px"}}>
                            <img
                                src={`https://res.cloudinary.com/indexpro/image/upload/Research Impact Icons/${i.map.replace('&', 'and')}.svg`}
                                height="50px"
                                style={{textAlign: "center",maxWidth: "95px"}}/>
                        </Grid>
                        <Grid item md={10} style={{marginTop: "20px"}}>
                            <p style={{fontSize: "15px",marginBottom: "0px",fontWeight: "600"}}>{i.map}</p>
                            <p style={{fontSize: "15px",fontWeight: "500",marginTop: "0px",color: "#555555"}}>
                                {msci_abc_rating(i.value.toFixed(1))}
                            </p>
                        </Grid>
                    </Grid>
                ))}
            </Paper>
        )
    }

    const ThemeResearch = () => {
        return (
            <Paper style={{width: "585px", paddingBottom: "10px", marginTop: "20px"}} elevation={0}>
                <Grid container direction="row" alignItems="center" justifyContent="center" style={{backgroundColor: "grey"}}>
                    <p style={{color: "white",fontSize: "17px",margin: "5px"}}>Themes</p>
                </Grid>
                {filtered_theme_data().map(i => (
                    <Grid container direction={"row"} alignItems={"center"}>
                        <Grid item md={2} style={{marginTop: "20px"}}>
                            <img
                                src={`https://res.cloudinary.com/indexpro/image/upload/Impact Icons/${i.map.replace('&', 'and')}.svg`}
                                height="50px"
                                style={{textAlign: "center",maxWidth: "95px"}}/>
                        </Grid>
                        <Grid item md={10} style={{marginTop: "20px"}}>
                            <p style={{fontSize: "15px",marginBottom: "0px",fontWeight: "600"}}>{i.map}</p>
                            <p style={{fontSize: "15px",fontWeight: "500",marginTop: "0px",color: "#555555"}}>
                                {((i.value.toFixed(1))*10).toString() + '% Exposure'}
                            </p>
                        </Grid>
                    </Grid>
                ))}
            </Paper>
        )
    }

    const ExclusionResearch = () => {
        return (
            <Paper style={{width: "585px", paddingBottom: "10px", marginTop: "20px"}}
                   elevation={0}>
                <Grid container direction="row" alignItems="center" justifyContent="center" style={{backgroundColor: "grey"}}>
                    <p style={{color: "white",fontSize: "17px",margin: "5px"}}>Exclusions</p>
                </Grid>
                {filtered_exclusion_data().map(i => (
                    <Grid container direction={"row"} alignItems={"center"}>
                        <Grid item md={2} style={{marginTop: "20px"}}>
                            <Fab size="medium" style={{backgroundColor: ipRed}}>
                                <ClearIcon style={{color: "white"}}/>
                            </Fab>
                        </Grid>
                        <Grid item md={10}>
                            <p style={{fontSize: "15px",marginBottom: "0px",fontWeight: "600"}}>{i.map}</p>
                        </Grid>
                    </Grid>
                ))}
            </Paper>
        )
    }

    const HighLevelStats = () => {
        return (
            <>
                <div>
                    <ExpandMoreIcon style={{marginLeft: "15px", fontSize: "40px", color: themeColor}}/>
                    <div className="circle" style={{animationDelay: "0s"}}/>
                    <div className="circle" style={{animationDelay: "2s"}}/>
                </div>
                <p style={{marginLeft: "40px", fontSize: "16px"}}>Underweight Candidates: <span
                    style={{fontWeight: "600"}}>{value_exclusion_data[0]} Companies</span></p>
                <p style={{marginLeft: "40px", fontSize: "16px"}}>Overweight Candidates: <span
                    style={{fontWeight: "600"}}>{value_overweight_data[0]} Companies</span></p>
            </>
        )
    }

    const OverallESGRating = () => {
        const esg_score =  esg_value_data.filter(i => i.company_name=== researchCompany.company_name).filter(i => i.map === 'ESG Score')[0]
        return esg_score ? (msci_abc_rating(esg_score.value)) : ('N/A')
    }


    return (
        <div>
            <Grid container direction="row" style={{marginLeft: "0px", width: "1278px"}}>
                {!openPositionBreakdown ? (
                    <div style={{
                        backgroundColor: "white",
                        width: "878px",
                        marginBottom: "-58px",
                        border: "1px solid #dadce0",
                        cursor: "pointer",

                    }} onClick={openPositionBreakdown ? (handlePositionBreakdownClose) : (handlePositionBreakdownOpen)}>
                        <Grid container direction="row" alignItems="center">
                            {HighLevelStats()}
                        </Grid>
                    </div>) : (
                    <div style={{
                        backgroundColor: "white",
                        width: "1278px",
                        height: "640px",
                        marginBottom: "-50px",
                        border: "1px solid #dadce0",
                        zIndex: "99",
                        overflowY: "scroll",
                        overflowX: "hidden"
                    }}>
                        <Grid container direction="row" alignItems="center">
                            <Grid container direction="row" alignItems="center" style={{width: "1040px", cursor: "pointer"}}
                                  onClick={openPositionBreakdown ? (handlePositionBreakdownClose) : (handlePositionBreakdownOpen)}>
                                {HighLevelStats()}
                            </Grid>
                            <div style={{marginTop: "5px"}}>
                                <Button style={Object.assign({}, bordered_button, {width: "200px"})}
                                        onClick={() => {mode === 0 ? setMode(1) : setMode(0);setResearchCompany(null)}}
                                        startIcon={mode === 0 ? (<BarChartOutlinedIcon/>) : (<ZoomOutMapIcon/>)}
                                >
                                    {mode === 0 ? ('Research') : ('Overview')}
                                </Button>
                            </div>
                        </Grid>
                        <Grid container direction="row" alignItems="flex-start" style={{marginTop: "20px", marginLeft: "20px"}}>
                            {mode === 0 ? (
                                <>
                                    <Paper style={Object.assign({}, paper_right, {width: "600px", border: "none"})} elevation={0}>
                                        <Grid container direction="row" justifyContent="space-between"
                                              alignItems="center">
                                            <div>
                                                <p style={card_title}>Underweight Candidates</p>
                                                <p style={card_subtitle}>All Underweight Candidates</p>
                                            </div>
                                            {_value_exclusion_data.currentData().map(item => {
                                                return (statusDiv(item.company_name, item.label, item.weight, item.ticker, item.gics_1,'Underweight'))
                                            })}
                                        </Grid>
                                        <Pagination
                                            count={Math.ceil(value_exclusion_data[0]/5)}
                                            style={{marginTop: "20px"}}
                                            page={page}
                                            onChange={handleChange}
                                        />
                                    </Paper>
                                    <Paper style={Object.assign({}, paper_right, {width: "600px",marginLeft: "30px",border: "none",})} elevation={0}>
                                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                            <div>
                                                <p style={card_title}>Overweight Candidates</p>
                                                <p style={card_subtitle}>All Overweight Candidates</p>
                                            </div>
                                            {_value_overweight_data.currentData().map(item => {
                                                return (statusDiv(item.company_name, item.label, item.weight, item.ticker, item.gics_1, 'Overweight'))
                                            })}
                                        </Grid>
                                        <Pagination
                                            count={Math.ceil(value_overweight_data[0]/5)}
                                            style={{marginTop: "20px"}}
                                            page={pageOverweight}
                                            onChange={handleChangeOverweight}
                                        />
                                    </Paper>
                                </>
                            ) : (
                                <>
                                    <Grid container direction="row" justifyContent="center" alignItems="center">
                                        <Autocomplete
                                            options={[...new Map(redux(esg_value_data,
                                                ['company_name', 'ticker', 'weight', 'gics_1']).map((item) =>
                                                [item["company_name"], item])).values()].sort((a, b) => b.weight - a.weight)}
                                            autoHighlight
                                            filterOptions={createFilterOptions({limit: 500, stringify: (option) => JSON.stringify(option)})}
                                            getOptionLabel={(option) => option.company_name}
                                            getOptionSelected={(option, value) => option.company_name === value.company_name}
                                            renderOption={(option) => (
                                                <Grid container direction="row" justifyContent="space-between"
                                                      alignItems="center">
                                                    <Grid item xs={10}>
                                                        {option.company_name}
                                                        <p style={{fontSize: "10px",fontWeight: "600",marginTop: "0px",marginBottom: "0px"}}>
                                                            {option.ticker}
                                                        </p>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        {(option.weight * 100).toFixed(2)}%
                                                    </Grid>
                                                </Grid>
                                            )}
                                            renderInput={(params) => <TextField {...params} label="Search for stocks" variant="outlined"/>}
                                            style={{width: "415px"}}
                                            onChange={(option, value) => setResearchCompany(value)}
                                        >
                                        </Autocomplete>
                                        {researchCompany && researchCompanyHasData() ? (
                                            <Grid container direction="row" alignItems="center" justifyContent="flex-start" style={{marginBottom: "20px", marginTop: "20px"}}>
                                                <Grid container direction="row" alignItems="center" justifyContent="space-between" style={{marginRight: "35px"}}>
                                                    <div>
                                                        <Grid container direction="row" alignItems="center" justifyContent="flex-start">
                                                            <Avatar style={{height: "60px", width: "60px"}}
                                                                    src={"https://universal.hellopublic.com/companyLogos/" +
                                                                    researchCompany.ticker
                                                                    + "@2x.png?v=1b72d14c-4115-4bac-ab09-487a2ffb364e"}>
                                                                {researchCompany.ticker}
                                                            </Avatar>
                                                            <div>
                                                                <p style={Object.assign({}, card_title, {fontSize: "18px"})}>
                                                                    {researchCompany.company_name}
                                                                </p>
                                                                <p style={Object.assign({}, card_subtitle, {marginBottom: "0px",marginTop: "0px"})}>
                                                                    {researchCompany.ticker}
                                                                </p>
                                                                <p style={Object.assign({}, card_subtitle, {marginBottom: "18px",marginTop: "0px"})}>
                                                                    {researchCompany.gics_1}
                                                                </p>
                                                            </div>
                                                        </Grid>
                                                    </div>
                                                    <div>
                                                        <Grid container direction="row" alignItems="center" justifyContent="center">
                                                            <Avatar style={{height: "60px", width: "60px",backgroundColor: msci_abc_rating_to_color(OverallESGRating())}}>
                                                                {OverallESGRating()}
                                                            </Avatar>
                                                            <div style={{marginRight: "30px"}}>
                                                                <p style={Object.assign({}, card_title, {
                                                                    fontSize: "18px",
                                                                })}>ESG Rating</p>
                                                                <p style={Object.assign({}, card_subtitle, {
                                                                    marginBottom: "0px",
                                                                    marginTop: "0px",
                                                                    color: "black"
                                                                })}>MSCI ESG Ratings</p>
                                                                <Grid container direction="row" style={{marginBottom: "18px",marginTop: "5px", marginLeft: "20px",
                                                                    width: "282px", height:"20px",border: "1px solid black"}}>
                                                                    {['CCC', 'B', 'BB', 'BBB', 'A', 'AA', 'AAA'].map((i, index) => (
                                                                        <div style={{width: "40px", textAlign: "center",
                                                                        backgroundColor: OverallESGRating() === i ?
                                                                            (msci_abc_rating_to_color(i)) : ("white"),
                                                                            color: "black",
                                                                            fontSize: "12px", margin: "0px",
                                                                            borderRight: index !== 6  ? ("1px solid black") : ("")
                                                                        }}>
                                                                            <div>{i}</div>
                                                                        </div>
                                                                    ))}
                                                                </Grid>
                                                            </div>
                                                        </Grid>
                                                    </div>
                                                </Grid>
                                                <Grid container direction="row" alignItems="stretch" justifyContent="space-between" style={{marginRight: "35px", marginTop: "20px"}}>
                                                    {ESGResearch('ESG Laggard')}
                                                    {ESGResearch('Average')}
                                                    {ESGResearch('ESG Leader')}
                                                    {ThemeResearch()}
                                                    {ExclusionResearch()}
                                                </Grid>
                                            </Grid>
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </div>
                    )
                }
                <Button style={{
                    borderRadius: "0px", height: "50px", width: "400px", marginLeft: "878px",
                    textTransform: "none"
                }} variant="contained" color="primary" onClick={handleNextButton}>
                    <Grid container direction="row" alignItems="center">
                        <p className="ControlPanelText">
                            {next_data[builder_state.tab]}
                        </p>
                        <NavigateNextIcon style={{position: "absolute", right: "40px"}}/>
                    </Grid>
                </Button>
            </Grid>
            <Modal
                open={open}
            >
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Paper elevation={0} style={{
                        width: "600px", height: "300px", marginTop: "400px"
                    }}>
                        <Grid container direction="row">
                            <Grid item xs={10} style={{paddingTop: "25px", marginBottom: "10px"}}>
                                <p style={card_title}>Save Strategy</p>
                            </Grid>
                            {error ? (<Grid item xs={12} style={{marginLeft: "20px", marginRight: "20px"}}>
                                <Alert severity="error">{error}</Alert>
                            </Grid>) : (
                                <></>
                            )
                            }
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    autoFocus
                                    required
                                    label="Name your strategy"
                                    value={strategy_name}
                                    onChange={(e) => handleStrategyNameChange(e)}
                                    style={{
                                        backgroundColor: "#F5F8FA",
                                        width: "560px",
                                        marginLeft: "20px",
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} style={{marginLeft: "380px", marginTop: error ? ("40px") : ("70px")}}>
                                <Button style={Object.assign({}, bordered_button, {width: "95px", marginRight: "10px"})}
                                        onClick={() => {handleClose()}}>
                                    Cancel
                                </Button>
                                <Button style={Object.assign({}, bordered_button, {
                                    width: "95px",
                                    color: "white",
                                    backgroundColor: themeColor
                                })} onClick={() => {userRoleActionWrapper(null, user_role,saveStrategy)}}>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </Modal>

        </div>
    )
}

export default observer(BuilderJourney);