import {Grid, Paper} from "@material-ui/core";
import {
    big_paper,
    card_subtitle,
    card_title,
    HeaderCell,
    ipGreen,
    ipRed,
    IPTableRow,
    ValueCell
} from "../../Styles/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import {numWithCommas} from "../../Utils/num_utils";
import React from "react";

function AccountActivity(props) {
    return (
        <div className="Activity">
            <Paper style={big_paper} elevation={0}>
                <div>
                    <p style={card_title}>Activity</p>
                    <p style={card_subtitle}>Top 10 Trades in Portfolio (Past 30 Days)</p>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <HeaderCell align="left">Company Name</HeaderCell>
                                    <HeaderCell align="left">Transaction Type</HeaderCell>
                                    <HeaderCell align="center">Trade Date</HeaderCell>
                                    <HeaderCell align="right">Trade Value</HeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.account.data['Account Activity'].map((row) => (
                                    <IPTableRow>
                                        <ValueCell>
                                            <div>
                                                <Grid container direction="row" alignItems="center"
                                                      justifyContent="flex-start">
                                                    <Avatar style={{height: "35px", width: "35px"}}
                                                            src={"https://universal.hellopublic.com/companyLogos/" +
                                                            row.ticker
                                                            + "@2x.png?v=1b72d14c-4115-4bac-ab09-487a2ffb364e"}>
                                                        {row.ticker}
                                                    </Avatar>
                                                    <div style={{marginLeft: "15px"}}>
                                                        {row.company_name}
                                                    </div>
                                                </Grid>
                                            </div>
                                        </ValueCell>
                                        {row.TransactionCategory === 'PURCHASE' ? (
                                            <ValueCell align="left"><Chip icon={<ArrowUpwardIcon
                                                style={{fontSize: "16px", color: ipGreen}}/>} label="Buy"
                                                                          style={{
                                                                              backgroundColor: "#e7f8f0",
                                                                              color: ipGreen,
                                                                              minWidth: "70px"
                                                                          }}/></ValueCell>
                                        ) : (
                                            <ValueCell align="left"><Chip icon={<ArrowDownwardIcon
                                                style={{fontSize: "16px", color: ipRed}}/>} label="Sell"
                                                                          style={{
                                                                              backgroundColor: "#ffe9ef",
                                                                              color: ipRed, minWidth: "70px"
                                                                          }}/></ValueCell>
                                        )}
                                        <ValueCell align="center">{row.TradeDate}</ValueCell>
                                        {row.TradeValue >= 0 ? (
                                            <ValueCell align="right"
                                                       style={{color: ipGreen}}>${numWithCommas(Math.round(row.TradeValue))}</ValueCell>
                                        ) : (
                                            <ValueCell align="right"
                                                       style={{color: ipRed}}>-${numWithCommas(Math.abs(Math.round(row.TradeValue)))}</ValueCell>
                                        )}
                                    </IPTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Paper>
        </div>
    )
}

export default AccountActivity