import {Button, Grid, Paper} from "@material-ui/core";
import {
    bordered_button,
    card_subtitle,
    card_title,
    HeaderCell,
    IPTableRow,
    paper_right,
    ValueCell
} from "../../Styles/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import React from "react";
import {Storage} from "@aws-amplify/storage";
import {Mixpanel} from "../../Utils/Mixpanel";

function AccountTransitionHoldings(props) {

    function downloadBlob(blob, filename) {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename || 'download';
        const clickHandler = () => {
            setTimeout(() => {
                URL.revokeObjectURL(url);
                a.removeEventListener('click', clickHandler);
            }, 150);
        };
        a.addEventListener('click', clickHandler, false);
        a.click();
        return a;
    }

    async function download(id, filename) {
        Mixpanel.track('Downloaded Transition Report');
        const result = await Storage.get(`transition_reports/${id}.csv`, {
            download: true,
            level: "private",
            cacheControl: 'no-cache'
        });
        downloadBlob(result.Body, filename + '_transition_report' + '.csv');
    }

    return (
        <div className="Holdings">
            <Paper style={paper_right} elevation={0}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <div>
                        <p style={card_title}>Holdings</p>
                        <p style={card_subtitle}>Input vs. Benchmark vs. Target</p>
                    </div>
                    {props.account.transition_data ? (
                        <div>
                            <Button
                                startIcon={<GetAppIcon/>}
                                style={Object.assign({}, bordered_button, {marginRight: "20px"})}
                                onClick={() => download(props.account.id, props.account.legal_name)}>
                                Download Transition Details
                            </Button>
                        </div>
                    ) : (<></>)
                    }
                </Grid>
                {props.account.transition_data ? (
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <HeaderCell>Name</HeaderCell>
                                        <HeaderCell align="left">Symbol</HeaderCell>
                                        <HeaderCell align="right">Input Weight</HeaderCell>
                                        <HeaderCell align="right">Benchmark Weight</HeaderCell>
                                        <HeaderCell align="right">Target Weight</HeaderCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.account.transition_data['top_10_positions'].map((row) => (
                                        <IPTableRow key={row.ticker}>
                                            <ValueCell>{row.company_name}</ValueCell>
                                            <ValueCell>{row.ticker}</ValueCell>
                                            <ValueCell align="right">{row.input_weight}</ValueCell>
                                            <ValueCell align="right">{row.benchmark_weight}</ValueCell>
                                            <ValueCell align="right">{row.weight}</ValueCell>
                                        </IPTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                    :
                    (<></>)
                }
            </Paper>
        </div>
    )
}

export default AccountTransitionHoldings