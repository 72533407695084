import React, {useEffect, useState} from "react";
import {Grid, Button} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import {observer} from "mobx-react-lite"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Route, Link, useLocation} from "react-router-dom";
import {bordered_button, tab_label, app_bar_style, simple_text, ipLogoIcon,
    dashboard_icon, accounts_icon, strategies_icon, builder_icon} from "../Styles/styles";
import Routes from "../Routes/Routes"
import {useHistory} from 'react-router-dom'
import {Auth, Hub} from "aws-amplify";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {DataStore} from '@aws-amplify/datastore';
import AddIcon from "@material-ui/icons/Add";
import {useUser, useUserPhoto} from "./Hooks/Data/User/user";
import Avatar from '@material-ui/core/Avatar';
import {useSnackbar} from "notistack";
import SnackbarUtils from "../Utils/snackbar_utils"
import DescriptionIcon from '@material-ui/icons/Description';
import {Paper} from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import {Mixpanel} from "../Utils/Mixpanel";

function Application() {

    const history = useHistory();
    const location = useLocation();

    //user info
    const [user, setUser] = useState(null);
    const [userName, setUserName] = useState(null);

    const userPhoto = useUserPhoto()

    //user popup
    const [anchorEl, setAnchorEl] = React.useState(null);

    //app bar routes
    const routes = ['/participant/dashboard', '/participant/accounts', '/participant/strategies', '/participant/builder']


    useEffect( () => {
        async function checkUserState() {
            try {
                await Auth.currentAuthenticatedUser()
            } catch (e) {
                history.push('/signin')
            }
        }
        checkUserState();
    }, [location])

    //sign out method
    const signOut = async () => {
        try {
            localStorage.removeItem("signed-in-user")
            window.Intercom("shutdown");
            Mixpanel.track('Logout');
            await Auth.signOut();
            history.push('/signin')
        } catch (error) {
            console.error('error', error);
        }
    }

    //account settings method
    const accountSettings = () => {
        handleClose()
        history.push('/participant/account-settings')
    }

    //help center
    const helpCenter = () => {
        window.Intercom('trackEvent', 'help-center');
        Mixpanel.track('Help Center');
        handleClose()
        history.push('/participant/help-center')
    }

    //add account method
    const addAccount = () => {
        history.push('/participant/add-account')
    }

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    //get user info
    useEffect(() => {
        SnackbarUtils.setSnackBar(enqueueSnackbar,closeSnackbar)
        let getUser = async() => {
            try {
                let user = JSON.parse(localStorage.getItem("signed-in-user"))
                await setUser(user);
                await setUserName(user.attributes.name);
            } catch (error) {
                console.error('error:', error)
            }
        }
        getUser();
        let intercom = async()  => {
            window.Intercom("boot", {
                app_id: "k6ascwdh",
                name: JSON.parse(localStorage.getItem("signed-in-user")).attributes.name,
                email: JSON.parse(localStorage.getItem("signed-in-user")).attributes.email,
                created_at: Date.now().toString()
            });
        }
        intercom();
    },[]);


    //popup helper functions
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };



    return (
        <div>
            <Route path="/" render={(history) => (
                <AppBar elevation={0} position="fixed" style={app_bar_style}>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Link to="/participant">
                            <img
                                style={{marginBottom: "-1px"}}
                                src={ipLogoIcon}
                                alt="logo" width="45" height="45"/>
                        </Link>
                        <Tabs indicatorColor="primary"
                              centered
                              style={{color: "#000", marginRight: "120px", marginLeft: "120px"}}
                              value={history.location.pathname}
                        >
                            <Tab
                                label={
                                    <Grid container direction="row" justifyContent="center">
                                        <img
                                            src={dashboard_icon}
                                            alt="dashboard"
                                            width="20" height="20"
                                            style={{marginRight: "5px"}}
                                        />
                                        <p style={tab_label}>Dashboard</p>
                                    </Grid>
                                }
                                value={routes[0]}
                                component={Link}
                                to={routes[0]}
                            />
                            <Tab
                                label={
                                    <Grid container direction="row" justifyContent="center">
                                        <img
                                            src={accounts_icon}
                                            alt="clients"
                                            width="20" height="20"
                                            style={{marginRight: "5px"}}
                                        />
                                        <p style={tab_label}>Accounts</p>
                                    </Grid>
                                }
                                value={history.location.pathname.includes(routes[1]) ? (history.location.pathname) : (routes[1])}
                                component={Link}
                                to={routes[1]}
                            />
                            <Tab
                                label={
                                    <Grid container direction="row" justifyContent="center">
                                        <img
                                            src={strategies_icon}
                                            alt="strategies"
                                            width="20" height="20"
                                            style={{marginRight: "5px"}}
                                        />
                                        <p style={tab_label}>Strategies</p>
                                    </Grid>
                                }
                                value={history.location.pathname.includes(routes[2]) ? (history.location.pathname) : (routes[2])}
                                component={Link}
                                to={routes[2]}
                            />
                            <Tab
                                label={
                                    <Grid container direction="row" justifyContent="center">
                                        <img
                                            src={builder_icon}
                                            alt="builder"
                                            width="20" height="20"
                                            style={{marginRight: "5px"}}
                                        />
                                        <p style={tab_label}>Builder</p>
                                    </Grid>
                                }
                                value={routes[3]}
                                component={Link}
                                to={routes[3]}
                            />
                        </Tabs>
                        <Button style={Object.assign({}, bordered_button, {marginRight: "50px", marginBottom: "3px"})}
                                onClick={() => addAccount()}
                                startIcon={<AddIcon style={{marginRight: "-5px"}}/>}
                        >
                            Add Account
                        </Button>
                        <Button
                            aria-haspopup="true"
                            onClick={handleClick}
                            startIcon={<Avatar alt="Remy Sharp" src={userPhoto} />}
                            endIcon={<ExpandMoreIcon/>}
                            style={{padding: "0px", margin: "0px", opacity:"80%" ,whiteSpace: "nowrap", fontSize: "14px", textTransform: "none"}}>
                            {userName}
                        </Button>
                        <Menu
                            elevation={1}
                            disableScrollLock
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            style={{marginTop: "40px"}}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                        >
                            <MenuItem onClick={() => accountSettings()} style={{opacity: "75%"}}><SettingsIcon style={{marginRight: "5px"}}/><p style={simple_text}>Account Settings</p></MenuItem>
                            <MenuItem onClick={() => helpCenter()} style={{opacity: "75%"}}><HelpIcon style={{marginRight: "5px"}}/><p style={simple_text}>Help Center</p></MenuItem>
                            <MenuItem style={{opacity: "75%"}}><DescriptionIcon style={{marginRight: "5px"}}/><a
                                target="_blank" rel="noopener noreferrer" href="https://www.indexprogroup.com/platform-user-agreement"><p style={simple_text}>User Agreement</p></a></MenuItem>
                            <MenuItem onClick={() => signOut() } style={{opacity: "75%"}}><ExitToAppIcon style={{marginRight: "5px"}}/><p style={simple_text}>Logout</p></MenuItem>
                        </Menu>
                    </Grid>
                </AppBar>
            )}
            />
            <Routes/>
        </div>

    )
}

export default observer(Application);