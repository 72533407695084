import React from "react";
import { observer }  from "mobx-react-lite"
import IndexSelector from "./select-index";
import ApplyValues from "./apply-values";
import Tax from "./tax"
import Summary from "./summary";

function ControlPanel({builder_state}) {
    const tab_value = builder_state.tab
    if (tab_value === 0) {
        return (
            <IndexSelector builder_state={builder_state}/>
        )
    }
    else if (tab_value === 4) {
        return (
            <Tax builder_state={builder_state}/>
        )
    }
    else if (tab_value === 5) {
        return (
            <Summary builder_state={builder_state}/>
        )
    }
}

export default observer(ControlPanel);