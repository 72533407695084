import React, {useEffect, useState} from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {Grid, Typography, Paper, Button} from "@material-ui/core";
import {observer} from "mobx-react-lite";
import FormGroup from "@material-ui/core/FormGroup";
import BuilderSwitch from "./builder-switch-style"
import {
    control_paper_left,
    big_paper,
    card_title,
    card_subtitle,
    bordered_button,
    card_subtitle_black, themeColor, simple_text_bold, simple_text, noborder_button, transparent_button
} from "../../Styles/styles";
import GetInTouch from "./builder-journey";
import {DataStore} from "@aws-amplify/datastore";
import {Themes} from "../../models";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from "@material-ui/icons/Check";
import Modal from "@material-ui/core/Modal";
import ClearIcon from "@material-ui/icons/Clear";

function ThemesComp({builder_state}) {

    //value data + handlers
    const values_data = builder_state.values_data
    const apply_your_values = builder_state.apply_your_values
    const esg_value = builder_state.esg_value
    const esg_implementation = builder_state.esg_implementation === 'Exclude'
    const handleValueSelect = (name) => {
        builder_state.setESGValue(name);
    }
    const handleApplyValuesButton = (event) => {
        builder_state.setApplyYourValues(event.target.checked);
    };
    const handleESGImplementationButton = (event) => {
        let val = event.target.checked ? 'Exclude' : 'Tilt'
        builder_state.setESGImplementation(val);
    };


    const [preConfigValues, setPreConfigValues] = useState([]);


    //expansion panel
    const [expanded, setExpanded] = useState('0');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        window.scrollTo(0, 0)
        let getValuesData = async () => {
            try {
                const values_data = await DataStore.query(Themes);
                values_data.sort(function(a,b) {
                    return a.title.localeCompare(b.title)
                })
                await setPreConfigValues(values_data)
            } catch (error) {
                console.error('error:', error)
            }
        }
        getValuesData();
    }, []);


    const addThemes = (value) => {
        builder_state.setThemes(value)
    }

    const removeThemes = (value) => {
        builder_state.removeThemes(value)
    }

    const fabState = (title) => {
        if (!builder_state.themes.has(title)) {
            return (
                <Fab size="small" style={{marginTop: "-20px", marginLeft: "10px", backgroundColor: "lightgrey"}}
                     onClick={() => addThemes(title)}>
                    <AddIcon/>
                </Fab>
            )
        } else if (builder_state.themes.has(title)) {
            return (
                <Fab size="small" style={{marginTop: "-20px", marginLeft: "10px", backgroundColor: themeColor}}
                     onClick={() => removeThemes(title)}>
                    <CheckIcon style={{color: "white"}}/>
                </Fab>
            )
        }
    }

    const [clickeditem, setItem] = useState({});

    const [open, setOpen] = React.useState(false);

    const handleOpen = (item) => {
        setItem(item)
        setOpen(true);
    };

    const handleClose = () => {
        setItem({})
        setOpen(false);
    };

    const fontSize = (chars) => {
        if (!chars) {
            return (
                15
            )
        } else if (chars.length >= 450) {
            return (
                12
            )
        } else if (chars.length < 450 && chars.length >= 350) {
            return (
                13
            )
        } else if (chars.length >= 200 && chars.legnth < 350) {
            return (
                14
            )
        } else {
            return (
                15
            )
        }
    }


    const createPreConfigValuesContainer = () => {
        return (
            <Grid container direction="row" justifyContent="flex-start" style={{marginTop: "50px", marginLeft: "30px"}}>
                {preConfigValues.map(function (item, index) {
                    return (
                        <Grid item style={{
                            height: "290px",
                            width: "275px",
                            marginTop: "30px",
                            marginRight: "30px"
                        }}>
                            <Grid item xs={12}>
                                <div class="wrapper" style={{height: "190px"}} onClick={() => handleOpen(item)}>
                                    <img src={item.logo_url.split("/upload/").join("/upload/w_275,c_scale/q_auto/")}
                                         alt="logo" width="275" height="190"/>
                                    <div class="overlay">
                                        <div class="content"><p>Learn More</p></div>
                                    </div>
                                </div>
                                {fabState(item.title)}
                            </Grid>
                            <Grid item xs={12}>
                                <p style={Object.assign({}, card_title, {
                                    marginLeft: "0px"
                                })}>{item.title}</p>
                            </Grid>
                            <Grid item xs={12}>
                                <p style={Object.assign({}, card_subtitle, {
                                    marginLeft: "0px",
                                    fontSize: "11px"
                                })}>{item.short_description}</p>
                            </Grid>
                        </Grid>
                    )
                })}
                <Modal
                    open={open}
                    style={{overflow: "scroll"}}
                >
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Paper elevation={0} style={{
                            width: "1278px", height: "600px", marginTop: "300px"
                        }}>
                            <Grid  container direction="row" justifyContent="space-around">
                                <Grid item xs={4}>
                                <p style={Object.assign({},simple_text_bold,{marginLeft: "50px", marginTop: "100px", fontSize: "25px", width: "400px"})}>{clickeditem.title}</p>
                                    <p style={Object.assign({},simple_text,{marginLeft: "50px", marginTop: "20px",
                                        fontSize: `${fontSize(clickeditem.long_description)}px`, lineHeight: 2.3})}>{clickeditem.long_description}</p>
                                    {!builder_state.themes.has(clickeditem.title) ? (
                                        <div style={{marginLeft: "80px", marginTop:"20px"}}>
                                            <Button style={Object.assign({},noborder_button, {backgroundColor: 'black', color: "white", fontSize: "16px", width: "300px"})}
                                                    onClick={() => {addThemes(clickeditem.title); handleClose();}}>Add to Portfolio</Button>
                                        </div>
                                    ) : (
                                        <div style={{marginLeft: "80px", marginTop:"20px"}}>
                                            <Button style={Object.assign({},noborder_button, {backgroundColor: themeColor, color: "white", fontSize: "16px", width:"300px"})}
                                                    onClick={() => {removeThemes(clickeditem.title); handleClose();}}>Remove from Portfolio</Button>
                                        </div>
                                    )}
                                    <div style={{marginLeft: "160px", marginTop:"20px"}}>
                                    <Button style={Object.assign({}, transparent_button,{fontWeight: "600", textDecoration: "underline", color: "black", fontSize: "16px"})}
                                            onClick={()=>handleClose()}
                                    >Back to Themes</Button>
                                    </div>
                                </Grid>
                                <Grid item xs={8}>
                                <img src={clickeditem.logo_url}
                                     alt="logo" width="800" height="600" style={{marginLeft: "52px"}}/>
                                </Grid>
                            </Grid>
                            <div style={{position: "relative", top: "-580px", right: "20px"}}>
                                <Fab onClick={handleClose} style={{float: "right"}}>
                                    <ClearIcon/>
                                </Fab>
                            </div>
                        </Paper>
                    </div>
                </Modal>
            </Grid>
        )
    };

    return (
        <Paper style={big_paper} elevation={0} square>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <div item>
                    <p style={card_title}>Investment Themes</p>
                    <p style={card_subtitle}>Tilt your portfolio on specific themes</p>
                </div>
            </Grid>
            {createPreConfigValuesContainer()}
        </Paper>
    )
}

export default observer(ThemesComp);