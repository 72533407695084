import React, {useEffect, useState} from "react";
import Top10Holdings from "./top-10-holdings";
import SectorBenchmark from "./sector-benchmark";
import GetInTouch from "./builder-journey";
import {observer} from "mobx-react-lite"
import {Paper, Grid} from "@material-ui/core";
import {
    account_preferences_icon, alpha_icon,
    builder_paper_right,
    card_subtitle, card_subtitle_black, card_subtitle_grey,
    card_title, exclusions_icon, revenue_icon, risk_icon, security_exclusion_icon, tax_icon, themes_icon, values_icon
} from "../../Styles/styles";
import {DataStore} from "@aws-amplify/datastore";
import {CustomOptions, PreConfiguredValues, Themes} from "../../models";
import Fab from "@material-ui/core/Fab";
import ClearIcon from "@material-ui/icons/Clear";
import {useCoreValueData, useCustomValueData, useThemeData} from "../Hooks/Data/Builder/builder";


function BuilderSummary({builder_state}) {

    const button_map = {false: 'No', true: 'Yes'}
    const benchmark_name = builder_state.benchmark_name
    const core_options = builder_state.core_options
    const custom_options = builder_state.custom_options
    const custom_exclusions = builder_state.custom_exclusions
    const revenue_threshold_exclusions5 = builder_state.revenue_threshold_exclusions5
    const revenue_threshold_exclusionsAny = builder_state.revenue_threshold_exclusionsAny
    const selected_themes = builder_state.themes
    const tax = builder_state.apply_tax_preferences
    const tax_preference = builder_state.tax_preference
    const capital_gain_management = builder_state.capital_gain_management
    const transition_management = builder_state.transition_management
    const alpha = builder_state.alpha_group
    const tracking_error = builder_state.tracking_error
    const max_position_count = builder_state.max_position_count
    const rebalance_frequency = builder_state.rebalance_frequency
    const security_exclusions = builder_state.security_exclusions


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="Summary">
                <Paper style={builder_paper_right} elevation={0}>
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid container direction="row" justifyContent="flex-start" style={{width: "824px"}}>
                            <Grid item xs={12}>
                                <p style={card_title}>Overview</p>
                                <p style={card_subtitle}>Special Client Considerations</p>
                            </Grid>
                            <Grid container direction="row" alignItems="flex-start">
                                <Grid item xs={6}>
                                    <div style={account_preferences_icon}>
                                        <img
                                            src={values_icon}
                                            alt="dashboard"
                                            width="30" height="30"
                                            style={{marginRight: "10px"}}
                                        />
                                        <p style={card_subtitle_black}>Values</p>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <p style={card_subtitle_grey}>{Array.from(core_options).concat(Array.from(custom_options)).join(", ")}</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <div style={account_preferences_icon}>
                                        <img
                                            src={exclusions_icon}
                                            alt="dashboard"
                                            width="30" height="30"
                                            style={{marginRight: "10px"}}
                                        />
                                        <p style={card_subtitle_black}>Exclusions</p>
                                    </div>
                                </Grid>

                                <Grid item xs={6}>
                                    <p style={card_subtitle_grey}>{Array.from(custom_exclusions).join(", ")}</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <div style={account_preferences_icon}>
                                        <img
                                            src={security_exclusion_icon}
                                            alt="dashboard"
                                            width="30" height="30"
                                            style={{marginRight: "10px"}}
                                        />
                                        <p style={card_subtitle_black}>Security Exclusions</p>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <p style={card_subtitle_grey}>{security_exclusions.map(i => i.company_name).join(", ")}</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <div style={account_preferences_icon}>
                                        <img
                                            src={revenue_icon}
                                            alt="dashboard"
                                            width="30" height="30"
                                            style={{marginRight: "10px"}}
                                        />
                                        <p style={card_subtitle_black}>Revenue</p>
                                    </div>
                                </Grid>

                                <Grid item xs={6}>
                                    <p style={card_subtitle_grey}>{Array.from(revenue_threshold_exclusions5).concat(Array.from(revenue_threshold_exclusionsAny)).join(", ")}</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <div style={account_preferences_icon}>
                                        <img
                                            src={themes_icon}
                                            alt="dashboard"
                                            width="30" height="30"
                                            style={{marginRight: "10px"}}
                                        />
                                        <p style={card_subtitle_black}>Themes</p>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <p style={card_subtitle_grey}>{Array.from(selected_themes).join(", ")}</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <div style={account_preferences_icon}>
                                        <img
                                            src={tax_icon}
                                            alt="dashboard"
                                            width="30" height="30"
                                            style={{marginRight: "10px"}}
                                        />
                                        <p style={card_subtitle_black}>Tax</p>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <p style={card_subtitle_grey}>{tax_preference ? (tax_preference) : ('No Tax Strategy')}
                                    </p>
                                </Grid>
                                <Grid item xs={6}>
                                    <div style={account_preferences_icon}>
                                        <img
                                            src={alpha_icon}
                                            alt="dashboard"
                                            width="30" height="30"
                                            style={{marginRight: "10px"}}
                                        />
                                        <p style={card_subtitle_black}>Alpha</p>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <p style={card_subtitle_grey}>{alpha ? (alpha) : ('No Alpha')}</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <div style={account_preferences_icon}>
                                        <img
                                            src={risk_icon}
                                            alt="dashboard"
                                            width="30" height="30"
                                            style={{marginRight: "10px"}}
                                        />
                                        <p style={card_subtitle_black}>Risk</p>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <p style={card_subtitle_grey}>Target Tracking
                                        Error: {tracking_error}%
                                        , Max Position
                                        Count: {max_position_count ? (max_position_count) : ('None')}
                                        , Rebalance
                                        Frequency: {rebalance_frequency}</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
            <div style={{position: "fixed", bottom: "0", display: "flex", justifyContent: "center", width: "100%"}}>
                <GetInTouch builder_state={builder_state}/>
            </div>
        </>
)
}

export default observer(BuilderSummary);