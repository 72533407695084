import React, {useEffect, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import {
    bordered_button,
    simple_text_bold,
    transparent_button,
    onboarding_paper,
    simple_text,
    tab_label,
    ChromeAutoFillOverride,
    big_paper,
    themeColor,
    themeBackground,
    paper_right,
    card_title,
    paper_left,
    ipGreen,
    form_button,
    card_subtitle, AssetGraphLabel, manage_your_plan_icon, ipLogo
} from "../../Styles/styles";
import {useHistory} from "react-router-dom";
import "./dashboard.css"
import AccordionSummary from "@material-ui/core/AccordionSummary";
import {createTheme, Grid, TextField, ThemeProvider} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import DoneIcon from "@material-ui/icons/Done";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import PropTypes from "prop-types";
import {dollarsWithCommas} from "../../Utils/num_utils";
import {useOnboardingData, useOnboarding1Complete, useOnboarding2Complete, useUserLogo} from "../Hooks/Data/User/user";
import Alert from '@material-ui/lab/Alert';
import {DataStore} from '@aws-amplify/datastore';
import {Onboarding, User} from '../../models';
import Fab from "@material-ui/core/Fab";
import ClearIcon from "@material-ui/icons/Clear";
import GetAppIcon from "@material-ui/icons/GetApp";
import Modal from "@material-ui/core/Modal";
import {OnboardingAccordionSummary} from "../../Styles/styles";
import IconButton from "@material-ui/core/IconButton";
import {Mixpanel} from "../../Utils/Mixpanel";

function getToKnowIndexProSteps() {
    return ['See what you’ll be able to do with Index Pro',
        'Customize your profile',
        'Create your first strategy',
        'Create your first account',
        'Invite a teammate'
    ];
}

function OnboardingComp() {
    const history = useHistory();
    const onboarding_status = useOnboardingData();
    const onboarding_complete_length = Object.keys(onboarding_status).length;

    const [productTourOpen, setProductTourOpen] = useState(false)
    const handleProductTourOpen = () => {
        setProductTourOpen(true)
    }
    const handleClose = async () => {
        setProductTourOpen(false);
        Mixpanel.track('Welcome Video');
        window.Intercom('trackEvent', 'welcome-video');
        await getToKnow(0);
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: ipGreen,
            },
        },

    })

    //progress bar component
    function LinearProgressWithLabel(props) {

        return (
            <ThemeProvider theme={theme}>
                <Box display="flex" alignItems="center">
                    <Box width="100%">
                        <LinearProgress variant="determinate" {...props}
                                        style={{height: "5px", backgroundColor: "#e8e8e8"}}/>
                    </Box>
                </Box>
            </ThemeProvider>
        );
    }

    LinearProgressWithLabel.propTypes = {
        value: PropTypes.number.isRequired,
    };

    //invite user
    const [showModal, setShow] = useState(false);
    const [email, setEmail]= useState("");

    const handleCloseInvite = () => setShow(false);
    const handleShowInvite = () => setShow(true);

    const sendEmail = () => {
        getToKnow(4)
        var subject = "You’ve been invited to join Index Pro!";
        var emailBody = "Hello, \n\nYou've been invited to join Index Pro. To sign up please go to https://www.indexpro.tech/signup. \n\nThank you! \n\n";
        document.location = "mailto:"+email+"?subject="+subject+"&body="+encodeURIComponent(emailBody);
    }

    const inviteUser = () => {
        return (
            <>
                <div>
                    <Button style={Object.assign({}, bordered_button,
                        {color: "white", backgroundColor: themeColor})} onClick={() => {
                        handleShowInvite()
                    }}>Invite Teammate</Button>
                </div>
                <Modal
                    open={showModal}
                    style={{overflow: "scroll"}}
                >
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Paper elevation={0} style={{
                            width: "400px", height: "300px", marginTop: "300px",
                        }}>
                            <Grid container direction="row">
                                <Grid item xs={10} style={{paddingTop: "25px"}}>
                                    <p style={card_title}>Invite people to Index Pro</p>
                                </Grid>
                                <Grid item xs={2} style={{paddingRight: "20px", paddingTop: "20px"}}>
                                    <Fab onClick={handleCloseInvite} style={{float: "right"}}>
                                        <ClearIcon/>
                                    </Fab>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justifyContent="center" style={{marginTop: "30px"}}>
                                <TextField
                                    variant="outlined"
                                    type="email"
                                    autoFocus
                                    label="Enter email address"
                                    value={email}
                                    style={{
                                        backgroundColor: "#F5F8FA",
                                        width: "255px",
                                        marginTop: "5px", marginBottom: "5px"
                                    }}
                                    inputProps={{style: ChromeAutoFillOverride}}
                                    onChange={(event) => {event.persist();
                                        setEmail(event.target.value)}}
                                />
                            </Grid>
                            <Grid container direction="row" justifyContent="center" style={{marginTop: "30px"}}>
                                <Button style={bordered_button} onClick={() => {
                                    Mixpanel.track('Invited Teammate');
                                    window.Intercom('trackEvent', 'invited-teammate');
                                    sendEmail();
                                    handleCloseInvite()
                                }}>Invite Teammate</Button>
                            </Grid>
                        </Paper>
                    </div>
                </Modal>
            </>
        );
    }


    //accordion states and helpers
    const [expandedAccordion1Sub, setExpandedAccordion1Sub] = React.useState(false);


    const handleChangeTaskAccordion1 = (panel) => (event, isExpanded) => {
        setExpandedAccordion1Sub(isExpanded ? panel : false);
    };


    const getToKnow = async (i) => {
        if (!onboarding_status.hasOwnProperty(getToKnowIndexProSteps()[0])) {
            let onboarding_status_copy = Object.assign({}, onboarding_status);
            onboarding_status_copy[getToKnowIndexProSteps()[i]] = true
            await DataStore.save(
                new Onboarding({
                    'data': JSON.stringify(onboarding_status_copy),
                    'user_info': localStorage.getItem('signed-in-user')
                })
            );
        }
        const step_complete = onboarding_status.hasOwnProperty(getToKnowIndexProSteps()[i])
        if (!step_complete) {
            let onboarding_status_copy = Object.assign({}, onboarding_status);
            onboarding_status_copy[getToKnowIndexProSteps()[i]] = true
            const original = await DataStore.query(Onboarding)
            let filtered_data = original.filter(obj => obj.user_info.id === JSON.parse(localStorage.getItem('signed-in-user')).id)
            await DataStore.save(
                Onboarding.copyOf(filtered_data[0], updated => {
                    updated.data = JSON.stringify(onboarding_status_copy)
                })
            );
        }
    }


    const [completedStepsAccordion1, setCompletedStepAccordion1] = React.useState([])

    const [selectedColor, setSelectedColor] = React.useState(ipGreen);


    const getStepContentAccordion1 = (step) => {
        switch (step) {
            case 0:
                return (
                    <Grid container direction="row">
                        {onboarding_status.hasOwnProperty(getToKnowIndexProSteps()[0]) ? (
                            <Grid item xs={12}>
                                <Alert severity="success" style={{marginTop: "10px", marginBottom: "40px"}}>
                                    This step is done, but you can always <a onClick={handleProductTourOpen}
                                                                             style={{textDecoration: "underline", cursor: "pointer"}}>watch
                                    the video</a> again.</Alert>
                            </Grid>
                        ) : <></>}
                        <Grid item xs={12}>
                            <p style={Object.assign({}, simple_text, {
                                marginTop: "0px",
                                paddingTop: "0px",
                                paddingRight: "20px"
                            })}>
                            </p>
                        </Grid>
                    </Grid>

                );
            case 1:
                return (
                    <Grid container direction="row">
                        {onboarding_status.hasOwnProperty(getToKnowIndexProSteps()[1]) ? (
                            <Grid item xs={12}>
                                <Alert severity="success" style={{marginTop: "10px", marginBottom: "40px"}}>
                                    Looks great! You can always make more changes to your profile <a
                                    onClick={() => history.push('/participant/account-settings')}
                                    style={{textDecoration: "underline", cursor: "pointer"}}>here</a>.</Alert>
                            </Grid>
                        ) : <></>}
                        <Grid item xs={12}>
                            <p style={Object.assign({}, simple_text, {
                                marginTop: "0px",
                                paddingTop: "0px",
                                paddingRight: "20px"
                            })}>
                            </p>
                        </Grid>
                        {onboarding_status.hasOwnProperty(getToKnowIndexProSteps()[1]) ? (
                            <Grid item xs={12}>
                                <></>
                            </Grid>
                        ) : <Button style={Object.assign({}, bordered_button,
                            {color: "white", backgroundColor: themeColor})}
                                    onClick={() => {
                                        window.Intercom('startTour', 298669);
                                        history.push('/participant/account-settings')
                                    }}>
                            Customize Profile</Button>}
                    </Grid>
                );
            case 2:
                return (
                    <div>
                        {onboarding_status.hasOwnProperty(getToKnowIndexProSteps()[2]) ? (
                            <Grid item xs={12}>
                                <Alert severity="success" style={{marginTop: "10px", marginBottom: "40px"}}>
                                    Congrats on creating your first strategy! You can always access the tour again <a onClick={() => {
                                    window.Intercom('startTour', 373831);
                                    history.push('/participant/strategies')
                                }} style={{textDecoration: "underline", cursor: "pointer"}}>here</a> or watch an in depth video in the <a
                                    onClick={() => history.push('/participant/help-center')} style={{textDecoration: "underline", cursor: "pointer"}}>help center</a>.</Alert>
                            </Grid>
                        ) : <></>}
                        <Grid item xs={12}>
                            <p style={Object.assign({}, simple_text, {
                                marginTop: "0px",
                                paddingTop: "0px",
                                paddingRight: "20px"
                            })}>
                            </p>
                        </Grid>
                        {onboarding_status.hasOwnProperty(getToKnowIndexProSteps()[2]) ? (
                            <Grid item xs={12}>
                                <></>
                            </Grid>
                        ) : <Button style={Object.assign({}, bordered_button,
                            {color: "white", backgroundColor: themeColor})} onClick={() => {
                            window.Intercom('startTour', 373831);
                            history.push('/participant/strategies')
                        }}>Start Tour</Button>}
                    </div>
                );
            case 3:
                return (
                    <div>
                        {onboarding_status.hasOwnProperty(getToKnowIndexProSteps()[3]) ? (
                            <Grid item xs={12}>
                                <Alert severity="success" style={{marginTop: "10px", marginBottom: "40px"}}>
                                    Congrats on creating your first account! You can always access the tour again <a onClick={() => {
                                    window.Intercom('startTour', 373848);
                                    history.push('/participant/add-account')
                                }} style={{textDecoration: "underline"}}>here</a>.</Alert>
                            </Grid>
                        ) : <></>}
                        <Grid item xs={12}>
                            <p style={Object.assign({}, simple_text, {
                                marginTop: "0px",
                                paddingTop: "0px",
                                paddingRight: "20px"
                            })}>
                            </p>
                        </Grid>
                        {onboarding_status.hasOwnProperty(getToKnowIndexProSteps()[3]) ? (
                            <Grid item xs={12}>
                                <></>
                            </Grid>
                        ) : <Button style={Object.assign({}, bordered_button,
                            {color: "white", backgroundColor: themeColor})} onClick={() => {
                            window.Intercom('startTour', 373848);
                            history.push('/participant/add-account')
                        }}>Start Tour</Button>}
                    </div>
                );
            case 4:
                return (
                    <div>
                        {onboarding_status.hasOwnProperty(getToKnowIndexProSteps()[4]) ? (
                            <Grid item xs={12}>
                                <Alert severity="success" style={{marginTop: "10px", marginBottom: "40px"}}>
                                    Congrats on inviting a teammate! You can always invite another teammate below
                                </Alert>
                            </Grid>
                        ) : <></>}
                        {inviteUser()}
                    </div>
                );
        }
    }

    const stepsAccordion1 = getToKnowIndexProSteps();

    const ProgressBar1 = (percentage) => {
        return (
            <Grid>
                <LinearProgressWithLabel
                    value={Math.floor((percentage) * 100)}
                    style={{width: "100%"}}/>
            </Grid>
        )
    }


    return (
        <div className="Onboarding">
            {!onboarding_status.hasOwnProperty(getToKnowIndexProSteps()[0]) ? (
                    <Paper style={paper_right} elevation={0}>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <div style={{width: "638", paddingLeft: "60px", marginTop: "40px", paddingRight: "60px"}}>
                                <p style={{fontSize: "18px", color: themeColor}}>Welcome!</p>
                                <p style={{fontSize: "20px", fontWeight: "600"}}>Let's start with a quick product tour</p>
                                <p style={{fontSize: "17px", marginTop: "40px"}}>We'll have you up and running in no time.</p>
                                <Button onClick={handleProductTourOpen}
                                    style={Object.assign({}, bordered_button, {color: "white", backgroundColor: themeColor, width: "200px", marginTop: "80px"})}>Get Started</Button>
                            </div>

                        </Grid>
                    </Paper>
            ) : (
                    <Paper style={paper_right} elevation={0}>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <div>
                                <p style={card_title}>Get to Know Index Pro</p>
                                {Math.floor((onboarding_complete_length / stepsAccordion1.length) * 100) === 100 ? (
                                    <Grid>
                                        <p style={Object.assign({}, card_subtitle, {color: selectedColor})}>All
                                            Steps Complete!</p>
                                    </Grid>
                                ) : (
                                    <Grid>

                                        {(stepsAccordion1.length - onboarding_complete_length) === 1 ? (
                                            <p style={Object.assign({}, card_subtitle, {})}>
                                                {stepsAccordion1.length - onboarding_complete_length} step
                                                left</p>
                                        ) : (
                                            <p style={Object.assign({}, card_subtitle, {})}>
                                                {stepsAccordion1.length - onboarding_complete_length} steps
                                                left</p>
                                        )}

                                    </Grid>
                                )}
                            </div>
                        </Grid>
                        <Accordion expanded={true} elevation={0} style={{marginTop: "-10px"}}>
                            <AccordionSummary style={{padding: "0px"}}
                            >
                                <Grid container direction="column">
                                    {ProgressBar1(onboarding_complete_length / stepsAccordion1.length)}
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container direction="column">
                                    {stepsAccordion1.map((label, index) => (
                                        <Paper elevation={0}>
                                            <Accordion elevation={0} style={{
                                                width: "100%",
                                                border: expandedAccordion1Sub === index.toString() ? `1px solid ${themeColor}` : '0px'
                                            }}
                                                       expanded={expandedAccordion1Sub === index.toString()}
                                                       onChange={handleChangeTaskAccordion1(index.toString())}>
                                                <OnboardingAccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                                    <Grid container direction="column">
                                                        {!onboarding_status.hasOwnProperty(label) ? (
                                                            <Grid container direction="row" justifyContent="flex-start"
                                                                  alignItems="center">
                                                                <Avatar style={{
                                                                    marginRight: "10px",
                                                                    width: "30px",
                                                                    height: "30px",
                                                                    backgroundColor: "white",
                                                                    border: "1px solid grey"
                                                                }}><p
                                                                    style={Object.assign({}, simple_text, {color: "grey"})}>{index + 1}</p>
                                                                </Avatar>
                                                                <p style={simple_text_bold}>{label}</p>
                                                            </Grid>
                                                        ) : (
                                                            <Grid container direction="row" justifyContent="flex-start"
                                                                  alignItems="center">
                                                                <Avatar style={{
                                                                    marginRight: "10px",
                                                                    width: "30px",
                                                                    height: "30px",
                                                                    backgroundColor: selectedColor,
                                                                }}><DoneIcon
                                                                    style={Object.assign({}, simple_text, {
                                                                        color: "white", width: "25px",
                                                                        height: "25px"
                                                                    })}/>
                                                                </Avatar>
                                                                <p style={simple_text_bold}>{label}</p>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </OnboardingAccordionSummary>
                                                <AccordionDetails>
                                                    {!completedStepsAccordion1.includes(index) ? (
                                                        <Grid container direction="column">
                                                            <Typography>{getStepContentAccordion1(index)}</Typography>
                                                        </Grid>
                                                    ) : (
                                                        <Grid container direction="column">
                                                            <Typography>{getStepContentAccordion1(index)}</Typography>
                                                        </Grid>
                                                    )}
                                                </AccordionDetails>
                                            </Accordion>
                                        </Paper>
                                    ))}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Paper>
            )}
            <Modal
                open={productTourOpen}
                style={{overflow: "scroll"}}
            >
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Paper elevation={0} style={{
                        width: "1200px", height: "800px", marginTop: "200px"
                    }}>
                        <Grid container direction="row">
                            <Grid item xs={12} style={{paddingRight: "20px", paddingTop: "20px"}}>
                                <ClearIcon onClick={handleClose}
                                           style={{float: "right", width: "20px", height: "20px"}}/>
                            </Grid>
                            <Grid item xs={12} style={{marginTop: "20px"}}>
                                <iframe
                                    width="1200"
                                    height="750"
                                    src={`https://res.cloudinary.com/indexpro/video/upload/Onboarding%20Videos/Overview.mp4`}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    title="Product Tour"
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </Modal>
        </div>
    );
}

export default OnboardingComp;