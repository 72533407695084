import React from "react";

export const Disclosure = () => {
    return (
        <div style={{height: "100%", position: "relative", color: "black", fontSize: "9px"}}>
            <div style={{position: "relative", top: "30px", margin: "60px"}}>
                    <div style={{
                            width: "755px", fontSize: "14px", fontWeight: "600", borderBottom: "1px solid black"
                    }}>
                            Index Pro Disclosure Statement
                    </div>
            <br/><br/>
            This document and the information contained herein is the proprietary information of Atom Investors LP (“Atom”); it may not be reproduced or distributed to anyone other than the intended recipient, without the prior written permission of Atom.  This material has been prepared by Atom, which is registered as an investment adviser with the U.S. Securities and Exchange Commission (“SEC”), pursuant the Investment Advisers Act of 1940 (“Advisers Act”).  Registration with the SEC does not imply a certain level of skill or training. Copies of the most recently filed Form ADV for Atom and additional information about registered investment advisers is available through the Investment Adviser Public Disclosure at www.adviserinfo.sec.gov. The material has been prepared solely for use by the intended recipient, and information herein has been customized exclusively for the recipient based on information collected electronically from the recipient through Atom’s digital investment tool, Index Pro.
            <br/><br/>
            This material contains an illustrative example of an investment portfolio and analytics based on information and preferences previously provided to Atom by the recipient of this material.  If a transition report is included in the brochure, Atom makes no representations concerning the accuracy of the initial securities holdings provided to Atom or any information provided by a third party. By investing with Atom, there can be no guarantee that Atom will be able to replicate for an investor the sample investment portfolio described herein or that the analysis associated with particular factors and potential impact depicted will come to fruition.  Actual results could vary materially.
            <br/><br/>
            The information in this material is for illustration and discussion purposes only.  Nothing in this document constitutes an offer, solicitation, or recommendation to purchase any security, financial instrument, investment, or service or engage in any other transaction. The information contained in this document does not constitute any form of legal, tax, securities or investment advice, or fiduciary investment advice. It does not take into account the financial objectives, situation or needs of any persons, which are necessary considerations before making any investment decision.  Prior to making any investment or ﬁnancial decisions, investors should seek individualized advice from personal ﬁnancial, legal, tax and other professionals that take into account all of the particular facts and circumstances of an individual investor’s own situation. Atom does not represent that any securities, financial instruments, products or services contained in these materials are suitable or appropriate for any particular investor or type of investor.
            <br/><br/>
            Unless stated otherwise, all views expressed herein represent Atom’s opinion at the time of writing. The general opinions and information herein should be used as a component of an investor’s broader due diligence process. Exclusive reliance on the information herein is not advised. The information herein is based on current market conditions and is subject to change.  Certain information, research data, and, if applicable, initial securities holdings pertaining to a transition report contained in this material may have been obtained or derived from independent sources believed to be reliable. Atom cannot guarantee the accuracy or completeness of such information and has not reviewed the assumptions on which such information is based.  Atom does not undertake to update this document, and the information and views discussed may change without notice. Legal, accounting and tax restrictions, transaction costs and changes to any assumptions may significantly affect the economics and results of any transaction or investment.
            <br/><br/>
            <span style={{fontWeight: "600"}}>Past performance is not indicative of future results. Future performance is not guaranteed and a loss of principal may occur. Market and exchange rate movements may cause the capital value of investments, and the income from them, to go down as well as up and the investor may not get back the amount originally invested.</span>
            <br/><br/>
            This presentation includes “forward-looking statements” and estimates relating to the potential impact of certain investment factors. All targets, forecasts, or related statements or expressions of opinion are forward-looking statements.  Although Atom believes that the expectations reflected in such forward-looking statements are reasonable, it can give no assurance that such statements will prove to be correct, and such forward-looking statements should not be regarded as a guarantee, prediction, or definitive statement of fact or probability.
            <br/><br/>
            Certain figures and calculations contained herein were internally generated by Atom based on assumptions and estimated data and analysis. These figures and calculations have not been audited or otherwise confirmed by a third party.  Assumptions may prove incorrect and such estimates may vary materially from actual results.
            <br/><br/>
            Atom does not provide tax or legal advice. Tax-related statements are based on Atom’s understanding of the tax laws. Investors should seek the advice of their independent legal and tax counsel before investing.
            <br/><br/>
            <u>Important Disclosure Relating to Indices and Tracking Error</u>
            <br/>
            To the extent that a model portfolio managed by Atom attempts to track the holdings and weightings of an index, such model portfolio may experience tracking error risk.  Tracking error refers to the risk that Atom may not be able to cause a model portfolio’s performance to match or correlate to that of an index that it seeks to track, either on a daily or aggregate basis. There are a number of factors that may contribute to a model portfolio’s tracking error, such as model portfolio expenses, imperfect correlation between the portfolio’s investments and those of the applicable index, rounding of share prices, the timing or magnitude of changes to the composition of the index, regulatory policies, and high portfolio turnover rate. There are operating expenses related to managing a portfolio, including costs associated with buying and selling securities, which will not apply to the relevant index. A portfolio is especially susceptible to tracking error when rebalancing the portfolio’s holdings to reflect changes in the composition of the index. To the extent a portfolio utilizes a sampling approach, it may experience tracking error to a greater extent than if the Fund sought to replicate its index. Tracking error may cause a portfolio’s performance to be less than expected.
            <br/><br/>
            <u>Environmental, Social and Governance and Use of MSCI ESG Ratings</u>
            <br/>
            Through its Index Pro tool, Atom integrates Environmental, Social and Governance (“ESG”) factors into its investment models and analysis. When managing the model portfolios discussed herein, Atom will consider ESG factors, which may include, but are not limited to, environmental concerns (e.g., climate change and pollution and waste), social issues (e.g., child labor and diversity, equity and inclusion), governance issues (e.g., corporate behavior). Atom’s assessment of these and other ESG issues are limited by the availability of information about companies in which a model portfolio will seek to invest.
            <br/><br/>
            In particular, Atom’s analysis of ESG factors relies heavily on ESG-related data and ratings provided by a third-party research firm, MSCI ESG Research LLC (“MSCI ESG”).  MSCI ESG specializes in assessing companies’ exposure to ESG risks and each company’s effectiveness in managing these risks.  Based on data that it collects, and by using a proprietary methodology over which Atom has no influence, MSCI ESG assigns ESG ratings to each company it evaluates.  Atom Index Pro uses MSCI ESG’s ratings to develop portfolios that seek to align with the stated investment preferences and goals of an investor.  Atom cannot guarantee the accuracy or completeness of MSCI ESG’s data and has not reviewed the assumptions on which such information is based.  There can be no guarantee that MSCI ESG’s methodology will, in every instance, appropriately measure a company’s ESG risks or such company’s effectiveness in addressing these risks. To learn more about MSCI ESG Ratings, please refer to https://www.msci.com/our-solutions/esg-investing/esg-ratings.
            <br/><br/>
            Issues associated with a particular industry, changing social conditions, or other circumstances may affect the manner in which Index Pro’s ESG criteria are applied in a particular situation.  Companies in which a model portfolio will seek to invest will not necessarily meet targeted standards in all aspects of ESG performance.  Consideration of a company’s ESG profile and risks is only one factor among several other components of Atom’s investment thesis, and such ESG considerations are not determinative of Atom’s selection of an investment.  In addition, by considering ESG factors, Atom may forgo opportunities to select or retain investments for its portfolios when it might otherwise be advantageous to do so.  The exclusion of investments for ESG purposes may cause the performance of a portfolio to be lower than it otherwise would be if such investments were included.
        </div>
        </div>
    )
}