import React from "react";

export const EnvironmentalSelectionsTitlePage = () => {
    return (
        <div style={{height: "100%", position: "relative",  fontDisplay: "swap"}}>
            <img
                src="https://res.cloudinary.com/indexpro/image/upload/v1652901748/Proposal/Environmental%20Selections%20Title%20Page.jpg"
                width="100%" height="100%"/>
            <div style={{
                position: "absolute",
                top: "250px",
                left: "60px",
                fontSize: "84px",
                fontWeight: "700",
                color: "white",
                lineHeight: 1
            }}>
                ENVIRONMENTAL SELECTIONS
            </div>
            <div style={{
                position: "absolute",
                bottom: "80px",
                left: "60px",
                fontSize: "18px",
                color: "white",
                width: "300px"
            }}>
                “The earth does not belong to us. We belong to the Earth.”
            </div>
            <div style={{position: "absolute", bottom: "40px", left: "60px", fontSize: "18px", color: "white"}}>
                Chief Seattle
            </div>
        </div>
    )
}
