import React, {useState} from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {Grid, Typography, Paper, Button} from "@material-ui/core";
import {observer} from "mobx-react-lite";
import FormGroup from "@material-ui/core/FormGroup";
import BuilderSwitch from "./builder-switch-style"
import {control_paper_left} from "../../Styles/styles";
import GetInTouch from "./builder-journey";


function ApplyValues({builder_state}) {

    //value data + handlers
    const values_data = builder_state.values_data
    const apply_your_values = builder_state.apply_your_values
    const esg_value = builder_state.esg_value
    const esg_implementation = builder_state.esg_implementation === 'Exclude'
    const handleValueSelect = (name) => {
        builder_state.setESGValue(name);
    }
    const handleApplyValuesButton = (event) => {
        builder_state.setApplyYourValues(event.target.checked);
    };
    const handleESGImplementationButton = (event) => {
        let val = event.target.checked ? 'Exclude' : 'Tilt'
        builder_state.setESGImplementation(val);
    };

    //expansion panel
    const [expanded, setExpanded] = useState('0');
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    //create accordion for value data
    const createValuesDropDown = (values_data, index) => {
        //pull out data
        let logo = values_data.logo
        let options = values_data['options']
        return (
            <Accordion className="ExpansionPanel" square style={{boxShadow: "none"}}
                       expanded={expanded === index.toString()} onChange={handleChange(index.toString())}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    classes={{expanded: 'expanded'}}
                >
                    <img src={logo}
                         alt="logo" width="220" height="50"/>
                </AccordionSummary>
                {options.map(function (name, index) {
                    return (
                        <AccordionDetails className="ExpansionDetails">
                            {(() => {
                                if (name === esg_value) {
                                    return (
                                        <Button style={{marginLeft: "20px", textTransform: "none"}}
                                                onClick={() => handleValueSelect(name)}>
                                            <Typography style={{fontWeight: 700}}>{name}</Typography>
                                        </Button>
                                    )
                                } else {
                                    return (
                                        <Button style={{marginLeft: "20px", textTransform: "none"}}
                                                onClick={() => handleValueSelect(name)}>
                                            <Typography>{name}</Typography>
                                        </Button>
                                    )
                                }
                            })()}

                        </AccordionDetails>
                    )
                })}
            </Accordion>
        )
    };

    return (
        <Paper style={control_paper_left} elevation={0} square>
            <div className="ControlPanelTitle">
                <p className="ControlPanelText" style={{textAlign: "center"}}>Apply your values</p>
                <div className="BuilderButton">
                    <FormGroup>
                        <Typography component="div">
                            <Grid component="label" container alignItems="center" spacing={1}>
                                <Grid item>No</Grid>
                                <Grid item>
                                    <BuilderSwitch
                                        checked={apply_your_values}
                                        onChange={handleApplyValuesButton}
                                        name="apply_your_values"
                                    />
                                </Grid>
                                <Grid item>Yes</Grid>
                            </Grid>
                        </Typography>
                    </FormGroup>
                </div>
                <p style={{margin: "10px", marginTop: "30px", fontSize: "14px"}}>CHOOSE A PROVIDER
                    ({Object.keys(values_data).length.toString()})</p>
            </div>
            {
                Object.keys(values_data).map((key, index) => {
                    return (
                        <div>
                            {createValuesDropDown(values_data[key], index)}
                        </div>
                    )
                })
            }
            <div className="ControlPanelSubtitle">
                <p style={{margin: "10px"}}>ESG IMPLEMENTATION</p>
                <div className="BuilderButton">
                    <FormGroup>
                        <Typography component="div">
                            <Grid component="label" container alignItems="center" spacing={1}>
                                <Grid item>Tilt</Grid>
                                <Grid item>
                                    <BuilderSwitch
                                        checked={esg_implementation}
                                        onChange={handleESGImplementationButton}
                                        name="esg_implementation"
                                    />
                                </Grid>
                                <Grid item>Exclude</Grid>
                            </Grid>
                        </Typography>
                    </FormGroup>
                </div>
            </div>
        </Paper>
    )
}

export default observer(ApplyValues);