import {Grid, Paper} from "@material-ui/core";
import {big_paper, card_subtitle, card_title, card_value} from "../../Styles/styles";
import {dollarsWithCommas, percentage} from "../../Utils/num_utils";
import React from "react";

function AccountOverview(props) {
    return (
        <div className="Account-Overview">
            <Paper style={Object.assign({}, big_paper, {paddingBottom: "20px"})} elevation={0}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center"
                      style={{paddingRight: "20px"}}>
                    <Grid item>
                        <p style={card_title}>Total Balance</p>
                        <p style={card_subtitle}>Investment Account</p>
                        <p style={card_value}>{dollarsWithCommas(Math.round(props.account.data['Total Balance']))}</p>
                    </Grid>
                    <Grid item>
                        <p style={card_title}>ITD Performance</p>
                        <p style={card_subtitle}>Money-Weighted Return</p>
                        <p style={card_value}>{percentage(props.account.data['Money-Weighted Return'])}</p>
                    </Grid>
                    <Grid item>
                        <p style={card_title}>Total Earnings</p>
                        <p style={card_subtitle}>Investment Account</p>
                        <p style={card_value}>{dollarsWithCommas(Math.round(props.account.data['Total Earnings']))}</p>
                    </Grid>
                    <Grid item>
                        <p style={Object.assign({}, card_title, {marginRight: "100px"})}>Strategy</p>
                        <p style={card_subtitle}>Investment Account</p>
                        <p style={card_value}>{props.strategy ? (props.strategy.strategy_name) : null}</p>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

export default AccountOverview