import {Paper} from "@material-ui/core";
import {card_subtitle, card_title, HeaderCell, IPTableRow, paper_left, ValueCell} from "../../Styles/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import React from "react";

function AccountTransitionSectors(props) {
    return (
        <div className="Sectors">
            <Paper style={paper_left} elevation={0}>
                <div>
                    <p style={card_title}>Sector Breakdown</p>
                    <p style={card_subtitle}>Input vs. Benchmark vs. Target</p>
                </div>
                {props.account.transition_data ? (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <HeaderCell>Sector</HeaderCell>
                                    <HeaderCell align="right">Input Weight</HeaderCell>
                                    <HeaderCell align="right">Benchmark Weight</HeaderCell>
                                    <HeaderCell align="right">Target Weight</HeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.account.transition_data['sector_breakdown'].map((row) => (
                                    <IPTableRow key={row.gics_1}>
                                        <ValueCell>{row.gics_1}</ValueCell>
                                        <ValueCell align="right">{row.input_weight}</ValueCell>
                                        <ValueCell align="right">{row.benchmark_weight}</ValueCell>
                                        <ValueCell align="right">{row.weight}</ValueCell>
                                    </IPTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (<></>)
                }
            </Paper>
        </div>
    )
}

export default AccountTransitionSectors