import React, {useEffect, useState} from "react";
import {DataStore} from "@aws-amplify/datastore";
import {
    AllocationModel,
    BenchmarkLogo,
    Benchmarks,
    CustomAlphaModel, CustomExclusions,
    CustomOptions, CustomStrategies, Firm,
    PreConfiguredValues,
    PublicFundData, RevenueThresholdExclusions, Strategies,
    Themes
} from "../../../../models";
import {Auth, Storage} from "aws-amplify";

//custom hook returns benchmark data
export function useBenchmarkData(benchmark_name, allocation_model_id) {
    const [data, setData] = useState(null)

    async function getAllocationModel() {
        try {
            const allocation_model = await DataStore.query(AllocationModel, allocation_model_id);
            setData(allocation_model.data)
        } catch (error) {
            console.error('error:', error)
        }
    }

    async function getBenchmarkData() {
        try {
            const data = await DataStore.query(Benchmarks);
            const unique_index = [...new Set(data.map(item => item.index_name))];
            const obj = {}
            for (let i = 0; i < unique_index.length; i++) {
                let index = unique_index[i]
                let index_obj = data.filter(x => x.index_name === index)[0];
                let index_data = index_obj['data']
                obj[index] = index_data
            }
            setData(obj[benchmark_name])
        } catch (error) {
            console.error('error:', error)
        }
    }
    useEffect(() => {
        if (allocation_model_id) {
            getAllocationModel()
            const subscription = DataStore.observe(AllocationModel, allocation_model_id).subscribe(getAllocationModel)
            return () => subscription.unsubscribe();
        }
        else {
            getBenchmarkData()
        }
    }, [benchmark_name, allocation_model_id]);
    return data
}

export function useAllocationModel(id) {
    const [data, setData] = useState(null)

    async function getAllocationModel() {
        try {
            const allocation_model = await DataStore.query(AllocationModel, id);
            setData(allocation_model)
        } catch (error) {
            console.error('error:', error)
        }
    }
    useEffect(()=>{
        if (id) {
            getAllocationModel()
            const subscription = DataStore.observe(AllocationModel, id).subscribe(getAllocationModel)
            return () => subscription.unsubscribe();
        }
        else {
            setData(null)
        }
    }, [id])
    return data
}

//custom hook that returns benchmark data for benchmark selector
export function useBenchmarkDataForSelector() {
    const [data, setData] = useState(null)
    useEffect(() => {
        let getBenchmarkData = async () => {
            try {
                const data = await DataStore.query(Benchmarks);
                const benchmark_logo = await DataStore.query(BenchmarkLogo);
                const unique_index_family = [...new Set(data.map(item => item.index_family))];
                const obj = {}
                for (let i = 0; i < unique_index_family.length; i++) {
                    let index_family = unique_index_family[i]
                    let index_family_logo = benchmark_logo.filter(x => x.index_family === index_family)[0];
                    let index_family_objs = data.filter(x => x.index_family === index_family);
                    index_family_objs.sort(function (a, b) {
                        return a.order - b.order
                    })
                    var options = index_family_objs.map(function (el) {
                        return el.index_name;
                    });
                    obj[index_family] = {'options': options, 'logo': index_family_logo['logo_url']}
                }
                setData(obj)
            } catch (error) {
                console.error('error:', error)
            }
        }
        getBenchmarkData();
    }, []);
    return data
}

//custom hook that returns benchmark title data
export function useBenchmarkTitle(benchmark_name, allocation_model_id) {
    const [data, setData] = useState(null)
    useEffect(() => {
        let getBenchmarkData = async () => {
            if (!allocation_model_id) {
                try {
                    const data = await DataStore.query(Benchmarks);
                    const unique_index = [...new Set(data.map(item => item.index_name))];
                    const obj = {}
                    for(let i=0; i<unique_index.length; i++){
                        let index = unique_index[i]
                        let index_obj = data.filter(x => x.index_name === index)[0];
                        obj[index] = [index_obj['index_family'], index_obj['index_name'], index_obj['description']]
                    }
                    setData(obj[benchmark_name])
                } catch (error) {
                    console.error('error:', error)
                }
            }
            else {
                const data = await DataStore.query(Firm);
                setData(['Custom',data[0]['firm_name'],benchmark_name])
            }
        }
        getBenchmarkData();
    }, [benchmark_name, allocation_model_id]);
    return data
}

//custom hook returns core value data
export function useCoreValueData() {
    const [data, setData] = useState([])

    useEffect(() => {
        let getCoreValuesData = async () => {
            try {
                const core_values_data = await DataStore.query(PreConfiguredValues);
                core_values_data.sort(function (a, b) {
                    return a.order - b.order
                })
                await setData(core_values_data)
            } catch (error) {
                console.error('error:', error)
            }
        }
        getCoreValuesData();
    }, []);
    return data
}

//custom hook returns custom value data
export function useCustomValueData() {
    const [data, setData] = useState([])

    useEffect(() => {
        let getCustomValuesData = async () => {
            try {
                const custom_values_data = await DataStore.query(CustomOptions);
                custom_values_data.sort(function (a, b) {
                    return a.title.localeCompare(b.title)
                })
                await setData(custom_values_data)
            } catch (error) {
                console.error('error:', error)
            }
        }
        getCustomValuesData();
    }, []);
    return data
}

//custom hook returns exclusion data
export function useCustomExclusionData() {
    const [data, setData] = useState([])

    useEffect(() => {
        let getCustomExclusionsData = async () => {
            try {
                const exclusion_data = await DataStore.query(CustomExclusions);
                exclusion_data.sort(function (a, b) {
                    return a.label.localeCompare(b.label)
                })
                await setData(exclusion_data)
            } catch (error) {
                console.error('error:', error)
            }
        }
        getCustomExclusionsData();
    }, []);
    return data
}


//custom hook returns theme data
export function useThemeData() {
    const [data, setData] = useState([])

    useEffect(() => {
        let getThemeData = async () => {
            try {
                const themes = await DataStore.query(Themes);
                themes.sort(function (a, b) {
                    return a.title.localeCompare(b.title)
                })
                await setData(themes)
            } catch (error) {
                console.error('error:', error)
            }
        }
        getThemeData();
    }, []);
    return data
}

export function useRevenueExclusionsData() {
    const [data, setData] = useState([])

    useEffect(() => {
        let getRevenueData = async () => {
            try {
                const revenue_data = await DataStore.query(RevenueThresholdExclusions);
                revenue_data.sort(function (a, b) {
                    return a.title.localeCompare(b.title)
                })
                await setData(revenue_data)
            } catch (error) {
                console.error('error:', error)
            }
        }
        getRevenueData();
    }, []);
    return data
}


//custom hook that returns public fund data
export function usePublicFundData() {
    const [data, setData] = useState([])

    useEffect(() => {
        let getPublicFundData = async () => {
            try {
                const public_fund_data = await DataStore.query(PublicFundData);
                let deserialized_data = public_fund_data[0]['data']
                setData(deserialized_data)
            } catch (error) {
                console.error('error:', error)
            }
        }
        getPublicFundData();
    }, []);
    return data
}

//custom hook returns user's alpha models
export function useAlphaModels() {
    const [data, setData] = useState([])

    useEffect(() => {
        let getAlphaModels = async () => {
            try {
                const public_fund_data = await DataStore.query(CustomAlphaModel);
                setData(public_fund_data.map(item => {
                    return item.name
                }))
            } catch (error) {
                console.error('error:', error)
            }
        }
        getAlphaModels();
    }, []);
    return data
}


//custom hook returns user's alpha models
export function useAllocationModels() {
    const [data, setData] = useState([])

    async function getAllocationModels() {
        try {
            const allocation_models = await DataStore.query(AllocationModel);
            setData(allocation_models)
        } catch (error) {
            console.error('error:', error)
        }
    }
    useEffect(() => {
        getAllocationModels();
        const subscription = DataStore.observe(AllocationModel).subscribe(getAllocationModels)
        return () => subscription.unsubscribe();
    }, []);
    return data
}


//custom hook that returns msci value data for benchmark
export function useESGValueData(code, allocation_model_id, allocation_model) {
    const [data, setData] = useState([]);
    useEffect(() => {
        let getESGValueData = async () => {
            if (!allocation_model_id) {
                try {
                    if (code) {
                        const result = await Storage.get(`value_data/${code}.txt`, {
                            level: "public",
                            download: true,
                            cacheControl: 'no-cache'
                        });
                        result.Body.text().then(i => {
                            setData(JSON.parse(i))
                        });
                    }
                } catch (error) {
                    setData([])
                    console.error('error: ', error)
                }
            } else {
                try {
                    const result = await Storage.get(`allocation_model/${allocation_model_id}.txt`, {
                        level: "private",
                        download: true,
                        cacheControl: 'no-cache',
                    });
                    result.Body.text().then(i => {
                        setData(JSON.parse(i))
                    });
                } catch (error) {
                    setData([])
                    console.error('error: ', error)
                }
            }
        }
        getESGValueData();
    }, [code, allocation_model_id, allocation_model]);
    return data
}

//custom hook that returns benchmark code given a benchmark name
export function useBenchmarkCode(benchmark_name, allocation_model_id) {
    const [data, setData] = useState(null)

    useEffect(() => {
        let getBenchmarkCode = async () => {
            if (!allocation_model_id) {
                try {
                    const benchmarks = await DataStore.query(Benchmarks);
                    const benchmark = benchmarks.filter(i => i.index_name === benchmark_name)
                    setData(benchmark[0]['code'])
                } catch (error) {
                    console.error('error: ', error)
                }
            }
            else {
                setData(null)
            }
        }
        getBenchmarkCode();
    }, [benchmark_name, allocation_model_id]);
    return data
}

//custom hook that returns mutual fund data for alpha model
export function useMutualFundData() {
    const [data, setData] = useState([]);
    useEffect(() => {
        let getFundData = async () => {
            try {
                const result = await Storage.get(`funds-new.txt`, {
                    level: "public",
                    download: true,
                    cacheControl: 'no-cache'
                });
                result.Body.text().then(i => {
                    setData(JSON.parse(i))
                });
            } catch (error) {
                console.error('error: ', error)
            }
        }
        getFundData();
    }, []);
    return data
}

//custom hook that returns esg models based on array titles
export function useESGModels(selection_array) {
    const [data, setData] = useState([]);
    useEffect(() => {
        let getESGModels = async () => {
            try {
                const core = await DataStore.query(PreConfiguredValues);
                const custom = await DataStore.query(CustomOptions);
                const result = core.concat(custom).filter(i => selection_array.includes(i.title))
                setData(result)
            } catch (error) {
                console.error('error: ', error)
            }
        }
        getESGModels();
    }, []);
    return data
}


//custom hook that returns theme models based on array titles
export function useThemeModels(selection_array) {
    const [data, setData] = useState([]);
    useEffect(() => {
        let getThemeModels = async () => {
            try {
                const core = await DataStore.query(Themes);
                const result = core.filter(i => selection_array.includes(i.title))
                setData(result)
            } catch (error) {
                console.error('error: ', error)
            }
        }
        getThemeModels();
    }, []);
    return data
}


export function useCustomExclusionModels(selection_array) {
    const [data, setData] = useState([]);
    useEffect(() => {
        let getCustomExclusionModels = async () => {
            try {
                const core = await DataStore.query(CustomExclusions);
                const result = core.filter(i => selection_array.includes(i.title))
                setData(result)
            } catch (error) {
                console.error('error: ', error)
            }
        }
        getCustomExclusionModels();
    }, []);
    return data
}

export function usePagination(data, itemsPerPage) {
    const [currentPage, setCurrentPage] = useState(1);
    const maxPage = Math.ceil(data.length / itemsPerPage) + 1;
    function currentData() {
        const begin = (currentPage - 1) * itemsPerPage;
        const end = begin + itemsPerPage;
        return data.slice(begin, end);
    }

    function next() {
        setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
    }

    function prev() {
        setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
    }

    function jump(page) {
        const pageNumber = Math.max(1, page);
        setCurrentPage(currentPage => Math.min(pageNumber, maxPage));
    }

    return { next, prev, jump, currentData, currentPage, maxPage };
}
