import {big_paper} from "../../Styles/styles";
import Paper from "@material-ui/core/Paper";
import {Divider, Grid} from "@material-ui/core";
import React from "react";

function HelpBuilder() {
    return (
        <div>
            <Paper style={big_paper} elevation={0}>
                <div style={{padding: "20px"}}>
                    <p style={{fontWeight: "600", fontSize: "18px"}}>Video Tutorial: Builder</p>
                    <Grid item xs={12} style={{marginTop: "20px"}}>
                        <iframe
                            width="1240"
                            height="750"
                            src={`https://res.cloudinary.com/indexpro/video/upload/Onboarding%20Videos/Builder.mp4`}
                            frameBorder="0"
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Product Tour"
                        />
                    </Grid>
                    <br/><br/>
                    <p style={{fontWeight: "600", fontSize: "18px"}}>Builder Overview:</p>
                    <br/>
                    The <span style={{fontWeight: "600"}}>Builder</span> allows you to build single index strategies or allocation models to include indexes,
                    mutual funds, and ETFs. On this page, you can learn how to build a single index strategy.
                    The sample strategy referenced in this guide is for informational purposes only and is not
                    intended for use, unless you independently determine its suitability for one of your clients.
                    Additionally, it shall not be construed as investment advice made by Index Pro. To learn how to
                    create an allocation model, please click on the Allocation Model help page in our help center.
                    <br/><br/>
                    <p style={{fontWeight: "600", fontSize: "18px"}}>Building a Single Index Strategy:</p>
                    <br/>
                    <Grid container direction="row" >
                        <div style={{width: "600px", marginRight: "20px"}}>
                            <span style={{fontWeight: "600"}}>Step 1 | Select your Index</span>
                            <br/><br/>
                            You can choose from a list of S&P, FTSE Russell, or MSCI indexes.
                            <br/><br/>
                            <span style={{fontStyle: "italic"}}>You will notice the information on the left-hand side
                                updating as you go from index to index. This is an overview of the selected index,
                                its sector breakdown and the top 10 holdings based on their weight. </span>
                            <br/><br/>
                        </div>
                        <div style={{width: "550px", marginLeft: "50px"}}>
                            <img src={"https://res.cloudinary.com/indexpro/image/upload/Help%20Section%20Images/Builder-Index-1.png"}
                                 alt="builder-index" width="550px"/>
                        </div>
                    </Grid>
                    <br/>
                    <Divider/>
                    <br/><br/>
                    <Grid container direction="row" >
                        <div style={{width: "600px", marginRight: "20px"}}>
                            <span style={{fontWeight: "600"}}>Step 2 | Apply Your Values</span>
                            <br/><br/>
                            Apply the values your client believes in. These groupings are based on the MSCI ESG ratings.
                            You can select from <span style={{fontWeight: "600"}}>Core Options, Custom Options, Custom Exclusions, and Revenue Threshold Exclusions.</span>
                        </div>
                        <div style={{width: "600px"}}>
                        </div>
                    </Grid>
                    <br/><br/><br/><br/>
                    <Grid container direction="row" >
                        <div style={{width: "400px", marginRight: "20px"}}>
                            <span style={{fontWeight: "600"}}>1. Core Options</span>
                            <br/><br/>
                            Select your core options by clicking on the + mark.
                            <br/><br/>
                            <span style={{fontStyle: "italic"}}>You can learn more about a specific value by clicking on it. </span>
                        </div>
                        <div style={{width: "800px"}}>
                            <img src={"https://res.cloudinary.com/indexpro/image/upload/Help%20Section%20Images/Builder-CoreOptions.png"}
                                 alt="builder-core-options" width="800px"/>
                        </div>
                    </Grid>
                    <br/><br/><br/><br/>

                    <Grid container direction="row" >
                        <div style={{width: "400px", marginRight: "20px"}}>
                            <span style={{fontWeight: "600"}}>2. Custom Options</span>
                            <br/><br/>
                            Apply more specific values by selecting from the list of 10 custom options. Simply click on the + mark to select.
                            <br/><br/>
                            <span style={{fontStyle: "italic"}}>You can learn more about a specific value by clicking on it. </span>

                        </div>
                        <div style={{width: "800px"}}>
                            <img src={"https://res.cloudinary.com/indexpro/image/upload/Help%20Section%20Images/Builder-CustomOptions.png"}
                                 alt="builder-custom-options" width="800px"/>
                        </div>
                    </Grid>
                    <br/><br/><br/><br/>
                    <Grid container direction="row" >
                        <div style={{width: "400px", marginRight: "20px"}}>
                            <span style={{fontWeight: "600"}}>3. Custom Exclusions</span>
                            <br/><br/>
                            Exclude companies based on a value your client believes in. Click on the + mark to select.
                        </div>
                        <div style={{width: "800px"}}>
                            <img src={"https://res.cloudinary.com/indexpro/image/upload/Help%20Section%20Images/Builder-CustomExclusions.png"}
                                 alt="builder-custom-exclusions" width="800px"/>
                        </div>
                    </Grid>
                    <br/><br/><br/><br/>
                    <Grid container direction="row" >
                        <div style={{width: "400px", marginRight: "20px"}}>
                            <span style={{fontWeight: "600"}}>4. Revenue Threshold Exclusions</span>
                            <br/><br/>
                            Exclude companies based on revenue from a specific source. Choose between any % of revenue
                            or click on the + mark to specifically exclude companies with specific revenue sources above 5%.
                        </div>
                        <div style={{width: "800px"}}>
                            <img src={"https://res.cloudinary.com/indexpro/image/upload/Help%20Section%20Images/Builder-RevenueExclusions.png"}
                                 alt="builder-revenue" width="800px"/>
                        </div>
                    </Grid>
                    <br/><br/>
                    <Divider/>
                    <br/><br/>
                    <Grid container direction="row" >
                        <div style={{width: "500px", marginRight: "20px"}}>
                            <span style={{fontWeight: "600"}}>Step 3 | Select Your Investment Themes</span>
                            <br/><br/>
                            By selecting a theme, you are proposing to overweight companies that fall in that category.
                            <br/><br/>
                            <span style={{fontStyle: "italic"}}>You can learn more about a specific theme by clicking on it. </span>
                            <br/><br/>
                            <span style={{fontStyle: "italic"}}>As you make these selections, you will notice the
                                Overweight / Underweight bar at the bottom of your page updating. This is a tally of
                                your overweight and underweight candidates and by clicking on it you can see the top
                                candidates in both categories as well as which one of your selections impacts the
                                position in your strategy. For more in-depth research, you can click on the company
                                name, see their MSCI ESG rating, a breakdown of their MSCI ESG score and specific
                                exclusions that apply to that company.  </span>
                            <br/><br/>
                            <span style={{fontStyle: "italic"}}>The candidate companies in the image below are for illustration purposes only. </span>
                            <br/><br/>
                            <img src={"https://res.cloudinary.com/indexpro/image/upload/Help%20Section%20Images/Builder-Journey.png"}
                                 alt="builder-journey" width="500px"/>
                        </div>
                        <div style={{width: "700px"}}>
                            <img src={"https://res.cloudinary.com/indexpro/image/upload/Help%20Section%20Images/Builder-Themes.png"}
                                 alt="builder-themes" width="720px"/>
                        </div>
                    </Grid>
                    <br/><br/>
                    <Divider/>
                    <br/><br/>
                    <Grid container direction="row" >
                        <div style={{width: "600px", marginRight: "20px"}}>
                            <span style={{fontWeight: "600"}}>Step 4 | Performance & Risk</span>
                        </div>
                        <div style={{width: "600px"}}>
                        </div>
                    </Grid>
                    <br/><br/><br/><br/>
                    <Grid container direction="row" >
                        <div style={{width: "400px", marginRight: "20px"}}>
                            <span style={{fontWeight: "600"}}>1. Active Alpha Signal</span>
                            <br/><br/>
                            Click on Private Label to use Index Pro’s proprietary alpha foundation and inform any
                            passive index with important active inputs. To use your own active alpha signal,
                            please reach out to one of our team members.
                            <br/><br/>
                            <span style={{fontStyle: "italic"}}>Bottom-up stock insights can make your values, thematic, and tax choices more impactful.</span>
                        </div>
                        <div style={{width: "800px"}}>
                            <img src={"https://res.cloudinary.com/indexpro/image/upload/Help%20Section%20Images/Builder-Alpha.png"}
                                 alt="builder-alpha" width="800px"/>
                        </div>
                    </Grid>
                    <br/><br/><br/><br/>

                    <Grid container direction="row" >
                        <div style={{width: "400px", marginRight: "20px"}}>
                            <span style={{fontWeight: "600"}}>2. Tracking Error</span>
                            <br/><br/>
                            Use the slider to select the desired tracking error in relation to your selected benchmark.
                            <br/><br/><br/><br/>
                            <span style={{fontWeight: "600"}}>3. Rebalance Frequency</span>
                            <br/><br/>
                            Pick your default frequency of trading.
                            <br/><br/><br/><br/>
                            <span style={{fontWeight: "600"}}>4. Cash Position Size</span>
                            <br/><br/>
                            Write in your percentage allocation to cash.
                            <br/><br/><br/><br/>
                            <span style={{fontWeight: "600"}}>5. Max Position Count </span>
                            <br/><br/>
                            You can choose to limit the number of issuers by writing that number in here.
                            <br/><br/><br/><br/>
                            <span style={{fontWeight: "600"}}>6. Sector Constraints </span>
                            <br/><br/>
                            You can set a limit on sector exposures relative to the selected benchmark.
                            <br/><br/><br/><br/>
                            <span style={{fontWeight: "600"}}>7. Industry Constraints </span>
                            <br/><br/>
                            You can set a limit on industry exposures relative to the selected benchmark.
                            <br/><br/>
                        </div>
                        <div style={{width: "800px"}}>
                            <img src={"https://res.cloudinary.com/indexpro/image/upload/Help%20Section%20Images/Builder-Risk.png"}
                                 alt="builder-risk" width="800px"/>
                        </div>
                    </Grid>
                    <br/><br/>
                    <Divider/>
                    <br/><br/>
                    <Grid container direction="row" >
                        <div style={{width: "500px", marginRight: "20px"}}>
                            <span style={{fontWeight: "600"}}>Step 5 | Taxes</span>
                            <br/><br/>
                            You can choose to apply tax preferences and the level of tax efficiency by selecting from one of the following options:
                            <br/><br/>
                            1. Tax Aware
                            <br/>
                            2. Tax Neutral
                            <br/>
                            3. Tax-Loss Harvesting
                            <br/><br/>
                            <span style={{fontStyle: "italic"}}>Atom nor Index Pro provide tax advice.
                                Please make sure you consult with a tax professional before selecting tax preferences. </span>
                            <br/><br/>
                        </div>
                        <div style={{width: "700px"}}>
                            <img src={"https://res.cloudinary.com/indexpro/image/upload/Help%20Section%20Images/Builder-Taxes.png"}
                                 alt="builder-taxes" width="720px"/>
                        </div>
                    </Grid>
                    <br/><br/>
                    <Divider/>
                    <br/><br/>
                    <Grid container direction="row" >
                        <div style={{width: "500px", marginRight: "20px"}}>
                            <span style={{fontWeight: "600"}}>Step 6 | Summary</span>
                            <br/><br/>
                            The last step of the Index Pro builder gives you a summary of the strategy you have created.
                            You can save & share this strategy, or you can go back to making changes to it by
                            clicking on one of the steps in the bar at the top of the builder page.
                            <br/><br/>
                        </div>
                        <div style={{width: "700px"}}>
                        </div>
                    </Grid>
                    <br/><br/>
                    <Divider/>
                    <br/><br/>
                    <Grid container direction="row" >
                        <div style={{width: "500px", marginRight: "20px"}}>
                            <span style={{fontWeight: "600"}}>Step 7 | Save & Share</span>
                            <br/><br/>
                            Name the portfolio, hit save and Index Pro will optimize it in real time for you.
                            You will notice that the builder at this step moves you to the Strategies page,
                            which is where all your strategies live.
                            <br/><br/>
                            <span style={{fontStyle: "italic"}}>The optimization runs in the background and in a matter
                                of seconds you can view the strategy you have created. For an overview of the Strategy
                                page, please visit the Strategy help page. </span>
                            <br/><br/>
                        </div>
                        <div style={{width: "700px"}}>
                            <img src={"https://res.cloudinary.com/indexpro/image/upload/Help%20Section%20Images/Builder-Save.png"}
                                 alt="builder-themes" width="600px"/>
                        </div>
                    </Grid>
                    <br/><br/>
                    <Divider/>
                    <br/><br/>
                    For more help with your <span style={{fontWeight: "600"}}>Builder</span> please e-mail us at <a href={"mailto: indexpro@atomlp.com"}>indexpro@atomlp.com</a> or use the chat bubble on the bottom right of this page to chat with us.
                </div>
            </Paper>
        </div>
    )
}

export default HelpBuilder