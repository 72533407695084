import {Grid, TextField} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {card_description, card_title, ipGreen, ReportHeaderCell, ReportValueCell, ipRed} from "../../Styles/styles";
import TableBody from "@material-ui/core/TableBody";
import React from "react";
import {superScript} from "../../Utils/compliance_utils";
import {dollarsWithCommas, percentage, pos_neg_dollar_card_description} from "../../Utils/num_utils";
import InputAdornment from "@material-ui/core/InputAdornment";

export const TransitionDetails = (benchmark, headers, transition_data, tax) => {

    const overview = transition_data.transition_overview

    return (
        <div style={{height: "100%", position: "relative", color: "black",  fontDisplay: "swap"}}>
            <div style={{height: "390px"}}>
                <div style={{
                    position: "absolute",
                    top: "54px",
                    right: "60px",
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "black"
                }}>4
                </div>
                <Grid container justifyContent="flex-start" style={{
                    position: "absolute", top: "40px", left: "60px",
                    fontSize: "14px", color: "#c2c2c1", fontWeight: "600", width: "800px"
                }}>
                    <p style={{marginRight: "25px", color: "#009494"}}>Portfolio</p>
                    {headers.map(title => {
                        return(
                            <p style={{marginRight: "25px"}}>{title}</p>
                        )
                    })}
                </Grid>
                <div style={{
                    position: "absolute",
                    top: "140px",
                    left: "60px",
                    fontSize: "14px",
                    borderBottom: "1px solid black",
                    width: "755px",
                    fontWeight: "600"
                }}>
                    TRANSITION OVERVIEW {superScript(1)}
                </div>
                <div style={{position: "absolute", top: "180px", left: "60px", fontSize: "13px", width: "755px"}}>
                    <Grid container direction="row" justifyContent="space-between"
                          alignItems="flex-start">
                        <Grid item>
                            <Grid container direction="column">
                            <div>
                                <p style={{fontWeight: "600"}}># OF POSITIONS</p>
                                <p>{overview['input_num_positions']} ➝ {overview['target_num_positions']}</p>
                            </div>
                                <div>
                                    <p style={{fontWeight: "600"}}>TRACKING ERROR {superScript(2)}</p>
                                    <p>{overview['input_tracking_error']} ➝ {overview['target_tracking_error']}</p>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction="column">
                            <div>
                                <p style={{fontWeight: "600"}}>LONG TERM REALIZED GAINS</p>
                                <p><span style={{color: (overview['realized_pl_lt'].toFixed(0))>=0 ? (ipGreen) : (ipRed)}}>
                                    {dollarsWithCommas((overview['realized_pl_lt'].toFixed(0)))}</span> ({percentage((overview['realized_pl_lt_pct']).toFixed(2))})</p>
                            </div>
                            <div>
                                <p style={{fontWeight: "600"}}>SHORT TERM REALIZED GAINS</p>
                                <p><span style={{color: (overview['realized_pl_st'].toFixed(0))>=0 ? (ipGreen) : (ipRed)}}>
                                    {dollarsWithCommas((overview['realized_pl_st'].toFixed(0)))}</span> ({percentage((overview['realized_pl_st_pct']).toFixed(2))})</p>
                            </div>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction="column">
                            <div>
                                <p style={{fontWeight: "600"}}>LONG TERM TAX RATE {superScript(3)}</p>
                                <p>{tax.long_term}%</p>
                            </div>
                                <div>
                                    <p style={{fontWeight: "600"}}>SHORT TERM TAX RATE</p>
                                    <p>{tax.short_term}%</p>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction="column">
                            <div>
                                <p style={{fontWeight: "600"}}>LONG TERM TAXES {overview['realized_pl_lt'] >= 0 ? ("") : ("HARVESTED")} {superScript(4)}</p>
                                <p><span style={{color: (-1 * ((tax.long_term / 100) * overview['realized_pl_lt']))>=0 ? (ipGreen) : (ipRed)}}>
                                    {dollarsWithCommas((-1 * ((tax.long_term / 100) * overview['realized_pl_lt']).toFixed(0)))}</span> ({percentage((-1 * ((tax.long_term / 100) * overview['realized_pl_lt_pct'])).toFixed(2))})</p>
                            </div>
                                <div>
                                    <p style={{fontWeight: "600"}}>SHORT TERM TAXES {overview['realized_pl_st'] >= 0 ? ("") : ("HARVESTED")}</p>
                                    <p><span style={{color: (-1 * ((tax.short_term / 100) * overview['realized_pl_st']))>=0 ? (ipGreen) : (ipRed)}}>
                                    {dollarsWithCommas((-1 * ((tax.short_term / 100) * overview['realized_pl_st']).toFixed(0)))}</span> ({percentage((-1 * ((tax.short_term / 100) * overview['realized_pl_st_pct'])).toFixed(2))})</p>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div style={{
                position: "absolute",
                top: "400px",
                left: "60px",
                fontSize: "14px",
                borderBottom: "1px solid black",
                width: "755px",
                fontWeight: "600"
            }}>
                SECTOR BREAKDOWN
            </div>
            <div style={{position: "absolute", top: "420px", left: "60px", width: "755px"}}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <ReportHeaderCell>SECTOR</ReportHeaderCell>
                                <ReportHeaderCell align="right">INPUT WEIGHT {superScript(5)}</ReportHeaderCell>
                                <ReportHeaderCell align="right">BENCHMARK WEIGHT</ReportHeaderCell>
                                <ReportHeaderCell align="right">TARGET WEIGHT {superScript(6)}</ReportHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {transition_data['sector_breakdown'].map((row, i) => (
                                <TableRow>
                                    <ReportValueCell>{row.gics_1.toUpperCase()}</ReportValueCell>
                                    <ReportValueCell align="right"
                                                     style={{fontWeight: "600"}}>{row.input_weight}</ReportValueCell>
                                    <ReportValueCell align="right"
                                                     style={{fontWeight: "600"}}>{row.benchmark_weight}</ReportValueCell>
                                    <ReportValueCell align="right"
                                                     style={{fontWeight: "600"}}>{row.weight}</ReportValueCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div style={{
                position: "absolute",
                top: "780px",
                left: "60px",
                fontSize: "14px",
                borderBottom: "1px solid black",
                width: "755px",
                fontWeight: "600"
            }}>
                TOP 10 HOLDINGS
            </div>
            <div style={{position: "absolute", top: "800px", left: "60px", width: "755px"}}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <ReportHeaderCell>NAME</ReportHeaderCell>
                                <ReportHeaderCell align="center">SYMBOL</ReportHeaderCell>
                                <ReportHeaderCell align="center">INPUT WEIGHT {superScript(5)}</ReportHeaderCell>
                                <ReportHeaderCell align="center">BENCHMARK WEIGHT</ReportHeaderCell>
                                <ReportHeaderCell align="center">TARGET WEIGHT {superScript(7)}</ReportHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {transition_data.top_10_positions.map((row) => (
                                <TableRow>
                                    <ReportValueCell>{row.company_name.toUpperCase()}</ReportValueCell>
                                    <ReportValueCell align="center">{row.ticker}</ReportValueCell>
                                    <ReportValueCell align="center"
                                                     style={{fontWeight: "600"}}>{row.input_weight}</ReportValueCell>
                                    <ReportValueCell align="center"
                                                     style={{fontWeight: "600"}}>{row.benchmark_weight}</ReportValueCell>
                                    <ReportValueCell align="center"
                                                     style={{fontWeight: "600"}}>{row.weight}</ReportValueCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div style={{
                position: "absolute", bottom: "20px", marginLeft: "60px", marginRight: "60px",
                width: "755px", fontSize: "10px", fontWeight: "600"
            }}>

            </div>
        </div>
    )
}