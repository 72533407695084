import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Grid,Button} from '@material-ui/core';
import Paper from "@material-ui/core/Paper";
import {card_subtitle, card_title, paper_left, themeColor} from "../../Styles/styles";
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import IconButton from '@material-ui/core/IconButton';
import {useEarningsData} from "../Hooks/Data/Dashboard/dashboard";
import {Mixpanel} from "../../Utils/Mixpanel";


const Earnings = () => {
    const earnings = useEarningsData();

    function formatDate(dateStr, timeStr) {
        const date = new Date(dateStr + 'T' + timeStr + '-05:00');
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZoneName: 'short'
        };
        return date.toLocaleString('en-US', options);
    }

    function formatDateShort(dateStr, timeStr) {
        const date = new Date(dateStr + 'T' + timeStr + '-05:00');
        const options = {
            month: 'short',
            day: 'numeric'
        };
        const formattedDate = date.toLocaleString('en-US', options);
        const [month, day] = formattedDate.split(' ');
        return { month, day };
    }

    const handleAddToCalendar = (dateStr, timeStr, subject) => {
        Mixpanel.track('Added Earnings to Calendar');
        const date = new Date(dateStr + 'T' + timeStr + '-05:00').toISOString().replace(/[:-]/g, "").split('.')[0]+"Z";
        const url = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(subject) + ' Earnings'}&dates=${date}/${date}`;
        window.open(url);
    }

    return (
        <div className="Earnings">
            <Paper style={paper_left} elevation={0}>
                <p style={card_title}>Earnings calendar</p>
                <p style={card_subtitle}>Based on popular stocks</p>
                <div style={{marginTop: "40px"}}>
                    {earnings.map(earning => (
                        <Paper elevation={0} style={{
                            marginLeft: "20px", marginRight: "20px", borderTop: "1px solid #dadce0",
                            borderRadius: "0px", paddingTop: "20px", paddingBottom: "20px"
                        }}>
                            <Grid container direction="row" justifyContent={"space-between"} alignItems="center">
                                <div>
                                <Grid container direction="row" justifyContent={"flex-start"} alignItems="center">
                                    <div style={{marginRight: "15px"}}>
                                        <div style={{
                                            height: "42px",
                                            width: "42px",
                                            backgroundColor: "#e8f0fe",
                                            borderRadius: "5px",
                                            display: "flex",
                                            justifyContent: "center"
                                        }}>
                                            <Grid container direction="column" justifyContent="center"
                                                  alignItems="center">
                                                <p style={{
                                                    margin: "0px",
                                                    color: themeColor,
                                                    fontSize: "12px"
                                                }}>{formatDateShort(earning.date, earning.time).month}</p>
                                                <p style={{
                                                    margin: "0px",
                                                    color: themeColor,
                                                    fontSize: "14px"
                                                }}>{formatDateShort(earning.date, earning.time).day}</p>
                                            </Grid>
                                        </div>
                                    </div>
                                    <div>
                                        <p style={{
                                            fontSize: "15px",
                                            marginTop: "0px",
                                            marginBottom: "0px"
                                        }}>{earning.title}</p>
                                        <p style={{
                                            fontSize: "12px",
                                            color: "#5f6368",
                                            marginTop: "0px",
                                            marginBottom: "0px"
                                        }}>{formatDate(earning.date, earning.time)}</p>
                                    </div>
                                </Grid>
                                </div>
                                <div>
                                    <IconButton
                                        onClick={() => handleAddToCalendar(earning.date, earning.time, earning.title)}>
                                        <InsertInvitationIcon/>
                                    </IconButton>
                                </div>
                            </Grid>
                        </Paper>
                    ))}
                </div>
            </Paper>
        </div>
    );
};

export default Earnings;