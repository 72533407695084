import React from "react";
import {observer} from "mobx-react-lite";
import {Grid, Paper} from "@material-ui/core"
import Chart from "react-apexcharts";
import {big_paper, card_title, card_subtitle, AssetGraphLabel} from "../../Styles/styles"
import {dollarsWithCommas} from "../../Utils/num_utils";
import {useUserAssetData} from "../Hooks/Data/User/user"
import {useAssetTimeSeriesStyles} from "../Hooks/Styles/GraphStyles/graph-styles";

//Assets Component for Dashboard Page
//Returns a time series of a users' accounts agg value
function Assets() {



    const [userAssets, dates] = useUserAssetData();
    const options = useAssetTimeSeriesStyles(dates)

    return (
        <Paper style={big_paper} elevation={0}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <div>
                    <p style={card_title}>Total Assets</p>
                    <p style={card_subtitle}>Aggregate Value of Client Assets</p>
                </div>
                <div>
                    {(userAssets && userAssets.length) ?
                        (
                            <p style={AssetGraphLabel}>{dollarsWithCommas(userAssets[userAssets.length - 1])}</p>
                        ) : (
                            <></>
                        )}
                </div>
            </Grid>
            {userAssets.length === 0 ? (
              <p style={{marginLeft: "20px"}}>
                  You have no live accounts
              </p>
            ) : (
                <Chart
                    options={options}
                    series={[{
                        name: 'Account Assets',
                        data: userAssets
                    }]} type="area" height="350px"/>
            )}
        </Paper>
    )
}

export default Assets;

