import {Grid} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {ReportHeaderCell, ReportValueCell} from "../../Styles/styles";
import TableBody from "@material-ui/core/TableBody";
import React from "react";
import {superScript} from "../../Utils/compliance_utils";

export const PortfolioDetails = (benchmark, headers, description, portfolio_details, sector_breakdown, top_10_positions, transition_data) => {

    const colors = ['#1f77b4','#ff7f0e','#2ca02c','#d62728','#9467bd','#8c564b','#e377c2','#7f7f7f', '#bcbd22','#17becf','#aec7e8']

    const sector_breakdown_sort = [...sector_breakdown]

    let offset = 0
    transition_data ? (offset = offset + 10) : (offset = offset)

    return (
        <div style={{height: "100%", position: "relative", color: "black", fontDisplay: "swap"}}>
            <div style={{backgroundColor: "#ececed", height: "390px"}}>
                <div style={{
                    position: "absolute",
                    top: "54px",
                    right: "60px",
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "black"
                }}>{transition_data ? (7) : (4)}
                </div>
                <Grid container justifyContent="flex-start" style={{
                    position: "absolute", top: "40px", left: "60px",
                    fontSize: "14px", color: "#c2c2c1", fontWeight: "600", width: "800px"
                }}>
                    <p style={{marginRight: "25px", color: "#009494"}}>Portfolio</p>
                    {headers.map(title => {
                        return(
                            <p style={{marginRight: "25px"}}>{title}</p>
                        )
                    })}
                </Grid>
                <div style={{
                    position: "absolute",
                    top: "140px",
                    left: "60px",
                    fontSize: "14px",
                    borderBottom: "1px solid black",
                    width: "367px",
                    fontWeight: "600"
                }}>
                    ABOUT <span style={{color: "#896db0"}}>{benchmark} INDEX PRO {superScript(1 + offset)}</span>
                </div>
                <div style={{position: "absolute", top: "160px", left: "60px"}}>
                    <p style={{fontSize: "12px", textTransform: "none", width: "367px"}}>
                        {description}
                    </p>
                </div>
                <div style={{
                    position: "absolute",
                    top: "140px",
                    left: "448px",
                    fontSize: "14px",
                    borderBottom: "1px solid black",
                    width: "367px",
                    fontWeight: "600"
                }}>
                    CUSTOM INVESTMENT DETAILS
                </div>
                <div style={{position: "absolute", top: "160px", left: "448px", width: "367px"}}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <ReportHeaderCell>INDEX</ReportHeaderCell>
                                    <ReportHeaderCell align="right">{benchmark} PRO</ReportHeaderCell>
                                    <ReportHeaderCell align="right">{benchmark} {superScript(2 + offset)}</ReportHeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {portfolio_details.map((row) => (
                                    <TableRow>
                                        <ReportValueCell>{row.title === 'TARGET TRACKING ERROR' ? (<>{row.title} {superScript(3 + offset)}</>) : (row.title)}</ReportValueCell>
                                        <ReportValueCell align="right"
                                                         style={{fontWeight: "600"}}>{row.pro}</ReportValueCell>
                                        <ReportValueCell align="right"
                                                         style={{fontWeight: "600"}}>{row.benchmark}</ReportValueCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            <div style={{
                position: "absolute",
                top: "400px",
                left: "60px",
                fontSize: "14px",
                borderBottom: "1px solid black",
                width: "755px",
                fontWeight: "600"
            }}>
                SECTOR WEIGHTINGS {superScript(4 + offset)} VS. INDEX
            </div>
            <div style={{position: "absolute", top: "420px", left: "60px", width: "755px"}}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <ReportHeaderCell>SECTOR</ReportHeaderCell>
                                <ReportHeaderCell align="right">{benchmark} PRO</ReportHeaderCell>
                                <ReportHeaderCell align="right">{benchmark} {superScript(5 + offset)}</ReportHeaderCell>
                                <ReportHeaderCell algin="right"/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sector_breakdown_sort.sort(function(a,b) {return parseFloat(b.pro) - parseFloat(a.pro)}).map((row, i) => (
                                <TableRow>
                                    <ReportValueCell>{row.sector}</ReportValueCell>
                                    <ReportValueCell align="right"
                                                     style={{fontWeight: "600"}}>{row.pro}</ReportValueCell>
                                    <ReportValueCell align="right"
                                                     style={{fontWeight: "600"}}>{row.benchmark}</ReportValueCell>
                                    <ReportValueCell align="right">
                                        <div style={{
                                            width: `${Math.min(Math.round(parseFloat(row.pro) * 10), 250)}px`,
                                            height: "18px",
                                            backgroundColor: colors[i],
                                        }}/>
                                    </ReportValueCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div style={{
                position: "absolute",
                top: "780px",
                left: "60px",
                fontSize: "14px",
                borderBottom: "1px solid black",
                width: "755px",
                fontWeight: "600"
            }}>
                TOP 10 {benchmark} PRO POSITIONS
            </div>
            <div style={{position: "absolute", top: "800px", left: "60px", width: "755px"}}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <ReportHeaderCell>NAME</ReportHeaderCell>
                                <ReportHeaderCell align="center">SYMBOL</ReportHeaderCell>
                                <ReportHeaderCell align="center">WEIGHT {superScript(6 + offset)}</ReportHeaderCell>
                                <ReportHeaderCell align="center">SECTOR</ReportHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {top_10_positions.map((row) => (
                                <TableRow>
                                    <ReportValueCell>{row.company_name.toUpperCase()}</ReportValueCell>
                                    <ReportValueCell align="center">{row.ticker}</ReportValueCell>
                                    <ReportValueCell align="center"
                                                     style={{fontWeight: "600"}}>{row.weight}</ReportValueCell>
                                    <ReportValueCell align="center">{row.sector.toUpperCase()}</ReportValueCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div style={{
                position: "absolute", bottom: "20px", marginLeft: "60px", marginRight: "60px",
                width: "755px", fontSize: "10px", fontWeight: "600"
            }}>
                The portfolio composition and impact shown reflect the factors and inputs selected by your adviser. This proposal
                is for informational purposes only and is not a suggested portfolio or investment recommendation by Atom Investors LP ("Atom").
            </div>
        </div>
    )
}