import {
    useFirmUserData,
    useTeams,
    useUser,
    useUserProfilePictures,
    useUserTeamFilterInfo
} from "../Hooks/Data/User/user";
import Paper from "@material-ui/core/Paper";
import {
    big_paper,
    bordered_button,
    card_title,
    HeaderCell,
    IPTableRow,
    ValueCell,themeColor, ipRed, transparent_button, card_subtitle
} from "../../Styles/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import {Avatar, TextField} from "@material-ui/core";
import React, {useState} from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {DataStore} from "@aws-amplify/datastore";
import {Team, User} from "../../models";
import Modal from "@material-ui/core/Modal";
import Fab from "@material-ui/core/Fab";
import ClearIcon from "@material-ui/icons/Clear";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import {safeAWSJSONArrayQuery} from "../../Utils/data_utils";
import {Storage} from "@aws-amplify/storage";


//site roles component
function SiteRoles() {

    //constants
    const site_roles = ['Explorer', 'Creator', 'Administrator']
    const role_descriptions = {
        'Administrator': 'An administrator can view, create, and edit their firms\' strategies and accounts.',
        'Creator': 'A creator can view, create, and edit their teams\' strategies and accounts.',
        'Explorer': 'An explorer can only view their teams\' strategies and accounts.'
    }

    //team states
    const [teamModalOpen, setTeamModalOpen] = useState(false)
    const [teamEdit, setTeamEdit] = useState(false)
    const [teamName, setTeamName] = useState(null)
    const [teamUsers, setTeamUsers] = useState([])
    const [deleteTeamOpen, setDeleteTeamOpen] = useState(false)
    const [selectedTeam, setSelectedTeam] = useState({})
    const teams = useTeams()
    const user_photos = useUserProfilePictures()

    //logged in user data
    const user_data = useUser()
    //all users in firm
    const firm_users = useFirmUserData()
    //returns users role
    const user_role = user_data ? (user_data.user_role) : null
    //checks if user is super admin
    const user_super_admin = user_data ? (user_data.email.startsWith('indexpro-admin')) : false

    //user helpers
    const updateUserRole = async (user_id, user_role) => {
        const original = await DataStore.query(User, user_id)
        await DataStore.save(User.copyOf(original, item => {
            item['user_role'] = user_role
            })
        );
    }

    //team helpers
    const createTeam = async () => {
        if(teamEdit) {
            if(teamName && teamUsers.length>0) {
                const original = await DataStore.query(Team, selectedTeam.id)
                await DataStore.save(Team.copyOf(original, item => {
                    item['team_name'] = teamName
                    item['team_users'] = teamUsers
                }));
            }
        } else {
            if(teamName && teamUsers.length>0) {
                await DataStore.save(
                    new Team(
                        {
                            'team_name' : teamName,
                            'team_users' : teamUsers,
                            'user_info': localStorage.getItem('signed-in-user')
                        }
                    )
                )
            }
        }
    }

    const handleCreateTeamClose = () => {
        setTeamModalOpen(false)
        setTeamName(null)
        setTeamEdit(false)
        setTeamUsers([])
        setSelectedTeam({})
    }

    const deleteTeam = async () => {
        const modelToDelete = await DataStore.query(Team, selectedTeam.id);
        DataStore.delete(modelToDelete);
        setSelectedTeam({})
        setDeleteTeamOpen(false)
    }

    //user role paper
    const createUserRoleDescription = () => {
        return (
            <Paper style={big_paper} elevation={0}>
                <p style={card_title}>User Roles Available</p>
                <p style={card_subtitle}>A role provides access to predefined menus and features so that depending
                    on the assigned role (Administrator, Creator, Explorer) a user can have access to what they need</p>
                <Grid container direction="row" alignContent="flex-start" justifyContent="flex-start" style={{marginLeft: "20px", marginTop: "20px"}}>
                    {Object.keys(role_descriptions).map(i => (
                        <div style={{height: "120px", width: "300px", border: `1px solid ${user_super_admin ? ("#dadce0") : (user_role === i ? (themeColor) : ("#dadce0"))}`, marginRight: "20px", borderRadius: "5px", paddingRight: "20px"}}>
                            <p style={card_title}>{i}</p>
                            <p style={card_subtitle}>{role_descriptions[i]}</p>
                        </div>
                    ))}
                </Grid>
            </Paper>
        )
    }

    //user role paper
    const createYourAdmins = () => {
        return (
            <Paper style={big_paper} elevation={0}>
                <p style={card_title}>Firm Administrators</p>
                <Grid container direction="row" justifyContent="flex-start" style={{marginLeft: "20px", marginRight: "20px", marginTop: "40px"}}>
                    {firm_users.filter(i => i.user_role === 'Administrator').map(admin=> (
                        <div style={{height: "200px", width: "200px", border: "1px solid #dadce0",
                            borderRadius: "5px", marginRight: "20px", padding: "5px", marginBottom: "20px"}}>
                            <Grid container direciton="row" justifyContent="center" alignItems="center">
                                <p style={{fontWeight: "600"}}>{admin.name}</p>
                            </Grid>
                            <Grid container direciton="row" justifyContent="center" alignItems="center" style={{marginTop: "10px"}}>
                                <Avatar src={user_photos[admin['id']]} style={{height: "60px", width: "60px"}}/>
                            </Grid>
                        </div>
                    ))}
                </Grid>
            </Paper>
        )
    }



    if(user_role === 'Administrator' || user_super_admin) {
        return (
            <div>
                {createUserRoleDescription()}
                <div style={{marginTop: "30px"}}/>
                <Paper style={big_paper} elevation={0}>
                    <Grid container direction="row" alignContent="flex-start" justifyContent="center">
                        <Grid item md={12}>
                            <Grid container direction="row" justifyContent="space-between" alignContent="center">
                                <p style={card_title}>Users</p>
                            </Grid>
                        </Grid>
                        <TableContainer style={{marginTop: "20px"}}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <HeaderCell>Name</HeaderCell>
                                        <HeaderCell align="left">Site role</HeaderCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {firm_users.map((item, index) => (
                                        <IPTableRow key={item['obj_id']}>
                                            <ValueCell align="left">
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                >
                                                    <Avatar src={user_photos[item['id']]} style={{height: "50px", width: "50px"}}/>
                                                    <div>
                                                        <p style={{marginLeft: "20px", marginTop: "10px", marginBottom: "10px", fontWeight: "600"}}>{item['name']}</p>
                                                        <p style={{marginLeft: "20px", marginTop: "10px", marginBottom: "10px"}}>{item['email']}</p>
                                                    </div>
                                                </Grid>
                                            </ValueCell>
                                            <ValueCell align="left">
                                                <Autocomplete
                                                    disabled={item['obj_id'] === user_data['id']}
                                                    value={item['user_role'] ? (item['user_role']) : ('Explorer')}
                                                    options={site_roles}
                                                    onChange={(option, value) => updateUserRole(item['obj_id'], value)}
                                                    style={{
                                                        backgroundColor: "#F5F8FA",
                                                        width: "200px",
                                                    }}
                                                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                                                />
                                            </ValueCell>
                                        </IPTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Paper>
                <div style={{marginTop: "30px"}}/>
                <Paper style={big_paper} elevation={0}>
                    <Grid container direction="row" alignContent="flex-start" justifyContent="center">
                        <Grid item md={12}>
                            <Grid container direction="row" justifyContent="space-between" alignContent="center">
                                <p style={card_title}>Teams</p>
                                <Button style={Object.assign({}, bordered_button, {marginRight: "20px", marginTop: "10px"})}
                                        startIcon={<AddIcon/>} onClick={() => setTeamModalOpen(true)}>
                                    Create Team
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justifyContent="flex-start" style={{marginLeft: "20px", marginRight: "20px", marginTop: "40px"}}>
                        {teams.map(team=> (
                            <div style={{height: "200px", width: "200px", border: "1px solid #dadce0",
                                borderRadius: "5px", marginRight: "20px", padding: "5px", marginBottom: "20px"}}>
                                <Grid container direciton="row" justifyContent="center" alignItems="center">
                                    <p style={{fontWeight: "600"}}>{team.team_name}</p>
                                </Grid>
                                <Grid container direciton="row" justifyContent="center" alignItems="center">
                                <AvatarGroup max={4} style={{marginTop: "20px"}}>
                                    {safeAWSJSONArrayQuery(team.team_users).map(i=> (
                                        <Avatar src={user_photos[i['id']]}/>
                                    ))}
                                </AvatarGroup>
                                </Grid>
                                <Grid container direciton="row" justifyContent="flex-end" alignItems="center" style={{marginTop: "40px"}}>
                                    <Tooltip title="Edit">
                                        <IconButton style={{padding: "2px"}}  onClick={() => {setSelectedTeam(team);
                                        setTeamEdit(true); setTeamName(team.team_name); setTeamUsers(safeAWSJSONArrayQuery(team.team_users));
                                        setTeamModalOpen(true)}}>
                                            <EditIcon style={{color: "#adafb3"}}/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                        <IconButton style={{padding: "2px"}}  onClick={() => {setSelectedTeam(team); setDeleteTeamOpen(true)}}>
                                            <DeleteIcon style={{color: "#adafb3"}}/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </div>
                        ))}
                        <div style={{height: "200px", width: "200px", border: "1px solid #dadce0",
                            borderRadius: "5px", marginRight: "20px", padding: "5px"}}>
                            <Grid container direciton="row" justifyContent="center" alignItems="center">
                                <p style={{fontWeight: "600"}}>Your new team!</p>
                            </Grid>
                            <Grid container direciton="row" justifyContent="center" alignItems="center">
                                <AvatarGroup max={4} style={{marginTop: "20px"}}>
                                    {firm_users.map(i=> (
                                        <Avatar src={user_photos[i['id']]}/>
                                    ))}
                                </AvatarGroup>
                            </Grid>
                            <Grid container direciton="row" justifyContent="center" alignItems="center" style={{marginTop: "20px"}}>
                                <Button style={Object.assign({}, bordered_button, {marginTop: "10px"})}
                                        startIcon={<AddIcon/>} onClick={() => setTeamModalOpen(true)}>
                                    Create Team
                                </Button>
                            </Grid>
                        </div>
                    </Grid>
                </Paper>
                <Modal open={teamModalOpen}  style={{overflow: "scroll"}}>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Paper elevation={0} style={{minHeight: "500px", width: "500px", marginTop: "250px"}}>
                            <Grid container direction="row">
                                <Grid item xs={10} style={{paddingTop: "20px"}}>
                                    <p style={card_title}>{teamEdit ? ("Edit Team") : ("Create Team")}</p>
                                </Grid>
                                <Grid item xs={2} style={{paddingRight: "20px", paddingTop: "20px"}}>
                                    <Fab style={{float: "right"}} onClick={handleCreateTeamClose}>
                                        <ClearIcon/>
                                    </Fab>
                                </Grid>
                            </Grid>
                            <Grid container direction="column" alignItems="center" justifyContent="center">
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    label="Team Name"
                                    value={teamName}
                                    type="text"
                                    autoFocus
                                    style={{
                                        backgroundColor: "#F5F8FA",
                                        width: "460px",
                                        marginTop: "40px"
                                    }}
                                    onChange={(event) => {event.persist();
                                        setTeamName(event.target.value)}}
                                />
                                <Autocomplete
                                    options={firm_users}
                                    value={teamUsers}
                                    multiple
                                    autoHighlight
                                    onChange={(event, newValue) => {
                                        setTeamUsers(newValue.map((el) => el));
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    getOptionSelected={(option, value) => option.name === value.name}
                                    renderOption={(option) => (
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >
                                            <Avatar src={user_photos[option['id']]} style={{height: "50px", width: "50px"}}/>
                                            <div>
                                                <p style={{marginLeft: "20px", marginTop: "10px", marginBottom: "10px", fontWeight: "600"}}>{option['name']}</p>
                                                <p style={{marginLeft: "20px", marginTop: "10px", marginBottom: "10px"}}>{option['email']}</p>
                                            </div>
                                        </Grid>
                                    )}
                                    renderInput={(params) => <TextField {...params} label="Add Users" variant="outlined"/>}
                                    style={{backgroundColor: "#F5F8FA",width: "460px", marginTop: "20px"}}
                                />
                            </Grid>
                            <Grid container justify="center" style={{marginTop: "40px"}}>
                                <Button style={Object.assign({}, bordered_button, {
                                    width: "100px", marginRight: "20px",
                                    fontSize:"16px"
                                })}
                                        onClick={handleCreateTeamClose}
                                >Cancel</Button>
                                <Button style={Object.assign({}, bordered_button, {
                                    width: "100px", marginLeft: "20px",
                                    fontSize:"16px", color: "white",
                                    backgroundColor: themeColor
                                })}
                                startIcon={<SaveAltIcon/>}
                                onClick={() => {createTeam(); handleCreateTeamClose()}}>
                                    Save
                                </Button>
                            </Grid>
                        </Paper>
                    </div>
                </Modal>
                <Modal open={deleteTeamOpen}>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Paper elevation={0} style={{
                            width: "600px", height: "600px", marginTop: "300px"
                        }}>
                            <Grid container direction="row">
                                <Grid item xs={10} style={{paddingTop: "25px"}}>
                                    <></>
                                </Grid>
                                <Grid item xs={2} style={{paddingRight: "20px", paddingTop: "20px"}}>
                                    <Fab onClick={() => {setDeleteTeamOpen(false); setSelectedTeam({})}} style={{float: "right"}}>
                                        <ClearIcon/>
                                    </Fab>
                                </Grid>
                                <Grid container justify = "center">
                                    <HighlightOffOutlinedIcon style={{fontSize: "150px", color: ipRed}}/>
                                </Grid>
                                <Grid container justify = "center">
                                    <p style={{fontSize: "30px"}}>Are you sure?</p>
                                </Grid>
                                <Grid container justify = "center">
                                    <p style={{fontSize: "16px", paddingRight: "40px", paddingLeft: "40px", textAlign: "center", paddingBottom: "40px"}}>
                                        Do you really want to delete <span style={{fontWeight: "600"}}>{selectedTeam.team_name}</span> team?
                                        This process cannot be undone.</p>
                                </Grid>
                                <Grid container justify = "center">
                                    <Button style={Object.assign({}, bordered_button,
                                        {width: "100px",
                                            marginRight: "20px", fontSize:"16px"})}
                                            onClick={() => {setDeleteTeamOpen(false); setSelectedTeam({})}}>
                                        Cancel
                                    </Button>
                                    <Button style={Object.assign({}, transparent_button, {backgroundColor: ipRed,
                                        color: "white", width: "100px", marginLeft: "20px",fontSize:"16px"})} onClick={() => deleteTeam()}>
                                        Delete
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </div>
                </Modal>
            </div>
        )
    } else {
        return (
            <div>
                {createUserRoleDescription()}
                <div style={{marginTop: "30px"}}/>
                <Paper style={big_paper} elevation={0}>
                    <p style={card_title}>Your Teams</p>
                    <p style={card_subtitle}>Your firm administrator manages team preferences</p>
                    <Grid container direction="row" alignContent="flex-start" justifyContent="center">
                        {user_data ? (
                            <Grid container direction="row" justifyContent="flex-start"
                                  style={{marginLeft: "20px", marginRight: "20px", marginTop: "40px"}}>
                                {teams.map(i => (
                                    <>
                                        {safeAWSJSONArrayQuery(i.team_users).some(function (i) {
                                            return i.obj_id === user_data.id
                                        }) ? (
                                            <div style={{
                                                height: "200px", width: "200px", border: "1px solid #dadce0",
                                                borderRadius: "5px", marginRight: "20px", padding: "5px", marginBottom: "20px"
                                            }}>
                                                <Grid container direciton="row" justifyContent="center" alignItems="center">
                                                    <p style={{fontWeight: "600"}}>{i.team_name}</p>
                                                </Grid>
                                                <Grid container direciton="row" justifyContent="center" alignItems="center">
                                                    <AvatarGroup max={4} style={{marginTop: "20px"}}>
                                                        {safeAWSJSONArrayQuery(i.team_users).map(i => (
                                                            <Avatar src={user_photos[i['id']]}/>
                                                        ))}
                                                    </AvatarGroup>
                                                </Grid>
                                            </div>
                                        ) : (
                                            <>
                                            </>
                                        )}
                                    </>
                                ))}
                            </Grid>
                        ) : (
                            <></>
                        )}
                    </Grid>
                </Paper>
                <div style={{marginTop: "30px"}}/>
                {createYourAdmins()}
            </div>
        )
    }
}


export default SiteRoles