import React, {useEffect} from "react";
import {observer} from "mobx-react-lite"
import './accounts.css'
import {useParams} from "react-router-dom";
import {useAccountByID} from "../Hooks/Data/Accounts/accounts";
import {useCustomStrategyByID} from "../Hooks/Data/Strategies/strategies";
import AccountInfoLive from "./account-info-live";
import AccountInfoTransition from "./account-info-transition";
import AccountInfoOnboarding from "./account-info-onboarding";
import {Mixpanel} from "../../Utils/Mixpanel";

// account info component
function AccountInfo() {

    const {account_id} = useParams();
    const [accountSelected, accountData] = useAccountByID(account_id)
    const strategySelected = useCustomStrategyByID(accountSelected ? accountSelected.custom_strategy_id : null)

    useEffect(() => {
        Mixpanel.track('Viewed Account', {'account_id': account_id});
    }, [])

    if (accountSelected === null) {
        //while data is running
        return (
            <div>Loading...</div>
        )
    } else if (!accountData && !accountSelected.transition) {
        //if not transition
        return (
            <AccountInfoOnboarding account={accountSelected} strategy={strategySelected}/>
        )
    } else if (accountSelected.transition !== null && !accountData) {
        //if transition and not live account
        return (
            <AccountInfoTransition account={accountSelected} strategy={strategySelected}/>
        )
    } else {
        //account info for live account
        return (
            <AccountInfoLive account={accountSelected} strategy={strategySelected}/>
        )
    }

}

export default observer(AccountInfo);
