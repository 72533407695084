import {Grid, Paper} from "@material-ui/core";
import {big_paper, card_subtitle, card_title, HeaderCell, IPTableRow, ValueCell} from "../../Styles/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import {dollarsWithCommas, numWithCommas} from "../../Utils/num_utils";
import TablePagination from "@material-ui/core/TablePagination";
import React from "react";
import {useAccountTransitionExclusionByID} from "../Hooks/Data/Accounts/accounts";
import {useParams} from "react-router-dom";

function AccountTransitionExclusions(props) {

    const {account_id} = useParams();

    const exclusions = useAccountTransitionExclusionByID(account_id)

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <div className="Exclusions">
            <Paper style={Object.assign({}, big_paper, {paddingBottom: "20px"})} elevation={0}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <div>
                        <p style={card_title}>Exclusions</p>
                        <p style={card_subtitle}>Positions excluded from transition analysis</p>
                    </div>
                </Grid>
                {props.account["transition_data"] ? (
                    <div>
                        {props.account ? (
                            <div>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <HeaderCell>Security Name</HeaderCell>
                                                <HeaderCell>Security Ticker</HeaderCell>
                                                <HeaderCell>Provided Shares</HeaderCell>
                                                <HeaderCell>Provided Market Value</HeaderCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {exclusions.length === 0 ? (
                                                <></>
                                            ) : (
                                                exclusions.sort(function (a, b) {
                                                    const a_balance = parseFloat(a['Current Market Value'])
                                                    const b_balance = parseFloat(b['Current Market Value'])
                                                    return b_balance - a_balance
                                                }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                                    <IPTableRow>
                                                        <ValueCell>{row['Security Name']}</ValueCell>
                                                        <ValueCell>{row['Security Ticker']}</ValueCell>
                                                        <ValueCell>{numWithCommas(row['Current Shares'])}</ValueCell>
                                                        <ValueCell>{dollarsWithCommas(row['Current Market Value'])}</ValueCell>
                                                    </IPTableRow>
                                                ))
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[]}
                                    component="div"
                                    count={exclusions.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                ) : (
                    <></>
                )
                }
            </Paper>
        </div>
    )
}

export default AccountTransitionExclusions