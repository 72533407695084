import {useUserFirmName, useUserLogo, useUserName} from "../Hooks/Data/User/user";
import {ipLogoWhite} from "../../Styles/styles";
import React from "react";

export const CoverPage = (benchmark, name, proposal_cover_url) => {
    const logo = useUserLogo()
    const firm_name = useUserFirmName()
    return (
        <div style={{height: "100%", position: "relative", color: "white", width: "875px", fontDisplay: "swap"}}>
            <img
                src={proposal_cover_url}
                width="100%" height="100%"/>
            <div style={{position: "absolute", top: "40px", left: "60px"}}>
                <img src={logo} height="40px" style={{filter: "brightness(0) invert(1)"}}/>
            </div>
            <div style={{
                position: "absolute",
                top: "40px",
                right: "60px",
                fontSize: "13px",
                fontWeight: "700"
            }}>
                Prepared for {name}
            </div>
            <div style={{
                position: "absolute",
                top: "140px",
                left: "60px",
                fontSize: "49px",
                fontWeight: "700"
            }}>
                Custom Investment Proposal
            </div>
            <div style={{
                position: "absolute",
                top: "200px",
                left: "60px",
                fontSize: "32px",
                fontWeight: "700"
            }}>
                {benchmark} PRO
            </div>
            <div style={{
                position: "absolute",
                bottom: "90px",
                left: "60px",
                fontSize: "12px",
                fontWeight: "600"
            }}>
                Powered by:
            </div>
            <div style={{position: "absolute", bottom: "40px", left: "60px"}}>
                <img src={ipLogoWhite} height="45px"/>
            </div>
            <div style={{
                position: "absolute",
                bottom: "30px",
                right: "60px",
                fontSize: "13px",
                fontWeight: "700"
            }}>
                For Use By {firm_name} Only
            </div>
        </div>
    )
}