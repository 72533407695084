import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {Grid, Button, Paper} from "@material-ui/core"
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import {
    paper_left,
    HeaderCell,
    SpecialCell,
    transparent_button,
    ValueCell,
    card_title,
    card_subtitle,
    paper_right,
    builder_paper_right, IPTableRow
} from "../../Styles/styles";
import {DataStore} from "@aws-amplify/datastore";
import {Benchmarks} from "../../models";

function TaxGraphic({builder_state}) {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className='Tax-Image1' style={{height: "100%"}}>
                <Grid container direction="row" style={Object.assign({}, builder_paper_right, {border: "none"})}>
            <Paper style={{width: "400px", marginRight: "22px", border: "1px solid #dadce0"}} elevation={0}>
                <Grid container direction="row" justify="center" align="center">
                    <Grid item xs={12}>
                        <p style={Object.assign({}, card_title, {marginLeft: "0px", marginBottom: "20px"})}>Step 1</p>
                    </Grid>
                    <Grid item xs={12}>
                        <img src="https://res.cloudinary.com/indexpro/image/upload/v1649008430/smart-tax-loss-harvesting-1_odetql.svg" height="200px"/>
                    </Grid>
                    <Grid item xs={12}>
                        <p style={Object.assign({}, card_title, {marginLeft: "20px", marginRight: "20px"})}>
                            Manage investment gains with investment losses
                        </p>
                    </Grid>
                    <Grid item xs={12}>
                        <p style={Object.assign({}, card_subtitle, {marginLeft: "20px", marginRight: "20px"})}>
                            Goal: Match and manage investment gains with investment losses to target a specific strategy
                        </p>
                    </Grid>
                </Grid>

            </Paper>
            <Paper style={{width: "400px", border: "1px solid #dadce0"}}
                   elevation={0}>
                <Grid container direction="row" justify="center" align="center">
                    <Grid item xs={12}>
                        <p style={Object.assign({}, card_title, {marginLeft: "0px", marginBottom: "20px"})}>Step 2</p>
                    </Grid>
                    <Grid item xs={12}>
                        <img src="https://res.cloudinary.com/indexpro/image/upload/v1649008430/smart-tax-loss-harvesting-2_ld2lmi.svg" height="200px"/>
                    </Grid>
                    <Grid item xs={12}>
                        <p style={Object.assign({}, card_title, {marginLeft: "20px", marginRight: "20px"})}>
                            Harvest Investment Losses
                        </p>
                    </Grid>
                    <Grid item xs={12}>
                        <p style={Object.assign({}, card_subtitle, {marginLeft: "20px", marginRight: "20px"})}>
                            Lock in losses by selling losing securities
                        </p>
                    </Grid>
                </Grid>
            </Paper>
                </Grid>
            </div>
            <div className='Tax-Image2' style={{height: "100%"}}>
            <Paper style={Object.assign({}, builder_paper_right, {width: "400px"})} elevation={0}>
                <Grid container direction="row" justify="center" align="center">
                    <Grid item xs={12}>
                    <p style={Object.assign({}, card_title, {marginLeft: "0px", marginBottom: "20px"})}>Step 4</p>
                </Grid>
                    <Grid item xs={12}>
                    <img src="https://res.cloudinary.com/indexpro/image/upload/v1649008430/smart-tax-loss-harvesting-4_pgj45f.svg" height="200px"/>
                </Grid>
                    <Grid item xs={12}>
                        <p style={Object.assign({}, card_title, {marginLeft: "20px", marginRight: "20px"})}>
                            Reduce Tax Burden
                        </p>
                    </Grid>
                    <Grid item xs={12}>
                        <p style={Object.assign({}, card_subtitle, {marginLeft: "20px", marginRight: "20px"})}>
                            Claim a loss on your tax return, optimizing your portfolio for tax burden
                        </p>
                    </Grid>
                </Grid>
            </Paper>
            <Paper style={Object.assign({}, builder_paper_right, {width: "400px", marginRight: "22px"})}
                   elevation={0}>
                <Grid container direction="row" justify="center" align="center">
                    <Grid item xs={12}>
                    <p style={Object.assign({}, card_title, {marginLeft: "0px", marginBottom: "20px"})}>Step 3</p>
                    </Grid>
                    <Grid item xs={12}>
                        <img src="https://res.cloudinary.com/indexpro/image/upload/v1649008430/smart-tax-loss-harvesting-3_ckfy7h.svg" height="200px"/>
                    </Grid>
                    <Grid item xs={12}>
                        <p style={Object.assign({}, card_title, {marginLeft: "20px", marginRight: "20px"})}>
                            Repurchase Assets
                        </p>
                    </Grid>
                    <Grid item xs={12}>
                        <p style={Object.assign({}, card_subtitle, {marginLeft: "20px", marginRight: "20px"})}>
                            Purchase similar securities while intelligently waiting 30-days to re-enter previous positions.
                        </p>
                    </Grid>
                </Grid>
            </Paper>
            </div>
        </>
    )
}


export default observer(TaxGraphic);
