import {
    action,
    computed,
    makeObservable,
    observable,
    autorun,
    runInAction,
    extendObservable
} from "mobx";
import {ipLogo} from "../../Styles/styles";


class BuilderState {
    //
    isLoggedIn = false

    //app bar
    tab = 0

    //index page
    benchmark_name = 'S&P 500'
    allocation_model_id = null

    //values page
    core_options = new Set()
    custom_options = new Set()
    custom_exclusions = new Set()
    themes = new Set()
    revenue_threshold_exclusions5 = new Set()
    revenue_threshold_exclusionsAny = new Set()

    //tax page
    apply_tax_preferences = false
    tax_preference = null
    wash_sales = false
    capital_gain_management = false
    transition_management = false
    tax_data = {
        'Tax Management' : {
            'options' : ['Tax Aware', 'Tax Neutral', 'Tax-Loss Harvesting'],
            'logo': ipLogo
        }
    }


    //performance page
    apply_alpha = false
    tracking_error = 0.5
    alpha_group = null
    rebalance_frequency = 'M'
    cash_position_size = '0.25'
    sector_constraints = '5'
    industry_constraints = '5'
    max_position_count = ''
    security_exclusions = []



    constructor() {
        makeObservable(this, {
            isLoggedIn: observable,
            setLogin: action,

            //reset store
            reset: action,

            //app bar
            tab: observable,
            setTab: action,

            //index page
            benchmark_name: observable,
            setBenchmarkName: action,
            allocation_model_id: observable,
            setAllocationModelId: action,
            //value page
            core_options: observable,
            setCoreOptions: action,
            removeCoreOptions: action,
            custom_options: observable,
            setCustomOptions: action,
            removeCustomOptions: action,
            custom_exclusions: observable,
            setCustomExclusions: action,
            removeCustomExclusions: action,
            revenue_threshold_exclusions5: observable,
            revenue_threshold_exclusionsAny: observable,
            set5pctThreshold: action,
            remove5pctThreshold: action,
            setAnyThreshold: action,
            removeAnyThreshold: action,

            //themes
            themes: observable,
            setThemes: action,
            removeThemes: action,

            //tax
            apply_tax_preferences: observable,
            tax_preference: observable,
            wash_sales: observable,
            capital_gain_management: observable,
            transition_management: observable,
            tax_data: observable,
            setApplyTaxPreferences: action,
            setTaxPreference: action,
            setWashSales: action,
            setCapitalGainManagement: action,
            setTransitionManagement: action,

            //performance
            apply_alpha: observable,
            tracking_error: observable,
            alpha_group: observable,
            rebalance_frequency: observable,
            cash_position_size: observable,
            sector_constraints: observable,
            industry_constraints: observable,
            max_position_count: observable,
            setApplyAlpha: action,
            setTrackingError: action,
            setAlphaGroup: action,
            setRebalanceFrequency: action,
            setCashPositionSize: action,
            setSectorConstraints: action,
            setIndustryConstraints: action,
            setMaxPositionCount: action,
            security_exclusions: observable,
            setSecurityExclusions: action
        });
    }

    reset(){
        //app bar
        this.tab = 0

        //index page
        this.benchmark_name = 'S&P 500'
        this.allocation_model_id = null
        //values page
        this.core_options = new Set()
        this.custom_options = new Set()
        this.custom_exclusions = new Set()
        this.themes = new Set()
        this.revenue_threshold_exclusions5 = new Set()
        this.revenue_threshold_exclusionsAny = new Set()

        //tax page
        this.apply_tax_preferences = false
        this.tax_preference = null
        this.wash_sales = false
        this.capital_gain_management = false
        this.transition_management = false
        this.tax_data = {
            'Tax Management' : {
                'options' : ['Tax Aware', 'Tax Neutral', 'Tax-Loss Harvesting'],
                'logo': ipLogo
            }
        }
        this.alpha_group = null
        this.tracking_error = 0.5
        this.rebalance_frequency = 'M'
        this.cash_position_size = '0.25'
        this.sector_constraints = '5'
        this.industry_constraints = '5'
        this.max_position_count = ''
        this.security_exclusions = []
    }

    setLogin(isLoggedIn){
        //sets tab clicked
        this.isLoggedIn = isLoggedIn
        return isLoggedIn
    }
    //builder app bar
    setTab(tab_value){
        //sets tab clicked
        this.tab = tab_value
        return tab_value
    }

    //index page
    setBenchmarkName(benchmark_name){
        //sets benchmark name
        this.benchmark_name = benchmark_name
        return benchmark_name
    }
    setAllocationModelId(allocation_model_id){
        //sets benchmark type
        this.allocation_model_id = allocation_model_id
        return allocation_model_id
    }

    //values page
    setCoreOptions(value){
        //sets esg value
        this.core_options.add(value)
        return value
    }

    removeCoreOptions(value){
        //remove esg value
        this.core_options.delete(value)
        return value
    }

    setCustomOptions(value){
        //sets esg value
        this.custom_options.add(value)
        return value
    }

    removeCustomOptions(value){
        //remove esg value
        this.custom_options.delete(value)
        return value
    }

    setCustomExclusions(value){
        //sets esg value
        this.custom_exclusions.add(value)
        return value
    }

    removeCustomExclusions(value){
        //remove esg value
        this.custom_exclusions.delete(value)
        return value
    }

    set5pctThreshold(value){
        this.revenue_threshold_exclusions5.add(value + ' 5%')
        this.revenue_threshold_exclusionsAny.delete(value)
    }
    setAnyThreshold(value){
        this.revenue_threshold_exclusionsAny.add(value)
        this.revenue_threshold_exclusions5.delete(value + ' 5%')
    }
    remove5pctThreshold(value){
        this.revenue_threshold_exclusions5.delete(value + ' 5%')
    }
    removeAnyThreshold(value){
        this.revenue_threshold_exclusionsAny.delete(value)
    }

    //themes
    setThemes(value){
        //sets esg value
        this.themes.add(value)
        return value
    }

    removeThemes(value){
        //remove esg value
        this.themes.delete(value)
        return value
    }

    //tax page
    setApplyTaxPreferences(value){
        //sets apply tax preferences
        this.apply_tax_preferences = value
        return value
    }
    setTaxPreference(value){
        //sets apply values
        this.tax_preference = value
        return value
    }
    setWashSales(value){
        //sets wash sales
        this.wash_sales = value
        return value
    }
    setCapitalGainManagement(value){
        //sets capital gain management
        this.capital_gain_management = value
        return value
    }
    setTransitionManagement(value){
        //sets transition_management
        this.transition_management = value
        return value
    }

    //performance page
    setApplyAlpha(value){
        //sets apply alpha
        this.apply_alpha = value
        return value
    }
    setTrackingError(value){
        //sets tracking error
        this.tracking_error = value
        return value
    }

    setAlphaGroup(value){
        //sets alpha group
        this.alpha_group = value
        return value
    }

    setRebalanceFrequency(value){
        this.rebalance_frequency = value
        return value
    }
    setCashPositionSize(value){
        this.cash_position_size = value
        return value
    }
    setSectorConstraints(value){
        this.sector_constraints = value
        return value
    }
    setIndustryConstraints(value){
        this.industry_constraints = value
        return value
    }
    setMaxPositionCount(value){
        this.max_position_count = value
        return value
    }

    setSecurityExclusions(value){
        this.security_exclusions = value
        return value
    }

}

export default BuilderState;

