import React, {useEffect} from "react";
import {Grid, IconButton} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import {observer} from "mobx-react-lite"
import MobileStepper from '@material-ui/core/MobileStepper';
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import GetInTouch from "./builder-journey";
import ReplayIcon from '@material-ui/icons/Replay';
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "1278px",
        margin: "0 auto"
    },
    button: {
        marginRight: theme.spacing(1)
    },
    completed: {
        display: "inline-block",
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));



function getSteps() {
    return ["Select Index", "Values", "Themes", "Performance & Risk", "Taxes", "Summary"];
}

function BuilderAppBar({builder_state}) {

    const builder_tab = builder_state.tab
    const handleTabChange = (event, value) => {
        builder_state.setTab(value)
    }

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(builder_tab);
    const [completed, setCompleted] = React.useState({});
    const steps = getSteps();

    useEffect(() => {
        setActiveStep(builder_tab)
    });

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                  // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
        builder_state.setTab(step)
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };

    return (
            <div className={classes.root}>
                <div style={{justifyContent: "space-evenly", justifyItems: "center", alignContent: "center", alignItems: "center"}}>
                    <Stepper nonLinear activeStep={activeStep} style={{ backgroundColor: "transparent", padding: "0px" }}>
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepButton
                                    onClick={handleStep(index)}
                                    completed={completed[index]}
                                >
                                    {label}
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                </div>
            </div>
    )
}

export default observer(BuilderAppBar);