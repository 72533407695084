import AccountTitle from "./account-title";
import AccountOverview from "./account-overview";
import AccountPreferences from "./account-preferences";
import AccountHistory from "./account-history";
import AccountActivity from "./account-activity";
import AccountHoldings from "./account-holdings";
import AccountSectors from "./account-sectors";
import AccountImpact from "./account-impact";
import React from "react";

function AccountInfoLive(props) {

    const account = props.account
    const strategy = props.strategy

    return (
        <div className="AccountInfoLiveContainer">
            <AccountTitle account={account}/>
            <AccountOverview account={account} strategy={strategy}/>
            <AccountPreferences editable={true} strategy={strategy} account={account}/>
            <AccountHistory account={account}/>
            <AccountActivity account={account}/>
            <AccountHoldings account={account}/>
            <AccountSectors account={account}/>
            <AccountImpact strategy={strategy}/>
        </div>
    )
}

export default AccountInfoLive