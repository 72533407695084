import React, {useEffect, useState} from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {Grid, Typography, Paper, Button} from "@material-ui/core";
import {observer} from "mobx-react-lite";
import FormGroup from "@material-ui/core/FormGroup";
import BuilderSwitch from "./builder-switch-style"
import {
    control_paper_left,
    big_paper,
    card_title,
    card_subtitle,
    bordered_button,
    card_subtitle_black, themeColor
} from "../../Styles/styles";
import GetInTouch from "./builder-journey";
import {DataStore} from "@aws-amplify/datastore";
import {RevenueThresholdExclusions} from "../../models";
import Fab from '@material-ui/core/Fab';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import {Clear} from "@material-ui/icons";
import {useRevenueExclusionsData} from "../Hooks/Data/Builder/builder";

function RevenueThresholdExclusionsComp({builder_state}) {

    const revenue_exclusion_data = useRevenueExclusionsData()


    const add5pctRevenueThresholdExclusion = (value) => {
        builder_state.set5pctThreshold(value)
    }
    const addAnyRevenueThresholdExclusion = (value) => {
        builder_state.setAnyThreshold(value)
    }
    const removeAnyRevenueThresholdExclusion = (value) => {
        builder_state.removeAnyThreshold(value)
    }
    const remove5pctRevenueThresholdExclusion = (value) => {
        builder_state.remove5pctThreshold(value)
    }

    const fabState = (title) => {
        if (!builder_state.revenue_threshold_exclusions5.has(title + ' 5%') && !builder_state.revenue_threshold_exclusionsAny.has(title)) {
            return (
                <div style={{display:"flex"}}>
                <div>
                    <div style={{marginLeft: "25px"}}>
                        <Fab size="small" style={{backgroundColor: "lightgrey"}} onClick={() => addAnyRevenueThresholdExclusion(title)}>
                            <AddIcon/>
                        </Fab>
                    </div>
                </div>
                <div>
                    <div style={{marginLeft: "60px"}}>
                        <Fab size="small" style={{backgroundColor: "lightgrey"}} onClick={() => add5pctRevenueThresholdExclusion(title)}>
                            <AddIcon/>
                        </Fab>
                    </div>
                </div>
                    </div>
            )
        } else if (!builder_state.revenue_threshold_exclusions5.has(title + ' 5%') && builder_state.revenue_threshold_exclusionsAny.has(title)) {
            return (
                <div style={{display:"flex"}}>
                    <div>
                        <div style={{marginLeft: "25px"}}>
                            <Fab size="small" style={{backgroundColor: "#D62728"}} onClick={() => removeAnyRevenueThresholdExclusion(title)}>
                                <ClearIcon style={{color: "white"}}/>
                            </Fab>
                        </div>
                    </div>
                    <div>
                        <div style={{marginLeft: "60px"}}>
                            <Fab size="small" style={{backgroundColor: "lightgrey"}} onClick={() => add5pctRevenueThresholdExclusion(title)}>
                                <AddIcon/>
                            </Fab>
                        </div>
                    </div>
                </div>
            )
        } else if (builder_state.revenue_threshold_exclusions5.has(title + ' 5%') && !builder_state.revenue_threshold_exclusionsAny.has(title)) {
            return (
                <div style={{display:"flex"}}>
                    <div>
                        <div style={{marginLeft: "25px"}}>
                            <Fab size="small" style={{backgroundColor: "lightgrey"}} onClick={() => addAnyRevenueThresholdExclusion(title)}>
                                <AddIcon/>
                            </Fab>
                        </div>
                    </div>
                    <div>
                        <div style={{marginLeft: "60px"}}>
                            <Fab size="small" style={{backgroundColor: "#D62728"}} onClick={() => remove5pctRevenueThresholdExclusion(title)}>
                                <ClearIcon style={{color: "white"}}/>
                            </Fab>
                        </div>
                    </div>
                </div>
            )
        }
    }

    const createCustomExclusions = () => {
        return (
            <Grid container direction="column" justifyContent="flex-start" alignContent="center"
                  alignItems="center" style={{marginTop: "10px"}}>
                {revenue_exclusion_data.map(function (item, index) {
                    return (
                        <Grid item alignContent="center"
                              alignItems="center" style={{
                            width: "1228px",
                            marginLeft: "20px",
                            marginRight: "20px"
                        }}>
                            <div style={{borderTop: `2px solid lightgrey`, paddingTop: "20px", paddingBottom: "20px"}}>
                                <Grid container direction="row" justifyContent="flex-start" alignContent="center"
                                      alignItems="center">
                                    <Grid item xs={6}>
                                        <p style={Object.assign({}, card_title,
                                            {
                                                marginLeft: "5px",
                                                marginTop: "0px",
                                                fontSize: "13px",
                                                fontWeight: "500"
                                            })}>{item.title}</p>
                                    </Grid>
                                    {fabState(item.title)}
                                </Grid>

                            </div>
                        </Grid>
                    )
                })}
            </Grid>
        )
    };

    return (
        <Paper style={big_paper} elevation={0} square>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <div>
                    <p style={card_title}>Revenue Threshold Exclusions</p>
                </div>
            </Grid>
            <div style={{marginLeft: "650px"}}>
                <p style={Object.assign({}, card_title,
                    {
                        marginTop: "0px",
                        fontSize: "13px",
                    })}>Revenue Exclusions</p>
            </div>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <p style={Object.assign({}, card_title,
                    {
                        marginTop: "0px",
                        fontSize: "13px",
                        fontWeight: "500",
                        marginLeft: "670px"
                    })}>Any</p>
                <p style={Object.assign({}, card_title,
                    {
                        marginTop: "0px",
                        fontSize: "13px",
                        fontWeight: "500",
                        marginLeft: "75px"
                    })}>> 5%</p>
            </Grid>
            {createCustomExclusions()}
        </Paper>
    )
}

export default observer(RevenueThresholdExclusionsComp);