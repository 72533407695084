import {Grid} from "@material-ui/core";
import {themeColor} from "../../Styles/styles";
import React from "react";
import {useHistory} from "react-router-dom";

function AccountTitle(props) {
    const history = useHistory()
    return (
        <div className="Account-Title">
            <Grid direction="row" container alignItems="center" justifyContent="space-between"
                  style={{width: "1278px", margin: "0 auto"}}>
                <a onClick={() => history.push('/participant/accounts')} style={{textDecoration: "none"}}>
                    <p style={{
                        paddingBottom: "0px",
                        color: themeColor,
                        fontSize: "18px",
                        margin: "0px"
                    }}>Accounts > <span style={{color: "black"}}>{props.account.legal_name}</span></p></a>
                {props.account.data ? (
                    <p style={{
                        paddingBottom: "0px",
                        fontSize: "15px",
                        fontWeight: "500",
                        margin: "0px"
                    }}>Last Updated: {props.account.data.as_of_date}</p>
                ) : (
                    <></>
                )}
            </Grid>
        </div>
    )
}

export default AccountTitle