import {card_title, ipGreen, ipRed, ReportHeaderCell, ReportValueCell, color_filters} from "../../Styles/styles";
import {Grid} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import React from "react";
import {ESGTStartingNumber, superScript} from "../../Utils/compliance_utils";

export const SelectionImpact = (type, headers, page_number, benchmark, selections, exclusions, impact_data, transition_data) => {

    let cloneSelections = selections.slice(0)
    let top3Selections = cloneSelections.slice(0, 3)

    let footnote_start = ESGTStartingNumber(page_number, transition_data)

    return (
        <div style={{height: "100%", position: "relative", color: "black", fontDisplay: "swap"}}>
            <div style={{
                position: "absolute",
                top: "54px",
                right: "60px",
                fontSize: "14px",
                fontWeight: "600",
                color: "black"
            }}>{page_number}
            </div>
            <Grid container justifyContent="flex-start" style={{
                position: "absolute", top: "40px", left: "60px",
                fontSize: "14px", color: "#c2c2c1", fontWeight: "600", width: "800px"
            }}>
                <p style={{marginRight: "25px"}}>Portfolio</p>
                {headers.map(title => {
                    if (title === type) {
                        return (
                            <p style={{marginRight: "25px", color: "#009494"}}>{title}</p>
                        )
                    }
                    else {
                        return(
                            <p style={{marginRight: "25px"}}>{title}</p>
                        )
                    }
                })}
            </Grid>
            <div style={{
                position: "absolute",
                top: "140px",
                left: "60px",
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: 0.96
            }}>{type}<br/> Selections{superScript(footnote_start)}
            </div>
            <div style={{
                position: "absolute", top: "240px", marginLeft: "60px", marginRight: "60px",
                width: "755px", fontSize: "14px", fontWeight: "600", borderBottom: "1px solid black"
            }}>
                {type.toUpperCase()} SELECTIONS {superScript(footnote_start + 1)}
            </div>

            <Grid container direction="row" justifyContent="flex-start" style={{
                position: "absolute", top: "270px", marginLeft: "60px", marginRight: "60px",
                width: "550px", fontSize: "10px", fontWeight: "600"
            }}>
                {top3Selections.map(function (item, index) {
                    if (item) {
                        return (
                            <Grid item style={{marginRight: "10px"}}>
                                <Grid item xs={12}>
                                    <div style={{height: "120px"}}>
                                        <img
                                            src={item.logo_url.split("/upload/").join("/upload/w_300,c_scale/q_auto/")}
                                            alt="logo" width="170" height="120"/>
                                    </div>
                                </Grid>
                                <Grid item xs={12} style={{width: "170px"}}>
                                    <p style={Object.assign({}, card_title, {
                                        marginLeft: "0px",
                                        fontSize: "10px",
                                        marginBottom: "0px"
                                    })}>{item.title.toUpperCase()}</p>
                                </Grid>
                                <Grid item xs={12} style={{width: "170px", color: "#b5b4b9"}}>
                                    <p style={Object.assign({}, card_title, {
                                        marginTop: "0px",
                                        marginLeft: "0px",
                                        fontSize: "9px"
                                    })}>{item.short_description}</p>
                                </Grid>
                            </Grid>
                        )
                    }
                })}
            </Grid>
            {selections.length > 3 ?
                (<>
                        <div style={{
                            position: "absolute", top: "275px", right: "60px", width: "200px",
                            fontSize: "10px", fontWeight: "600", borderBottom: "2px solid black", color: "#009494"
                        }}>
                            ADDITIONAL SELECTIONS
                        </div>
                        <Grid container style={{
                            position: "absolute", top: "295px", right: "60px", width: "200px",
                            fontSize: "10px", color: "black"
                        }}>
                            {selections.slice(3, selections.length).map(function (item, index) {
                                return (
                                    <Grid item xs={12} style={{borderBottom: "1px solid #dadce0", marginBottom: "2px"}}>
                                        {item.title.toUpperCase()}
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </>
                )
                :
                (<></>)
            }

            <div style={{
                position: "absolute", top: "470px", marginLeft: "60px", marginRight: "60px",
                width: "755px", fontSize: "14px", fontWeight: "600", borderBottom: "1px solid black"
            }}>
                {type === 'Themes' ?
                    (<>PORTFOLIO CHANGES <span style={{fontWeight: "500", fontSize: "12px"}}>(TOP POSITIONS IMPACTED BY THIS SELECTION) {superScript(footnote_start + 2)}</span></>)
                    :
                    (<>PORTFOLIO CHANGES <span style={{fontWeight: "500", fontSize: "12px"}}>(TOP POSITIONS EXCLUDED) {superScript(footnote_start + 2)}</span></>)
                }
            </div>
            <div style={{
                position: "absolute", top: "490px", marginLeft: "60px", marginRight: "60px",
                width: "755px", fontWeight: "600"
            }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <ReportHeaderCell>COMPANY</ReportHeaderCell>
                                {type === 'Themes' ?
                                    (<ReportHeaderCell align="center">WEIGHT {superScript(footnote_start + 3)}</ReportHeaderCell>)
                                    :
                                    (<ReportHeaderCell align="center">WEIGHTING ADJUSTMENT {superScript(footnote_start + 3)}</ReportHeaderCell>)
                                }
                                <ReportHeaderCell align="center">REASON</ReportHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {exclusions.map((row) => (
                                <TableRow>
                                    <ReportValueCell>{row.company_name}</ReportValueCell>
                                    {type === 'Themes' ?
                                        (<ReportValueCell align="center"
                                                          style={{fontWeight: "600"}}>{row.weight}</ReportValueCell>)
                                        :
                                        (<ReportValueCell align="center"
                                                          style={{fontWeight: "600"}}>-{row.weight}</ReportValueCell>)
                                    }
                                    <ReportValueCell align="center">{row.factor}</ReportValueCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div style={{
                position: "absolute", top: "680px", marginLeft: "60px", marginRight: "60px",
                width: "755px", fontSize: "14px", fontWeight: "600", borderBottom: "1px solid black"
            }}>
                IMPACT VS. BENCHMARK <span
                style={{fontWeight: "500", fontSize: "12px"}}>(POTENTIAL {type.toUpperCase()} IMPACT OF INDEX PRO PORTFOLIO VERSUS {benchmark}) {superScript(footnote_start + 4)}</span>
            </div>
            <Grid container direction="row" justifyContent="space-between" style={{
                position: "absolute", top: "720px", marginLeft: "60px", marginRight: "60px",
                width: "755px", fontSize: "16px", fontWeight: "500"
            }}>
                {impact_data.slice(0, 3).map((item, i) => (
                    <div style={{
                        backgroundColor: "#f6f7f8",
                        width: "241px",
                        height: "180px",
                        color: "black",
                        textAlign: "center",
                    }}>
                        <img src={`https://res.cloudinary.com/indexpro/image/upload/Impact Icons/${item.title}.svg`}
                             height="50px"
                             style={{marginTop: "40px", textAlign: "center", maxWidth: "95px", filter: color_filters[i]}}/>
                        <p style={{fontSize: "12px", textAlign: "center", marginBottom: "0px"}}>{item.title}</p>
                        <p style={{
                            fontSize: "12px",
                            textAlign: "center",
                            fontWeight: "500",
                            marginTop: "0px",
                            marginLeft: "10px",
                            marginRight: "10px",
                            color: "#555555"
                        }}>{item.pct} improvement in MSCI {item.title} score</p>
                    </div>
                ))}
            </Grid>
            <Grid container direction="row" justifyContent="space-between" style={{
                position: "absolute", top: "916px", marginLeft: "60px", marginRight: "60px",
                width: "755px", fontSize: "16px", fontWeight: "500"
            }}>
                {impact_data.slice(3, 5).map((item, i) => (
                    <div style={{
                        backgroundColor: "#f6f7f8",
                        width: "370px",
                        height: "180px",
                        color: "black",
                        textAlign: "center",
                    }}>
                        <img src={`https://res.cloudinary.com/indexpro/image/upload/Impact Icons/${item.title}.svg`} height="50px"
                             style={{marginTop: "40px", textAlign: "center", maxWidth: "95px", filter: color_filters[i + 3]}}/>
                        <p style={{fontSize: "12px", textAlign: "center", marginBottom: "0px"}}>{item.title}</p>
                        <p style={{
                            fontSize: "12px",
                            textAlign: "center",
                            fontWeight: "500",
                            marginTop: "0px",
                            marginLeft: "10px",
                            marginRight: "10px",
                            color: "#555555"
                        }}>{item.pct} improvement in MSCI {item.title} score</p>
                    </div>
                ))}
            </Grid>
        </div>
    )
}
