import AccountTitle from "./account-title";
import AccountPreferences from "./account-preferences";
import AccountOnboarding from "./account-onboarding";
import AccountTransitionPortfolio from "./account-transition-portfolio";
import React from "react";

function AccountInfoOnboarding(props) {

    const account = props.account
    const strategy = props.strategy

    return (
        <div className="AccountOnboardingContainer">
            <AccountTitle account={account}/>
            <AccountPreferences editable={false} strategy={strategy} account={account}/>
            <AccountOnboarding account={account}/>
            <AccountTransitionPortfolio account={account}/>
        </div>
    )
}

export default AccountInfoOnboarding