import React, {useState} from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {Grid, Typography, Paper, Button} from "@material-ui/core";
import { observer }  from "mobx-react-lite"
import FormGroup from "@material-ui/core/FormGroup";
import BuilderSwitch from "./builder-switch-style";
import {control_paper_left} from "../../Styles/styles";


function Tax({builder_state}) {

    //tax data + handlers
    const tax_data = builder_state.tax_data
    const apply_tax_preferences = builder_state.apply_tax_preferences
    const tax_preference = builder_state.tax_preference
    const wash_sales = builder_state.wash_sales
    const capital_gain_management = builder_state.capital_gain_management
    const transition_management = builder_state.transition_management
    const handleTaxPreferenceSelect = (name) => {
        builder_state.setApplyTaxPreferences(true)
        builder_state.setTaxPreference(name);
    }
    const handleApplyTaxPreferencesButton = (event) => {
        if (event.target.checked === false) {
            builder_state.setTaxPreference(null)
        }
        builder_state.setApplyTaxPreferences(event.target.checked);
    };
    const handleWashSalesButton = (event) => {
        builder_state.setWashSales(event.target.checked);
    };
    const handleCapitalGainManagementButton = (event) => {
        builder_state.setCapitalGainManagement(event.target.checked);
    };
    const handleTransitionManagementButton = (event) => {
        builder_state.setTransitionManagement(event.target.checked);
    };


    //Expansion Panel
    const [expanded, setExpanded] = useState('0');


    const createTaxDropDown = (tax_data, index) => {

        //pull tax data
        let logo = tax_data.logo
        let options = tax_data['options']
        return (
            <Accordion className="ExpansionPanel" square style={{boxShadow: "none"}} expanded={expanded === index.toString()}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    classes = {{expanded: 'expanded'}}
                >
                    <img src={logo}
                         alt="logo" width="160" height="50"/>
                </AccordionSummary>
                {options.map(function(name, index){
                    return (
                        <AccordionDetails className="ExpansionDetails">
                            {(() => {
                                if (name === tax_preference) {
                                    return (
                                        <Button style={{marginLeft: "20px", textTransform: "none"}}
                                                onClick={()=>handleTaxPreferenceSelect(name)}>
                                            <Typography style={{fontWeight: 700}}>{name}</Typography>
                                        </Button>
                                    )
                                }
                                else {
                                    return (
                                        <Button style={{marginLeft: "20px", textTransform: "none"}}
                                                onClick={()=>handleTaxPreferenceSelect(name)}>
                                            <Typography>{name}</Typography>
                                        </Button>
                                    )
                                }
                            })()}

                        </AccordionDetails>
                    )
                })}
            </Accordion>
        )
    };

    return (
        <>
            <Paper style={control_paper_left} elevation={0} square>
                <div className="ControlPanelTitle">
                    <p className="ControlPanelText" style={{textAlign: "center"}}>Apply tax preferences</p>
                    <div className="BuilderButton">
                        <FormGroup>
                            <Typography component="div">
                                <Grid component="label" container alignItems="center" spacing={1}>
                                    <Grid item>No</Grid>
                                    <Grid item>
                                        <BuilderSwitch
                                            checked={apply_tax_preferences}
                                            onChange={handleApplyTaxPreferencesButton}
                                            name="apply_tax_preferences"
                                        />
                                    </Grid>
                                    <Grid item>Yes</Grid>
                                </Grid>
                            </Typography>
                        </FormGroup>
                    </div>
                    <p style={{margin: "10px", marginTop: "30px", fontSize: "14px"}}>TAX STRATEGY</p>
                </div>
                {
                    Object.keys(tax_data).map((key, index) => {
                        return (
                            <div>
                                {createTaxDropDown(tax_data[key], index)}
                            </div>
                        )
                    })
                }
            </Paper>
        </>
    )
}

export default observer(Tax);