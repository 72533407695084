import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {Button, Grid, Paper} from "@material-ui/core"
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import {
    paper_left,
    HeaderCell,
    SpecialCell,
    transparent_button,
    ValueCell,
    card_title,
    card_subtitle,
    paper_right,
    builder_paper_right, IPTableRow
} from "../../Styles/styles";
import {DataStore} from "@aws-amplify/datastore";
import {Benchmarks} from "../../models";
import {useBenchmarkData} from "../Hooks/Data/Builder/builder";
import Lottie from "react-lottie";
import optimizing from "../../Styles/optimizing.json";

function Top10Holdings({builder_state}) {

    let benchmark_name = builder_state.benchmark_name
    let allocation_model_id = builder_state.allocation_model_id
    const benchmark_data = useBenchmarkData(benchmark_name, allocation_model_id)

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: optimizing,
    };

    if (!benchmark_data && !allocation_model_id) {
        return (
            <></>
        )
    }


    return (
        <div style={{height: "100%"}}>
            <Paper style={builder_paper_right} elevation={0}>
                <div>
                    <p style={card_title}>Holdings</p>
                    <p style={card_subtitle}>Top 10 Holdings</p>
                </div>
                {!benchmark_data && allocation_model_id ? (
                    <>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Lottie options={defaultOptions} height={250} width={250}/>
                        </Grid>
                    </>
                ) : (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <HeaderCell>Name</HeaderCell>
                                    <HeaderCell align="left">Symbol</HeaderCell>
                                    <HeaderCell align="right">Weight</HeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {benchmark_data['top_10_holdings'].map((row) => (
                                    <IPTableRow key={row.ticker}>
                                        <ValueCell>{row.company_name}</ValueCell>
                                        <ValueCell>{row.ticker}</ValueCell>
                                        <ValueCell align="right">{row.weight}</ValueCell>
                                    </IPTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
                }
            </Paper>
        </div>
    )
}


export default observer(Top10Holdings);
