import {
    useUserBio,
    useUserEmail, useUserFirmBio, useUserFirmCity, useUserFirmEmail,
    useUserFirmName, useUserFirmPhoto, useUserFirmWebsite,
    useUserLogo,
    useUserName, useUserPhone,
    useUserPhoto,
    useUserTitle
} from "../Hooks/Data/User/user";
import React from "react";
import {formatPhoneNumber} from "../../Utils/num_utils";

export const About = () => {

    const name = useUserName()
    const email = useUserEmail()
    const title = useUserTitle()
    const firm_name = useUserFirmName()
    const photo = useUserPhoto()
    const logo = useUserLogo()
    const firm_photo = useUserFirmPhoto()
    const phone = formatPhoneNumber(useUserPhone())
    const bio = useUserBio()
    const firm_bio = useUserFirmBio()
    const firm_email = useUserFirmEmail()
    const firm_website = useUserFirmWebsite()
    const firm_city = useUserFirmCity()

    if ([name, email, title, firm_name, photo, logo].includes(null) === true) {
        return (
            <></>
        )
    } else {
        return (
            <div style={{height: "100%", position: "relative", color: "black", fontDisplay: "swap"}}>
                <div style={{backgroundColor: "#ececed", height: "560px"}}>
                    <div style={{
                        position: "absolute", top: "110px", marginLeft: "60px", marginRight: "60px",
                        width: "500px", fontSize: "14px", fontWeight: "600", borderBottom: "1px solid black"
                    }}>
                        ABOUT <span style={{color: "#009494"}}>{name.toUpperCase()}</span>
                    </div>
                    <div style={{
                        position: "absolute", top: "130px", marginLeft: "60px",
                        width: "500px", fontSize: "12px"
                    }} dangerouslySetInnerHTML={{__html: bio}}>
                    </div>
                </div>
                <div style={{
                    position: "absolute", top: "150px", right: "60px",
                    width: "200px", fontSize: "14px", fontWeight: "600"
                }}>
                    <img
                        src={photo}
                        width="200px" height="200px"/>
                </div>
                <div style={{
                    position: "absolute", top: "360px", right: "60px",
                    width: "200px", fontSize: "13px", fontWeight: "600"
                }}>
                    {name}
                </div>
                <div style={{
                    position: "absolute", top: "380px", right: "60px",
                    width: "200px", fontSize: "11px",
                }}>
                    {title}
                </div>
                <div style={{
                    position: "absolute", top: "395px", right: "60px",
                    width: "200px", fontSize: "11px",
                }}>
                    {phone}
                </div>
                <div style={{
                    position: "absolute", top: "410px", right: "60px",
                    width: "200px", fontSize: "11px", color: "#009494"
                }}>
                    {email}
                </div>
                <div style={{
                    position: "absolute", top: "580px", marginLeft: "60px", marginRight: "60px",
                    width: "350px", fontSize: "14px", fontWeight: "600", borderBottom: "1px solid black"
                }}>
                    ABOUT <span style={{color: "#009494"}}>{firm_name.toUpperCase()}</span>
                </div>
                <div style={{
                    position: "absolute", top: "620px", marginLeft: "60px", marginRight: "60px",
                    width: "350px"
                }}>
                    <img
                        src={firm_photo}
                        width="380px" height="220px"/>
                </div>
                <div style={{
                    position: "absolute", top: "605px", right: "60px",
                    width: "350px", fontSize: "12px"
                }} dangerouslySetInnerHTML={{__html: firm_bio}}>
                </div>
                <div style={{
                    position: "absolute", top: "850px", marginLeft: "60px", marginRight: "60px",
                    width: "350px", fontSize: "14px", fontWeight: "600"
                }}>
                    {firm_name.toUpperCase()}
                </div>
                <div style={{
                    position: "absolute", top: "870px", marginLeft: "60px", marginRight: "60px",
                    width: "350px", fontSize: "14px"
                }}>
                    {firm_city}
                </div>
                <div style={{
                    position: "absolute", top: "895px", marginLeft: "60px", marginRight: "60px",
                    width: "390px", fontSize: "14px", color: "#1c3d6f"
                }}>
                    {phone} | {firm_email} | {firm_website}
                </div>
                <div style={{position: "absolute", bottom: "40px", left: "60px"}}>
                    <img
                        src="https://res.cloudinary.com/indexpro/image/upload/v1641491104/NewIndexPro-2_1_alzj0m.svg"
                        height="45px"/>
                </div>
                <div style={{position: "absolute", bottom: "40px", right: "60px"}}>
                    <img
                        src={logo}
                        height="40px"/>
                </div>
            </div>
        )
    }
}
