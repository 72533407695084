import Modal from "@material-ui/core/Modal";
import {Button, Grid, Paper} from "@material-ui/core";
import {bordered_button, themeColor} from "../../Styles/styles";
import React from "react";
import {useNoUser, useUserLogo} from "../Hooks/Data/User/user";
import {DataStore} from "@aws-amplify/datastore";
import {User} from "../../models";


function Welcome() {

    const firm_logo = useUserLogo()
    const hasUser = useNoUser()

    const save = async () => {
        try {
            const data = JSON.parse(localStorage.getItem("signed-in-user"))
            await DataStore.save(
                new User({
                    "first_name": data.attributes.name.split(' ')[0],
                    "last_name": data.attributes.name.split(' ')[1],
                    "email": data.attributes.email,
                    "user_role": "Creator",
                    "user_info": data,
                })
            );
        } catch(error) {
            console.error(error)
        }

    }

    return (
        <Modal
            open={hasUser}
            style={{overflow: "scroll"}}
        >
            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Paper elevation={0} style={{
                    width: "800px", height: "560px", marginTop: "300px"
                }}>
                    <Grid container direction="row" style={{padding: "40px"}}>
                        <Grid>
                            <img src={firm_logo} style={{height: "40px"}}/>
                        </Grid>
                        <Grid>
                            <p style={{fontWeight: "600", fontSize: "27px"}}>Welcome to Index Pro!</p>
                            <p style={{color: "grey", fontSize: "17px"}}>
                                We are honored you have selected us to help with your clients' investment journey.
                                The platform is simple to use and we have created short video guides and tutorials to help you make the most out of it.
                                We are excited for you to unlock all the awesome features of the Index Pro platform and let you begin the journey of helping your clients invest better while doing better.
                                <br/><br/>
                                For help please contact us using the chat box or email us at <a href={"mailto: indexpro@atomlp.com"}>indexpro@atomlp.com</a>.
                                <br/><br/>
                                Thank you!
                                <br/>
                                The Index Pro Team
                            </p>
                        </Grid>
                        <Grid container justifyContent={"center"} style={{marginTop: "40px"}}>
                            <Button
                                onClick={save}
                                style={Object.assign({}, bordered_button,
                                {backgroundColor: themeColor, color: "white", width: "200px", fontSize: "15px"})}>Get Started Now!</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        </Modal>
    )

}

export default Welcome