import React, {useState} from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {Grid, Typography, Paper, Button, BottomNavigation, BottomNavigationAction} from "@material-ui/core";
import {observer} from "mobx-react-lite"
import {
    control_paper_left,
    transparent_button,
    themeColor,
    card_title, card_subtitle, ipRed, bordered_button, themeBackground
} from "../../Styles/styles";
import {useUserLogo, useUserProfilePictures, useUserRole, useUserTeamFilterInfo} from "../Hooks/Data/User/user";
import Modal from "@material-ui/core/Modal";
import Fab from "@material-ui/core/Fab";
import ClearIcon from "@material-ui/icons/Clear";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import fuzzysort from "fuzzysort";
import {
    useAllocationModels,
    useBenchmarkDataForSelector,
    useMutualFundData,
    usePublicFundData
} from "../Hooks/Data/Builder/builder";
import TimelineIcon from '@material-ui/icons/Timeline';
import PeopleIcon from '@material-ui/icons/People';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Chip from "@material-ui/core/Chip"
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import {findAllByKey} from "../../Utils/data_utils";
import {DataStore} from "@aws-amplify/datastore";
import {AllocationModel, CustomAlphaModel, CustomStrategies} from "../../models";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import {Auth} from "aws-amplify";
import {useCustomStrategies} from "../Hooks/Data/Strategies/strategies";
import Snackbar from "@material-ui/core/Snackbar";
import {Alert} from "@material-ui/lab";
import {millionNumWithCommas, numWithCommas} from "../../Utils/num_utils";
import Tooltip from "@material-ui/core/Tooltip";
import {userRoleActionWrapper} from "../../Utils/role_utils";
import {teamDataFilterWrapper} from "../../Utils/team_utils";
import {Mixpanel} from "../../Utils/Mixpanel";

function IndexSelector({builder_state}) {
    //builder state
    const benchmark_name = builder_state.benchmark_name

    //selections
    const [selections, setSelection] = React.useState([]);


    //search
    const [search, setSearch] = React.useState('');
    const [filtered_benchmarks, setFilteredBenchmarks] = React.useState([])

    //model states
    const [open, setOpen] = React.useState(false);
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [error, setError] = React.useState(null)

    //allocationModel
    const [allocationModel, setAllocationModel] = React.useState([])
    const [type, setType] = React.useState(0);
    const [modelName, setModelName] = React.useState('');
    const [allocate, setAllocate] = React.useState(0);
    const [deleteAllocationModel, setDeleteAllocationModel] = React.useState(null)


    //data hooks
    const firm_logo = useUserLogo()
    const public_fund_data = useMutualFundData()
    const benchmark_data = useBenchmarkDataForSelector()
    const teamInfoObjs = useUserTeamFilterInfo()
    const user_role = useUserRole()
    const allocation_models = useAllocationModels()
    const filtered_allocation_models = teamDataFilterWrapper(user_role, teamInfoObjs,allocation_models)
    const custom_strategies = useCustomStrategies()

    //open + close modal
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSearch('')
        setModelName('')
        setSelection([])
        setFilteredBenchmarks([])
        setAllocate(0)
        setAllocationModel([])
        setType(0)
    };

    //search method
    const searchBenchmarks = (string) => {
        setSearch(string)
        if (string !== '') {
            let result = fuzzysort.go(string, type === 0 ?
                (findAllByKey(benchmark_data, 'options')) : (public_fund_data), {
                limit: 100,
                keys: type === 0 ? null : ['manager_name', 'fund_name', 'ticker']
            })
            type === 0 ? (setFilteredBenchmarks(result.map(i => i.target))) : (setFilteredBenchmarks(result.map(i => i.obj)))
        } else {
            setFilteredBenchmarks([])
        }
    }

    //display benchmark
    const benchmarkListComponent = () => {
        const dataset = () => {
            if (filtered_benchmarks.length !== 0) {
                return filtered_benchmarks
            } else if (type === 0) {
                return findAllByKey(benchmark_data, 'options').slice(0, 100)
            } else {
                return public_fund_data.slice(0, 100)
            }
        }
        return (
            dataset().map(function (item, index) {
                return (
                    <div className="hover-fund" style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                        marginRight: "5px",
                        cursor: "pointer"
                    }}
                       onClick={() => addBenchmark(item, type===0 ? 'INDEX' : 'FUND')}>
                        {type === 0 ? (
                            <>
                                <p style={{marginTop: "10px", marginBottom: "10px"}}>{item}</p>
                            </>
                        ) : (
                            <div style={{paddingBottom: "20px"}}>
                                <p style={{fontSize: "12px", color: "#B5B4B9", margin: "0px"}}>{item.manager_name}</p>
                                <p style={{margin: "0px", marginBottom: "5px"}}>{item.fund_name}</p>
                                {item.ticker === '' ? (<></>) : (<Chip label={'Ticker: ' + item.ticker} style={{backgroundColor: "#f3f4f8",
                                    color: "#717682", marginRight: "10px", marginTop: "5px"}}/>)}
                                <Chip label={'# of Positions: ' + numWithCommas(item.numberOfPositions)} style={{backgroundColor: "#f3f4f8",
                                    color: "#717682", marginRight: "10px", marginTop: "5px"}}/>
                                <Chip label={'AUM: $' + millionNumWithCommas(item.aum)} style={{backgroundColor: "#f3f4f8",
                                    color: "#717682", marginTop: "5px"}}/>
                            </div>
                        )}
                    </div>
                )
            })
        )
    }


    //add + remove benchmark
    const addBenchmark = (benchmark, allocation_model_id) => {
        let exists = selections.find(el => (el[0] === benchmark && el[1] === allocation_model_id))
        if (!exists) {
            setSelection([...selections, [benchmark, allocation_model_id]])
        }
    };

    const removeBenchmark = (benchmark) => {
        setSelection(selections.filter(item => item !== benchmark))
    };

    //allocate
    const addAllocationPercentage = (pct, fund) => {
        let alloc_pct = parseInt(pct)
        if (isNaN(alloc_pct)) {
            alloc_pct = 0
        }
        let benchmark_obj = {'name': fund[0], 'type': fund[1], 'pct': alloc_pct}
        setAllocationModel([...allocationModel.filter(item => (item.name !== fund[0])), benchmark_obj])
    }

    const allocationTotal = () => {
        return allocationModel.map(item => item.pct).reduce((prev, next) => prev + next)
    }

    const readyToAllocate = () => {
        return allocationModel.length !== 0 && allocationTotal() === 100 && modelName !== '';
    }

    const allocateState = () => {
        return allocationModel.length !== 0 && allocationTotal() === 100;
    }

    const allocationError = (s) => {
        return (allocationModel.filter(i => i.name === s[0]).length !== 0 && (allocationModel.filter(i => i.name === s[0])[0]['pct'] > 100
                || allocationModel.filter(i => i.name === s[0])[0]['pct'] < 0))
    }

    const saveAllocationModel = async () => {
        const admin_info = await Auth.currentUserInfo()
        if (!allocation_models.map(i => i.name).includes(modelName)) {
            try {
                const model = await DataStore.save(
                    new AllocationModel({
                        "name": modelName,
                        "model": allocationModel.filter(i => i.pct !== 0),
                        "data": null,
                        "user_info": localStorage.getItem("signed-in-user"),
                        "admin_id": admin_info["id"]
                    })
                );
                handleClose();
                handleBenchmarkSelect(modelName, model['id'])
            } catch (error) {
                console.log(error);
            }
        } else {
            setError('Allocation Model Name Taken By Another Model')
        }

    }

    const [openDeleteError, setDeleteError] = React.useState(false);

    const handleSnackBarOpen = () => {
        setDeleteError(true);
    };
    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setDeleteError(false);
    };

    const deleteModel = async (id) => {
        const cannot_delete = custom_strategies.map(i => i['settings']['allocation_model_id']).includes(deleteAllocationModel[0])
        if (cannot_delete) {
            handleSnackBarOpen()
        } else {
            try {
                const model = await DataStore.query(AllocationModel, id);
                DataStore.delete(model)
                handleBenchmarkSelect('S&P 500', null)

            } catch (error) {
                console.log(error)
            }
        }
        setDeleteOpen(false)
    }

    const handleDeleteOpen = () => {
        setDeleteOpen(true)
    }

    //accordion state + handle
    const [expanded, setExpanded] = useState('0');
    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    //handle benchmark select
    const handleBenchmarkSelect = (benchmark_name, allocation_model_id) => {
        builder_state.setBenchmarkName(benchmark_name);
        builder_state.setAllocationModelId(allocation_model_id);
    }



    //create accordion from benchmark data
    const createBenchmarkDropDown = (benchmark_data, index) => {
        let logo = benchmark_data.logo
        let options = benchmark_data['options']
        return (
            <Accordion className="ExpansionPanel" square style={{boxShadow: "none"}}
                       expanded={expanded === index.toString()} onChange={handleAccordionChange(index.toString())}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    classes={{expanded: 'expanded'}}
                >
                    <img src={logo}
                         alt="logo" width="320" height="70"/>
                </AccordionSummary>
                {options.map(function (name, index) {
                    return (
                        <AccordionDetails className="ExpansionDetails" key={index}>
                            <Button style={{marginLeft: "20px", textTransform: "none"}}
                                    onClick={() => handleBenchmarkSelect(name, null)}>
                                <Typography style={name === benchmark_name ? ({fontWeight: 700}) : ({fontWeight: 500})}>
                                    {name}
                                </Typography>
                            </Button>
                        </AccordionDetails>
                    )
                })}
            </Accordion>
        )
    };

    const createCustomBenchmark = () => {
        return (
            <Accordion className="ExpansionPanel" square
                       style={{boxShadow: "none", marginBottom: "0px", marginTop: "0px", backgroundColor: "#F8F9FA"}}
                       expanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                >
                    <img src={firm_logo}
                         style={{marginLeft: "20px", maxHeight: "100px", maxWidth: "280px"}}
                         alt="logo" />
                </AccordionSummary>
                <Button style={{marginLeft: "20px", textTransform: "none", color: themeColor, marginBottom: "20px"}} onClick={() => userRoleActionWrapper(null, user_role,handleOpen)}>
                    <Typography>Create Allocation Model </Typography>
                </Button>
                {filtered_allocation_models.map(function (model, index) {
                    return (
                        <AccordionDetails className="ExpansionDetails" key={index}>
                            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                <Tooltip title={model.user_info.attributes.name}>
                                    <Button style={{marginLeft: "20px", textTransform: "none"}}
                                            onClick={() => handleBenchmarkSelect(model['name'], model['id'])}>
                                            <Typography style={model['name'] === benchmark_name ? ({fontWeight: 700}) : ({fontWeight: 500})}>
                                                {model['name']}
                                            </Typography>
                                    </Button>
                                </Tooltip>
                                <div style={{marginRight: "10px"}}>
                                    {!model.data ? (<div className="dot-flashing"/>): (
                                        <IconButton style={{padding: "0px"}} onClick={() => {userRoleActionWrapper(model.user_info, user_role, handleDeleteOpen); setDeleteAllocationModel([model.id, model.name])}}>
                                            <DeleteIcon style={{color: "grey"}}/>
                                        </IconButton>
                                    )}
                                </div>
                            </Grid>
                        </AccordionDetails>
                    )
                })}
            </Accordion>
        )
    }

    const createBenchmarkSelection = () => {
        return (
            <>
                <Grid item xs={5} style={{marginLeft: "20px", marginBottom: "20px"}}>
                    <Paper elevation={0} style={{height: "550px",border: "1px solid #dadce0",
                        borderRadius: "6px",width: "460px"}}>
                        <p style={card_title}>Investment Options</p>
                        <p style={card_subtitle}>Search Indexes or Funds</p>
                        <BottomNavigation showLabels value={type}
                                          style={{width: "450px",marginLeft: "2px",marginBottom: "5px"}}
                                          onChange={(event, type) => {
                                              setType(type);setFilteredBenchmarks([]);setSearch('')}}>
                            <BottomNavigationAction label="Indexes" icon={<TimelineIcon/>}/>
                            <BottomNavigationAction label="Funds" icon={<PeopleIcon/>}/>
                        </BottomNavigation>
                        <TextField variant="outlined" label="Search"
                                   style={{width: "415px", marginLeft: "20px"}} type="text"
                                   InputProps={{
                                       endAdornment: <InputAdornment position="start"><SearchIcon
                                           style={{color: "#909394"}}/></InputAdornment>,
                                   }}
                                   value={search}
                                   onChange={(e) => searchBenchmarks(e.target.value)}
                        />
                        <div style={{height: "345px",overflowY: "scroll",marginTop: "2px",marginLeft: "20px"}}>
                            {benchmarkListComponent()}
                        </div>
                    </Paper>
                </Grid>
                <ArrowForwardIosIcon
                    style={{marginLeft: "50px", marginTop: "250px", color: "grey"}}/>
                <Grid item xs={5} style={{marginLeft: "10px", marginBottom: "20px"}}>
                    <Paper elevation={0} style={{
                        height: "495px",
                        border: "1px solid #dadce0",
                        borderRadius: "6px",
                        width: "460px"
                    }}>
                        <p style={card_title}>Selections ({selections.length})</p>
                        <p style={card_subtitle}>Selected Indexes & Funds</p>
                        <div style={{overflowY: "scroll", height: "410px", marginTop: "10px"}}>
                            {selections.map((s, index) => {
                                return (
                                    <Grid container direction="row" justifyContent="center"
                                          alignItems="center">
                                        <Grid item xs={11}>
                                            <p key={index} style={{marginLeft: "20px",marginRight: "40px",
                                                fontSize: "14px",marginTop: "3px",marginBottom: "3px",paddingTop: "4px"}}>
                                                <Chip style={s[1] === 'INDEX' ?
                                                    ({marginRight: "5px",backgroundColor: "white",border: "1px solid black",
                                                        height: "20px",fontSize: "12px",width: "60px"})
                                                    : ({marginRight: "5px",backgroundColor: "grey",color: "white",
                                                        height: "20px",fontSize: "12px",width: "60px"})} label={s[1]}/>
                                                {s[1] === 'INDEX' ? (s[0]) : (s[0].fund_name)}
                                            </p>
                                        </Grid>
                                        <Grid item xs={1} style={{height: "20px"}}>
                                            <RemoveCircleIcon
                                                style={{
                                                    color: ipRed,
                                                    cursor: "pointer",
                                                    fontSize: "20px"
                                                }}
                                                onClick={() => removeBenchmark(s)}/>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </div>
                    </Paper>
                    <Button style={Object.assign({}, bordered_button,
                        {width: "460px",color: "white",backgroundColor: themeColor,marginTop: "10px",fontSize: "17px"})}
                            onClick={() => setAllocate(1)}>Next / Allocate
                        <NavigateNextIcon style={{position: "absolute", right: "40px"}}/>
                    </Button>
                </Grid>
            </>
        )
    }

    const createAllocationPage = () => {
        return (
            <div style={{marginLeft: "20px"}}>
                <Paper elevation={0} style={{height: "550px",border: "1px solid #dadce0",borderRadius: "6px",width: "960px"}}>
                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={9}>
                            <p style={card_title}>Allocate Indexes & Funds</p>
                            <p style={card_subtitle}>Create your custom portfolio by adjusting the
                                allocations of individual funds until they sum to 100%.</p>
                        </Grid>
                        <Grid item xs={3}>
                            <p style={Object.assign({}, card_title, !allocateState() ? {color: ipRed} : {color: "black"})}>
                                Total Allocation: {allocationModel.length !== 0 ? (allocationTotal() + '%') : ('0%')}
                            </p>
                        </Grid>
                    </Grid>
                    <div style={{height: "470px",overflowY: "scroll",marginTop: "2px",marginLeft: "20px"}}>
                        {selections.map((s, index) => {
                            return (
                                <Grid container direction="row" justifyContent="center" alignItems="center"
                                      style={index % 2 !== 0 ?
                                          ({marginBottom: "10px"}) :
                                          ({marginBottom: "10px",backgroundColor: themeBackground})}>
                                    <Grid item xs={10}>
                                        <p key={index} style={{marginLeft: "20px",marginRight: "40px",fontSize: "14px",
                                            marginTop: "5px",marginBottom: "5px",paddingTop: "4px",}}>
                                            <Chip style={s[1] === 'INDEX' ?
                                                ({marginRight: "5px",backgroundColor: "white",border: "1px solid black",
                                                    height: "20px",fontSize: "12px",width: "60px"}):
                                                ({marginRight: "5px",backgroundColor: "grey",color: "white",height: "20px",
                                                    fontSize: "12px",width: "60px"})} label={s[1]}/>
                                            {s[1] === 'INDEX' ? (s[0]) : (s[0].fund_name)}
                                        </p>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField style={{width: "90px",paddingTop: "10px",paddingBottom: "10px"}}
                                            error={allocationError(s)}
                                            type={"number"} defaultValue={0} id="filled-start-adornment" variant="outlined"
                                            onChange={(e) => addAllocationPercentage(e.target.value, s)}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">%</InputAdornment>}}/>
                                    </Grid>
                                </Grid>
                            )
                        })}
                        <Grid container direction="row" style={{marginBottom: "20px", marginTop: "20px"}}
                              alignItems="flex-start" justifyContent="space-between">
                            <Grid item xs={6}>
                                <TextField variant="outlined" margin="normal" required value={modelName} label="Allocation Model"
                                    type="text" style={{width: "420px",marginLeft: "20px",marginTop: "0px"}}
                                    inputProps={{maxLength: 30}}
                                    helperText={`${modelName.length}/30`}
                                    onChange={(e) => setModelName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    disabled={!readyToAllocate()}
                                    onClick={() => {window.Intercom('trackEvent', 'created-allocation-model');Mixpanel.track('Created Allocation Model');
                                        saveAllocationModel()}}
                                    style={{color: "white",backgroundColor: readyToAllocate() ? themeColor : "grey",
                                        marginBottom: "10px",textTransform: "none",width: "420px",fontSize: "17px",height: "55px"
                                    }}>Create Allocation Model</Button>

                            </Grid>
                            {error ? (<Grid item xs={12} style={{marginLeft: "20px", marginRight: "20px"}}>
                                <Alert severity="error">{error}</Alert>
                            </Grid>) : (
                                <></>
                            )
                            }
                        </Grid>
                    </div>
                </Paper>
                <Button startIcon={<NavigateBeforeIcon style={{marginRight: "-5px"}}/>}
                        onClick={() => {setAllocate(0);setAllocationModel([])}}
                        style={Object.assign({}, transparent_button, {marginLeft: "0px",marginBottom: "10px"})}>
                    Back
                </Button>
            </div>
        )
    }

    if (!benchmark_data) {
        return (
            <></>
        )
    }
    return (
        <>
            <Paper style={control_paper_left} elevation={0} square>
                <Grid container direction="row" justifyContent="center" square="true" className="ControlPanelTitle">
                    <p className="ControlPanelText">Choose Your Allocation Model</p>
                </Grid>
                {createCustomBenchmark()}
                <Grid container direction="row" justifyContent="center" square="true" className="ControlPanelTitle">
                    <p className="ControlPanelText">Or Select an Index</p>
                </Grid>
                {
                    Object.keys(benchmark_data).map((key, index) => {
                        return (
                            <div key={index}>
                                {createBenchmarkDropDown(benchmark_data[key], index)}
                            </div>
                        )
                    })
                }
            </Paper>
            <Snackbar open={openDeleteError} autoHideDuration={6000} onClose={handleSnackBarClose}>
                <Alert handleSnackBarClose={handleSnackBarClose} severity="error" variant="filled">
                    This allocation model is currently connected to a strategy, please delete strategy first.
                </Alert>
            </Snackbar>
            <Modal open={open} style={{overflow: "scroll"}}>
                <div style={{display: "flex", alignItems: "center", justifyContent: "center",}}>
                    <Paper elevation={0} style={{width: "1000px", height: "690px", marginTop: "300px"}}>
                        <Grid container direction="row">
                            <Grid item xs={10} style={{paddingTop: "25px"}}>
                                <p style={card_title}>Create Allocation Model</p>
                            </Grid>
                            <Grid item xs={2} style={{paddingRight: "20px", paddingTop: "20px"}}>
                                <Fab onClick={handleClose} style={{float: "right"}}>
                                    <ClearIcon/>
                                </Fab>
                            </Grid>
                            <div style={{width: "100%",marginTop: "10px",marginBottom: "10px"}}/>
                            {allocate === 0 ? (createBenchmarkSelection()) : (createAllocationPage())}
                        </Grid>
                    </Paper>
                </div>
            </Modal>
            <Modal
                open={deleteOpen}
                style={{overflow: "scroll"}}
            >
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Paper elevation={0} style={{
                        width: "600px", height: "600px", marginTop: "300px"
                    }}>
                        <Grid container direction="row">
                            <Grid item xs={10} style={{paddingTop: "25px"}}>
                                <></>
                            </Grid>
                            <Grid item xs={2} style={{paddingRight: "20px", paddingTop: "20px"}}>
                                <Fab onClick={() => {setDeleteOpen(false)}} style={{float: "right"}}>
                                    <ClearIcon/>
                                </Fab>
                            </Grid>
                            <Grid container justify = "center">
                                <HighlightOffOutlinedIcon style={{fontSize: "150px", color: ipRed}}/>
                            </Grid>
                            <Grid container justify = "center">
                                <p style={{fontSize: "30px"}}>Are you sure?</p>
                            </Grid>
                            <Grid container justify = "center">
                                <p style={{fontSize: "16px", paddingRight: "40px", paddingLeft: "40px", textAlign: "center", paddingBottom: "40px"}}>
                                    Do you really want to delete <span style={{fontWeight: "600"}}>
                                    {deleteAllocationModel ? (deleteAllocationModel[1]) : null}
                                </span> custom allocation model? This process cannot be undone.</p>
                            </Grid>
                            <Grid container justify = "center">
                                <Button style={Object.assign({}, bordered_button,
                                    {width: "100px",
                                        marginRight: "20px", fontSize:"16px"})}
                                        onClick={() => {setDeleteOpen(false)}}
                                >Cancel</Button>
                                <Button
                                    style={Object.assign({}, transparent_button, {backgroundColor: ipRed,
                                    color: "white", width: "100px", marginLeft: "20px",
                                    fontSize:"16px"})} onClick={() => {deleteModel(deleteAllocationModel[0])}}>Delete</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </Modal>
        </>
    )
}

export default observer(IndexSelector);