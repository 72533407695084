import React, {useEffect, useState} from "react";
import {Grid, Typography, Paper, Button} from "@material-ui/core";
import {observer} from "mobx-react-lite";
import {
    control_paper_left,
    big_paper,
    card_title,
    card_subtitle,
    bordered_button,
    card_subtitle_black,
    themeBackground,
    themeColor,
    simple_text_bold,
    simple_text,
    form_button,
    noborder_button,
    transparent_button
} from "../../Styles/styles";
import GetInTouch from "./builder-journey";
import {DataStore} from "@aws-amplify/datastore";
import {PreConfiguredValues} from "../../models";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Modal from '@material-ui/core/Modal';
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from '@material-ui/icons/Check';

function PreConfiguredValuesComp({builder_state}) {

    //value data + handlers
    const values_data = builder_state.values_data
    const apply_your_values = builder_state.apply_your_values
    const esg_value = builder_state.esg_value
    const esg_implementation = builder_state.esg_implementation === 'Exclude'
    const handleValueSelect = (name) => {
        builder_state.setESGValue(name);
    }
    const handleApplyValuesButton = (event) => {
        builder_state.setApplyYourValues(event.target.checked);
    };
    const handleESGImplementationButton = (event) => {
        let val = event.target.checked ? 'Exclude' : 'Tilt'
        builder_state.setESGImplementation(val);
    };


    const [preConfigValues, setPreConfigValues] = useState([]);


    //expansion panel
    const [expanded, setExpanded] = useState('0');
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        window.scrollTo(0, 0)
        let getValuesData = async () => {
            try {
                const values_data = await DataStore.query(PreConfiguredValues);
                values_data.sort(function (a, b) {
                    return a.order - b.order
                })
                await setPreConfigValues(values_data)
            } catch (error) {
                console.error('error:', error)
            }
        }
        getValuesData();
    }, []);

    const [open, setOpen] = React.useState(false);

    const handleOpen = (item) => {
        setItem(item)
        setOpen(true);
    };

    const handleClose = () => {
        setItem({})
        setOpen(false);
    };

    const [clickeditem, setItem] = useState({});


    const addCoreOptions = (value) => {
        builder_state.setCoreOptions(value)
    }

    const removeCoreOptions = (value) => {
        builder_state.removeCoreOptions(value)
    }

    const fabState = (title) => {
        if (!builder_state.core_options.has(title)) {
            return (
                <Fab size="small" style={{marginTop: "-20px", marginLeft: "10px", backgroundColor: "lightgrey"}}
                     onClick={() => addCoreOptions(title)}>
                    <AddIcon/>
                </Fab>
            )
        } else if (builder_state.core_options.has(title)) {
            return (
                <Fab size="small" style={{marginTop: "-20px", marginLeft: "10px", backgroundColor: themeColor}}
                     onClick={() => removeCoreOptions(title)}>
                    <CheckIcon style={{color: "white"}}/>
                </Fab>
            )
        }
    }

    const createPreConfigValuesContainer = () => {
        return (
            <Grid container direction="row" justifyContent="space-evenly" style={{marginTop: "50px"}}>
                {preConfigValues.map(function (item, index) {
                    return (
                        <Grid item style={{
                            height: "400px",
                            width: "400px",
                            marginTop: "0px"
                        }}>
                            <Grid item xs={12}>
                                <div class="wrapper" style={{height: "300px"}} onClick={() => handleOpen(item)}>

                                    <img src={item.logo_url.split("/upload/").join("/upload/w_400,c_scale/q_auto/")}
                                         alt="logo" width="400" height="300"/>
                                    <div class="overlay">
                                        <div class="content"><p>Learn More</p></div>
                                    </div>
                                </div>
                                {fabState(item.title)}
                            </Grid>
                            <Grid item xs={12}>
                                <p style={Object.assign({}, card_title, {marginLeft: "0px"})}>{item.title}</p>
                            </Grid>
                            <Grid item xs={12}>
                                <p style={Object.assign({}, card_subtitle, {marginLeft: "0px"})}>{item.short_description}</p>
                            </Grid>
                        </Grid>

                    )
                })}
                <Modal
                    open={open}
                    style={{overflow: "scroll"}}
                >
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Paper elevation={0} style={{
                            width: "1278px", height: "600px", marginTop: "300px"
                        }}>
                            <div style={{float: "right"}}>
                                <img src={clickeditem.logo_url}
                                     alt="logo" width="800" height="600"/>
                            </div>
                            <div style={{float: "left"}}>
                                <p style={Object.assign({}, simple_text_bold, {
                                    marginLeft: "50px",
                                    marginTop: "100px",
                                    fontSize: "25px"
                                })}>{clickeditem.title}</p>
                            </div>
                            <div style={{position: "relative", top: "20px", left: "780px"}}>
                                <Fab onClick={handleClose} style={{float: "right"}}>
                                    <ClearIcon/>
                                </Fab>
                            </div>
                            <div style={{
                                position: "relative",
                                top: "0px",
                                height: "200px",
                                width: "425px",
                                border: "1px solid transparent",
                                marginTop: "150px"
                            }}>
                                <p style={Object.assign({}, simple_text, {
                                    marginLeft: "50px",
                                    marginTop: "20px",
                                    fontSize: "15px",
                                    lineHeight: 2.3
                                })}>{clickeditem.long_description}</p>
                            </div>
                            {!builder_state.core_options.has(clickeditem.title) ? (
                                <div style={{position: "relative", top: "100px", left: "80px"}}>
                                    <Button style={Object.assign({}, noborder_button, {
                                        backgroundColor: 'black',
                                        color: "white",
                                        fontSize: "16px",
                                        width: "300px"
                                    })}
                                            onClick={() => {
                                                addCoreOptions(clickeditem.title);
                                                handleClose();
                                            }}>Add to Portfolio</Button>
                                </div>
                            ) : (
                                <div style={{position: "relative", top: "100px", left: "80px"}}>
                                    <Button style={Object.assign({}, noborder_button, {
                                        backgroundColor: themeColor,
                                        color: "white",
                                        fontSize: "16px",
                                        width: "300px"
                                    })}
                                            onClick={() => {
                                                removeCoreOptions(clickeditem.title);
                                                handleClose();
                                            }}>Remove from Portfolio</Button>
                                </div>
                            )}

                            <div style={{position: "relative", top: "115px", left: "160px"}}>
                                <Button style={Object.assign({}, transparent_button, {
                                    fontWeight: "600",
                                    textDecoration: "underline",
                                    color: "black",
                                    fontSize: "16px"
                                })}
                                        onClick={() => handleClose()}
                                >Back to Values</Button>
                            </div>
                        </Paper>
                    </div>
                </Modal>
            </Grid>
        )
    };

    return (
        <Paper style={big_paper} elevation={0} square>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <div item>
                    <p style={card_title}>Core Options</p>
                </div>
            </Grid>
            {createPreConfigValuesContainer()}
        </Paper>
    )
}

export default observer(PreConfiguredValuesComp);