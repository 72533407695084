import {Grid} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import {ipRed} from "../../Styles/styles";
import React from "react";
import {superScript, TaxStartingNumber} from "../../Utils/compliance_utils";

export const TaxImpact = (type, headers, page_number, tax_strategy, transition_management, transition_data) => {
    return (
        <div style={{height: "100%", position: "relative", color: "black", fontDisplay: "swap"}}>
            <div style={{
                position: "absolute",
                top: "54px",
                right: "60px",
                fontSize: "14px",
                fontWeight: "600",
                color: "black"
            }}>{page_number}
            </div>
            <Grid container justifyContent="flex-start" style={{
                position: "absolute", top: "40px", left: "60px",
                fontSize: "14px", color: "#c2c2c1", fontWeight: "600", width: "800px"
            }}>
                <p style={{marginRight: "25px"}}>Portfolio</p>
                {headers.map(title => {
                    if (title === type) {
                        return (
                            <p style={{marginRight: "25px", color: "#009494"}}>{title}</p>
                        )
                    }
                    else {
                        return(
                            <p style={{marginRight: "25px"}}>{title}</p>
                        )
                    }
                })}
            </Grid>
            <div style={{
                position: "absolute",
                top: "140px",
                left: "60px",
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: 0.96
            }}>Tax Strategy<br/>& Preferences
            </div>
            <div style={{
                position: "absolute", top: "400px", marginLeft: "60px", marginRight: "60px",
                width: "755px", fontSize: "14px", fontWeight: "600", borderBottom: "1px solid black"
            }}>
                TAX LOSS HARVESTING EXPLAINED
            </div>
            <div style={{
                position: "absolute", top: "440px", marginLeft: "60px", marginRight: "60px",
                width: "755px"
            }}>
                <img
                    src="https://res.cloudinary.com/indexpro/image/upload/v1644428818/Ilustrations_Index_Pro_report_2_fidjyu.svg"
                    width="755px"/>
            </div>
            <div style={{
                position: "absolute", top: "240px", marginLeft: "60px", marginRight: "60px",
                width: "755px", fontSize: "14px", fontWeight: "600", borderBottom: "1px solid black"
            }}>
                TAX TREATMENTS & SELECTIONS
            </div>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" alignContent="center"
                  style={{
                      position: "absolute", top: "290px", marginLeft: "60px", fontSize: "16px", fontWeight: "600",
                      width: "755px"
                  }}>
                <Grid alignItems="center" alignContent="center">
                    {tax_strategy ?  
                        (<><Fab size="small" style={{backgroundColor: "lightgrey", marginRight: "20px", float: "left"}}>
                                <CheckIcon style={{color: "#009494"}}/>
                            </Fab>
                                <p style={{float: "left", marginTop: "8px"}}>{tax_strategy}</p></>
                        )
                        :
                        (<><Fab size="small" style={{backgroundColor: "lightgrey", marginRight: "20px", float: "left"}}>
                            <ClearIcon style={{color: ipRed}}/>
                        </Fab>
                            <p style={{float: "left", marginTop: "8px"}}>No Tax Strategy</p></>)
                    }
                </Grid>
            </Grid>
            <div style={{
                position: "absolute", top: "720px", marginLeft: "60px", marginRight: "60px",
                width: "755px", fontSize: "14px", fontWeight: "600", borderBottom: "1px solid black"
            }}>
                EXPECTED POTENTIAL IMPACT
            </div>
            <Grid container direction="row" justifyContent="space-between" style={{position:"absolute", top: "760px", marginLeft: "60px", marginRight: "60px",
                width: "755px", fontSize: "16px", fontWeight: "500"}}>
                {[
                    {'text': <>Potential average increase in after-tax returns {superScript(TaxStartingNumber(page_number, transition_data, headers))}</>, 'icon': 'https://res.cloudinary.com/indexpro/image/upload/v1648761332/tax-performance-increase_l2vrks.svg'},
                    {'text': 'Fully integrated with custom value, theme and active alpha preferences', 'icon':'https://res.cloudinary.com/indexpro/image/upload/v1649101419/integration_tyvdqn.svg'},
                    {'text': 'Daily monitoring for tax opportunities', 'icon':'https://res.cloudinary.com/indexpro/image/upload/v1648761332/tax-daily-monitoring_sauvru.svg'}].map((item, i) => (
                    <div style={{
                        backgroundColor: "#f6f7f8",
                        width: "241px",
                        height: "241px",
                        color: "black",
                        textAlign: "center",
                    }}>
                        <img src={item.icon} height="50px"
                             style={{marginTop: "40px", textAlign: "center", maxWidth: "95px"}}/>
                        <p style={{
                            fontSize: "12px",
                            textAlign: "center",
                            fontWeight: "500",
                            marginTop: "14px",
                            marginLeft: "10px",
                            marginRight: "10px",
                            color: "#555555"
                        }}>{item.text}</p>
                    </div>
                ))}
            </Grid>
            <div style={{
                position: "absolute", bottom: "20px", marginLeft: "60px", marginRight: "60px",
                width: "755px", fontSize: "10px", fontWeight: "600"
            }}>
                Atom does not provide tax or legal advice. Tax-related statements are based on Atom's understanding of the tax
                laws. Investors should seek advice of their independent legal and tax counsel before investing.
            </div>
        </div>
    )
}
