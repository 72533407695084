import React from "react";

export const PortfolioDetailsTitlePage = () => {
    return (
        <div style={{height: "100%", position: "relative", color: "white", fontDisplay: "swap"}}>
            <img
                src="https://res.cloudinary.com/indexpro/image/upload/v1652891441/Proposal/Portfolio%20Details%20Title%20Page.jpg"
                width="100%" height="100%"/>
            <div style={{
                position: "absolute",
                top: "220px",
                left: "60px",
                fontSize: "86px",
                fontWeight: "700",
                lineHeight: 1
            }}>
                PORTFOLIO DETAILS
            </div>
        </div>
    )
}