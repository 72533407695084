import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid"
import Paper from '@material-ui/core/Paper';
import {
    bordered_button,
    form_button,
    transparent_button,
    onboarding_paper,
    card_title,
    card_subtitle,
    themeColor,
    tab_label, ChromeAutoFillOverride
} from "../../Styles/styles";
import './add-account.css'
import {DataStore} from '@aws-amplify/datastore';
import {CustodianMaster, User} from '../../models';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import {useHistory} from "react-router-dom";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {Client, Account} from '../../models';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LinearProgress from '@material-ui/core/LinearProgress';
import {useUserRole} from "../Hooks/Data/User/user";
import {userRoleActionWrapper} from "../../Utils/role_utils";
import {Mixpanel} from "../../Utils/Mixpanel";

function AddAccount() {
    const history = useHistory();

    const [step, setStep] = React.useState(1)
    const [error, setError] = React.useState(null)
    const [submitError, setSubmitError] = React.useState(null)
    const [account_data, setAccountData] = React.useState(
        {
            account_type: null,
            account_number: null,
            legal_name: null,
            custodian: '',
            client_id: null,
            client_name: null,
            data: null
        })

    const [client_data, setClientData] = React.useState(
        {
            client_type: null,
            first_name: null,
            last_name: null,
            legal_name: null,
            address: null,
            city: null,
            state: null,
            zip_code: null,
            email: null
        })
    const [custodian_options, setCustodianOptions] = React.useState([])
    const [client_options, setClientOptions] = React.useState([])
    const [clients, setClients] = React.useState([])
    const user_role = useUserRole()

    useEffect(() => {
        let getCustodianOptions = async () => {
            try {
                const custodian_data = await DataStore.query(CustodianMaster);
                var options = custodian_data.map(a => a.custodian_name);
                setCustodianOptions(options.concat(['Other']))
            } catch (error) {
                console.error('error:', error)
            }
        }
        getCustodianOptions();
        let getClients = async () => {
            try {
                const client_data = await DataStore.query(Client);
                setClients(client_data)
                var options = client_data.map(a => a.legal_name);
                setClientOptions(options)
            } catch (error) {
                console.error('error:', error)
            }
        }
        getClients();
    }, []);

    const next_step = () => {
        setStep(step + 1)
    }

    const back = () => {
        setError(null)
        setStep(step -1)
    }
    const backAccounts = () => {
        setSubmitError(null)
        setStep(step -1)
    }


    const submitNewClient = () => {
        if ([client_data.first_name, client_data.last_name, client_data.legal_name
        ].some(element => element === null)) {
            setError('Not All Required Fields are Filled Out')
        } else {
            handleAccountSelect(client_data.first_name + ' ' + client_data.last_name, 'legal_name')
            setStep(step + 1)
        }
    }

    const handleAccountInputChange = (event, keyName) => {
        event.persist();
        setAccountData((account_data) => {
            return {...account_data, [keyName]: event.target.value}
        })
    }

    const handleAccountSelect = (val, keyName) => {
        setAccountData((account_data) => {
            return {...account_data, [keyName]: val}
        })
    }

    const handleClientInputChange = (event, keyName) => {
        event.persist();
        setClientData((client_data) => {
            return {...client_data, [keyName]: event.target.value}
        })
    }


    const handleAccountButtonClick = (value, keyName) => {
        setAccountData((account_data) => {
            return {...account_data, [keyName]: value}
        })
        next_step()
    }
    const handleClientButtonClick = (value, keyName) => {
        setClientData((client_data) => {
            return {...client_data, [keyName]: value}
        })
        next_step()
    }


    const step1 = () => {

        return (
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                {error ? (<Grid item xs={12} style={{marginLeft: "20px", marginRight: "20px"}}>
                    <Alert severity="error">{error}</Alert>
                </Grid>) : (
                    <></>
                )
                }
                <Grid item xs={12}>
                    <p style={card_title}>New client information:</p>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        label="First Name"
                        type="text"
                        autoFocus
                        value={client_data.first_name}
                        style={{
                            backgroundColor: "#F5F8FA",
                            width: "400px",
                            marginLeft: "40px",
                            marginTop: "40px"
                        }}
                        inputProps={{style: ChromeAutoFillOverride}}
                        onChange={(e) => handleClientInputChange(e, 'first_name')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        label="Last Name"
                        type="text"
                        value={client_data.last_name}
                        autoFocus
                        style={{
                            backgroundColor: "#F5F8FA",
                            width: "400px",
                            marginLeft: "40px",
                        }}
                        inputProps={{style: ChromeAutoFillOverride}}
                        onChange={(e) => handleClientInputChange(e, 'last_name')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        label="Legal Name (Firm Name if not an individual)"
                        type="text"
                        value={client_data.legal_name}
                        autoFocus
                        style={{
                            backgroundColor: "#F5F8FA",
                            width: "400px",
                            marginLeft: "40px",
                        }}
                        inputProps={{style: ChromeAutoFillOverride}}
                        onChange={(e) => handleClientInputChange(e, 'legal_name')}
                    />
                </Grid>
                <Grid item xs={12} style={{marginTop: "20px", marginBottom: "40px"}}>
                    <Button endIcon={<ArrowForwardIcon/>}
                            onClick={() => submitNewClient()}
                            style={Object.assign({}, bordered_button,
                                {
                                    marginLeft: "40px",
                                    width: "200px",
                                    backgroundColor: themeColor,
                                    color: "white"
                                })}>
                        Next
                    </Button>
                </Grid>
            </Grid>
        )
    }

    const step2 = () => {
        return (
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item xs={12}>
                    <p style={card_title}>Please select an account type:</p>
                </Grid>
                <Grid item xs={12} style={{marginTop: "40px", marginLeft: "40px"}}>
                    <Button style={form_button} onClick={() => handleAccountButtonClick('Sole Owner', 'account_type')}>Sole
                        Owner</Button>
                </Grid>
                <Grid item xs={12} style={{marginTop: "20px", marginLeft: "40px"}}>
                    <Button style={form_button} onClick={() => handleAccountButtonClick('Joint Owner', 'account_type')}>Joint
                        Owner</Button>
                </Grid>
                <Grid item xs={12} style={{marginTop: "20px", marginLeft: "40px"}}>
                    <Button style={form_button}
                            onClick={() => handleAccountButtonClick('IRA', 'account_type')}>IRA</Button>
                </Grid>
                <Grid item xs={12} style={{marginTop: "20px", marginLeft: "20px"}}>
                    <Button startIcon={<NavigateBeforeIcon style={{marginRight: "-5px"}}/>}
                            onClick={() => setStep(step - 1)}
                            style={transparent_button}>
                        Back
                    </Button>
                </Grid>
            </Grid>
        )
    }

    const step3 = () => {
        return (
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                {submitError ? (<Grid item xs={12} style={{marginLeft: "20px", marginRight: "20px"}}>
                    <Alert severity="error">{submitError}</Alert>
                </Grid>) : (
                    <></>
                )
                }
                <Grid item xs={12}>
                    <p style={card_title}>Account Information:</p>
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        options={custodian_options}
                        value={account_data.custodian}
                        style={{
                            width: "400px",
                            marginLeft: "40px",
                        }}
                        onChange={(option, value)=>handleAccountSelect(value, 'custodian')}
                        margin="normal"
                        renderInput={(params) => <TextField
                            style={{
                                backgroundColor: "#F5F8FA",
                            }}
                            margin="normal"
                            {...params} label="Custodian" variant="outlined" required
                        />}
                    />
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        label="Legal Name"
                        type="text"
                        autoFocus
                        required
                        value={account_data.legal_name}
                        style={{
                            backgroundColor: "#F5F8FA",
                            width: "400px",
                            marginLeft: "90px",
                        }}
                        inputProps={{style: ChromeAutoFillOverride}}
                        onChange={(e) => handleAccountInputChange(e, 'legal_name')}
                    />
                </Grid>
                <Grid item xs={6} style={{marginTop: "20px", marginBottom: "40px"}}>
                    <Button startIcon={<NavigateBeforeIcon style={{marginRight: "-5px"}}/>}
                            onClick={() => backAccounts()}
                            style={Object.assign({}, transparent_button, {marginLeft: "40px"})}>
                        Back
                    </Button>
                </Grid>
                <Grid item xs={6} style={{marginTop: "20px", marginBottom: "40px"}}>
                    <Button endIcon={<ArrowForwardIcon/>}
                            onClick={() => userRoleActionWrapper(null, user_role,saveClientAccountData)}
                            style={Object.assign({}, bordered_button,
                                {marginLeft: "80px", width: "200px", backgroundColor: themeColor, color: "white"})}>
                        Submit
                    </Button>
                </Grid>
            </Grid>
        )
    }


    const saveClientAccountData = async () => {
        if ([account_data.legal_name, account_data.custodian].some(element => element === null) || account_data.custodian === '') {
            setSubmitError('Not All Required Fields are Filled Out')
        } else {
            try {
                const post = await DataStore.save(
                    new Client({
                        "last_name": client_data.last_name,
                        "first_name": client_data.first_name,
                        "legal_name": client_data.legal_name,
                        "address": client_data.address,
                        "state": client_data.state,
                        "city": client_data.city,
                        "zip_code": client_data.zip_code,
                        "email": client_data.email,
                        "user_info": localStorage.getItem("signed-in-user")
                    })
                );
                await DataStore.save(
                    new Account({
                        "account_type": account_data.account_type,
                        "legal_name": account_data.legal_name,
                        "custodian": account_data.custodian,
                        "account_number": account_data.account_number,
                        "clientID": post.id,
                        "data": account_data.data,
                        "user_info": localStorage.getItem("signed-in-user"),
                        "transition": null
                    })
                );
                Mixpanel.track('Created Account');
                window.Intercom('trackEvent', 'created-account');
                history.push('/participant/accounts')
            } catch (error) {
                console.error(error);
            }
        }
    }
    return (
        <div>
            <div className="AddAccountContainer">
                <div className="AddAccount">
                    <Paper style={onboarding_paper}>
                        <Grid>
                            <LinearProgress
                                variant="determinate"
                                value={step/3 * 100}
                                style={{width: "100%"}}/>
                        </Grid>
                        <div>
                            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                <div>
                                    <p style={card_title}>Add Account</p>
                                    <p style={card_subtitle}>Index Pro Accounts</p>
                                </div>
                                {step === 1 && step1()}
                                {step === 2 && step2()}
                                {step === 3 && step3()}
                            </Grid>
                        </div>
                    </Paper>
                </div>
                <div style={{height: "30px"}}/>
            </div>
        </div>
    );
}

export default AddAccount