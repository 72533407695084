import {Route, Switch, Redirect, useHistory, useLocation} from "react-router-dom";
import Dashboard from "../Components/Dashboard/dashboard";
import Builder from "../Components/Builder/builder";
import Accounts from "../Components/Accounts/accounts";
import React from "react";
import AccountSettings from "../Components/AccountSettings/account-settings";
import AddAccount from "../Components/AddAccount/add-account";
import StrategyComp from "../Components/Strategies/strategies";
import StrategyInfo from "../Components/Strategies/strategy-info";
import AccountInfo from "../Components/Accounts/account-info";
import Page404 from "../Components/404";
import IdleTimer from 'react-idle-timer';
import {DataStore} from "@aws-amplify/datastore";
import {Auth} from "aws-amplify";
import {Button, Grid, Modal, Paper} from "@material-ui/core";
import {ipRed, transparent_button, bordered_button, themeColor, timeout_icon} from "../Styles/styles";
import BuilderState from "../Components/Builder/state-manager";
import ReactGA from "react-ga";
import HelpCenter from "../Components/HelpCenter/help-center";
import {Mixpanel} from "../Utils/Mixpanel";

const builder_state = new BuilderState()

function Routes() {
    const history = useHistory();

    const [timeout, setTimeout] = React.useState(480000);
    const [showModal, setShowModal] = React.useState(false);
    const [isTimedOut, setIsTimedOut] = React.useState(false);

    let idleTimer = null

    const onAction = (e) => {
        setIsTimedOut(false)
    }

    const onActive = (e) => {
        setIsTimedOut(false)
    }

    const signOut = async () => {
        try {

            window.Intercom("shutdown");
            Mixpanel.track('Auto Logout');
            localStorage.removeItem("signed-in-user")
            await Auth.signOut();
            history.push('/signin')


        } catch (error) {
            console.error('error', error);
        }
    }

    const onIdle = (e) => {
        if (isTimedOut) {
            signOut()
        } else {
            setShowModal(true)
            idleTimer.reset()
            setIsTimedOut(true)
        }
    }

    const TRACKING_ID = "UA-240852490-1";
    ReactGA.initialize(TRACKING_ID);

    const location = useLocation();

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [location]);

    return (
        <>
            <IdleTimer
                ref={ref => {
                    idleTimer = ref
                }}
                element={document}
                onActive={(e) => onActive(e)}
                onIdle={(e) => onIdle(e)}
                onAction={(e) => onAction(e)}
                debounce={250}
                timeout={timeout}/>
            <div>
                <Switch>
                    <Route exact path="/participant/dashboard">
                        <Dashboard/>
                    </Route>
                    <Route exact path="/participant/builder">
                        <Builder builder_state={builder_state}/>
                    </Route>
                    <Route exact path="/participant/accounts">
                        <Accounts/>
                    </Route>
                    <Route path="/participant/accounts/:account_id">
                        <AccountInfo/>
                    </Route>
                    <Route exact path="/participant/strategies">
                        <StrategyComp builder_state={builder_state}/>
                    </Route>
                    <Route path="/participant/strategies/:type/:id">
                        <StrategyInfo builder_state={builder_state}/>
                    </Route>
                    <Route exact path="/participant/account-settings">
                        <AccountSettings/>
                    </Route>
                    <Route exact path="/participant/help-center">
                        <HelpCenter/>
                    </Route>
                    <Route exact path="/participant/add-account">
                        <AddAccount/>
                    </Route>
                    <Route exact path="/participant">
                        <Redirect to="/participant/dashboard"/>
                    </Route>
                    <Route path="/*">
                        <Page404/>
                    </Route>
                </Switch>
            </div>
            <Modal open={showModal}>
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Paper elevation={0} style={{
                        width: "500px", height: "400px", marginTop: "300px"
                    }}>
                        <Grid container direction="row">
                            <Grid container justify="center">
                                <img src={timeout_icon} style={{height: "100px", marginTop: "20px"}}/>
                            </Grid>
                            <Grid container justify="center" style={{marginTop: "20px"}} alignItems="flex-end">
                                <p style={{fontSize: "18px", marginBottom: "0px", fontWeight: "600"}}>You're being timed out due to
                                    inactivity.</p>
                            </Grid>
                            <Grid container justify="center" style={{marginTop: "10px"}}>
                                <p style={{fontSize: "15px"}}>Please click "Continue" to stay logged in.</p>
                                <p style={{fontSize: "15px", marginTop: "0px"}}>Otherwise, you will be logged off
                                    automatically.</p>
                            </Grid>
                            <Grid container justify="center" style={{marginTop: "50px"}}>
                                <Button style={Object.assign({}, transparent_button,
                                    {
                                        backgroundColor: themeColor, color: "white", width: "100px",
                                        marginRight: "20px", fontSize: "16px"
                                    })} onClick={() => setShowModal(false)}
                                >Continue</Button>
                                <Button style={Object.assign({}, bordered_button, {
                                    width: "100px", marginLeft: "20px",
                                    fontSize: "16px"
                                })} onClick={() => signOut()}>
                                    Log Off
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </Modal>
        </>
    )
}


export default Routes;