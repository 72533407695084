import {Grid, Paper} from "@material-ui/core";
import {big_paper, card_subtitle, card_title, HeaderCell, IPTableRow, ValueCell} from "../../Styles/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Avatar from "@material-ui/core/Avatar";
import {dollarsWithCommas, percentage} from "../../Utils/num_utils";
import React from "react";

function AccountHoldings(props) {
    return (
        <div className="Holdings">
            <Paper style={big_paper} elevation={0}>
                <div>
                    <p style={card_title}>Portfolio</p>
                    <p style={card_subtitle}>Top 10 Holdings</p>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <HeaderCell>Company Name</HeaderCell>
                                    <HeaderCell align="left">Ticker</HeaderCell>
                                    <HeaderCell align="right">Market Value</HeaderCell>
                                    <HeaderCell align="right">Weight</HeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.account.data['Account Holdings'].map((row) => (
                                    <IPTableRow>
                                        <ValueCell>
                                            <div>
                                                <Grid container direction="row" alignItems="center"
                                                      justifyContent="flex-start">
                                                    <Avatar style={{height: "35px", width: "35px"}}
                                                            src={"https://universal.hellopublic.com/companyLogos/" +
                                                            row.ticker
                                                            + "@2x.png?v=1b72d14c-4115-4bac-ab09-487a2ffb364e"}>
                                                        {row.ticker}
                                                    </Avatar>
                                                    <div style={{marginLeft: "15px"}}>
                                                        {row.company_name}
                                                    </div>
                                                </Grid>
                                            </div>
                                        </ValueCell>
                                        <ValueCell align="left">{row.ticker}</ValueCell>
                                        <ValueCell
                                            align="right">{dollarsWithCommas(Math.round(row.MarketValue))}</ValueCell>
                                        <ValueCell
                                            align="right">{percentage(row.MarketValue / props.account.data['Total Balance'] * 100)}</ValueCell>
                                    </IPTableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Paper>
        </div>
    )
}

export default AccountHoldings