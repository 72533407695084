import Paper from "@material-ui/core/Paper";
import {
    big_paper, bordered_button,
    card_subtitle,
    card_title,
    ChromeAutoFillOverride,
    simple_text,
    themeColor
} from "../../Styles/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {DropzoneArea} from "material-ui-dropzone";
import {Editor} from "react-draft-wysiwyg";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import React, {useEffect} from "react";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {ContentState, convertFromHTML, convertToRaw, EditorState} from "draft-js";
import {DataStore} from "@aws-amplify/datastore";
import {User} from "../../models";
import {Storage} from "@aws-amplify/storage";
import draftToHtml from "draftjs-to-html";
import Modal from "@material-ui/core/Modal";
import Cropper from "react-cropper";
import CropIcon from "@material-ui/icons/Crop";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "cropperjs/dist/cropper.css";
import {formatPhoneNumber} from "../../Utils/num_utils";
import {Mixpanel} from "../../Utils/Mixpanel";
import FormHelperText from '@material-ui/core/FormHelperText';


//styles
const formStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundColor:
        theme.palette.background.default,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: "40px"
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
        paddingTop: "20px"
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        width: "250px"
    },
}));


function UserProfile() {

    const classes = formStyles();
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());

    //photo state
    const [profilePhoto, setProfilePhoto] = React.useState();
    const [filePreview, setFilePreview] = React.useState();
    const [cropData, setCropData] = React.useState();
    const [cropper, setCropper] = React.useState();
    const [open, setOpen] = React.useState(false);

    const [personalData, setPersonalData] = React.useState({
        first_name: null,
        middle_name: null,
        last_name: null,
        title: null,
        email: null,
        user_role: null,
        user_status: null,
        cognito_id: null,
        phone_number: null,
        photo: null,
        bio: null,
    })

    const [edit, setEdit] = React.useState(0)

    //on change method
    const handleInputChange = (event, keyName) => {
        event.persist();
        setPersonalData((user) => {
            return {...user, [keyName]: event.target.value}
        })
    }


    const handleUserData = (val, keyName) => {
        setPersonalData((user) => {
            return {...user, [keyName]: val}
        })
    }


    const fetchUserData = async () => {
        const all = await DataStore.query(User);
        let data = all.filter(obj => obj.user_info.id === JSON.parse(localStorage.getItem('signed-in-user')).id)
        const user = JSON.parse(localStorage.getItem("signed-in-user"))
        handleUserData(user.attributes.username, 'cognito_id')
        if (data[0]) {
            await setPersonalData(data[0])
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(data[0]['bio']))))
            try {
                const result = await Storage.list('profile_photo/', {
                    level: 'private'
                });
                let key = result.filter(i => i.key.startsWith('profile_photo/' + JSON.parse(localStorage.getItem('signed-in-user')).id))[0].key
                const photo = await Storage.get(key, {
                    level: 'private'
                });
                handleUserData(photo, 'photo')
            } catch (error) {
            }
        } else {
            handleUserData(user.attributes.email, 'email')
            handleUserData(user.attributes.name.split(' ')[0], 'first_name')
            handleUserData(user.attributes.name.split(' ')[1], 'last_name')
        }

    };


    const saveUserData = async () => {
        if (edit) {
            setEdit(!edit)
            if (profilePhoto.length !== 0) {
                const blob = await (await fetch(cropData)).blob();
                try {
                    await Storage.put(`profile_photo/${JSON.parse(localStorage.getItem('signed-in-user')).id}.jpg`, blob, {
                        level: 'private',
                    });
                } catch (error) {
                    console.error(error)
                }
                const result = await Storage.list('profile_photo/', {
                    level: 'private'
                });
                let key = result.filter(i => i.key.startsWith('profile_photo/' + JSON.parse(localStorage.getItem('signed-in-user')).id))[0].key
                const photo = await Storage.get(key, {
                    level: 'private'
                });
                handleUserData(photo, 'photo')
            }
            try {
                Mixpanel.track('Updated User Profile');
                const all = await DataStore.query(User); //think about passing in ID from cognito ID
                let original = all.filter(obj => obj.user_info.id === JSON.parse(localStorage.getItem('signed-in-user')).id)
                if (original[0]) {
                    handleUserData(draftToHtml(convertToRaw(editorState.getCurrentContent())), 'bio')
                    await DataStore.save(User.copyOf(original[0], item => {
                        item.first_name = personalData.first_name
                        item.middle_name = personalData.middle_name
                        item.last_name = personalData.last_name
                        item.title = personalData.title
                        item.email = personalData.email
                        item.user_role = personalData.user_role
                        item.user_status = personalData.user_status
                        item.phone_number = personalData.phone_number
                        item.user_info = localStorage.getItem("signed-in-user")
                        item.bio = draftToHtml(convertToRaw(editorState.getCurrentContent()))
                    }));
                } else {
                    handleUserData(draftToHtml(convertToRaw(editorState.getCurrentContent())), 'bio')
                    await DataStore.save(
                        new User({
                            "first_name": personalData.first_name,
                            "middle_name": personalData.middle_name,
                            "last_name": personalData.last_name,
                            "title": personalData.title,
                            "email": personalData.email,
                            "user_role": personalData.user_role,
                            "user_status": personalData.user_status,
                            "phone_number": personalData.phone_number,
                            "user_info": localStorage.getItem("signed-in-user"),
                            "bio": draftToHtml(convertToRaw(editorState.getCurrentContent()))
                        })
                    );
                }
            } catch (error) {
                console.error(error);
            }

        } else {
            setEdit(!edit)
        }

    }

    //get user info
    useEffect(() => {
        fetchUserData();
        const subscription = DataStore.observe(User).subscribe(fetchUserData)
        return () => subscription.unsubscribe()
    }, []);


    const useStyles = makeStyles(() =>
        createStyles({
            fileDropzone: {
                "& .MuiDropzonePreviewList-removeButton": {
                    top: "25px",
                    left: "225px",
                },
            },
            firmDropzone: {
                "& .MuiDropzonePreviewList-removeButton": {
                    top: "25px",
                    left: "250px",
                },
            },
        })
    );


    const cropImage = () => {
        if (typeof cropper !== "undefined") {
            setCropData(cropper.getCroppedCanvas().toDataURL());
        }
        setOpen(false)
    };


    const styles = useStyles();

    return (
        <div className="Main">
            <div>
                <Paper style={big_paper} elevation={0}>
                    {!edit ? (
                        <div>
                            <Grid container direction="row" alignContent="flex-start" justifyContent="center">
                                <Grid item md={12}>
                                    <p style={Object.assign({}, card_title, {
                                        paddingTop: '20px',
                                        paddingLeft: '20px'
                                    })}>Your
                                        Information</p>
                                </Grid>
                                <Grid item md={6}>
                                    <p style={Object.assign({}, card_subtitle, {
                                        paddingTop: '20px',
                                        paddingLeft: '20px'
                                    })}>NAME</p>
                                    <p style={Object.assign({}, simple_text, {paddingLeft: '40px'})}>{personalData.first_name + ' ' + personalData.last_name}</p>
                                    <p style={Object.assign({}, card_subtitle, {
                                        paddingTop: '20px',
                                        paddingLeft: '20px'
                                    })}>EMAIL</p>
                                    <p style={Object.assign({}, simple_text, {paddingLeft: '40px'})}>{personalData.email}</p>
                                </Grid>
                                <Grid item md={6}>
                                    <p style={Object.assign({}, card_subtitle, {
                                        paddingTop: '20px',
                                        paddingLeft: '20px'
                                    })}>PHONE
                                        NUMBER</p>
                                    <p style={Object.assign({}, simple_text, {paddingLeft: '40px'})}>{formatPhoneNumber(personalData.phone_number)}</p>
                                    <p style={Object.assign({}, card_subtitle, {
                                        paddingTop: '20px',
                                        paddingLeft: '20px'
                                    })}>TITLE</p>
                                    <p style={Object.assign({}, simple_text, {paddingLeft: '40px'})}>{personalData.title}</p>
                                </Grid>
                                <Grid item md={12}>
                                    <p style={Object.assign({}, card_subtitle, {
                                        paddingTop: '20px',
                                        paddingLeft: '20px'
                                    })}>PROFILE PHOTO</p>
                                    <div style={{marginLeft: "40px"}}>
                                        <img
                                            src={personalData.photo === null ? ("https://res.cloudinary.com/indexpro/image/upload/v1645053914/person_mrlnlr.jpg")
                                                : (personalData.photo)} width="175" height="175" style={{borderRadius: "5px"}}/>
                                    </div>
                                </Grid>
                                <Grid item md={12}>
                                    <p style={Object.assign({}, card_subtitle, {
                                        paddingTop: '20px',
                                        paddingLeft: '20px',
                                    })}>BIO</p>
                                    <p style={Object.assign({}, simple_text, {
                                        paddingLeft: '40px',
                                        paddingRight: "40px"
                                    })}
                                       dangerouslySetInnerHTML={{__html: personalData.bio}}/>
                                </Grid>
                            </Grid>

                        </div>
                    ) : (
                        <form className={classes.form} noValidate>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                                <Grid item md={12}>
                                    <p style={Object.assign({}, card_title, {
                                        paddingLeft: '20px',
                                        paddingBottom: '20px'
                                    })}>Your
                                        Information</p>
                                </Grid>

                                <Grid item md={4}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        label="First Name"
                                        value={personalData.first_name}
                                        type="text"
                                        autoFocus
                                        style={{
                                            backgroundColor: "#F5F8FA",
                                            width: "300px",
                                            marginLeft: "40px"
                                        }}
                                        inputProps={{style: ChromeAutoFillOverride}}
                                        onChange={(e) => handleInputChange(e, 'first_name')}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        label="Middle Name"
                                        type="text"
                                        value={personalData.middle_name}
                                        autoFocus
                                        style={{
                                            backgroundColor: "#F5F8FA",
                                            width: "300px",
                                            marginLeft: "40px"
                                        }}
                                        inputProps={{style: ChromeAutoFillOverride}}
                                        onChange={(e) => handleInputChange(e, 'middle_name')}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        label="Last Name"
                                        value={personalData.last_name}
                                        type="text"
                                        autoFocus
                                        style={{
                                            backgroundColor: "#F5F8FA",
                                            width: "300px",
                                            marginLeft: "40px"
                                        }}
                                        inputProps={{style: ChromeAutoFillOverride}}
                                        onChange={(e) => handleInputChange(e, 'last_name')}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        disabled
                                        label="Email"
                                        type="email"
                                        value={personalData.email}
                                        autoFocus
                                        style={{
                                            backgroundColor: "#F5F8FA",
                                            width: "300px",
                                            marginLeft: "40px"

                                        }}
                                        inputProps={{style: ChromeAutoFillOverride}}
                                        onChange={(e) => handleInputChange(e, 'email')}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        label="Phone Number"
                                        type="phone_number"
                                        value={personalData.phone_number}
                                        autoFocus
                                        style={{
                                            backgroundColor: "#F5F8FA",
                                            width: "300px",
                                            marginLeft: "40px"
                                        }}
                                        inputProps={{style: ChromeAutoFillOverride}}
                                        onChange={(e) => handleInputChange(e, 'phone_number')}
                                    />
                                    <FormHelperText style={{marginLeft: "40px"}}>Example: 737-400-6686 or 7374006686</FormHelperText>
                                </Grid>
                                <Grid item md={4}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        label="Title"
                                        type="text"
                                        autoFocus
                                        value={personalData.title}
                                        style={{
                                            backgroundColor: "#F5F8FA",
                                            width: "300px",
                                            marginLeft: "40px"
                                        }}
                                        inputProps={{style: ChromeAutoFillOverride}}
                                        onChange={(e) => handleInputChange(e, 'title')}
                                    />
                                </Grid>
                                <Grid item style={{marginTop: "20px", marginLeft: "40px", width: "300px"}}
                                      className={styles.fileDropzone}>
                                    <p style={Object.assign({}, card_subtitle, {
                                        marginLeft: '0px'
                                    })}>Profile Photo</p>
                                    <DropzoneArea
                                        onChange={(e) => {
                                            setProfilePhoto(e)
                                        }}
                                        Icon={"null"}
                                        acceptedFiles={['image/jpeg', 'image/png', 'image/svg']}
                                        dropzoneText={<p style={{fontSize: "16px"}}>Drop your image here, or <span
                                            style={{color: themeColor}}>browse</span><br/><span
                                            style={{fontSize: "12px"}}>PNG and JPG files are allowed</span></p>}
                                        filesLimit={1}
                                        showAlerts={true}
                                        onDrop={(files) => {
                                            setOpen(true);
                                            setFilePreview(URL.createObjectURL(files[0]))
                                        }}
                                        alertSnackbarProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'center'
                                            }
                                        }}
                                        getPreviewIcon={(file) => {
                                            if (file.file.type.split('/')[0] === 'image')
                                                return (
                                                    <img style={{
                                                        height: "150px",
                                                        width: "150px",
                                                        marginLeft: "75px"
                                                    }} src={(cropData) ? (cropData) : (file.data)}/>
                                                );
                                        }}

                                    />
                                </Grid>
                                <Grid item md={12}
                                      style={{marginLeft: "40px", marginRight: "80px", marginTop: "40px"}}>
                                    <p style={Object.assign({}, card_subtitle, {
                                        marginLeft: '0px'
                                    })}>Bio</p>
                                    <div style={{
                                        border: "1px solid #e0e0e0",
                                        borderRadius: "5px",
                                        padding: "5px",
                                        backgroundColor: "#f8f9fa"
                                    }}>
                                        <Editor
                                            editorState={editorState}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={(e) => setEditorState(e)}
                                            stripPastedStyles={true}
                                            toolbar={
                                                {
                                                    options: ['inline', 'list'],
                                                    inline: {
                                                        options: ['italic', 'underline', 'strikethrough', 'superscript', 'subscript']
                                                    }
                                                }
                                            }
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </form>
                    )
                    }
                    <Button style={Object.assign({}, bordered_button, {
                        marginTop: '20px',
                        marginLeft: '40px',
                    })}
                            startIcon={!edit ? (<EditIcon/>) : (<SaveAltIcon/>)}
                            onClick={saveUserData}>{!edit ? ('Edit') : ('Save')} </Button>
                </Paper>
            </div>
            <Modal
                open={open}
                style={{overflow: "scroll"}}
            >
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Paper elevation={0} style={{
                        width: "600px", height: "600px", marginTop: "300px",
                    }}>
                        <Grid container direction="row" alignContent="center" justifyContent="center"
                              style={{paddingTop: "0px"}}>
                            <Cropper
                                style={{height: '500px', width: "600px"}}
                                aspectRatio={1}
                                preview=".img-preview"
                                guides={false}
                                onInitialized={(instance) => {
                                    setCropper(instance);
                                }}
                                src={filePreview}
                                viewMode={1}
                                dragMode="move"
                                cropBoxMovable={false}
                            />
                        </Grid>
                        <Grid container direction="row" alignContent="center" justifyContent="center"
                              style={{paddingTop: "25px"}}>
                            <Button style={Object.assign({}, bordered_button, {
                                width: "100px", marginLeft: "20px",
                                fontSize: "16px"
                            })} onClick={() => cropImage()}
                                    startIcon={<CropIcon/>}>
                                Crop </Button>
                        </Grid>
                    </Paper>
                </div>
            </Modal>
        </div>
    )
}

export default UserProfile