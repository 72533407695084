import React, {useEffect, useState} from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {Grid, Typography, Paper, Button} from "@material-ui/core";
import {observer} from "mobx-react-lite";
import FormGroup from "@material-ui/core/FormGroup";
import BuilderSwitch from "./builder-switch-style"
import {
    control_paper_left,
    big_paper,
    card_title,
    card_subtitle,
    bordered_button,
    card_subtitle_black, themeColor
} from "../../Styles/styles";
import GetInTouch from "./builder-journey";
import {DataStore} from "@aws-amplify/datastore";
import {CustomExclusions} from "../../models";
import Fab from '@material-ui/core/Fab';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";

function CustomExclusionsComp({builder_state}) {

    //value data + handlers
    const values_data = builder_state.values_data
    const apply_your_values = builder_state.apply_your_values
    const esg_value = builder_state.esg_value
    const esg_implementation = builder_state.esg_implementation === 'Exclude'
    const handleValueSelect = (name) => {
        builder_state.setESGValue(name);
    }
    const handleApplyValuesButton = (event) => {
        builder_state.setApplyYourValues(event.target.checked);
    };
    const handleESGImplementationButton = (event) => {
        let val = event.target.checked ? 'Exclude' : 'Tilt'
        builder_state.setESGImplementation(val);
    };


    const [preConfigValues, setPreConfigValues] = useState([]);


    //expansion panel
    const [expanded, setExpanded] = useState('0');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        let getValuesData = async () => {
            try {
                const values_data = await DataStore.query(CustomExclusions);
                values_data.sort(function(a,b) {
                    return a.label.localeCompare(b.label)
                })
                await setPreConfigValues(values_data)
            } catch (error) {
                console.error('error:', error)
            }
        }
        getValuesData();
    }, []);

    const addCustomExclusions = (value) => {
        builder_state.setCustomExclusions(value)
    }

    const removeCustomExclusions = (value) => {
        builder_state.removeCustomExclusions(value)
    }

    const fabState = (title) => {
        if (!builder_state.custom_exclusions.has(title)) {
            return (
                <Fab size="small" style={{backgroundColor: "lightgrey"}}
                     onClick={() => addCustomExclusions(title)}>
                    <AddIcon/>
                </Fab>
            )
        } else if (builder_state.custom_exclusions.has(title)) {
            return (
                <Fab size="small" style={{backgroundColor: "#D62728"}}
                     onClick={() => removeCustomExclusions(title)}>
                    <ClearIcon style={{color: "white"}}/>
                </Fab>
            )
        }
    }

    const createCustomExclusions = () => {
        return (
            <Grid container direction="row" justifyContent="flex-start" style={{marginTop: "50px"}}>
                {preConfigValues.map(function (item, index) {
                    return (
                        <Grid item style={{
                            height: "70px",
                            width: "598px",
                            marginLeft: "20px",
                            marginRight: "20px"
                        }}>
                            <div style={{borderTop: `2px solid lightgrey`}}>
                                <Grid container direction="row" style={{paddingTop: "10px"}}>
                                    <Grid item xs={1}>
                                        {fabState(item.title)}
                                    </Grid>
                                <Grid item xs={6}>
                                    <p style={Object.assign({}, card_title, {marginLeft: "20px", fontSize: "11px", marginTop: "5px"})}>{item.label}</p>
                                </Grid>
                                <Grid item xs={12}>
                                    <p style={Object.assign({}, card_title, {marginLeft: "70px", fontSize: "13px", marginTop: "-17px", fontWeight: "500"})}>{item.title}</p>
                                </Grid>
                            </Grid>
                            </div>
                        </Grid>

                    )
                })}
            </Grid>
        )
    };

    return (
        <Paper style={big_paper} elevation={0} square>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <div item>
                    <p style={card_title}>Custom Exclusions</p>
                </div>
            </Grid>
            {createCustomExclusions()}
        </Paper>
    )
}

export default observer(CustomExclusionsComp);