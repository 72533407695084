import {Grid, Paper} from "@material-ui/core";
import {card_subtitle, card_title, ipGreen, ipRed, paper_left} from "../../Styles/styles";
import Chart from "react-apexcharts";
import {dollarsWithCommas, percentage} from "../../Utils/num_utils";
import React from "react";
import {useAssetTimeSeriesStyles} from "../Hooks/Styles/GraphStyles/graph-styles";

function AccountHistory(props) {
    const assets_chart_style = useAssetTimeSeriesStyles(props.account.data ? (props.account.data['Account History']['datadate']) : ([]))
    return (
        <div className="Account-History">
            <Paper style={paper_left} elevation={0}>
                <div>
                    <p style={card_title}>Account History</p>
                    <p style={card_subtitle}>Net Deposits and Investment Account Value</p>
                </div>
                <Chart
                    options={assets_chart_style}
                    series={[{
                        name: 'Account Assets',
                        data: props.account.data['Account History']['assets']
                    }]}
                    type="area" height="350px"/>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        {props.account.data['Total Earnings'] >= 0 ? (
                            <p style={Object.assign({}, card_title, {color: ipGreen})}>{dollarsWithCommas(Math.round(props.account.data['Total Earnings']))}</p>
                        ) : (
                            <p style={Object.assign({}, card_title, {color: ipRed})}>{dollarsWithCommas(Math.round(props.account.data['Total Earnings']))}</p>

                        )
                        }
                        <p style={card_subtitle}>Total Earnings</p>
                    </Grid>
                    <Grid item>
                        {props.account.data['Money-Weighted Return'] >= 0 ? (
                            <p style={Object.assign({}, card_title, {color: ipGreen})}>{percentage(props.account.data['Money-Weighted Return'])}</p>
                        ) : (
                            <p style={Object.assign({}, card_title, {color: ipRed})}>{percentage(props.account.data['Money-Weighted Return'])}</p>

                        )
                        }
                        <p style={card_subtitle}>Money-Weighted Return</p>
                    </Grid>
                    <Grid item>
                        <p style={Object.assign({}, card_title, {color: ipGreen})}>{dollarsWithCommas(Math.round(props.account.data['Realized Losses Harvested']))}</p>
                        <p style={Object.assign({}, card_subtitle, {marginRight: "100px"})}>Realized Losses
                            Harvested</p>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

export default AccountHistory