import React from "react";
import {card_description, ipGreen, ipRed} from "../Styles/styles";

export const dollarsWithCommas = (x) => {
    return '$' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export const percentageNum = (x) => {
    return parseFloat((x * 100).toString()).toFixed(2) + '%'
}

export const percentage = (x) => {
    return parseFloat((x).toString()).toFixed(2) + '%'
}

export const numWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const millionNumWithCommas = (x) => {
    return Math.round(x / 1e6).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' MM';
}

export const median = (values) => {
    values.sort(function (a, b) {
        return a - b;
    });

    var half = Math.floor(values.length / 2);

    if (values.length % 2)
        return values[half];

    return (values[half - 1] + values[half]) / 2.0;
}

export const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
}


export const pos_neg_dollar_card_description = (val) => {
    return (
        val >= 0 ? (
            <p style={Object.assign({}, card_description, {color: ipGreen})}>{dollarsWithCommas(val)}</p>
        ) : (
            <p style={Object.assign({}, card_description, {color: ipRed})}>{dollarsWithCommas(val)}</p>

        )
    )
}

export const pos_neg_dollar_card_description_proposal = (val) => {
    return (
        val >= 0 ? (
            <p style={{color: ipGreen}}>{dollarsWithCommas(val)}</p>
        ) : (
            <p style={{color: ipRed}}>{dollarsWithCommas(val)}</p>

        )
    )
}
