import React from "react";
import {observer} from "mobx-react-lite";
import {Paper, Button} from "@material-ui/core"
import {
    paper_left,
    card_title,
    noborder_button,
    simple_text,
    simple_text_bold,
    card_subtitle
} from "../../Styles/styles"

//component for notification handler
function Notifications() {

    const notification_data = [
        ['08/04/21', 'New Client - Mackenzie Family Office'],
        ['08/04/21', 'Report Updated - July 2021'],
        ['08/04/21', 'New Product - MSCI Emerging Markets Pro'],
        ['08/04/21', 'Q2 2021 Quarterly Statement is now available'],
        ['08/04/21', 'Q2 2021 Impact Statement is now available'],

    ];

    return (
        <Paper style={paper_left} elevation={0}>
            <p style={card_title}>Notifications</p>
            <div style={{marginTop: "40px"}}>
                {notification_data.map((value, index) => {
                    return (
                        <div key={index} style={{display: "flex", flexGrow: "1", marginTop: "6px"}}>
                            <div style={{marginLeft: "20px"}}>
                                <p style={simple_text_bold}>{value[0]}</p>
                            </div>
                            <div style={{marginLeft: "20px"}}>
                                <p style={simple_text}>{value[1]}</p>
                            </div>
                            <div style={{marginLeft: "auto", marginRight: 0}}>
                                <Button variant="contained"
                                        style={Object.assign({}, noborder_button, {marginRight: "10px"})}>
                                    View
                                </Button>
                                <Button variant="contained"
                                        style={Object.assign({}, noborder_button, {marginRight: "10px"})}>
                                    Hide
                                </Button>
                            </div>
                        </div>
                    )
                })}
            </div>
        </Paper>
    )
}


export default observer(Notifications);