import React from 'react';
import Grid from "@material-ui/core/Grid"
import Paper from '@material-ui/core/Paper';
import {
    card_title,
    big_paper,
} from "../../Styles/styles";
import './account-settings.css'
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import SiteRoles from "./site-roles";
import UserProfile from "./user-profile"
import FirmProfile from "./firm-profile"

function AccountSettings() {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>

            <div className="AccountSettingsContainer">
                <div className="Title">
                    <Paper style={Object.assign({}, big_paper, {
                        backgroundColor: "transparent",
                        border: "none",
                        padding: "0px"
                    })} elevation={0}>
                        <Grid container direction="row" alignContent="flex-start" justifyContent="space-between">
                            <p style={Object.assign({}, card_title, {marginLeft: "0px", marginTop: "0px"})}>
                                Account Settings</p>

                        </Grid>
                    </Paper>
                </div>
                <TabContext value={value}>
                    <div className="Tabs">
                        <AppBar position="static" style={{
                            width: "1278px",
                            margin: "auto",
                            color: "black",
                            backgroundColor: "transparent"
                        }} elevation={0}>
                            <TabList value={value} onChange={handleChange} indicatorColor="primary">
                                <Tab label="Profile & Branding" value="1" style={{
                                    fontSize: "14px",
                                    margin: "0px",
                                    textTransform: "none",
                                    minWidth: "150px"
                                }}/>
                                <Tab label="Users & Teams" value="2" style={{
                                    fontSize: "14px",
                                    margin: "0px",
                                    textTransform: "none",
                                    minWidth: "150px"
                                }}/>
                            </TabList>
                        </AppBar>
                    </div>
                    <TabPanel value="1" style={{padding: "0px"}}>
                        <UserProfile/>
                        <div style={{marginBottom: "30px"}}/>
                        <FirmProfile/>
                    </TabPanel>
                    <TabPanel value="2" style={{padding: "0px"}}>
                        <div className="Main">
                            <SiteRoles/>
                        </div>
                    </TabPanel>
                </TabContext>
            </div>
        </div>
    );
}

export default AccountSettings