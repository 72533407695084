import React, {useEffect, useState} from "react";
import {DataStore} from "@aws-amplify/datastore";
import { Earnings } from "../../../../models";
import {Auth, Storage} from "aws-amplify";

//custom hook returns benchmark data
export function useEarningsData() {
    const [data, setData] = useState([])

    async function getEarningsData() {
        try {
            const earnings_data = await DataStore.query(Earnings);
            earnings_data[0].data ? (setData(earnings_data[0].data)) : (setData([]))
        } catch (error) {
            console.error('error:', error)
        }
    }
    useEffect(() => {
        getEarningsData()
    }, []);
    return data
}