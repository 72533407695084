import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {Paper, Button} from "@material-ui/core";
import {big_paper, bordered_button} from "../Styles/styles";
import Grid from "@material-ui/core/Grid";
import {Route, useHistory} from "react-router-dom";


function Page404() {
    const history = useHistory()
    return (
<div>

        <Paper style={Object.assign({}, big_paper, {backgroundColor: 'transparent', border: 'none'})}
               elevation={0}>
            <Grid container direction="column" justifyContent="center" alignItems="center">
                <p style={{fontWeight: "600", fontSize: "300px", marginBottom: "0px", marginTop: "50px"}}>404</p>

                <p style={{fontSize: "50px", marginTop: "0px"}}>Sorry, Page Not Found</p>
                <div style={{marginTop: "0px"}}>
                    <Button style={Object.assign({}, bordered_button,
                        {fontSize: '30px', borderRadius: "60px", width: "250px"})}
                            onClick={() => history.push('/participant/dashboard')}
                    >
                        Home
                    </Button>
                </div>
            </Grid>
        </Paper>

</div>
    )
}

export default observer(Page404);

