import React from "react";
import {dollarsWithCommas} from "../../../../Utils/num_utils";
import {themeColor} from "../../../../Styles/styles";

//custom hook that returns style for any asset graphs takes in a list of dates for the labels
export function useAssetTimeSeriesStyles(dateList) {
    return {
        chart: {
            fontFamily: "Poppins",
            height: 350,
            type: 'area',
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            }
        },
        labels: dateList,
        stroke: {
            curve: 'smooth',
        },
        dataLabels:{
            enabled: false
        },
        tooltip: {
            enabled: true
        },
        yaxis: {
            show: true,
            labels: {
                formatter: function (value) {
                    return dollarsWithCommas(value);
                }
            }
        },
        colors: [themeColor],
        xaxis: {
            type: 'datetime',
            labels: {
                show: true,
                datetimeFormatter: {
                    year: "M/d/yy",
                    month: "M/d/yy",
                    day: "M/d/yy"
                }
            }
        },
        grid: {
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            },
            borderColor: '#dadce0',
        },
        fill: {
            type: 'gra',
            opacity: [0.05]
        }
    }
}


//custom hook that returns style for any radial style graphs
export function useRadialStyles() {
    return {
        chart: {
            fontFamily: "Poppins",
            height: 350,
            type: 'radialBar'
        },
        plotOptions: {
            radialBar: {
                hollow: {
                    margin: 0,
                    size: '65%',
                    position: 'front',
                },
                dataLabels: {
                    show: true,
                    name: {
                        show: false,
                    },
                    value: {
                        fontSize: "45px",
                        fontFamily: "Poppins",
                        show: true,
                    }
                }
            }
        },
        colors: [themeColor],
        labels: [''],
    }
}


//custom hook that returns style for any strategy vs benchmark graphs
export function useStrategyBenchmarkStyle(datelist) {
    return {
        chart: {
            fontFamily: "Poppins",
            height: 350,
            type: 'line',
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            }
        },
        labels: datelist,
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        tooltip: {
            enabled: true
        },
        yaxis: {
            show: true
        },
        colors: ["#1f77b4", "#FF7F0E"],
        xaxis: {
            type: 'datetime',
            labels: {
                show: true,
                datetimeFormatter: {
                    month: "M/yy"
                }
            }
        },
        grid: {
            show: true
        }
    }
}

