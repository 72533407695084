import {Grid} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import ClearIcon from "@material-ui/icons/Clear";
import {card_title, ReportHeaderCell, ReportValueCell} from "../../Styles/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import React from "react";
import {ExclusionStartingNumber} from "../../Utils/compliance_utils";
import {superScript} from "../../Utils/compliance_utils";

export const Exclusions = (type, headers, page_number, exclusions, revenue5, revenueAny, exclusion_data, transition_data) => {

    let footnote_start = ExclusionStartingNumber(page_number, transition_data)

    var revenue_rows = []
    revenue5.map(function (item, index) {
        var obj = {'item': item.split(' 5%')[0], 'revenue': '> 5%'}
        revenue_rows.push(obj)
    })
    revenueAny.map(function (item, index) {
        var obj = {'item': item, 'revenue': 'ANY'}
        revenue_rows.push(obj)
    })
    return (
        <div style={{height: "100%", position: "relative", color: "black", fontDisplay: "swap"}}>
            <div style={{
                position: "absolute",
                top: "54px",
                right: "60px",
                fontSize: "14px",
                fontWeight: "600",
                color: "black"
            }}>{page_number}
            </div>
            <Grid container justifyContent="flex-start" style={{
                position: "absolute", top: "40px", left: "60px",
                fontSize: "14px", color: "#c2c2c1", fontWeight: "600", width: "800px"
            }}>
                <p style={{marginRight: "25px"}}>Portfolio</p>
                {headers.map(title => {
                    if (title === type) {
                        return (
                            <p style={{marginRight: "25px", color: "#009494"}}>{title}</p>
                        )
                    }
                    else {
                        return(
                            <p style={{marginRight: "25px"}}>{title}</p>
                        )
                    }
                })}
            </Grid>
            <div style={{
                position: "absolute",
                top: "140px",
                left: "60px",
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: 0.96
            }}>Exclusions<br/></div>
            <div style={{
                position: "absolute", top: "210px", marginLeft: "60px", marginRight: "60px",
                width: "755px", fontSize: "14px", fontWeight: "600", borderBottom: "1px solid black"
            }}>
                CUSTOM EXCLUSIONS {superScript(footnote_start)}
            </div>
            <Grid container direction="column" justifyContent="flex-start"
                  style={{width: "365px", height: "480px", position: "absolute", top: "240px", marginLeft: "60px"}}>
                {exclusions.map(function (item, index) {
                    return (
                        <Grid container direction="row" style={{paddingBottom: "10px"}}>
                            <Grid item xs={1}>
                                <Fab size="small" style={{backgroundColor: "#D62728"}}>
                                    <ClearIcon style={{color: "white"}}/>
                                </Fab>
                            </Grid>
                            <Grid item xs={6}>
                                <p style={Object.assign({}, card_title, {
                                    marginLeft: "30px",
                                    fontSize: "13px",
                                    marginTop: "5px"
                                })}>{item.label}</p>
                            </Grid>
                            <Grid item xs={12}>
                                <p style={Object.assign({}, card_title, {
                                    marginLeft: "60px",
                                    fontSize: "13px",
                                    marginTop: "-20px",
                                    fontWeight: "500"
                                })}>{item.title}</p>
                            </Grid>
                        </Grid>
                    )
                })}

            </Grid>
            <div style={{
                position: "absolute", top: "700px", marginLeft: "60px", marginRight: "60px",
                width: "755px", fontSize: "14px", fontWeight: "600", borderBottom: "1px solid black"
            }}>
                REVENUE THRESHOLD EXCLUSIONS {superScript(footnote_start+1)}
            </div>
            <div style={{
                position: "absolute", top: "720px", marginLeft: "60px", marginRight: "60px",
                width: "755px", fontWeight: "600"
            }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <ReportHeaderCell>CATEGORY</ReportHeaderCell>
                                <ReportHeaderCell align="center">REVENUE EXCLUSIONS</ReportHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {revenue_rows.map((row) => (
                                <TableRow>
                                    <ReportValueCell>{row.item}</ReportValueCell>
                                    <ReportValueCell align="center"
                                                     style={{fontWeight: "600"}}>{row.revenue}</ReportValueCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div style={{
                position: "absolute", top: "920px", marginLeft: "60px", marginRight: "60px",
                width: "755px", fontSize: "14px", fontWeight: "600", borderBottom: "1px solid black"
            }}>
                PORTFOLIO CHANGES <span style={{fontWeight: "500", fontSize: "12px"}}>(TOP POSITIONS EXCLUDED) {superScript(footnote_start+2)}</span>
            </div>
            <div style={{
                position: "absolute", top: "940px", marginLeft: "60px", marginRight: "60px",
                width: "755px", fontWeight: "600"
            }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <ReportHeaderCell>COMPANY</ReportHeaderCell>
                                <ReportHeaderCell align="center">WEIGHTING ADJUSTMENT {superScript(footnote_start+3)}</ReportHeaderCell>
                                <ReportHeaderCell align="center">REASON</ReportHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {exclusion_data.map((row) => (
                                <TableRow>
                                    <ReportValueCell>{row.company_name}</ReportValueCell>
                                    <ReportValueCell align="center"
                                                     style={{fontWeight: "600"}}>-{row.weight}</ReportValueCell>
                                    <ReportValueCell align="center">{row.factor}</ReportValueCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}