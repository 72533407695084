import {groupBy} from "../../../Utils/data_utils";
import {median, millionNumWithCommas, percentageNum} from "../../../Utils/num_utils";
import {ipGreen, ipRed, ipYellow} from "../../../Styles/styles";

export const builder_journey_value_exclusions = (selections, esg_value_data) => {
    //filter on builder value selections
    let filtered_data = esg_value_data.filter(i => selections.includes(i.map))
    //exclude positions that have a score < 2.87, sort by weight
    let exclusion_data = filtered_data.filter(x => x.value < 2.87);
    exclusion_data.sort((a, b) => b.weight - a.weight)

    //get # of companies excluded
    const unique_companies = [...new Set(exclusion_data.map(item => item.company_name))]
    const exclusion_count = unique_companies.length

    //group data by company and take top 5 exclusions, and add additional info like values, weight, ticker
    let grouped_exclusion_data = groupBy(exclusion_data, 'company_name')
    let x = Object.fromEntries(Object.entries(grouped_exclusion_data).filter(([key]) => unique_companies.includes(key)));
    const data = []
    for (const [key, value] of Object.entries(x)) {
        let cleaned_data = {
            'company_name': key,
            'label': [...new Set(value.map(item => item.map))],
            'weight': percentageNum([...new Set(value.map(item => item.weight))][0]),
            'ticker': [...new Set(value.map(item => item.ticker))][0]
        }
        data.push(cleaned_data)
    }
    return [exclusion_count, data]
}

export const builder_journey_overweight = (selections, esg_value_data, selections_with_themes, security_exclusions) => {
    //filter on builder selections
    let all_filtered_data = esg_value_data.filter(i => selections_with_themes.includes(i.map))
    let filtered_data = esg_value_data.filter(i => selections.includes(i.map))
    let filtered_data_grouped = groupBy(all_filtered_data, 'company_name')
    let exclusions_data = filtered_data.filter(x => x.value < 2.87);
    const unique_companies = [...new Set(exclusions_data.map(item => item.company_name))]
    unique_companies.forEach(e => delete filtered_data_grouped[e])
    if(security_exclusions) {
        security_exclusions.map(i => i.company_name).forEach(e => delete filtered_data_grouped[e])
    }
    const data = []
    for (const [key, value] of Object.entries(filtered_data_grouped)) {
        let val_array = value.map(item => item.value)
        let cleaned_data = {
            'company_name': key,
            'label': [...new Set(value.map(item => item.map))],
            'weight': percentageNum([...new Set(value.map(item => item.weight))][0]),
            'value': val_array.reduce((a, b) => a + b) / val_array.length,
            'ticker': [...new Set(value.map(item => item.ticker))][0]
        }
        data.push(cleaned_data)
    }
    let overweight_data = data.filter(x => x.value >= 7.15);
    overweight_data.sort((a, b) => parseFloat(b.weight) - parseFloat(a.weight))
    return [overweight_data.length, overweight_data]
}


export const msci_abc_rating = (value) => {
    const range = Math.ceil(value/1.43)
    switch (range) {
        case 0:
            return 'CCC'
        case 1:
            return 'CCC'
        case 2:
            return 'B'
        case 3:
            return 'BB'
        case 4:
            return 'BBB'
        case 5:
            return 'A'
        case 6:
            return 'AA'
        case 7:
            return 'AAA'
    }

}

export const msci_abc_rating_to_color = (value) => {
    switch (value) {
        case 'AAA':
            return ipGreen
        case 'AA':
            return ipGreen
        case 'A':
            return ipYellow
        case 'BBB':
            return ipYellow
        case 'BB':
            return ipYellow
        case 'B':
            return ipRed
        case 'CCC':
            return ipRed
    }
}

export const msci_colors_from_type = (type) => {
    switch (type) {
        case 'ESG Laggard':
            return ipRed
        case 'Average':
            return ipYellow
        case 'ESG Leader':
            return ipGreen
    }
}