import React from "react";

export const ThemesSelectionsTitlePage = () => {
    return (
        <div style={{height: "100%", position: "relative", fontDisplay: "swap"}}>
            <img
                src="https://res.cloudinary.com/indexpro/image/upload/v1652901838/Proposal/Theme%20Selections%20Title%20Page.jpg"
                width="100%" height="100%"/>
            <div style={{
                position: "absolute",
                top: "280px",
                left: "60px",
                fontSize: "84px",
                fontWeight: "700",
                color: "white",
                lineHeight: 1
            }}>
                INVESTMENT THEMES
            </div>
            <div style={{
                position: "absolute",
                bottom: "80px",
                left: "60px",
                fontSize: "18px",
                color: "white",
                width: "300px"
            }}>
                “Innovation distinguishes between a leader and a follower.”
            </div>
            <div style={{position: "absolute", bottom: "40px", left: "60px", fontSize: "18px", color: "white"}}>
                Steve Jobs
            </div>
        </div>
    )
}