import React, {useEffect} from "react";
import Assets from "./assets";
import Notifications from "./notifications";
import TopAccounts from "./top-accounts";
import TopPortfolios from "./top-portfolios";
import ComplianceStatus from "./compliance-status";
import Participation from "./participation";
import OnboardingComp from "./onboarding";
import './dashboard.css'
import Welcome from "./welcome";
import News from "../News/news";
import Earnings from "../Earnings/earnings";

//Container for Dashboard Page
//Build up of all underlying components
function Dashboard() {
    return (
        <div>
            <div className="DashContainer">
                <Welcome/>
                <OnboardingComp/>
                <Earnings/>
                <div className="Assets"><Assets/></div>
                {/*<div className="Notifications"><Notifications/></div>*/}
                <div className="Top-Accounts"><TopAccounts/></div>
                <div className="Top-Portfolios"><TopPortfolios/></div>
                <div className="Participation"><Participation/></div>
                <div className="Compliance-Status"><ComplianceStatus/></div>
            </div>
            <div style={{height: "30px"}}/>
            <div style={{display:"none"}}>
                <p>██████╗░██╗░░██╗</p>
                <p>██╔══██╗██║░██╔╝</p>
                <p>██████╔╝█████═╝░</p>
                <p>██╔══██╗██╔═██╗░</p>
                <p>██║░░██║██║░╚██╗</p>
                <p>╚═╝░░╚═╝╚═╝░░╚═╝</p>
            </div>
        </div>
    )
}

export default Dashboard;