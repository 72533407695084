import React from "react";
import BuilderAppBar from "./builder-app-bar";
import BuilderTitle from "./builder-title";
import Top10Holdings from "./top-10-holdings";
import SectorBenchmark from "./sector-benchmark";
import PreConfiguredValuesComp from "./pre-configured-values";
import BuilderJourney from "./builder-journey";
import ControlPanel from "./control-panel";
import {observer} from "mobx-react-lite"
import BuilderState from "./state-manager";
import CustomOptionsComp from "./custom-options";
import CustomExclusionsComp from "./custom-exclusions";
import RevenueThresholdExclusionsComp from "./revenue-threshold-exclusions";
import ThemesComp from "./themes";
import BenchmarkMetrics from "./benchmark-metrics";
import BuilderSummary from "./builder-summary";
import TaxGraphic from "./tax-graphic";
import { Storage } from 'aws-amplify';
import {Paper} from "@material-ui/core";
import {big_paper} from "../../Styles/styles";
import PerformanceComp from "./performance";


function Builder({builder_state}) {

    const tab_value = builder_state.tab

    if (tab_value === 0) {
        return (
            <div>
                <div className="container">
                    <div className="Nav-Bar"><BuilderAppBar builder_state={builder_state}/></div>
                    <div className="Title"><BuilderTitle builder_state={builder_state}/></div>
                    <div className="Control-Panel"><ControlPanel builder_state={builder_state}/></div>
                    <div className="Metrics"><BenchmarkMetrics builder_state={builder_state}/></div>
                    <div className="Top-10-Holdings"><Top10Holdings builder_state={builder_state}/></div>
                    <div className="Sector-vs-Benchmark"><SectorBenchmark builder_state={builder_state}/></div>
                </div>
                <div style={{position: "fixed", bottom: "0", display: "flex", justifyContent: "center", width: "100%"}}>
                    <BuilderJourney builder_state={builder_state}/>
                </div>
            </div>
        )
    } else if (tab_value === 3) {
        return (
            <div>
                <div className="containerValues">
                    <div className="Nav-Bar"><BuilderAppBar builder_state={builder_state}/></div>
                    <div className="Pre-Configured-Values"><PerformanceComp builder_state={builder_state}/></div>
                </div>
                <div style={{position: "fixed", bottom: "0", display: "flex", justifyContent: "center", width: "100%"}}>
                    <BuilderJourney builder_state={builder_state}/>
                </div>
            </div>
        )
    } else if (tab_value === 1) {
        return (
            <div>
                <div className="containerValues">
                    <div className="Nav-Bar"><BuilderAppBar builder_state={builder_state}/></div>
                    <div className="Pre-Configured-Values"><PreConfiguredValuesComp builder_state={builder_state}/>
                    </div>
                    <div className="Custom-Options"><CustomOptionsComp builder_state={builder_state}/></div>
                    <div className="Custom-Exclusions"><CustomExclusionsComp builder_state={builder_state}/></div>
                    <div className="Revenue-Threshold-Exclusions"><RevenueThresholdExclusionsComp
                        builder_state={builder_state}/></div>
                    <div className="Company-Industry-Exclusions"></div>
                </div>
                <div style={{position: "fixed", bottom: "0", display: "flex", justifyContent: "center", width: "100%"}}>
                    <BuilderJourney builder_state={builder_state}/>
                </div>
            </div>
        )
    } else if (tab_value === 4) {
        return (
            <div>
                <div className="containerTaxes">
                    <div className="Nav-Bar"><BuilderAppBar builder_state={builder_state}/></div>
                    <TaxGraphic builder_state={builder_state}/>
                    <div className="Control-Panel"><ControlPanel builder_state={builder_state}/></div>
                </div>
                <div style={{position: "fixed", bottom: "0", display: "flex", justifyContent: "center", width: "100%"}}>
                    <BuilderJourney builder_state={builder_state}/>
                </div>
            </div>
        )
    } else if (tab_value === 2) {
        return (
            <div>
                <div className="containerValues">
                    <div className="Nav-Bar"><BuilderAppBar builder_state={builder_state}/></div>
                    <div className="Pre-Configured-Values"><ThemesComp builder_state={builder_state}/></div>
                </div>
                <div style={{position: "fixed", bottom: "0", display: "flex", justifyContent: "center", width: "100%"}}>
                    <BuilderJourney builder_state={builder_state}/>
                </div>
            </div>
        )
    } else if (tab_value === 5) {
        return (
            <div>
            <div className="containerSummary">
                <div className="Nav-Bar"><BuilderAppBar builder_state={builder_state}/></div>
                <div className="Control-Panel"><ControlPanel builder_state={builder_state}/></div>
                <div className="Title"><BuilderTitle builder_state={builder_state}/></div>
                <BuilderSummary builder_state={builder_state}/>
            </div>
            </div>
        )
    }
}

export default observer(Builder);