import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Paper} from "@material-ui/core"
import Chart from 'react-apexcharts'
import {paper_right, card_title, card_subtitle, themeColor} from "../../Styles/styles"
import {DataStore} from "@aws-amplify/datastore";
import {Account} from "../../models";
import {useAccountParticipationStats} from "../Hooks/Data/Accounts/accounts";
import {useRadialStyles} from "../Hooks/Styles/GraphStyles/graph-styles";

//component for dashboard - shows pct of live accounts / total accounts
function Participation() {
    const [liveAccounts, totalAccounts] = useAccountParticipationStats()

    const options = useRadialStyles()

    return (
        <Paper style={paper_right} elevation={0}>
            <p style={card_title}>Participation (%)</p>
            {totalAccounts === 0 ? (
                <p style={card_subtitle}>No Accounts Created</p>
            ) : (
                <p style={card_subtitle}>{liveAccounts} of {totalAccounts} Accounts</p>
            )}
            <Chart
                options={options}
                series=
                    {totalAccounts === 0 ? (
                        [0]
                    ) : (
                        [Math.round((liveAccounts / totalAccounts) * 100)]
                    )}
                type="radialBar" height="250px"/>
        </Paper>
    )
}


export default observer(Participation);

