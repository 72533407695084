import React from "react";
import {TaxStartingNumber, ExclusionStartingNumber} from "../../Utils/compliance_utils";

export const Footnotes = (pages, transition_data, headers) => {
    const esgt = Object.keys(pages).filter(i => ['Environmental', 'Social', 'Governance', 'Themes'].includes(i))
    const exclusions = Object.keys(pages).filter(i => ['Exclusions'].includes(i))
    const tax = Object.keys(pages).filter(i => ['Taxes'].includes(i))
    const performance = Object.keys(pages).filter(i => ['Performance'].includes(i))

    let offset = 0
    transition_data ? (offset = offset + 10) : (offset = offset)

    return (
        <div style={{height: "100%", position: "relative", color: "black", fontSize: "8px"}}>
            <div style={{position: "relative", top: "30px", margin: "60px"}}>
                <div style={{
                    width: "755px", fontSize: "14px", fontWeight: "600", borderBottom: "1px solid black"
                }}>
                    Footnotes
                </div>
                <br/><br/>
                {transition_data ? (
                    <>
                        1.  Information related to the transition of an existing portfolio to a customized portfolio, using Index Pro, is entirely dependent upon the accuracy of information provided by the advisor and/or the client. Index Pro is not liable for producing a transition plan or the construction of a portfolio based on inaccurate security holdings.
                        <br/>
                        2.	This target tracking error is aspirational and provided for illustrative purposes only. There is no guarantee that this target will be achieved. Please refer to the Disclosure Page for more details.
                        <br/>
                        3.	Atom does not provide tax or legal advice. Tax-related statements are based on Atom’s understanding of the tax laws. Investors should seek advice of their independent legal and tax counsel before investing.
                        <br/>
                        4.	Atom does not provide tax or legal advice. Tax-related statements are based on Atom’s understanding of the tax laws. Investors should seek advice of their independent legal and tax counsel before investing.
                        <br/>
                        5.	Information related to the transition of an existing portfolio to a customized portfolio, using Index Pro, is entirely dependent upon the accuracy of information provided by the advisor and/or the client. Index Pro is not liable for producing a transition plan or the construction of a portfolio based on inaccurate security holdings.
                        <br/>
                        6.	The weighting adjustment represents each sector’s proportionate market capitalization adjustment within the selected benchmark.
                        <br/>
                        7.	These weightings represent only those of a sample investment portfolio, provided solely for illustrative purposes. The weightings in the sample portfolio as based on the adviser’s pre-selected preferences in combination with ratings provided by MSCI ESG Research, and there is no guarantee that the weightings of an actual portfolio will consist of the same investments within the same weightings. The composition and weightings are subject to change over time as ratings are updated or the investor’s preferences change.
                        <br/>
                        8.	The excluded positions listed in the proposal are only the top 10 exclusions based on the change to weightings between the selected benchmark and this sample portfolio. Each of these companies is excluded entirely from the sample portfolio and that such exclusion is based on a combination of the recipient’s pre-existing “values” selections and MSCI’s ESG ratings. The performance of a portfolio may be negatively impacted by the exclusion of companies on the basis of ESG factors.
                        <br/>
                        9.	The measure of any company’s potential impact is based on ESG ratings of MSCI ESG Research rather than Atom’s own ESG assessment of each company. There can be no guarantee that MSCI’s ESG ratings accurately represent the impact of a company’s ESG risks and effectiveness in addressing such risk.
                        <br/>
                        10.	The figures below are calculated by comparing, for each ESG factor, either on average or in the aggregate, MSCI’s ESG rating for all companies within the selected benchmark against MSCI’s ESG ratings for all companies included in the Index Pro sample portfolio after the ESG-rated exclusions are implemented. The figures are provided for illustrative purposes only, and actual ESG impact may differ from that measured by MSCI’s ESG ratings.
                        <br/>
                    </>
                ) : (<></>)}
                {1 + offset}.  Index Pro utilizes benchmark indices and mutual funds to create custom indices based on an investor’s preferences combined with the use of MSCI ratings.
                <br/>
                {2 + offset}.	Data provided by S&P Global Market Intelligence
                <br/>
                {3 + offset}.	This target tracking error is aspirational and provided for illustrative purposes only. There is no guarantee that this target will be achieved. Please refer to the Disclosure Page for more details.
                <br/>
                {4 + offset}.	These weightings represent only those of a sample investment portfolio, provided solely for illustrative purposes.  The weightings in the sample portfolio are based on the adviser’s pre-selected preferences in combination with ratings provided by MSCI ESG Research, and there is no guarantee that the weightings of an actual portfolio will consist of the same investments within the same weightings. The composition and weightings are subject to change over time as ratings are updated or the investor’s preferences change.
                <br/>
                {5 + offset}.	Data provided by S&P Global Market Intelligence
                <br/>
                {6 + offset}.	These weightings represent only those of a sample investment portfolio, provided solely for illustrative purposes.  The weightings in the sample portfolio are based on the adviser’s pre-selected preferences in combination with ratings provided by MSCI ESG Research, and there is no guarantee that the weightings of an actual portfolio will consist of the same investments within the same weightings. The composition and weightings are subject to change over time as ratings are updated or the investor’s preferences change.
                <br/>
                {esgt.map((i, index)=> (
                    <>
                        {7 + index*5 + offset}.	The measure of any company’s potential impact is based on ESG ratings of MSCI ESG Research rather than Atom’s own ESG assessment of each company.  There can be no guarantee that MSCI’s ESG ratings accurately represent the impact of a company’s ESG risks and effectiveness in addressing such risks.
                        <br/>
                        {8 + index*5 + offset}.	These selections represent the “values” pre-selected by or on behalf of the recipient.
                        <br/>
                        {9 + index*5 + offset}.	 The excluded positions listed in the proposal are only the top 5 exclusions based on the change to the weightings between the selected benchmark and this sample portfolio.  Each of these companies is excluded entirely from the sample portfolio and that such exclusion is based on a combination of the recipient’s pre-existing “values” selections and MSCI’s ESG ratings. The performance of a portfolio may be negatively impacted by the exclusion of companies on the basis of ESG factors.
                        <br/>
                        {10 + index*5 + offset}. The weighting adjustment represents each company’s proportionate market capitalization adjustment within the selected benchmark.
                        <br/>
                        {11 + index*5 + offset}.	The figures below are calculated by comparing, for each ESG factor, either on average or in the aggregate, MSCI’s ESG rating for all companies within the selected benchmark against MSCI’s ESG ratings for all companies included in the Index Pro sample portfolio after the ESG-rated exclusions are implemented. The figures are provided for illustrative purposes only, and actual ESG impact may differ from that measured by MSCI’s ESG ratings.
                        <br/>
                    </>
                ))}
                {exclusions.map((i, index)=> (
                    <>
                        {ExclusionStartingNumber(pages[exclusions],transition_data)}.	These exclusions were pre-selected by, or on behalf of, the recipient. Excluding these sectors will cause the Index Pro portfolio to seek to exclude companies with low or high MSCI ESG ratings. The measure of any company’s potential impact is based on ESG ratings of MSCI ESG Research rather than Atom’s own ESG assessment of each company.  There is no guarantee that MSCI’s ESG ratings accurately represent the impact of a company’s ESG risk and effectiveness in addressing such risks.
                        <br/>
                        {ExclusionStartingNumber(pages[exclusions],transition_data) + 1}.	A revenue threshold exclusion is an election made by, or on behalf, of the recipient to exclude particular issuers from the portfolio which engage in subjectively disfavored revenue generating activity.
                        <br/>
                        {ExclusionStartingNumber(pages[exclusions],transition_data) + 2}.	Each of these companies is excluded entirely from the sample portfolio and that such exclusion is based on a combination of the recipient’s pre-existing “values” selections and MSCI ratings.
                        <br/>
                        {ExclusionStartingNumber(pages[exclusions],transition_data) + 3}.	The weighting adjustment represents each company’s proportionate market capitalization within the selected benchmark.
                        <br/>
                    </>
                ))}
                {tax.map((i, index)=> (
                    <>
                        {TaxStartingNumber(pages[tax], transition_data, headers)}. This is an aspirational and provided only for illustrative purposes, and that there is no guarantee the target will be met.
                        <br/>
                    </>
                ))}
                {performance.map((i, index)=> (
                    <>
                        {TaxStartingNumber(pages[tax], transition_data, headers) + 1}. Alpha is based on proprietary algorithmic analysis which may take into account “values” selections, MSCI ratings, and historical financial data.
                        <br/>
                        {TaxStartingNumber(pages[tax], transition_data, headers) + 2}. The named securities may represent the largest active weights as compared to the benchmark.
                        <br/>
                    </>
                ))}
            </div>
        </div>
    )
}