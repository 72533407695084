import React from "react";

export const superScript = (number) => {
    return (
        <sup style={{color: "black", fontWeight: "500", fontSize: "8px"}}>{number}</sup>
    )
}

export const ESGTStartingNumber = (page_number, transition_data) => {
    if (transition_data) {
        return 2.5*page_number-5.5
    } else {
        return 2.5*page_number-8
    }
}

export const ExclusionStartingNumber = (page_number, transition_data) => {
    if(transition_data) {
        return 2.5*page_number - 3
    } else {
        return 2.5*page_number-5.5
    }
}

export const TaxStartingNumber = (page_number, transition_data, headers) => {
    if (headers) {
        if (!headers.includes('Exclusions')) {
            return ESGTStartingNumber(page_number, transition_data)
        } else {
            return ExclusionStartingNumber(page_number, transition_data) - 1
        }
    } else {
        return 0
    }
}