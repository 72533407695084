import React, {useEffect, useState} from "react";
import {DataStore} from "@aws-amplify/datastore";
import {User, Onboarding, Firm, Team, CustomStrategies, Account} from "../../../../models";
import {default_firm_photo, ipLogo} from "../../../../Styles/styles";
import {Storage} from "@aws-amplify/storage";
import {pick, safeAWSJSONArrayQuery} from "../../../../Utils/data_utils";

//custom hook that returns a list for agg assets graph
//first value is time series of assets, second is time series of dates
export function useUserAssetData() {
    const [userAggAssets, setUserAggAssets] = useState([])
    const [dateTimeSeries, setDateTimeSeries] = useState([])

    async function getUserData() {
        try {
            const data = await DataStore.query(User);
            let filtered_data = data.filter(obj => obj.user_info.id === JSON.parse(localStorage.getItem('signed-in-user')).id)
            if (filtered_data[0]['data'] === null) {
                return [userAggAssets, dateTimeSeries]
            }
            else {
                const asset_data = filtered_data[0]['data']
                setDateTimeSeries(asset_data['datadate'])
                setUserAggAssets(asset_data['assets'])
            }
        } catch (error) {
            console.error('error:', error)
        }
    }
    useEffect(() => {
        getUserData();
        const subscription = DataStore.observe(User).subscribe(getUserData)
        return () => subscription.unsubscribe()
    }, []);
    return [userAggAssets, dateTimeSeries]
}


//custom hook that returns logged in users user obj
export function useUser() {
    const [data, setData] = useState(null)

    async function getUserData() {
        try {
            const all = await DataStore.query(User);
            let data = all.filter(obj => obj.user_info.id === JSON.parse(localStorage.getItem('signed-in-user')).id)
            setData(data[0])
        } catch (error) {
            console.error('error:', error)
        }
    }

    useEffect(() => {
        getUserData();
        const subscription = DataStore.observe(User).subscribe(getUserData)
        return () => subscription.unsubscribe()
    }, []);
    return data
}

export function useUsers() {
    const [data, setData] = useState(null)

    async function getUserData() {
        try {
            const all = await DataStore.query(User);
            setData(all)
        } catch (error) {
            setData(null)
            console.error('error:', error)
        }
    }

    useEffect(() => {
        getUserData();
        const subscription = DataStore.observe(User).subscribe(getUserData)
        return () => subscription.unsubscribe()
    }, []);
    return data
}

export function useNoUser() {
    const [data, setData] = useState(false)

    async function getUserData() {
        try {
            const all = await DataStore.query(User);
            let data = all.filter(obj => obj.user_info.id === JSON.parse(localStorage.getItem('signed-in-user')).id)
            if (data.length !== 0) {
                setData(false)
            }
            else {
                setData(true)
            }
        } catch (error) {
            setData(true)
        }
    }

    useEffect(() => {
        getUserData();
        const subscription = DataStore.observe(User).subscribe(getUserData)
        return () => subscription.unsubscribe()
    }, []);
    return data
}

//custom hook that returns logged in users role
export function useUserRole() {
    const [data, setData] = useState(null)

    const getUserData = async () => {
        try {
            const all = await DataStore.query(User);
            let data = all.filter(obj => obj.user_info.id === JSON.parse(localStorage.getItem('signed-in-user')).id)
            if(data[0]['email'].startsWith('indexpro-admin')){
                setData('Administrator')
            } else {
                setData(data[0].user_role)
            }
        } catch (error) {
            console.error('error:', error)
        }
    }

    useEffect(() => {
        getUserData();
        const subscription = DataStore.observe(User).subscribe(getUserData)
        return () => subscription.unsubscribe()
    }, []);
    return data
}


//custom hook that returns logged in users name
export function useUserName() {
    const [name, setName] = useState('')

    useEffect(() => {
        let getUserData = async () => {
            try {
                const all = await DataStore.query(User);
                let data = all.filter(obj => obj.user_info.id === JSON.parse(localStorage.getItem('signed-in-user')).id)
                setName(data[0]['first_name'] + ' ' + data[0]['last_name'])
            } catch (error) {
                console.error('error:', error)
            }
        }
        getUserData();
    }, []);
    return name
}


export function useUserEmail() {
    const [email, setEmail] = useState('')

    useEffect(() => {
        let getUserData = async () => {
            try {
                const all = await DataStore.query(User);
                let data = all.filter(obj => obj.user_info.id === JSON.parse(localStorage.getItem('signed-in-user')).id)
                setEmail(data[0]['email'])
            } catch (error) {
                console.error('error:', error)
            }
        }
        getUserData();
    }, []);
    return email
}

export function useUserTitle() {
    const [title, setTitle] = useState('')

    useEffect(() => {
        let getUserData = async () => {
            try {
                const all = await DataStore.query(User);
                let data = all.filter(obj => obj.user_info.id === JSON.parse(localStorage.getItem('signed-in-user')).id)
                setTitle(data[0]['title'])
            } catch (error) {
                console.error('error:', error)
            }
        }
        getUserData();
    }, []);
    return title
}


export function useUserFirmName() {
    const [firmName, setFirmName] = useState('Firm Name')

    useEffect(() => {
        let getUserData = async () => {
            try {
                const data = await DataStore.query(Firm);
                setFirmName(data[0]['firm_name'])
            } catch (error) {
                console.error('error:', error)
            }
        }
        getUserData();
    }, []);
    return firmName
}

export function useUserPhone() {
    const [phone, setPhone] = useState('')

    useEffect(() => {
        let getUserData = async () => {
            try {
                const all = await DataStore.query(User);
                let data = all.filter(obj => obj.user_info.id === JSON.parse(localStorage.getItem('signed-in-user')).id)
                setPhone(data[0]['phone_number'])
            } catch (error) {
                console.error('error:', error)
            }
        }
        getUserData();
    }, []);
    return phone
}

export function useUserPhoto() {
    const [photo, setPhoto] = useState("https://res.cloudinary.com/indexpro/image/upload/v1645053914/person_mrlnlr.jpg")

    useEffect(() => {
        let getUserData = async () => {
            try {
                const result = await Storage.list('profile_photo/', {
                    level: 'private'
                });
                let key = result.filter(i => i.key.startsWith('profile_photo/' + JSON.parse(localStorage.getItem('signed-in-user')).id))[0].key
                const photo = await Storage.get(key, {
                    level: 'private'
                });
                setPhoto(photo)
            } catch (error) {
                console.error('error:', error)
            }
        }
        getUserData();
    }, []);
    return photo
}

export function useUserFirmPhoto() {
    const [photo, setPhoto] = useState(default_firm_photo)

    useEffect(() => {
        let getUserData = async () => {
            try {
                const result = await Storage.list('firm_photo', {
                    level: 'private'
                });
                if (result.length === 0) {
                    return setPhoto(default_firm_photo)
                }
                let key = result.filter(i => i.key.startsWith('firm_photo'))[0].key
                const photo = await Storage.get(key, {
                    level: 'private'
                });
                setPhoto(photo)
            } catch (error) {
                console.error('error:', error)
            }
        }
        getUserData();
    }, []);
    return photo
}

export function useUserLogo() {
    const [logo, setLogo] = useState(ipLogo)

    useEffect(() => {
        let getUserData = async () => {
            try {
                const result = await Storage.list('firm_logo', {
                    level: 'private'
                });
                let key = result.filter(i => i.key.startsWith('firm_logo'))[0].key
                const photo = await Storage.get(key, {
                    level: 'private'
                });
                setLogo(photo)
            } catch (error) {
                console.error('error:', error)
            }
        }
        getUserData();
    }, []);
    return logo
}

export function useUserBio() {
    const [bio, setBio] = useState('')

    useEffect(() => {
        let getUserData = async () => {
            try {
                const all = await DataStore.query(User);
                let data = all.filter(obj => obj.user_info.id === JSON.parse(localStorage.getItem('signed-in-user')).id)
                setBio(data[0]['bio'])
            } catch (error) {
                console.error('error:', error)
            }
        }
        getUserData();
    }, []);
    return bio
}

export function useUserFirmBio() {
    const [bio, setBio] = useState('')

    useEffect(() => {
        let getUserData = async () => {
            try {
                const data = await DataStore.query(Firm);
                setBio(data[0]['firm_bio'])
            } catch (error) {
                console.error('error:', error)
            }
        }
        getUserData();
    }, []);
    return bio
}

export function useUserFirmEmail() {
    const [email, setEmail] = useState('')

    useEffect(() => {
        let getUserData = async () => {
            try {
                const data = await DataStore.query(Firm);
                setEmail(data[0]['firm_email'])
            } catch (error) {
                console.error('error:', error)
            }
        }
        getUserData();
    }, []);
    return email
}


export function useUserFirmWebsite() {
    const [website, setWebsite] = useState('')

    useEffect(() => {
        let getUserData = async () => {
            try {
                const data = await DataStore.query(Firm);
                setWebsite(data[0]['firm_website'])
            } catch (error) {
                console.error('error:', error)
            }
        }
        getUserData();
    }, []);
    return website
}


export function useUserFirmCity() {
    const [city, setCity] = useState('')

    useEffect(() => {
        let getUserData = async () => {
            try {
                const data = await DataStore.query(Firm);
                setCity(data[0]['firm_city'])
            } catch (error) {
                console.error('error:', error)
            }
        }
        getUserData();
    }, []);
    return city
}


//custom hook that returns onboard data
export function useOnboardingData() {
    const [onboarding_data, setData] = useState({})
    const getOnboardingData = async () => {
        try {
            const data = await DataStore.query(Onboarding);
            let filtered_data = data.filter(obj => obj.user_info.id === JSON.parse(localStorage.getItem('signed-in-user')).id)
            const user_data = await DataStore.query(User);
            let filtered_user_data = user_data.filter(obj => obj.user_info.id === JSON.parse(localStorage.getItem('signed-in-user')).id)
            const strategy_data = await DataStore.query(CustomStrategies);
            let filtered_strategy_data = strategy_data.filter(obj => obj.user_info.id === JSON.parse(localStorage.getItem('signed-in-user')).id)
            const account_data = await DataStore.query(Account);
            let filtered_account_data = account_data.filter(obj => obj.user_info.id === JSON.parse(localStorage.getItem('signed-in-user')).id)
            if (filtered_data[0]) {
                setData(filtered_data[0]['data'])
            }
            if (filtered_user_data[0]) {
                const picked_user_data = pick('phone_number', 'title', 'bio')(filtered_user_data[0])
                const profile_complete = !Object.values(picked_user_data).includes(null)
                if (profile_complete) {
                    setData((prevState) => ({...prevState, 'Customize your profile': profile_complete}))
                }
            }
            if (filtered_strategy_data.length !== 0) {
                setData((prevState) => ({...prevState, 'Create your first strategy': true}))
            }
            if (filtered_account_data.length !== 0) {
                setData((prevState) => ({...prevState, 'Create your first account': true}))
            }
        } catch (error) {
            console.error('error:', error)
        }
    }

    useEffect(() => {
        getOnboardingData();
        const subscription = DataStore.observe(Onboarding).subscribe(getOnboardingData)
        return () => subscription.unsubscribe()
    }, []);
    return onboarding_data

}


export function useUserProfilePictures() {
    const [accounts, setAccounts] = useState({})

    const getProfilePics = async (id) => {
        try {
            const result = await Storage.list('profile_photo/', {
                level: 'private'
            });
            let key = result.filter(i => i.key.startsWith('profile_photo/' + id))[0]
            const defaultPhoto = 'https://res.cloudinary.com/indexpro/image/upload/v1645053914/person_mrlnlr.jpg'
            if (key) {
                key = key.key
                const photo = await Storage.get(key, {
                    level: 'private'
                });
                return photo
            }
            else {
                return defaultPhoto
            }

        } catch (error) {
            console.error('error:', error)
        }
    }
    useEffect(() => {
        let getAccountData = async () => {
            try {
                const data = await DataStore.query(User);
                const users = data.map(i => i.user_info.id)
                const unique_ids = [... new Set(users)]
                const obj = {}
                for (const i of unique_ids) {
                    obj[i] = await getProfilePics(i)
                }
                setAccounts(obj)
            } catch (error) {
                console.error('error:', error)
            }
        }
        getAccountData();
    }, []);
    return accounts
}

export function useFirmUserData() {
    const [data, setData] = useState([])

    async function getFirmUserData() {
        try {
            const data = await DataStore.query(User);
            const current = JSON.parse(localStorage.getItem('signed-in-user'))['id']
            const x = []
            for (const item of data) {
                if(!item['email'].startsWith('indexpro-admin')) {
                    x.push({
                        'id': item['user_info']['id'],
                        'name': item['first_name'] + ' ' + item['last_name'],
                        'email': item['email'],
                        'user_role' : item['user_role'],
                        'obj_id': item['id']
                    })
                }
            }
            x.sort((a, b) => a.name.localeCompare(b.name))
            var index = x.findIndex(p => p.id === current);
            x.unshift(x.splice(index, 1)[0]);
            setData(x.filter(item => item !== undefined))
        } catch (error) {
            console.error('error:', error)
        }
    }

    useEffect(() => {
        getFirmUserData();
        const subscription = DataStore.observe(User).subscribe(getFirmUserData)
        return () => subscription.unsubscribe();
    }, []);
    return data
}

export function useTeams() {
    const [data, setData] = useState([])

    async function getTeamData() {
        try {
            const data = await DataStore.query(Team);
            setData(data)
        } catch(error) {
            console.error('error:', error)
        }
    }

    useEffect(()=>{
        getTeamData();
        const subscription = DataStore.observe(Team).subscribe(getTeamData)
        return () => subscription.unsubscribe()
    }, [])
    return data
}

export function useUserTeamFilterInfo() {
    const [data, setData] = useState([])

    async function getTeamData() {
        try {
            const all_users = await DataStore.query(User);
            let user = all_users.filter(obj => obj.user_info.id === JSON.parse(localStorage.getItem('signed-in-user')).id)[0]
            const data = await DataStore.query(Team);
            let filtered = data.filter(i => safeAWSJSONArrayQuery(i.team_users).some(u => u.obj_id === user.id))
            let team_obj_ids = filtered.map(i => safeAWSJSONArrayQuery(i.team_users)).flat(1).map(i => i.obj_id)
            let admin_ids = all_users.filter(i => i.user_role === 'Administrator').map(i => i.id)
            let super_admin_id = all_users.filter(i => i.email.startsWith('indexpro-admin@')).map(i => i.id)
            team_obj_ids = [...new Set(team_obj_ids.concat(admin_ids).concat(super_admin_id).concat([user.id]))]
            let team_user_info_objs = all_users.filter(i => team_obj_ids.includes(i.id)).map(i => i.user_info)
            setData(team_user_info_objs.map(i => i.id))
        } catch(error) {
            console.error('error:', error)
        }
    }

    useEffect(()=>{
        getTeamData();
        const subscription = DataStore.observe(Team).subscribe(getTeamData)
        return () => subscription.unsubscribe()
    }, [])
    return data

}