import React from "react";
import {Paper, Button, Grid} from "@material-ui/core";
import {observer} from "mobx-react-lite"
import {control_paper_left, card_subtitle, card_title} from "../../Styles/styles";

function Summary({builder_state}) {
    const button_map = {false: 'No', true: 'Yes'}
    const benchmark_name = builder_state.benchmark_name
    const core_options = builder_state.core_options
    const custom_options = builder_state.custom_options
    const custom_exclusions = builder_state.custom_exclusions
    const revenue_threshold_exclusions5 = builder_state.revenue_threshold_exclusions5
    const revenue_threshold_exclusionsAny = builder_state.revenue_threshold_exclusionsAny
    const selected_themes = builder_state.themes
    const tax_preference = builder_state.tax_preference
    const capital_gain_management = builder_state.capital_gain_management
    const transition_management = builder_state.transition_management
    const tracking_error = builder_state.tracking_error

    return (
        <Paper style={Object.assign({}, control_paper_left, {paddingBottom: "30px"})} elevation={0} square>
            <p className="SummaryYourIndex">Your Index</p>
            <p className="SummaryTitle">{benchmark_name}</p>
            <p style={Object.assign({},card_subtitle,{marginLeft: "10px", marginBottom: "2px", marginTop: "15px"})}>Cost As Built</p>
            <p style={Object.assign({},card_title,{marginLeft: "10px", marginTop:"0px", fontSize: "30px"})}></p>
            <div className="SummaryDivider"/>
            <p className="SummaryHeader">Benchmark</p>
            <p className="SummaryDescription">{benchmark_name}</p>
            <p className="SummaryHeader">Core Values</p>
            {Array.from(core_options).map(function (item, index) {
                    return (
                        <p className="SummaryDescription">{item}</p>
                    )
                }
            )}
            <p className="SummaryHeader">Custom Values</p>
            {Array.from(custom_options).map(function (item, index) {
                    return (
                        <p className="SummaryDescription">{item}</p>
                    )
                }
            )}
            <p className="SummaryHeader">Exclusions</p>
            {Array.from(custom_exclusions).map(function (item, index) {
                    return (
                        <p className="SummaryDescription">{item}</p>
                    )
                }
            )}
            {Array.from(revenue_threshold_exclusionsAny).map(function (item, index) {
                    return (
                        <p className="SummaryDescription">Any {item} Revenue</p>
                    )
                }
            )}
            {Array.from(revenue_threshold_exclusions5).map(function (item, index) {
                    return (
                        <p className="SummaryDescription">> 5% {item.split(' 5%')[0]} Revenue</p>
                    )
                }
            )}
            <p className="SummaryHeader">Themes</p>
            {Array.from(selected_themes).map(function (item, index) {
                return (
                    <p className="SummaryDescription">{item}</p>
                )
                }
            )}

            <p className="SummaryHeader">Tax</p>
            <p className="SummaryDescription">{tax_preference}</p>
            <p className="SummaryHeader">Performance</p>
            <p className="SummaryDescription">Tracking Error: <span style={{fontWeight: 600}}>{tracking_error}%</span></p>
            <div className="SummaryDivider"/>
            <Grid container direction="row" justifyContent="space-between">
            <p style={Object.assign({},card_title,{marginLeft: "10px"})}>Total Cost</p>
            <p style={Object.assign({},card_title,{marginRight: "10px"})}></p>
            <div style={{marginTop: "80px"}}/>
            </Grid>
        </Paper>
    )
}

export default observer(Summary);