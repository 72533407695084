import _ from "lodash";
import SnackbarUtils from "../Utils/snackbar_utils"

export function userRoleActionWrapper(data_user, role, fn){
    const user = JSON.parse(localStorage.getItem("signed-in-user"))
    if (role === 'Administrator') {
        fn()
    } else if (role === 'Creator' && data_user === null) {
        fn()
    } else if (role === 'Creator' && data_user !== null) {
        if (_.isEqual(user, data_user)){
            fn()
        }
        else {
            SnackbarUtils.error("You are not the owner of object, please contact the owner to make changes or contact your administrator to update your user role.")
        }
    } else {
        SnackbarUtils.error("You are an explorer, please contact your administrator to update your user role.")
    }
}

export function adminOnlyActionWrapper(role, fn){
    if (role === 'Administrator') {
        fn()
    } else {
        SnackbarUtils.error("Only administrators can edit this, please contact your administrator to update your user role.")
    }
}