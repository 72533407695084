// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Earnings, Team, AllocationModel, CustomAlphaModel, PublicFundData, Onboarding, CustomStrategies, CustodianMaster, BenchmarkLogo, Benchmarks, Strategies, Themes, RevenueThresholdExclusions, CustomExclusions, CustomOptions, PreConfiguredValues, Account, Client, User, Firm } = initSchema(schema);

export {
  Earnings,
  Team,
  AllocationModel,
  CustomAlphaModel,
  PublicFundData,
  Onboarding,
  CustomStrategies,
  CustodianMaster,
  BenchmarkLogo,
  Benchmarks,
  Strategies,
  Themes,
  RevenueThresholdExclusions,
  CustomExclusions,
  CustomOptions,
  PreConfiguredValues,
  Account,
  Client,
  User,
  Firm
};