import {Button, Grid, Paper, TextField} from "@material-ui/core";
import {
    account_preferences_icon,
    alpha_icon,
    benchmark_icon,
    bordered_button,
    card_subtitle,
    card_subtitle_black,
    card_subtitle_grey,
    card_title,
    exclusions_icon,
    paper_right,
    revenue_icon,
    risk_icon,
    tax_icon,
    themeBackground,
    themes_icon,
    values_icon,
    themeColor,
    security_exclusion_icon
} from "../../Styles/styles";
import {userRoleActionWrapper} from "../../Utils/role_utils";
import React from "react";
import Modal from "@material-ui/core/Modal";
import Fab from "@material-ui/core/Fab";
import ClearIcon from "@material-ui/icons/Clear";
import {Alert} from "@material-ui/lab";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {useUserRole} from "../Hooks/Data/User/user";
import {DataStore} from "@aws-amplify/datastore";
import {Account} from "../../models";
import {useCustomStrategies} from "../Hooks/Data/Strategies/strategies";
import {Mixpanel} from "../../Utils/Mixpanel";
import {useHistory} from 'react-router-dom'

function AccountPreferences(props) {
    const history = useHistory();
    //hooks
    const user_role = useUserRole()
    const customStrategyItems = useCustomStrategies();

    const [account_selected_strategy, setAccountStrategy] = React.useState(null)

    //modal
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    //save new strategy
    const handleSaveStrategy = async () => {
        await DataStore.save(Account.copyOf(props.account, item => {
            item["custom_strategy_id"] = customStrategyItems.filter(x => x.strategy_name === account_selected_strategy)[0]["id"]
        }));
        handleClose();
    }


    return (
        <div className="Client-Preferences">
            <Paper style={Object.assign({}, paper_right, {paddingBottom: "20px"})} elevation={0}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <div>
                        <p style={card_title}>Account Preferences</p>
                        {props.strategy ? (
                            <p style={Object.assign({}, card_subtitle, {color: themeColor, cursor: 'pointer'})} onClick={() => history.push(`/participant/strategies/custom/${props.strategy.id}`)}>{props.strategy['strategy_name']}</p>
                        ) : (<></>)
                        }
                    </div>
                    {props.editable ? (
                        <div>
                            <Button
                                onClick={() => userRoleActionWrapper(props.account.user_info, user_role, handleOpen)}
                                style={Object.assign({}, bordered_button, {marginRight: "20px"})}>
                                Change
                            </Button>
                        </div>) : (
                        <></>
                    )
                    }
                </Grid>
                {props.strategy ? (
                    <Grid container direction="row" alignItems="flex-start">
                        <Grid item xs={6}>
                            <div style={account_preferences_icon}>
                                <img
                                    src={benchmark_icon}
                                    alt="dashboard"
                                    width="30" height="30"
                                    style={{marginRight: "10px"}}
                                />
                                <p style={card_subtitle_black}>Benchmark</p>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <p style={card_subtitle_grey}>{props.strategy.settings['benchmark']}</p>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={account_preferences_icon}>
                                <img
                                    src={values_icon}
                                    alt="dashboard"
                                    width="30" height="30"
                                    style={{marginRight: "10px"}}
                                />
                                <p style={card_subtitle_black}>Values</p>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <p style={card_subtitle_grey}>{props.strategy.settings['values'].join(", ")}</p>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={account_preferences_icon}>
                                <img
                                    src={exclusions_icon}
                                    alt="dashboard"
                                    width="30" height="30"
                                    style={{marginRight: "10px"}}
                                />
                                <p style={card_subtitle_black}>Exclusions</p>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <p style={card_subtitle_grey}>{props.strategy.settings['custom_exclusions'].join(", ")}</p>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={account_preferences_icon}>
                                <img
                                    src={security_exclusion_icon}
                                    alt="dashboard"
                                    width="30" height="30"
                                    style={{marginRight: "10px"}}
                                />
                                <p style={card_subtitle_black}>Security Exclusions</p>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <p style={card_subtitle_grey}>{props.strategy.settings['security_exclusions']  ? (props.strategy.settings['security_exclusions'].map(i => i.company_name).join(", ")) : ('')}</p>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={account_preferences_icon}>
                                <img
                                    src={revenue_icon}
                                    alt="dashboard"
                                    width="30" height="30"
                                    style={{marginRight: "10px"}}
                                />
                                <p style={card_subtitle_black}>Revenue</p>
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <p style={card_subtitle_grey}>{props.strategy.settings
                                ['revenue_any_exclusions'].concat(props.strategy.settings['revenue_5pct_exclusions']).join(", ")}</p>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={account_preferences_icon}>
                                <img
                                    src={themes_icon}
                                    alt="dashboard"
                                    width="30" height="30"
                                    style={{marginRight: "10px"}}
                                />
                                <p style={card_subtitle_black}>Themes</p>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <p style={card_subtitle_grey}>{props.strategy.settings['themes'].join(", ")}</p>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={account_preferences_icon}>
                                <img
                                    src={tax_icon}
                                    alt="dashboard"
                                    width="30" height="30"
                                    style={{marginRight: "10px"}}
                                />
                                <p style={card_subtitle_black}>Tax</p>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <p style={card_subtitle_grey}>{props.strategy.settings['tax_preference'] ? (props.strategy.settings['tax_preference']) : ('No Tax Strategy')}
                            </p>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={account_preferences_icon}>
                                <img
                                    src={alpha_icon}
                                    alt="dashboard"
                                    width="30" height="30"
                                    style={{marginRight: "10px"}}
                                />
                                <p style={card_subtitle_black}>Alpha</p>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <p style={card_subtitle_grey}>{props.strategy.settings['alpha'] ? (props.strategy.settings['alpha']) : ('No Alpha')}</p>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={account_preferences_icon}>
                                <img
                                    src={risk_icon}
                                    alt="dashboard"
                                    width="30" height="30"
                                    style={{marginRight: "10px"}}
                                />
                                <p style={card_subtitle_black}>Risk</p>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <p style={card_subtitle_grey}>Target Tracking
                                Error: {props.strategy.settings['tracking_error']}%
                                , Max Position
                                Count: {props.strategy.settings['max_position_count'] ? (props.strategy.settings['max_position_count']) : ('None')}
                                , Rebalance Frequency: {props.strategy.settings['rebalance_frequency']}</p>
                        </Grid>
                    </Grid>
                ) : (
                    <></>
                )}
            </Paper>
            <Modal open={open}>
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Paper elevation={0} style={{
                        width: "600px", height: "340px", marginTop: "300px"
                    }}>
                        <Grid container direction="row">
                            <Grid item xs={10} style={{paddingTop: "25px"}}>
                                <p style={card_title}>Change Strategy</p>
                            </Grid>
                            <Grid item xs={2} style={{paddingRight: "20px", paddingTop: "20px"}}>
                                <Fab onClick={handleClose} style={{float: "right"}}>
                                    <ClearIcon/>
                                </Fab>
                            </Grid>
                            <div style={{
                                width: "100%",
                                marginTop: "30px",
                                marginBottom: "10px",
                                borderBottom: "2px solid #dadce0"
                            }}/>
                            <Grid item xs={12} style={{marginLeft: "20px", marginRight: "20px"}}>
                                <Alert severity="info">Select a new strategy for your account or create one <a
                                    href="/participant/builder">here</a>!</Alert>
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={customStrategyItems.map(function (el) {
                                        return el.strategy_name;
                                    })}
                                    style={{
                                        width: "560px",
                                        marginLeft: "20px",
                                    }}
                                    margin="normal"
                                    onChange={(option) => setAccountStrategy(option.target.innerText)}
                                    value={account_selected_strategy}
                                    renderInput={(params) => <TextField
                                        style={{
                                            backgroundColor: themeBackground,
                                        }}
                                        margin="normal"

                                        {...params} label="Strategy Name" variant="outlined"
                                    />}
                                />
                            </Grid>
                            <Grid item xs={12} style={{marginLeft: "430px", marginTop: "10px"}}>
                                <Button style={Object.assign({}, bordered_button, {width: "150px"})}
                                        onClick={() => {
                                            Mixpanel.track('Changed Strategy on Account');
                                            window.Intercom('trackEvent', 'changed-strategy-on-account');
                                            handleSaveStrategy()
                                        }}
                                >Save Strategy</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </Modal>
        </div>
    )
}

export default AccountPreferences