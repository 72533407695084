import {big_paper} from "../../Styles/styles";
import Paper from "@material-ui/core/Paper";
import {Grid} from "@material-ui/core";
import React from "react";

function HelpAccounts() {
    return (
        <div>
            <Paper style={big_paper} elevation={0}>
                <div style={{padding: "20px"}}>
                    <p style={{fontWeight: "600", fontSize: "18px"}}>Video Tutorial: Accounts</p>
                    <Grid item xs={12} style={{marginTop: "20px"}}>
                        <iframe
                            width="1240"
                            height="750"
                            src={`https://res.cloudinary.com/indexpro/video/upload/Onboarding%20Videos/Accounts.mp4`}
                            frameBorder="0"
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Product Tour"
                        />
                    </Grid>
                    <br/><br/>
                    <p style={{fontWeight: "600", fontSize: "18px"}}>Accounts Section Overview:</p>
                    <br/>
                    The <span style={{fontWeight: "600"}}>Accounts</span> page hosts all the client accounts you have created and onboarded to Index Pro.
                    It features a Participation (%) rate section which shows the percentage of accounts that are live,
                    while the compliance checkmark confirms there are no onboarding compliance errors found.
                    <br/><br/>
                    <img src={"https://res.cloudinary.com/indexpro/image/upload/Help%20Section%20Images/Participation-Compliance.png"}
                         alt="participation-compliance" width="900px"/>
                    <br/><br/>
                    The list provides you an overview of the account, including the account number, whether it is a live account or not, the strategy it is following, the AUM, etc.
                    <br/><br/>
                    <img src={"https://res.cloudinary.com/indexpro/image/upload/Help%20Section%20Images/Accounts.png"}
                         alt="accounts" width="900px"/>
                     <br/><br/>
                    <p style={{fontWeight: "600", fontSize: "18px"}}>Accounts Page Explained:</p>
                    <br/>
                    You can view each account in more detail by simply clicking on the account name or number.
                    At the very top you will see an overview of your account, starting with the current balance (<span style={{fontWeight: "600"}}>Total Balance</span>),
                    performance since the investment was made (<span style={{fontWeight: "600"}}>ITD Performance</span>),
                    the dollar value of that performance (<span style={{fontWeight: "600"}}>Total Earnings</span>), and the strategy it is following (<span style={{fontWeight: "600"}}>Strategy</span>).
                    <br/><br/>
                    <img src={"https://res.cloudinary.com/indexpro/image/upload/Help%20Section%20Images/Account-Overview.png"}
                         alt="account-overview" width="900px"/>
                    <br/><br/>
                    <Grid container direction="row" >
                        <div style={{width: "600px", marginRight: "20px"}}>
                            The <span style={{fontWeight: "600"}}>Account Preferences</span> section provides a summary of the selections you have made, such as values, exclusions, tax preferences, etc.
                            <br/><br/>
                            <img src={"https://res.cloudinary.com/indexpro/image/upload/Help%20Section%20Images/Account-Preferences.png"}
                                 alt="account-preferences" width="400px"/>
                        </div>
                        <div style={{width: "600px"}}>
                            The <span style={{fontWeight: "600"}}>Account History</span> graph is interactive and shows the dollar amount in your account over time.
                            You can move the mouse cursor to any point on the graph to view the AUM on a specific date.
                            <br/><br/>
                            <img src={"https://res.cloudinary.com/indexpro/image/upload/Help%20Section%20Images/Account-History.png"}
                                 alt="account-history" width="450px"/>
                        </div>
                    </Grid>
                    <br/><br/>
                    The <span style={{fontWeight: "600"}}>Portfolio</span> section hosts your <span style={{fontWeight: "600"}}>Top 10 Holdings</span> and <span style={{fontWeight: "600"}}>Sectors</span> breakdown.
                    Your top holdings are ranked by the percentage weight in your account, from the largest to the smallest.
                    Besides the weight, the <span style={{fontWeight: "600"}}>Holdings</span> table also contains
                    the <span style={{fontWeight: "600"}}>Company Name</span>, <span style={{fontWeight: "600"}}>Ticker</span>, and the current <span style={{fontWeight: "600"}}>Market Value</span> of each holding in your account.
                    <br/><br/>
                    <img src={"https://res.cloudinary.com/indexpro/image/upload/Help%20Section%20Images/Account-Holdings.png"}
                         alt="account-holdings" width="900px"/>
                    <br/><br/>
                    <span style={{fontWeight: "600"}}>Sectors</span> are ranked by their weight in your portfolio, from the largest to the smallest.
                    Besides the weight, the <span style={{fontWeight: "600"}}>Sectors</span> table also shows the <span style={{fontWeight: "600"}}>Market Value</span> of each sector in your account.
                    <br/><br/>
                    <img src={"https://res.cloudinary.com/indexpro/image/upload/Help%20Section%20Images/Account-Sectors.png"}
                         alt="account-sectors" width="900px"/>
                    <br/><br/>
                    The <span style={{fontWeight: "600"}}>Activity</span> section shows the top 10 trades in your account during the past 30 days,
                    based on the trade value, whether it was a buy or sell transaction, the transaction date, and its trade value.
                    <br/><br/>
                    <img src={"https://res.cloudinary.com/indexpro/image/upload/Help%20Section%20Images/Account-Activity.png"}
                         alt="account-activity" width="900px"/>
                    <br/><br/>
                    The <span style={{fontWeight: "600"}}>Impact Summary</span> shows the improvements of your account, based on MSCI ratings, in comparison to the benchmark or benchmarks utilized.
                    <br/><br/>
                    <img src={"https://res.cloudinary.com/indexpro/image/upload/Help%20Section%20Images/Account-Impact.png"}
                         alt="account-impact" width="900px"/>
                    <br/><br/>
                    For more help with your <span style={{fontWeight: "600"}}>Account</span> please e-mail us at <a href={"mailto: indexpro@atomlp.com"}>indexpro@atomlp.com</a> or use the chat bubble on the bottom right of this page to chat with us.
                </div>
            </Paper>
        </div>
    )
}

export default HelpAccounts