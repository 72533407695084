import {Grid} from "@material-ui/core";
import React from "react";
import {superScript, TaxStartingNumber} from "../../Utils/compliance_utils";

export const Performance = (type, headers, page_number, page_number_tax, performance_data, transition_data) => {
    return (
        <div style={{height: "100%", position: "relative", color: "black",  fontDisplay: "swap"}}>
            <div style={{
                position: "absolute",
                top: "54px",
                right: "60px",
                fontSize: "14px",
                fontWeight: "600",
                color: "black"
            }}>{page_number}
            </div>
            <Grid container justifyContent="flex-start" style={{
                position: "absolute", top: "40px", left: "60px",
                fontSize: "14px", color: "#c2c2c1", fontWeight: "600", width: "800px"
            }}>
                <p style={{marginRight: "25px"}}>Portfolio</p>
                {headers.map(title => {
                    if (title === type) {
                        return (
                            <p style={{marginRight: "25px", color: "#009494"}}>{title}</p>
                        )
                    }
                    else {
                        return(
                            <p style={{marginRight: "25px"}}>{title}</p>
                        )
                    }
                })}
            </Grid>
            <div style={{
                position: "absolute",
                top: "140px",
                left: "60px",
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: 0.96
            }}>Active Performance<br/>Selections
            </div>
            <div style={{
                position: "absolute", top: "240px", marginLeft: "60px", marginRight: "60px",
                width: "755px", fontSize: "16px", fontWeight: "600"
            }}>
                Your model portfolio strategy includes active alpha {superScript(TaxStartingNumber(page_number_tax, transition_data, headers) + 1)} signals seeking to optimize performance.
                Bottom-up stock insights can potentially make your values, thematic, and tax choices even more effective.
            </div>
            <div style={{
                position: "absolute", top: "320px", marginLeft: "60px", marginRight: "60px",
                width: "755px", fontSize: "14px", fontWeight: "600", borderBottom: "1px solid black"
            }}>
                TOP ALPHA SIGNALS {superScript(TaxStartingNumber(page_number_tax, transition_data, headers) + 2)}
            </div>
            <Grid container direction="row" justifyContent="space-between" style={{
                position: "absolute", top: "360px", marginLeft: "60px", marginRight: "60px",
                width: "755px", fontSize: "14px", fontWeight: "600",
                height: "755px"
            }}>
                {performance_data.map(function (item, index) {
                    return (
                        <div style={{
                            backgroundColor: "#f6f7f8",
                            width: "170px",
                            height: "170px",
                            color: "black",
                            textAlign: "center"
                        }}>
                            <img src={`https://companiesmarketcap.com/img/company-logos/64/${item.ticker}.webp`}
                                 onError={({ currentTarget }) => {
                                     currentTarget.onerror = null;
                                     currentTarget.src="https://res.cloudinary.com/indexpro/image/upload/Misc/Company.png";
                                 }}
                                 height="35px"
                                 alt={item.ticker}
                                 style={{marginTop: "40px", textAlign: "center"}}/>
                            <p style={{fontSize: "12px", textAlign: "center", marginBottom: "0px"}}>{item.company_name.slice(0,29)}</p>
                            <p style={{
                                fontSize: "12px",
                                textAlign: "center",
                                fontWeight: "500",
                                marginTop: "0px",
                                color: "#555555"
                            }}>{item.ticker}</p>
                            <p style={{
                                fontSize: "12px",
                                textAlign: "center",
                                fontWeight: "500",
                                color: "#555555"
                            }}>{item.sector}</p>
                        </div>
                    )
                })
                }
            </Grid>
        </div>
    )
}
