import {
    account_onboarding_completed_line, account_onboarding_progress_line,
    account_onboarding_time,
    account_onboarding_title,
    card_subtitle,
    card_title,
    paper_left
} from "../../Styles/styles";
import {Grid, Paper} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import React from "react";

function AccountOnboarding(props) {
    return (
        <div className="Account-Onboarding">
            <Paper style={paper_left} elevation={0}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <div>
                        <p style={card_title}>Account Onboarding</p>
                        <p style={card_subtitle}>Account Onboarding Status</p>
                    </div>
                </Grid>
                <div style={{marginLeft: "20px", marginTop: "-20px"}}>
                    <Grid container direction="row" alignItems="center">
                        <CheckCircleIcon style={{color: "#34C36B"}}/>
                        <Grid item>
                            <p style={account_onboarding_time}>{new Date(props.account.createdAt).toString()}</p>
                            <p style={account_onboarding_title}>Account Created</p>
                        </Grid>
                    </Grid>
                    <div style={account_onboarding_completed_line}/>
                    <Grid container direction="row" alignItems="center">
                        <FiberManualRecordIcon style={{color: "#B5B4B9"}}/>
                        <Grid item>
                            <p style={account_onboarding_time}></p>
                            <p style={account_onboarding_title}>Onboarding Started</p>
                        </Grid>
                    </Grid>
                    <div style={account_onboarding_progress_line}/>
                    <Grid container direction="row" alignItems="center">
                        <FiberManualRecordIcon style={{color: "#B5B4B9"}}/>
                        <Grid item>
                            <p style={account_onboarding_time}></p>
                            <p style={account_onboarding_title}>Custodian Connected</p>
                        </Grid>
                    </Grid>
                    <div style={account_onboarding_progress_line}/>
                    <Grid container direction="row" alignItems="center">
                        <FiberManualRecordIcon style={{color: "#B5B4B9"}}/>
                        <Grid item>
                            <p style={account_onboarding_time}></p>
                            <p style={account_onboarding_title}>Ready to Invest</p>
                        </Grid>
                    </Grid>
                    <div style={Object.assign({}, account_onboarding_progress_line, {height: "40px"})}/>
                </div>
            </Paper>
        </div>
    )
}

export default AccountOnboarding