import {Grid, Paper} from "@material-ui/core";
import {big_paper, card_subtitle, card_title, color_filters} from "../../Styles/styles";
import React from "react";

function AccountTransitionImpact(props) {
    return (
        <div className="Impact-Summary">
            <Paper style={Object.assign({}, big_paper, {paddingBottom: "20px"})}
                   elevation={0}>
                <div>
                    <p style={card_title}>Impact Summary</p>
                    <p style={card_subtitle}>Target vs. Input</p>
                </div>
                {props.account["transition_data"] ? (
                    <div>
                        {props.account ? (
                            <div>
                                <Grid container direction="row" justifyContent="flex-start"
                                      alignItems="center" style={{marginLeft: "20px", marginRight: "20px"}}>
                                    {props.account["transition_data"]['impact_data'].map((item, x) =>
                                        <>
                                            <Grid item md={1} style={{marginTop: "20px"}}>
                                                <img
                                                    src={`https://res.cloudinary.com/indexpro/image/upload/Impact Icons/${item.title}.svg`}
                                                    height="50px"
                                                    style={{
                                                        textAlign: "center",
                                                        maxWidth: "95px",
                                                        filter: color_filters[x]
                                                    }}/>
                                            </Grid>
                                            <Grid item md={5} style={{marginTop: "20px"}}>
                                                <p style={{
                                                    fontSize: "15px",
                                                    marginBottom: "0px",
                                                    fontWeight: "600"
                                                }}>{item.title}</p>
                                                <p style={{
                                                    fontSize: "15px",
                                                    fontWeight: "500",
                                                    marginTop: "0px",
                                                    color: "#555555"
                                                }}>{item.pct} improvement in MSCI {item.title} score</p>
                                            </Grid>
                                        </>
                                    )
                                    }
                                </Grid>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                ) : (
                    <></>
                )
                }
            </Paper>
        </div>
    )
}

export default AccountTransitionImpact