import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {Button, Grid, Paper} from "@material-ui/core"
import {
    card_title,
    card_subtitle,
    paper_right,
    builder_paper_right, themeColor, themeBackground, ValueCell
} from "../../Styles/styles";
import Chart from "react-apexcharts";
import {useBenchmarkData,} from "../Hooks/Data/Builder/builder";
import Lottie from "react-lottie";
import optimizing from "../../Styles/optimizing.json";


function SectorBenchmark({builder_state}) {
    let benchmark_name = builder_state.benchmark_name
    let allocation_model_id = builder_state.allocation_model_id
    const benchmark_data = useBenchmarkData(benchmark_name, allocation_model_id)

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: optimizing,
    };

    if (!benchmark_data && !allocation_model_id) {
        return (
            <></>
        )
    } else {
        const options = {
            chart: {
                fontFamily: "Poppins",
                type: 'bar',
                toolbar: {
                    show: false
                },

            },
            grid: {
                show: false,
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    distributed: true,
                    horizontal: true,
                    dataLabels: {
                        position: "bottom"
                    }
                }
            },
            colors: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf', '#aec7e8'],
            dataLabels: {
                enabled: true,
                offsetX: 12,
                crop: true,
                style: {
                    colors: ['#fff'],
                    fontSize: "12px",
                    fontWeight: "400"
                },
                formatter: function (val) {
                    if (val >= 2) {
                        return val.toFixed(1) + '%'
                    }
                },
                dropShadow: {
                    enabled: false
                }
            },
            yaxis: {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    show: true,
                    style: {
                        fontSize: "12px",
                    }
                }
            },
            xaxis: {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    show: false
                }
            },
            legend: {
                show: false
            },

        }

        return (
            <Paper style={builder_paper_right} elevation={0}>
                <div>
                    <p style={card_title}>Sector Breakdown</p>
                    <p style={card_subtitle}>Sector Breakdown</p>
                </div>
                {!benchmark_data && allocation_model_id ? (
                    <>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Lottie options={defaultOptions} height={250} width={250}/>
                        </Grid>
                    </>
                ) : (
                    <div style={{marginLeft: "10px", marginRight: "10px"}}>
                        <Chart options={options} series={[{
                            name: 'Weight',
                            data: benchmark_data['sector_breakdown'].slice().sort(function (a, b) {
                                return b.y - a.y
                            })
                        }]} type="bar"/>
                    </div>
                )
                }
            </Paper>
        )
    }
}


export default observer(SectorBenchmark);
