import React from "react";
import {observer} from "mobx-react-lite";
import {Grid, Paper} from "@material-ui/core"
import {
    card_title,
    card_subtitle,
    builder_paper_right,
} from "../../Styles/styles";
import {percentageNum, millionNumWithCommas} from "../../Utils/num_utils";
import {useAllocationModel, useBenchmarkData} from "../Hooks/Data/Builder/builder";
import Lottie from "react-lottie";
import optimizing from "../../Styles/optimizing.json";
import Chart from "react-apexcharts";

function BenchmarkMetrics({builder_state}) {

    let benchmark_name = builder_state.benchmark_name
    let allocation_model_id = builder_state.allocation_model_id
    const benchmark_data = useBenchmarkData(benchmark_name, allocation_model_id)
    const allocation_model = useAllocationModel(allocation_model_id)
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: optimizing,
    };

    if (!benchmark_data && !allocation_model_id) {
        return (
            <></>
        )
    }

    const options = {
        chart: {
            fontFamily: "Poppins",
            toolbar: {
                show: false
            },

        },
        plotOptions: {
            treemap: {
                distributed: true,
                enableShades: false,
            }
        },

        colors: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf', '#aec7e8'],
        dataLabels: {
            offsetX: 0,
            offsetY: -5,
            style: {
                colors: ['#fff'],
                fontWeight: "400",
            },
            dropShadow: {
                enabled: false
            },
        },
        tooltip: {
            enabled: true,
            onDatasetHover: {
                highlightDataSeries: true,
            },
            x: {
                show: true,
                formatter: function (value, { w }) {
                    return w.globals.categoryLabels[value - 1]
                },
            },
            y: {
                formatter: function(val) {
                    return val + '%'
                },
                title: {
                    formatter: function (seriesName) {
                        return "Allocation: "
                    }
                }
            }
        },
    }
    return (
        <div>
            <Paper style={builder_paper_right} elevation={0}>
                {!benchmark_data && allocation_model_id ? (
                    <>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <div>
                                <p style={card_title}>Allocation Model Running</p>
                                <p style={card_subtitle}>Your allocation model will be ready to view shortly</p>
                            </div>
                        </Grid>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Lottie options={defaultOptions} height={250} width={250}/>
                        </Grid>
                    </>
                ) : (
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid container direction="row" justifyContent="space-between">
                            <Grid item xs={6}>
                                <p style={card_title}># of Positions</p>
                                <p style={card_subtitle}>{benchmark_data['position_count']}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p style={card_title}>Median Market Cap</p>
                                <p style={card_subtitle}>{millionNumWithCommas(benchmark_data['median_market_cap'])}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p style={card_title}>Largest Position Weight</p>
                                <p style={card_subtitle}>{percentageNum(benchmark_data['largest_weight'])}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p style={card_title}>Top 10 Weight</p>
                                <p style={card_subtitle}>{percentageNum(benchmark_data['top10_weight'])}</p>
                            </Grid>
                        </Grid>
                        {allocation_model ? (
                            <Grid container direction="row">
                                <Grid item xs={12}>
                                    <p style={card_title}>Custom Allocation Model</p>
                                    <Chart options={options} series={
                                        [{
                                            data: JSON.parse(allocation_model.model[0]).map(i => {
                                                if (i.type == 'INDEX') {
                                                    return {
                                                        x: i["name"].match(/.{1,22}(\s|$)/g),
                                                        y: i["pct"]
                                                    }
                                                } else {
                                                    return {
                                                        x: i["name"].fund_name.match(/.{1,22}(\s|$)/g),
                                                        y: i["pct"]
                                                    }
                                                }
                                            }
                                            ).sort((a,b) => b.y - a.y)
                                        }]
                                    } type="treemap" style={{marginLeft:"20px", marginRight: "20px"}}/>
                                </Grid>
                            </Grid>
                        ) : (
                            <></>
                        )}
                    </Grid>
                )}
            </Paper>
        </div>
    )
}



export default observer(BenchmarkMetrics);
