import React, {useEffect, useState} from "react";
import {DataStore, SortDirection, Predicates} from "@aws-amplify/datastore";
import {CustomStrategies, Strategies} from "../../../../models";
import {retry, delayError, delay} from "../../../../Utils/retry_utils";
import {refreshDB_and_run} from "../../../../Utils/db_utils";

//custom hook that returns list of top 10 performing index pro strategies
export function useTop10IPStrategies() {
    const [top10IPStrategies, setTop10IPStrategies] = useState([])

    useEffect(() => {
        let getStrategyData = async () => {
            try {
                const strategy_data = await DataStore.query(Strategies);
                strategy_data.sort(function (a, b) {
                    const a_performance = parseInt(a.data.ytd_performance)
                    const b_performance = parseInt(b.data.ytd_performance)
                    return b_performance - a_performance
                })
                setTop10IPStrategies(strategy_data.slice(0,10))
            } catch (error) {
                console.error('error:', error)
            }
        }
        getStrategyData();
    }, []);
    return top10IPStrategies
}

//custom hook that returns list of index pro strategies
export function useIPStrategies() {
    const [ipStrategies, setIPStrategies] = useState([])

    useEffect(() => {
        let getStrategyData = async () => {
            try {
                const strategy_data = await DataStore.query(Strategies);
                strategy_data.sort(function (a, b) {
                    const a_performance = parseInt(a.data.ytd_performance)
                    const b_performance = parseInt(b.data.ytd_performance)
                    return b_performance - a_performance
                })
                setIPStrategies(strategy_data)
            } catch (error) {
                console.error('error:', error)
            }
        }
        getStrategyData();
    }, []);
    return ipStrategies
}


//custom hook that returns list of users' custom strategies
export function useCustomStrategies() {
    const [customStrategies, setCustomStrategies] = useState([])

    async function fetchStrategyData() {
        const models = await DataStore.query(CustomStrategies, Predicates.ALL, {sort: i => i.updatedAt(SortDirection.DESCENDING)})
        setCustomStrategies(models)
    }
    useEffect(() => {
        fetchStrategyData()
        const subscription = DataStore.observe(CustomStrategies).subscribe(fetchStrategyData)
        return () => subscription.unsubscribe();
    }, []);

    return customStrategies
}

export function useStrategy(type, id) {
    const [customStrategy, setCustomStrategy] = useState(null)


    async function fetchStrategy() {
        const model = await DataStore.query(type === 'custom' ? (CustomStrategies) : (Strategies), id)
        setCustomStrategy(model)
    }
    useEffect( ()=> {
        fetchStrategy()
        const subscription = DataStore.observe(CustomStrategies, id).subscribe(fetchStrategy)
        return () => subscription.unsubscribe();
    }, []);

    return customStrategy
}

export function useCustomStrategyByID(id) {
    const [customStrategy, setCustomStrategy] = useState(null)
    async function fetchStrategy() {
        if (id) {
            const data = await DataStore.query(CustomStrategies, id)
            setCustomStrategy(data)
        } else {
            setCustomStrategy(null)
        }

    }

    useEffect( () => {
        fetchStrategy()
    }, [id]);

    return customStrategy
}