import './Components/Builder/strategy-builder.css'
import {createTheme, ThemeProvider} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import React from "react";
import SignIn from "./Auth/SignIn";
import {BrowserRouter as Router, Switch, Route, Link, Redirect} from "react-router-dom";
import Application from "./Components/application";
import PrivateRoute from "./Routes/private-route";
import SignUp from "./Auth/SignUp";
import {themeColor, themeBackground} from "./Styles/styles"
import {SnackbarProvider} from 'notistack'
import Amplify from "aws-amplify";
import ReactGA from 'react-ga';

const theme = createTheme ({
  typography: {
    fontFamily: "Poppins",
    fontSize: 14,
  },
  palette: {
    primary: {
      main: themeColor
    },
    background: {
      default: themeBackground
    }
  },
  overrides: {
    MuiStepIcon: {
      root: {
        color: themeBackground,
        border: "1px solid grey",
        borderRadius: "50%",
        '&$active': {
          color: themeColor,
          border: "none",
          '& $text': {
            fill: themeBackground,
          },
        },
      },
      text: {
        fill: "grey",
        '&$active': {
          color: "white"
        },
      },
      active: {},
    },
  }
})


function App() {

  const TRACKING_ID = "UA-240852490-1";
  ReactGA.initialize(TRACKING_ID);

  //todo: {console.log(window.innerHeight, window.innerWidth)}
    return (
          <ThemeProvider theme={theme}>
            <CssBaseline>
              <SnackbarProvider maxSnack={1} anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}>
                <Router>
                  <Switch>
                    <PrivateRoute path="/participant" children={<Application/>}/>
                    <Route exact path="/signin">
                      <SignIn/>
                    </Route>
                    <Route exact path="/signup">
                      <SignUp/>
                    </Route>
                    <Route path="*">
                      <Redirect to="/signin"/>
                    </Route>
                  </Switch>
                </Router>
              </SnackbarProvider>
            </CssBaseline>
          </ThemeProvider>
    );
}

export default App;
