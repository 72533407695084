import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite"
import './accounts.css'
import Participation from "../Dashboard/participation";
import ComplianceStatus from "../Dashboard/compliance-status";
import {Avatar, Button, Grid, Paper, TextField} from "@material-ui/core";
import {
    big_paper,
    bordered_button,
    card_subtitle,
    card_title,
    HeaderCell,
    SpecialCell,
    transparent_button, ValueCell, IPTableRow,
    themeColor,
    themeBackground, ipRed, ChromeAutoFillOverride, chip_style_green, chip_style_red
} from "../../Styles/styles";
import {Alert} from '@material-ui/lab';
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import {dollarsWithCommas} from "../../Utils/num_utils";
import {useHistory} from "react-router-dom";
import {DataStore} from "@aws-amplify/datastore";
import {CustomStrategies, Account} from "../../models";
import AddIcon from '@material-ui/icons/Add';
import Modal from "@material-ui/core/Modal";
import Fab from "@material-ui/core/Fab";
import ClearIcon from "@material-ui/icons/Clear";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {useAccounts, useAccountProfiles} from "../Hooks/Data/Accounts/accounts";
import {useIPStrategies, useCustomStrategies} from "../Hooks/Data/Strategies/strategies";
import Chip from '@material-ui/core/Chip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import Snackbar from '@material-ui/core/Snackbar';
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {
    useFirmUserData,
    useUsers,
    useUserProfilePictures,
    useUserRole,
    useUserTeamFilterInfo
} from "../Hooks/Data/User/user";
import {userRoleActionWrapper} from "../../Utils/role_utils";
import {teamDataFilterWrapper} from "../../Utils/team_utils";
import SettingsIcon from "@material-ui/icons/Settings";
import {AccountCircle, Person} from "@material-ui/icons";
import {Mixpanel} from "../../Utils/Mixpanel";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

//accounts page component
function Accounts() {
    const history = useHistory()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    const teamInfoObjs = useUserTeamFilterInfo()
    const user_role = useUserRole()
    const ipStrategyItems = useIPStrategies();
    const customStrategyItems = useCustomStrategies();
    const filteredCustomStrategyItems = teamDataFilterWrapper(user_role, teamInfoObjs,customStrategyItems)
    const accountData = useAccounts();
    const filteredAccountData = teamDataFilterWrapper(user_role, teamInfoObjs,accountData)
    const user_profile_pics = useUserProfilePictures()
    const firm_users = useFirmUserData()
    const [accountSelected, setAccountSelected] = React.useState(null)
    const [account_selected_strategy, setAccountStrategy] = React.useState(null)
    const [openDeleteError, setDeleteError] = React.useState(false);

    const [editAccountName, setEditAccountName] = React.useState({
        account_name: ''
    })

    const [editAccountNumber, setEditAccountNumber] = React.useState({
        account_number: ''
    })
    const handleSnackBarOpen = () => {
        setDeleteError(true);
    };

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setDeleteError(false);
    };

    const onAccountClick = (row) => {
        setAccountSelected(row)
        history.push(`/participant/accounts/${row.id}`)
    }

    const hasCustomStrategy = (strategy_id) => {
        return customStrategyItems.filter(x => x.id === strategy_id)[0]
    }

    const customStrategyName = (strategy_id) => {
        return customStrategyItems.filter(x => x.id === strategy_id)[0]["strategy_name"]
    }

    const ipStrategyName = (strategy_id) => {
        return ipStrategyItems.filter(x => x.id === strategy_id)[0]["strategy_name"]
    }

    const [actionRow, setActionRow] = React.useState({})

    const [editOpen, setEditOpen] = React.useState(false);
    const handleEditOpen = () => {
        setEditOpen(true);
    };
    const handleEditClose = () => {
        setEditAccountName({
            account_name: ''
        })
        setEditOpen(false);
    };


    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const handleDeleteOpen = () => {
        setDeleteOpen(true);
    };
    const handleDeleteClose = () => {
        setDeleteOpen(false);
    };

    const [ownershipOpen, setOwnershipOpen] = React.useState(false);
    const handleOwnershipOpen = () => {
        setOwnershipOpen(true);
    };
    const handleOwnershipClose = () => {
        setOwnershipOpen(false);
    };

    //strategy modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    //account number
    const [openAccountNumber, setOpenAccountNumber] = React.useState(false);
    const handleOpenAccountNumber = () => {
        setOpenAccountNumber(true);
    };
    const handleCloseAccountNumber = () => {
        setOpenAccountNumber(false);
    };

    const handleAccountNumber = async () => {
        const modelToEdit = await DataStore.query(Account, actionRow["id"]);
        await DataStore.save(Account.copyOf(modelToEdit, item => {
            item["account_number"] = editAccountNumber.account_number
        }));
    }


    const handleSaveStrategy = async () => {
        await DataStore.save(CustomStrategies.copyOf(accountSelected, item => {
            item["custom_strategy_id"] = customStrategyItems.filter(x => x.strategy_name === account_selected_strategy)[0] ? (
                customStrategyItems.filter(x => x.strategy_name === account_selected_strategy)[0]["id"]
            ) : (
                ipStrategyItems.filter(x => x.strategy_name === account_selected_strategy)[0]["id"]
            )
        }));
        handleClose();
    }

    const handleDeleteAccount = async () => {
        const modelToDelete = await DataStore.query(Account, actionRow["id"]);
        if (actionRow.data){
            handleSnackBarOpen()
        } else {
            DataStore.delete(modelToDelete);
        }
    }

    const handleEditAccount = async () => {
        const modelToEdit = await DataStore.query(Account, actionRow["id"]);
        await DataStore.save(Account.copyOf(modelToEdit, item => {
            item["legal_name"] = editAccountName.account_name
        }));
    }


    //on change method
    const handleInputChange = (event, keyName) => {
        event.persist();
        setEditAccountName((user) => {
            return {...user, [keyName]: event.target.value}
        })
    }
    const handleAccountNumberInputChange = (event, keyName) => {
        event.persist();
        setEditAccountNumber((user) => {
            return {...user, [keyName]: event.target.value}
        })
    }

    const users = useUsers()
    const [newOwner, setNewOwner]  = useState()

    const handleOwnershipChange = async () => {
        const new_user = users.filter(i => i.id === newOwner.obj_id)[0].user_info
        const modelToEdit = await DataStore.query(Account, actionRow["id"]);
        await DataStore.save(Account.copyOf(modelToEdit, item => {
            item["user_info"] = new_user
        }));
    }

    const handleStatus = (row) => {
        if (row.data && row.account_number) {
            return (
                <ValueCell align="left"><Chip label="Active" style={{backgroundColor: "#e7f8f0",
                    color: "#669984"}}/></ValueCell>
            )
        } else if (row.account_number && !row.data) {
            return (
                <ValueCell align="left"><Chip label="Onboarding" style={{backgroundColor: "#f3f4f8",
                    color: "#717682"}}/></ValueCell>
            )
        } else {
            return (
                <ValueCell align="left"><Chip label="Prospect" style={{backgroundColor: "#f0f5fd",
                    color: themeColor}}/></ValueCell>
            )
        }
    }

    const [state, setState] = React.useState({
        active: true,
        onboarding: true,
        prospect: true
    });

    const [search, setSearch] = React.useState(null)

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    function is_active(i){
        return Boolean(i.data && i.account_number)
    }

    function is_onboarding(i){
        return i.account_number && !i.data
    }

    function is_prospect(i){
        return !i.account_number && !i.data
    }

    const statusFilteredAccountData = (filteredAccountData) => {
        return filteredAccountData.filter(i => {
            return (is_onboarding(i) && state.onboarding) || ( is_active(i)&&state.active ) || ( is_prospect(i)&&state.prospect )
        })
    }

    const searchFilteredAccountData = (data) => {
        if (search === null || search === ''){
            return data
        } else {
            return data.filter(i => {
                return (i.legal_name.toLowerCase().includes(search.toLowerCase()))
            })
        }
    }

    const statusData = searchFilteredAccountData(statusFilteredAccountData(filteredAccountData))

    return (
        <div>
            <div className="ClientsContainer">
                <div className="Participation"><Participation/></div>
                <div className="Compliance-Status"><ComplianceStatus/></div>
                <div className="Accounts">
                    <Paper style={big_paper}
                           elevation={0}>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <div>
                                <p style={card_title}>Accounts</p>
                                <p style={card_subtitle}>All Accounts</p>
                            </div>
                            <div>
                                <Button style={Object.assign({}, bordered_button, {marginRight: "10px"})}
                                        onClick={() => history.push('/participant/add-account')}
                                        startIcon={<AddIcon style={{marginRight: "-5px"}}/>}
                                >
                                    Add Account
                                </Button>
                            </div>
                        </Grid>
                        <Grid style={{marginTop: "40px", marginRight: "20px"}} container direction="row" justifyContent="space-between">
                            <TextField variant="outlined"
                                       margin="normal"
                                       label="Search Account Name..."
                                       type="text"
                                       size="small"
                                       style={{
                                           backgroundColor: "#F5F8FA",
                                           width: "500px",
                                           marginLeft: "20px"
                                       }}
                                       onChange={(event) => {event.persist();
                                           setSearch(event.target.value)}}
                                       inputProps={{style: ChromeAutoFillOverride}}/>
                            <FormGroup row>
                                <FormControlLabel control={<Checkbox name="active" color="primary"/>} onChange={handleChange} checked={state.active} label={<p style={{fontSize: "14px"}}>Active</p>}/>
                                <FormControlLabel control={<Checkbox name="onboarding" color="primary"/>} onChange={handleChange} checked={state.onboarding} label={<p style={{fontSize: "14px"}}>Onboarding</p>}/>
                                <FormControlLabel control={<Checkbox name="prospect" color="primary"/>} onChange={handleChange} checked={state.prospect} label={<p style={{fontSize: "14px"}}>Prospect</p>}/>
                            </FormGroup>
                        </Grid>
                        <TableContainer style={{marginTop: "10px"}}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <HeaderCell style={{width: "200px"}}>Account Name</HeaderCell>
                                        <HeaderCell align="left">Account Number</HeaderCell>
                                        <HeaderCell>Status</HeaderCell>
                                        <HeaderCell align="left">Strategy</HeaderCell>
                                        <HeaderCell align="right">Account Value</HeaderCell>
                                        <HeaderCell align="center">Advisor</HeaderCell>
                                        <HeaderCell align="right">Actions</HeaderCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {statusData.map((row) => (
                                        <IPTableRow key={row.id}>
                                            <SpecialCell component="th" scope="row">
                                                <Button
                                                    onClick={() => onAccountClick(row)}
                                                    style={Object.assign({}, transparent_button, {
                                                        margin: "0px",
                                                        padding: "0px",
                                                        justifyContent: "flex-start",
                                                        textAlign: "left"
                                                    })}>
                                                    {row.legal_name}
                                                </Button>
                                            </SpecialCell>
                                            <SpecialCell component="th" scope="row" align="left">
                                                {row.account_number ? (
                                                    <Button
                                                        onClick={() => onAccountClick(row)}
                                                        style={Object.assign({}, transparent_button, {
                                                            margin: "0px",
                                                            padding: "0px",
                                                            justifyContent: "flex-start"
                                                        })}>
                                                        {row.account_number}
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        onClick={() => {setActionRow(row); userRoleActionWrapper(row.user_info, user_role, handleOpenAccountNumber)}}
                                                        style={Object.assign({}, bordered_button, {
                                                            margin: "0px",
                                                            padding: "4px",
                                                            justifyContent: "flex-start"
                                                        })}>Account Number</Button>
                                                )}
                                            </SpecialCell>
                                            {handleStatus(row)}
                                            <ValueCell align="left">{row.custom_strategy_id ? (
                                                <Button
                                                    onClick={() => history.push(`/participant/strategies/${
                                                        hasCustomStrategy(row.custom_strategy_id) ? (
                                                            'custom/' + row.custom_strategy_id
                                                        ) : (
                                                            'ip/' + row.custom_strategy_id
                                                        )
                                                    }`)}
                                                    style={Object.assign({}, transparent_button, {
                                                        margin: "0px",
                                                        padding: "0px",
                                                        justifyContent: "flex-start",
                                                    })}>
                                                    {hasCustomStrategy(row.custom_strategy_id) ? (
                                                        customStrategyName(row.custom_strategy_id)
                                                    ) : (
                                                        ipStrategyName(row.custom_strategy_id)
                                                    )}
                                                </Button>
                                            ) : (<Button
                                                onClick={() => {
                                                    setAccountSelected(row);
                                                    userRoleActionWrapper(row.user_info, user_role, handleOpen)
                                                }}
                                                style={Object.assign({}, bordered_button, {
                                                    margin: "0px",
                                                    padding: "4px",
                                                    justifyContent: "flex-start"
                                                })}>Select Strategy</Button>)}</ValueCell>
                                            <ValueCell
                                                align="right">{row.data === null ? null : dollarsWithCommas(Math.round(row.data['Total Balance']))}</ValueCell>
                                            <ValueCell align="center">
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-end"
                                                    alignItems="center"
                                                >
                                                    <p style={{marginRight: "20px", marginTop: "10px", marginBottom: "10px"}}>{row.user_info.attributes.name}</p>
                                                    <Avatar src={user_profile_pics[row.user_info.id]}/>
                                                </Grid>
                                            </ValueCell>
                                            <SpecialCell align="right">
                                                    <Tooltip title="Edit">
                                                        <IconButton style={{padding: "2px"}} onClick={() => {setActionRow(row);
                                                        userRoleActionWrapper(row.user_info, user_role, handleEditOpen)}}>
                                                            <EditIcon style={{color: "#adafb3"}}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Delete">
                                                        <IconButton style={{padding: "2px"}} onClick={() => {setActionRow(row);
                                                        userRoleActionWrapper(row.user_info, user_role, handleDeleteOpen)}}>
                                                            <DeleteIcon style={{color: "#adafb3"}}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Change Ownership">
                                                        <IconButton style={{padding: "2px"}} onClick={() => {setActionRow(row);
                                                            userRoleActionWrapper(row.user_info, user_role, handleOwnershipOpen)}}>
                                                            <Person style={{color: "#adafb3"}}/>
                                                        </IconButton>
                                                    </Tooltip>
                                            </SpecialCell>
                                        </IPTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Paper>
                </div>
            </div>
            <Snackbar open={openDeleteError} autoHideDuration={6000} onClose={handleSnackBarClose}>
                <Alert handleSnackBarClose={handleSnackBarClose} severity="error" variant="filled">
                    You do not have permission to delete active accounts, please contact support.
                </Alert>
            </Snackbar>
            <Modal
                open={openAccountNumber}
                style={{overflow: "scroll"}}
            >
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Paper elevation={0} style={{
                        width: "600px", height: "600px", marginTop: "300px"
                    }}>
                        <Grid container direction="row">
                            <Grid item xs={10} style={{paddingTop: "25px"}}>
                                <></>
                            </Grid>
                            <Grid item xs={2} style={{paddingRight: "20px", paddingTop: "20px"}}>
                                <Fab onClick={handleCloseAccountNumber} style={{float: "right"}}>
                                    <ClearIcon/>
                                </Fab>
                            </Grid>
                            <Grid container justify = "center">
                                <ErrorOutlineIcon style={{fontSize: "150px", color: themeColor}}/>
                            </Grid>
                            <Grid container justify="center">
                                <p style={{fontSize: "30px", textAlign: "center"}}>Add {actionRow.legal_name} Account Number</p>
                            </Grid>
                            <Grid container justify="center">
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    label="Add Account Number"
                                    value={editAccountNumber.account_number}
                                    type="text"
                                    autoFocus
                                    style={{
                                        backgroundColor: "#F5F8FA",
                                        width: "300px",
                                    }}
                                    inputProps={{style: ChromeAutoFillOverride}}
                                    onChange={(e) => handleAccountNumberInputChange(e, 'account_number')}
                                />
                            </Grid>
                            <Grid container justify = "center" style={{marginTop: "40px"}}>
                                <Button style={Object.assign({}, bordered_button, {
                                    width: "100px", marginRight: "20px",
                                    fontSize:"16px"
                                })}
                                        onClick={handleCloseAccountNumber}
                                >Cancel</Button>
                                <Button style={Object.assign({}, bordered_button, {
                                    width: "100px", marginLeft: "20px",
                                    fontSize:"16px"
                                })}
                                        startIcon={<SaveAltIcon/>} onClick={() => {window.Intercom('trackEvent', 'added-account-number');
                                        Mixpanel.track('Added Account Number');
                                    handleCloseAccountNumber(); handleAccountNumber()}}>
                                    Save </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </Modal>
            <Modal
                open={editOpen}
                style={{overflow: "scroll"}}
            >
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Paper elevation={0} style={{
                        width: "600px", height: "600px", marginTop: "300px"
                    }}>
                        <Grid container direction="row">
                            <Grid item xs={10} style={{paddingTop: "25px"}}>
                                <></>
                            </Grid>
                            <Grid item xs={2} style={{paddingRight: "20px", paddingTop: "20px"}}>
                                <Fab onClick={handleEditClose} style={{float: "right"}}>
                                    <ClearIcon/>
                                </Fab>
                            </Grid>
                            <Grid container justify = "center">
                                <ErrorOutlineIcon style={{fontSize: "150px", color: themeColor}}/>
                            </Grid>
                            <Grid container justify = "center">
                                <p style={{fontSize: "30px", textAlign: "center"}}>Editing {actionRow.legal_name} Account Name</p>
                            </Grid>
                            <Grid container justify="center">
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        label="New Account Name"
                                        value={editAccountName.account_name}
                                        type="text"
                                        autoFocus
                                        style={{
                                            backgroundColor: "#F5F8FA",
                                            width: "300px",
                                        }}
                                        inputProps={{style: ChromeAutoFillOverride}}
                                        onChange={(e) => handleInputChange(e, 'account_name')}
                                    />
                            </Grid>
                            <Grid container justify = "center" style={{marginTop: "40px"}}>
                                <Button style={Object.assign({}, bordered_button, {
                                    width: "100px", marginRight: "20px",
                                    fontSize:"16px"
                                })}
                                        onClick={handleEditClose}
                                >Cancel</Button>
                                <Button style={Object.assign({}, bordered_button, {
                                    width: "100px", marginLeft: "20px",
                                    fontSize:"16px"
                                })}
                                        startIcon={<SaveAltIcon/>} onClick={() => {handleEditClose(); handleEditAccount()}}>
                                    Save </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </Modal>
            <Modal
                open={deleteOpen}
                style={{overflow: "scroll"}}
            >
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Paper elevation={0} style={{
                        width: "600px", height: "600px", marginTop: "300px"
                    }}>
                        <Grid container direction="row">
                            <Grid item xs={10} style={{paddingTop: "25px"}}>
                                <></>
                            </Grid>
                            <Grid item xs={2} style={{paddingRight: "20px", paddingTop: "20px"}}>
                                <Fab onClick={handleDeleteClose} style={{float: "right"}}>
                                    <ClearIcon/>
                                </Fab>
                            </Grid>
                            <Grid container justify = "center">
                                <HighlightOffOutlinedIcon style={{fontSize: "150px", color: ipRed}}/>
                            </Grid>
                            <Grid container justify = "center">
                                <p style={{fontSize: "30px"}}>Are you sure?</p>
                            </Grid>
                            <Grid container justify = "center">
                                <p style={{fontSize: "16px", paddingRight: "40px", paddingLeft: "40px", textAlign: "center", paddingBottom: "40px"}}>
                                    Do you really want to delete <span style={{fontWeight: "600"}}>{actionRow.legal_name}</span> account? This process cannot be undone.</p>
                            </Grid>
                            <Grid container justify = "center">
                                <Button style={Object.assign({}, bordered_button,
                                    {width: "100px",
                                        marginRight: "20px", fontSize:"16px"})}
                                        onClick={handleDeleteClose}
                                >Cancel</Button>
                                <Button style={Object.assign({}, transparent_button, {backgroundColor: ipRed,
                                    color: "white", width: "100px", marginLeft: "20px",
                                    fontSize:"16px"})} onClick={() => {        Mixpanel.track('Deleted Account');
                                    window.Intercom('trackEvent', 'deleted-account'); handleDeleteClose(); handleDeleteAccount()}}>
                                    Delete</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </Modal>
            <Modal
                open={ownershipOpen}
                style={{overflow: "scroll"}}
            >
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Paper elevation={0} style={{
                        width: "600px", height: "600px", marginTop: "300px"
                    }}>
                        <Grid container direction="row">
                            <Grid item xs={10} style={{paddingTop: "25px"}}>
                                <></>
                            </Grid>
                            <Grid item xs={2} style={{paddingRight: "20px", paddingTop: "20px"}}>
                                <Fab onClick={handleOwnershipClose} style={{float: "right"}}>
                                    <ClearIcon/>
                                </Fab>
                            </Grid>
                            <Grid container justify = "center">
                                <ErrorOutlineIcon style={{fontSize: "150px", color: themeColor}}/>
                            </Grid>
                            <Grid container justify = "center">
                                <p style={{fontSize: "30px", textAlign: "center", marginLeft: "20px", marginRight: "20px"}}>Select new owner for <span style={{fontWeight: "600"}}>{actionRow.legal_name}</span> account</p>
                            </Grid>
                            <Grid container justify = "center">
                                <Autocomplete
                                    options={firm_users}
                                    autoHighlight
                                    onChange={(event, newValue) => {
                                        setNewOwner(newValue);
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    getOptionSelected={(option, value) => option.name === value.name}
                                    renderOption={(option) => (
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >
                                            <Avatar src={user_profile_pics[option['id']]} style={{height: "50px", width: "50px"}}/>
                                            <div>
                                                <p style={{marginLeft: "20px", marginTop: "10px", marginBottom: "10px", fontWeight: "600"}}>{option['name']}</p>
                                                <p style={{marginLeft: "20px", marginTop: "10px", marginBottom: "10px"}}>{option['email']}</p>
                                            </div>
                                        </Grid>
                                    )}
                                    renderInput={(params) => <TextField {...params} label="Owner" variant="outlined"/>}
                                    style={{backgroundColor: "#F5F8FA",width: "460px", marginTop: "20px"}}
                                />
                            </Grid>
                            <Grid container justify = "center" style={{marginTop: "40px"}}>
                                <Button style={Object.assign({}, bordered_button,
                                    {width: "100px",
                                        marginRight: "20px", fontSize:"16px"})}
                                        onClick={handleOwnershipClose}
                                >Cancel</Button>
                                <Button style={Object.assign({}, transparent_button, {backgroundColor: themeColor,
                                    color: "white", width: "100px", marginLeft: "20px",
                                    fontSize:"16px"})} onClick={() => {window.Intercom('trackEvent', 'changed-account-ownership');
                                    Mixpanel.track('Changed Account Ownership');
                                    handleOwnershipClose(); handleOwnershipChange()}}>
                                    Save</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>

            </Modal>
            <Modal
                open={open}
                style={{overflow: "scroll"}}
            >
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Paper elevation={0} style={{
                        width: "600px", height: "340px", marginTop: "300px"
                    }}>
                        <Grid container direction="row">
                            <Grid item xs={10} style={{paddingTop: "25px"}}>
                                <p style={card_title}>Select a Strategy</p>
                            </Grid>
                            <Grid item xs={2} style={{paddingRight: "20px", paddingTop: "20px"}}>
                                <Fab onClick={handleClose} style={{float: "right"}}>
                                    <ClearIcon/>
                                </Fab>
                            </Grid>
                            <div style={{
                                width: "100%",
                                marginTop: "30px",
                                marginBottom: "10px",
                                borderBottom: "2px solid #dadce0"
                            }}/>
                            <Grid item xs={12} style={{marginLeft: "20px", marginRight: "20px"}}>
                                <Alert severity="info">Select a strategy to add to your account or create one <a
                                    href="/participant/builder">here</a>!</Alert>
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={filteredCustomStrategyItems.map(function (el) {
                                        return el.strategy_name;
                                    })}
                                    style={{
                                        width: "560px",
                                        marginLeft: "20px",
                                    }}
                                    margin="normal"
                                    onChange={(option) => setAccountStrategy(option.target.innerText)}
                                    value={account_selected_strategy}
                                    renderInput={(params) => <TextField
                                        style={{
                                            backgroundColor: themeBackground,
                                        }}
                                        margin="normal"

                                        {...params} label="Strategy Name" variant="outlined"
                                    />}
                                />
                            </Grid>
                            <Grid item xs={12} style={{marginLeft: "430px", marginTop: "10px"}}>
                                <Button style={Object.assign({}, bordered_button, {width: "150px"})}
                                        onClick={() => {Mixpanel.track('Added Strategy to Account');
                                            window.Intercom('trackEvent', 'added-strategy-to-account'); handleSaveStrategy()}}
                                >Save Strategy</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </Modal>
        </div>
    )
}

export default observer(Accounts);
