import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite"
import './strategies.css'
import {
    big_paper,
    bordered_button,
    card_subtitle,
    card_title, ChromeAutoFillOverride,
    HeaderCell, ipRed,
    IPTableRow,
    SpecialCell, themeColor,
    transparent_button, ValueCell,
} from "../../Styles/styles";
import {Button, Grid, Paper, TextField} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import {useHistory} from "react-router-dom";
import {useCustomStrategies, useIPStrategies} from "../Hooks/Data/Strategies/strategies";
import {useAccountProfiles, useAccounts} from "../Hooks/Data/Accounts/accounts";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Modal from "@material-ui/core/Modal";
import Fab from "@material-ui/core/Fab";
import ClearIcon from "@material-ui/icons/Clear";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import {DataStore} from "@aws-amplify/datastore";
import {Account, CustomStrategies} from "../../models";
import Snackbar from "@material-ui/core/Snackbar";
import {Alert} from "@material-ui/lab";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import AddIcon from '@material-ui/icons/Add';
import {Auth} from "aws-amplify";
import Avatar from "@material-ui/core/Avatar";
import {
    useFirmUserData,
    useUserProfilePictures,
    useUserRole,
    useUsers,
    useUserTeamFilterInfo
} from "../Hooks/Data/User/user";
import TablePagination from "@material-ui/core/TablePagination";
import {userRoleActionWrapper} from "../../Utils/role_utils";
import {teamDataFilterWrapper} from "../../Utils/team_utils";
import {Person} from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Mixpanel} from "../../Utils/Mixpanel";

function StrategyComp({builder_state}) {
    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const ipStrategyItems = useIPStrategies()
    const teamInfoObjs = useUserTeamFilterInfo()
    const user_role = useUserRole()
    const customStrategyItems = useCustomStrategies()
    const filteredCustomStrategyItems = teamDataFilterWrapper(user_role, teamInfoObjs,customStrategyItems)
    const firm_users = useFirmUserData()

    const strategyAccountItems = useAccounts().map(a => a.custom_strategy_id)
    const onStrategyClick = (type,id) => {
        history.push(`/participant/strategies/${type}/${id}`)
    }
    const [editStratName, setEditStratName] = React.useState({
        strategy_name: ''
    })
    const [editOpen, setEditOpen] = React.useState(false);
    const handleEditOpen = () => {
        setEditOpen(true);
    };
    const handleEditClose = () => {
        setEditStratName({
            strategy_name: ''
        })
        setEditOpen(false);
    };

    const user_profile_pics  = useUserProfilePictures()

    const [openDeleteError, setDeleteError] = React.useState(false);
    const handleSnackBarOpen = () => {
        setDeleteError(true);
    };
    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setDeleteError(false);
    };


    const [actionRow, setActionRow] = React.useState({})
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const handleDeleteOpen = () => {
        setDeleteOpen(true);
    };
    const handleDeleteClose = () => {
        setDeleteOpen(false);
    };

    const handleEditStrategy = async () => {
        const modelToEdit = await DataStore.query(CustomStrategies, actionRow["id"]);
        await DataStore.save(CustomStrategies.copyOf(modelToEdit, item => {
            item["strategy_name"] = editStratName.strategy_name
        }));
    }


    const handleDeleteStrategy = async () => {
        const modelToDelete = await DataStore.query(CustomStrategies, actionRow["id"]);
        if (strategyAccountItems.includes(actionRow["id"])) {
            handleSnackBarOpen()
        }
        else {
            Mixpanel.track('Deleted Strategy');
            await DataStore.delete(modelToDelete);
        }
    }

    const [ownershipOpen, setOwnershipOpen] = React.useState(false);
    const handleOwnershipOpen = () => {
        setOwnershipOpen(true);
    };
    const handleOwnershipClose = () => {
        setOwnershipOpen(false);
    };


    const handleInputChange = (event, keyName) => {
        event.persist();
        setEditStratName((user) => {
            return {...user, [keyName]: event.target.value}
        })
    }

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const users = useUsers()
    const [newOwner, setNewOwner]  = useState()

    const handleOwnershipChange = async () => {
        const new_user = users.filter(i => i.id === newOwner.obj_id)[0].user_info
        const modelToEdit = await DataStore.query(CustomStrategies, actionRow["id"]);
        await DataStore.save(CustomStrategies.copyOf(modelToEdit, item => {
            item["user_info"] = new_user
        }));
    }

    const [search, setSearch] = React.useState(null)

    const searchFilteredStrategyData = (data) => {
        if (search === null || search === ''){
            return data
        } else {
            return data.filter(i => {
                return (i.strategy_name.toLowerCase().includes(search.toLowerCase()))
            })
        }
    }

    const searchFilteredCustomStrategyItems = searchFilteredStrategyData(filteredCustomStrategyItems)

    return (
        <div>
            <div className="StrategiesContainer">
                <div className="Custom-Strategies">
                    <Paper style={big_paper}
                           elevation={0}>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <div>
                                <p style={card_title}>Your Strategies</p>
                                <p style={card_subtitle}>Your Custom Strategies</p>
                            </div>
                            <div>
                                <Button style={Object.assign({}, bordered_button, {marginRight: "10px"})}
                                        startIcon={<AddIcon/>}
                                        onClick={() => {builder_state.reset(); history.push('/participant/builder')}}
                                >
                                    Create Strategy
                                </Button>
                            </div>
                        </Grid>
                        <Grid style={{marginTop: "40px", marginRight: "20px"}} container direction="row" justifyContent="space-between">
                            <TextField variant="outlined"
                                       margin="normal"
                                       label="Search Strategy Name..."
                                       type="text"
                                       size="small"
                                       style={{
                                           backgroundColor: "#F5F8FA",
                                           width: "500px",
                                           marginLeft: "20px"
                                       }}
                                       onChange={(event) => {event.persist();
                                           setSearch(event.target.value)}}
                                       inputProps={{style: ChromeAutoFillOverride}}/>
                        </Grid>
                        <TableContainer style={{marginTop: "10px"}}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <HeaderCell>Strategy Name</HeaderCell>
                                        <HeaderCell>Benchmark</HeaderCell>
                                        <HeaderCell align="right">Number of Positions</HeaderCell>
                                        <HeaderCell align="right">Tracking Error</HeaderCell>
                                        <HeaderCell align="center">Advisor</HeaderCell>
                                        <HeaderCell align="right">Actions</HeaderCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {searchFilteredCustomStrategyItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => (
                                        <IPTableRow key={customStrategyItems.id}>
                                            <SpecialCell component="th" scope="row">
                                                <Button
                                                    onClick={() => onStrategyClick('custom',row.id)}
                                                    style={Object.assign({}, transparent_button, {
                                                        margin: "0px",
                                                        padding: "0px",
                                                        justifyContent: "flex-start"
                                                    })}>
                                                    {row.strategy_name}
                                                </Button>
                                            </SpecialCell>
                                            <ValueCell align="left">{row.benchmark}</ValueCell>
                                            {row.proposal_data ? (
                                                <ValueCell align="right">{row.proposal_data['portfolio_details'][0]['pro']}</ValueCell>
                                            ) : (
                                                <ValueCell align="right">
                                                    <div className="dot-flashing"></div>
                                                </ValueCell>
                                            )
                                            }
                                            {row.proposal_data ? (
                                                <ValueCell align="right">{row.proposal_data['tracking_error']}</ValueCell>
                                            ) : (
                                                <ValueCell align="right">
                                                    <div className="dot-flashing"></div>
                                                </ValueCell>
                                            )
                                            }
                                            <ValueCell align="center">
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-end"
                                                    alignItems="center"
                                                >
                                                   <p style={{marginRight: "20px", marginTop: "10px", marginBottom: "10px"}}>{row.user_info.attributes.name}</p>
                                                <Avatar src={user_profile_pics[row.user_info.id]}/>
                                                </Grid>
                                            </ValueCell>
                                            <SpecialCell align="right">
                                                    <Tooltip title="Edit">
                                                        <IconButton style={{padding: "2px"}}>
                                                            <EditIcon style={{color: "#adafb3"}} onClick={() => {setActionRow(row); userRoleActionWrapper(row.user_info, user_role, handleEditOpen)}}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Delete">
                                                        <IconButton style={{padding: "2px"}} onClick={() => {setActionRow(row); userRoleActionWrapper(row.user_info, user_role, handleDeleteOpen)}}>
                                                            <DeleteIcon style={{color: "#adafb3"}}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Change Ownership">
                                                        <IconButton style={{padding: "2px"}} onClick={() => {setActionRow(row);
                                                            userRoleActionWrapper(row.user_info, user_role, handleOwnershipOpen)}}>
                                                            <Person style={{color: "#adafb3"}}/>
                                                        </IconButton>
                                                    </Tooltip>
                                            </SpecialCell>
                                        </IPTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[]}
                            component="div"
                            count={filteredCustomStrategyItems.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Paper>
                </div>
                <div className="All-Strategies">
                    <Paper style={big_paper}
                           elevation={0}>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <div>
                                <p style={card_title}>Index Pro Strategies</p>
                                <p style={card_subtitle}>Available Index Pro Strategies</p>
                            </div>
                        </Grid>
                        <TableContainer style={{marginTop: "60px"}}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <HeaderCell>
                                            Strategy Name
                                        </HeaderCell>
                                        <HeaderCell>Benchmark</HeaderCell>
                                        <HeaderCell>Values</HeaderCell>
                                        <HeaderCell align="right">YTD Performance</HeaderCell>
                                        <HeaderCell align="right">Tracking Error</HeaderCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ipStrategyItems.map((row) => (
                                        <IPTableRow key={ipStrategyItems.id}>
                                            <SpecialCell component="th" scope="row">
                                                <Button
                                                    onClick={() => onStrategyClick('ip',row.id)}
                                                    style={Object.assign({}, transparent_button, {
                                                        margin: "0px",
                                                        padding: "0px",
                                                        justifyContent: "flex-start"
                                                    })}>
                                                    {row.strategy_name}
                                                </Button>
                                            </SpecialCell>
                                            <ValueCell align="left">{row.benchmark}</ValueCell>
                                            <ValueCell align="left">{row.values}</ValueCell>
                                            <ValueCell
                                                align="right">{row.data.ytd_performance}</ValueCell>
                                            <ValueCell
                                                align="right">{row.data.tracking_error}</ValueCell>
                                        </IPTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </div>
            </div>
            <Snackbar open={openDeleteError} autoHideDuration={6000} onClose={handleSnackBarClose}>
                <Alert handleSnackBarClose={handleSnackBarClose} severity="error" variant="filled">
                    This strategy is currently connected to an account, please delete account first.
                </Alert>
            </Snackbar>
            <Modal
                open={editOpen}
                style={{overflow: "scroll"}}
            >
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Paper elevation={0} style={{
                        width: "600px", height: "600px", marginTop: "300px"
                    }}>
                        <Grid container direction="row">
                            <Grid item xs={10} style={{paddingTop: "25px"}}>
                                <></>
                            </Grid>
                            <Grid item xs={2} style={{paddingRight: "20px", paddingTop: "20px"}}>
                                <Fab onClick={handleEditClose} style={{float: "right"}}>
                                    <ClearIcon/>
                                </Fab>
                            </Grid>
                            <Grid container justify = "center">
                                <ErrorOutlineIcon style={{fontSize: "150px", color: themeColor}}/>
                            </Grid>
                            <Grid container justify = "center">
                                <p style={{fontSize: "30px", marginLeft: "20px", marginRight: "20px", textAlign: "center"}}>Editing {actionRow.strategy_name} Strategy Name</p>
                            </Grid>
                            <Grid container justify="center">
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    label="New Strategy Name"
                                    value={editStratName.strategy_name}
                                    type="text"
                                    autoFocus
                                    style={{
                                        backgroundColor: "#F5F8FA",
                                        width: "300px",
                                    }}
                                    inputProps={{style: ChromeAutoFillOverride}}
                                    onChange={(e) => handleInputChange(e, 'strategy_name')}
                                />
                            </Grid>
                            <Grid container justify = "center" style={{marginTop: "40px"}}>
                                <Button style={Object.assign({}, bordered_button, {
                                    width: "100px", marginRight: "20px",
                                    fontSize:"16px"
                                })}
                                        onClick={handleEditClose}
                                >Cancel</Button>
                                <Button style={Object.assign({}, bordered_button, {
                                    width: "100px", marginLeft: "20px",
                                    fontSize:"16px"
                                })}
                                        startIcon={<SaveAltIcon/>} onClick={() => {handleEditClose(); handleEditStrategy()}}>
                                    Save </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </Modal>
            <Modal
                open={deleteOpen}
                style={{overflow: "scroll"}}
            >
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Paper elevation={0} style={{
                        width: "600px", height: "600px", marginTop: "300px"
                    }}>
                        <Grid container direction="row">
                            <Grid item xs={10} style={{paddingTop: "25px"}}>
                                <></>
                            </Grid>
                            <Grid item xs={2} style={{paddingRight: "20px", paddingTop: "20px"}}>
                                <Fab onClick={handleDeleteClose} style={{float: "right"}}>
                                    <ClearIcon/>
                                </Fab>
                            </Grid>
                            <Grid container justify = "center">
                                <HighlightOffOutlinedIcon style={{fontSize: "150px", color: ipRed}}/>
                            </Grid>
                            <Grid container justify = "center">
                                <p style={{fontSize: "30px"}}>Are you sure?</p>
                            </Grid>
                            <Grid container justify = "center">
                                <p style={{fontSize: "16px", paddingRight: "40px", paddingLeft: "40px", textAlign: "center", paddingBottom: "40px"}}>
                                    Do you really want to delete <span style={{fontWeight: "600"}}>{actionRow.strategy_name}</span> strategy? This process cannot be undone.</p>
                            </Grid>
                            <Grid container justify = "center">
                                <Button style={Object.assign({}, transparent_button,
                                    {backgroundColor: "#adafb3", color: "white", width: "100px",
                                        marginRight: "20px", fontSize:"16px"})}
                                        onClick={handleDeleteClose}
                                >Cancel</Button>
                                <Button style={Object.assign({}, transparent_button, {backgroundColor: ipRed,
                                    color: "white", width: "100px", marginLeft: "20px",
                                    fontSize:"16px"})} onClick={() => {handleDeleteClose(); handleDeleteStrategy()}}>
                                    Delete</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </Modal>
            <Modal
                open={ownershipOpen}
                style={{overflow: "scroll"}}
            >
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Paper elevation={0} style={{
                        width: "600px", height: "600px", marginTop: "300px"
                    }}>
                        <Grid container direction="row">
                            <Grid item xs={10} style={{paddingTop: "25px"}}>
                                <></>
                            </Grid>
                            <Grid item xs={2} style={{paddingRight: "20px", paddingTop: "20px"}}>
                                <Fab onClick={handleOwnershipClose} style={{float: "right"}}>
                                    <ClearIcon/>
                                </Fab>
                            </Grid>
                            <Grid container justify = "center">
                                <ErrorOutlineIcon style={{fontSize: "150px", color: themeColor}}/>
                            </Grid>
                            <Grid container justify = "center">
                                <p style={{fontSize: "30px", textAlign: "center", marginLeft: "20px", marginRight: "20px"}}>Select new owner for <span style={{fontWeight: "600"}}>{actionRow.strategy_name}</span> strategy</p>
                            </Grid>
                            <Grid container justify = "center">
                                <Autocomplete
                                    options={firm_users}
                                    autoHighlight
                                    onChange={(event, newValue) => {
                                        setNewOwner(newValue);
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    getOptionSelected={(option, value) => option.name === value.name}
                                    renderOption={(option) => (
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >
                                            <Avatar src={user_profile_pics[option['id']]} style={{height: "50px", width: "50px"}}/>
                                            <div>
                                                <p style={{marginLeft: "20px", marginTop: "10px", marginBottom: "10px", fontWeight: "600"}}>{option['name']}</p>
                                                <p style={{marginLeft: "20px", marginTop: "10px", marginBottom: "10px"}}>{option['email']}</p>
                                            </div>
                                        </Grid>
                                    )}
                                    renderInput={(params) => <TextField {...params} label="Owner" variant="outlined"/>}
                                    style={{backgroundColor: "#F5F8FA",width: "460px", marginTop: "20px"}}
                                />
                            </Grid>
                            <Grid container justify = "center" style={{marginTop: "40px"}}>
                                <Button style={Object.assign({}, bordered_button,
                                    {width: "100px",
                                        marginRight: "20px", fontSize:"16px"})}
                                        onClick={handleOwnershipClose}
                                >Cancel</Button>
                                <Button style={Object.assign({}, transparent_button, {backgroundColor: themeColor,
                                    color: "white", width: "100px", marginLeft: "20px",
                                    fontSize:"16px"})} onClick={() => {window.Intercom('trackEvent', 'changed-strategy-ownership');
                                    Mixpanel.track('Changed Strategy Ownership'); handleOwnershipClose(); handleOwnershipChange()}}>
                                    Save</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </Modal>
        </div>
    )
}

export default observer(StrategyComp);