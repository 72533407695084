import React from "react";
import {observer} from "mobx-react-lite";
import {Paper} from "@material-ui/core"
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {paper_left, card_title, card_subtitle, ipGreen} from "../../Styles/styles"

//component showing compliance status
function ComplianceStatus() {
    return (
        <Paper style={paper_left} elevation={0}>
            <div>
                <p style={card_title}>Compliance</p>
                <p style={card_subtitle}>No Errors Reported</p>
            </div>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <CheckBoxIcon style={{color: ipGreen, height: "200px", width: "200px"}}/>
            </div>
        </Paper>
    )
}

export default observer(ComplianceStatus);