import React, {useEffect, useState} from "react";
import {DataStore, Predicates, SortDirection} from "@aws-amplify/datastore";
import {Account, AllocationModel, CustomStrategies} from "../../../../models";
import {Storage} from "@aws-amplify/storage";

//custom hook that returns list of top 10 accounts
export function useTop10Accounts() {
    const [top10Accounts, setTop10Accounts] = useState([])

    async function getAccountData() {
        try {
            const account_data = await DataStore.query(Account);
            let live_account_data = account_data.filter(account => account.data !== null)
            live_account_data.sort(function (a, b) {
                const a_balance = a.data['Total Balance']
                const b_balance = b.data['Total Balance']
                return b_balance - a_balance
            })
            setTop10Accounts(live_account_data)
        } catch (error) {
            console.error('error:', error)
        }
    }

    useEffect(() => {
        getAccountData();
        const subscription = DataStore.observe(Account).subscribe(getAccountData)
        return () => subscription.unsubscribe();
    }, []);
    return top10Accounts
}

//custom hook that returns number of live accounts and number of total accounts
//returns like [live,total]
export function useAccountParticipationStats() {
    const [liveAccounts, setLiveAccounts] = useState(0)
    const [totalAccounts, setTotalAccounts] = useState(0)

    useEffect(() => {
        let getAccountData = async () => {
            try {
                const account_data = await DataStore.query(Account);
                let live_account_data = account_data.filter(account => account.data !== null)
                setLiveAccounts(live_account_data.length)
                setTotalAccounts(account_data.length)
            } catch (error) {
                console.error(error)
            }
        }
        getAccountData();
    }, []);
    return [liveAccounts, totalAccounts]
}

//custom hook that returns all accounts
export function useAccounts() {
    const [accounts, setAccounts] = useState([])

    async function getAccountData() {
        try {
            const account_data = await DataStore.query(Account, Predicates.ALL, {sort: i => i.updatedAt(SortDirection.DESCENDING)});
            setAccounts(account_data)
        } catch (error) {
            console.error('error:', error)
        }
    }
    useEffect(() => {
        getAccountData();
        const subscription = DataStore.observe(Account).subscribe(getAccountData)
        return () => subscription.unsubscribe();
    }, []);
    return accounts
}


//custom hook that returns account by id
//returns [account, accountData]
export function useAccountByID(account_id) {
    const [account, setAccount] = useState(null)
    const [accountData, setAccountData] = useState(null)
    async function getAccountData() {
        try {
            const account = await DataStore.query(Account, account_id);
            setAccount(account)
            if (account.data) {
                setAccountData(account.data)
            } else {
                setAccountData(null)
            }
        } catch (error) {
            console.error('error:', error)
        }
    }
    useEffect(() => {
        getAccountData();
        const subscription = DataStore.observe(Account, account_id).subscribe(getAccountData)
        return () => subscription.unsubscribe();
    }, []);
    return [account, accountData]
}

export function useAccountTransitionExclusionByID(account_id) {
    const [data, setData] = useState([])
    async function getAccountData() {
        try {
            const result = await Storage.get(`transition_reports/${account_id}_exclusions.txt`, {
                level: "private",
                download: true,
                cacheControl: 'no-cache',
            });
            result.Body.text().then(i => {
                setData(JSON.parse(i))
            });
        } catch (error) {
            setData([])
        }
    }
    useEffect(() => {
        getAccountData();
        const subscription = DataStore.observe(Account, account_id).subscribe(getAccountData)
        return () => subscription.unsubscribe();
    }, []);
    return data
}