import AccountTitle from "./account-title";
import AccountPreferences from "./account-preferences";
import React from "react";
import AccountOnboarding from "./account-onboarding";
import AccountTransitionPortfolio from "./account-transition-portfolio";
import AccountTransition from "./account-transition-transition";
import AccountTransitionHoldings from "./account-transition-holdings";
import AccountTransitionSectors from "./account-transition-sectors";
import AccountTransitionImpact from "./account-transition-impact";
import AccountTransitionExclusions from "./account-transition-exclusions";
import AccountTransitionESG from "./account-transition-esg";

function AccountInfoTransition(props) {

    const account = props.account
    const strategy = props.strategy

    return (
        <div className="AccountTransitionContainer">
            <AccountTitle account={account}/>
            <AccountPreferences editable={false} strategy={strategy} account={account}/>
            <AccountOnboarding account={account}/>
            <AccountTransitionPortfolio account={account}/>
            <AccountTransition account={account} strategy={strategy}/>
            <AccountTransitionHoldings account={account}/>
            <AccountTransitionSectors account={account}/>
            <AccountTransitionESG account={account}/>
            <AccountTransitionImpact account={account}/>
            <AccountTransitionExclusions account={account}/>
        </div>
    )
}

export default AccountInfoTransition