import React, {useEffect} from "react";
import {Grid, Typography} from "@material-ui/core";
import {observer} from "mobx-react-lite"
import Paper from "@material-ui/core/Paper";
import {paper_right} from "../../Styles/styles"
import {useBenchmarkTitle} from "../Hooks/Data/Builder/builder";
import {useUserFirmName} from "../Hooks/Data/User/user";

function BuilderTitle({builder_state}) {
    let benchmark_name = builder_state.benchmark_name
    let allocation_model_id = builder_state.allocation_model_id
    const title_data = useBenchmarkTitle(benchmark_name, allocation_model_id)
    const firm_name = useUserFirmName()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    if (!title_data) {
        return (
            <></>
        )
    }
    return (
        <Paper elevation={0} style={Object.assign({}, paper_right, {
            backgroundColor: "transparent",
            width: "822px",
            marginRight: "-200px",
            border: "none"
        })}>
            <Grid container direction="row">
                <Grid item xs={12}>
                    <p style={{fontSize: "1em", fontWeight: "300", margin: "0px"}}>{title_data[0]}</p>
                </Grid>
                <div>
                    <p style={{fontSize: "1.8em", fontWeight: "600", margin: "0px"}}>
                        {title_data[1]}&nbsp;
                    </p>
                </div>
                <div>
                    <p style={{fontSize: "1.8em", fontWeight: "200", margin: "0px"}}>{title_data[2]}</p>
                </div>
            </Grid>
        </Paper>
    )
}

export default observer(BuilderTitle);