import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Paper, Button, Grid} from "@material-ui/core"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {
    paper_right,
    HeaderCell,
    SpecialCell,
    ValueCell,
    transparent_button,
    card_title,
    card_subtitle, IPTableRow
} from "../../Styles/styles"
import {useHistory} from "react-router-dom";
import {dollarsWithCommas} from "../../Utils/num_utils";
import {useTop10Accounts} from "../Hooks/Data/Accounts/accounts";
import {useUserRole, useUserTeamFilterInfo} from "../Hooks/Data/User/user";
import {useCustomStrategies} from "../Hooks/Data/Strategies/strategies";
import {teamDataFilterWrapper} from "../../Utils/team_utils";

//component of top 10 live accounts for dashboard
function TopAccounts() {
    const history = useHistory()

    const user_role = useUserRole()
    const teamInfoObjs = useUserTeamFilterInfo()
    const accountsItems = useTop10Accounts()
    const top_10_accounts = teamDataFilterWrapper(user_role, teamInfoObjs,accountsItems)
    //reroutes to accounts page with specific account id
    const onAccountClick = (row) => {
        history.push(`/participant/accounts/${row.id}`)
    }

    return (
        <Paper style={paper_right} elevation={0}>
            <p style={card_title}>Top Accounts</p>
            <p style={card_subtitle}>Top 10 Accounts</p>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <HeaderCell>Name</HeaderCell>
                            <HeaderCell align="right">Value</HeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {top_10_accounts.slice(0,10).map((row) => (
                            <IPTableRow>
                                <SpecialCell component="th" scope="row">
                                    <Button
                                        onClick={() => onAccountClick(row)}
                                        style={Object.assign({}, transparent_button, {
                                            margin: "0px",
                                            padding: "0px",
                                            justifyContent: "flex-start"
                                        })}>
                                        {row.legal_name}
                                    </Button>
                                </SpecialCell>
                                <ValueCell
                                    align="right">{dollarsWithCommas(Math.round(row.data['Total Balance']))}</ValueCell>
                            </IPTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container direction="row" justifyContent="flex-end">
                <Button
                    onClick={() => history.push('/participant/accounts')}
                    style={Object.assign({}, transparent_button, {float: "right", margin: "5px"})}>
                    Accounts <NavigateNextIcon fontSize="small"/>
                </Button>
            </Grid>
        </Paper>
    )
}


export default observer(TopAccounts);