import {withStyles} from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import {themeColor} from "../../Styles/styles";

const BuilderSwitch = withStyles((theme) => ({
    root: {
        width: 75,
        height: 32,
        padding: 0,
        display: "flex",
    },
    switchBase: {
        padding: 4,
        color: "white",
        "&$checked": {
            transform: "translateX(43px)",
            color: theme.palette.common.white,
            "& + $track": {
                opacity: 1,
                backgroundColor: themeColor,
            }
        }
    },
    thumb: {
        width: 24,
        height: 24,
        boxShadow: "none"
    },
    track: {
        borderRadius: 32 / 2,
        opacity: 1,
        backgroundColor: "#AFAFAF"
    },
    checked: {}
}))(Switch);


export default BuilderSwitch