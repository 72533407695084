import {big_paper} from "../../Styles/styles";
import Paper from "@material-ui/core/Paper";
import {Grid} from "@material-ui/core";
import React from "react";

function HelpStrategies() {
    return (
        <div>
            <Paper style={big_paper} elevation={0}>
                <div style={{padding: "20px"}}>
                    <p style={{fontWeight: "600", fontSize: "18px"}}>Video Tutorial: Strategies</p>
                    <Grid item xs={12} style={{marginTop: "20px"}}>
                        <iframe
                            width="1240"
                            height="750"
                            src={`https://res.cloudinary.com/indexpro/video/upload/Onboarding%20Videos/Strategies.mp4`}
                            frameBorder="0"
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Product Tour"
                        />
                    </Grid>
                    <br/><br/>
                    <p style={{fontWeight: "600", fontSize: "18px"}}>Strategies Section Overview:</p>
                    <br/>
                    The <span style={{fontWeight: "600"}}>Strategies</span> page hosts all your self-created strategies as well as strategies created by your team members.
                    The list of strategies is arranged by the date created, from the most recently created one to the oldest strategy.
                    While the list itself does give some information on the strategy, such as the <span style={{fontWeight: "600"}}>benchmark</span> it follows,
                    the <span style={{fontWeight: "600"}}>number of positions</span> and the <span style={{fontWeight: "600"}}>tracking error</span>, you can always click on the name of the strategy for more details.
                    <br/><br/>
                    <img src={"https://res.cloudinary.com/indexpro/image/upload/Help%20Section%20Images/Strategies.png"}
                         alt="strategies" width="900px"/>
                    <br/><br/>
                    <p style={{fontWeight: "600", fontSize: "18px"}}>Strategy Page Explained:</p>
                    <br/>
                    You can view each strategy in more detail by simply clicking on the strategy name. At the very top
                    you will see an overview of your strategy, starting with the benchmark it is following (<span style={{fontWeight: "600"}}>Benchmark</span>),
                    the number of positions in the strategy (<span style={{fontWeight: "600"}}># of Positions</span>), the tracking error in relation to the
                    benchmark (<span style={{fontWeight: "600"}}>Tracking Error</span>), the weight of the largest position in the strategy (<span style={{fontWeight: "600"}}>Largest Weight</span>),
                    the weight of the top 10 positions in the strategy (<span style={{fontWeight: "600"}}>Top 10 Weight</span>), and the total median market cap (<span style={{fontWeight: "600"}}>Median Market Cap</span>).
                    <br/><br/>
                    <img src={"https://res.cloudinary.com/indexpro/image/upload/Help%20Section%20Images/Strategy-Overview.png"}
                         alt="strategy-overview" width="900px"/>
                    <br/><br/>
                    <Grid container direction="row" >
                        <div style={{width: "600px"}}>
                            <img src={"https://res.cloudinary.com/indexpro/image/upload/Help%20Section%20Images/Strategy-Proposal.png"}
                                 alt="strategy-proposal" width="500px"/>
                        </div>
                        <div style={{width: "600px"}}>
                            <Grid container direction="column">
                                <div>By clicking on <span style={{fontWeight: "600"}}>Download Proposal</span> you can generate PDF format file to review or share with your client.</div>
                                <br/>
                                <div>1. Click on <span style={{fontWeight: "600"}}>Download Proposal</span> on the upper right side of the <span style={{fontWeight: "600"}}>Overview</span> section inside the <span style={{fontWeight: "600"}}>Strategy</span> page</div>
                                <br/>
                                <div>2.	Enter the <span style={{fontWeight: "600"}}>Client Name</span> to personalize the proposal</div>
                                <br/>
                                <div>3. Click <span style={{fontWeight: "600"}}>Download</span></div>
                            </Grid>
                        </div>
                    </Grid>
                    <br/><br/>
                    The proposal contains all the details of your strategy, your selections, exclusions, performance
                    and tax preferences. The proposal is personalized for your client and contains a short bio
                    of the advisor and the firm he or she represents.  If distributing the proposal to your client,
                    please consult with your Chief Compliance Officer to determine whether this is subject to the
                    Marketing Rule under Rule 206(4)-1 of the Investment Advisers Act of 1940.
                    <br/><br/>
                    The <span style={{fontWeight: "600"}}>Strategy Preferences</span> section is an overview of all the selections you made
                    when building this strategy. You can change any strategy by simply clicking on the
                    Change button on the upper right side of this section. This will open the strategy builder
                    where you can make new selections. Please visit our Builder help page for detailed instructions
                    on how to build a strategy.
                    <br/><br/>
                    <img src={"https://res.cloudinary.com/indexpro/image/upload/Help%20Section%20Images/Strategy-Preferences.png"}
                         alt="strategy-preferences" width="900px"/>
                    <br/><br/>
                    <Grid container direction="row" >
                        <div style={{width: "600px", marginRight: "20px"}}>
                            The <span style={{fontWeight: "600"}}>Holdings</span> section shows you the top 10 holdings based on the weight they carry in your strategy.
                            <br/><br/>
                            <img src={"https://res.cloudinary.com/indexpro/image/upload/Help%20Section%20Images/Strategy-Holdings.png"}
                                 alt="strategy-holdings" width="400px"/>
                        </div>
                        <div style={{width: "600px"}}>
                            The <span style={{fontWeight: "600"}}>Sector Breakdown</span> section shows the percentage of your strategy in each sector versus that of the benchmark.
                            <br/><br/>
                            <img src={"https://res.cloudinary.com/indexpro/image/upload/Help%20Section%20Images/Strategy-Sector.png"}
                                 alt="strategy-sectors" width="400px"/>
                        </div>
                    </Grid>
                    <br/><br/>
                    You can always download a complete list of your holdings by clicking on the <span style={{fontWeight: "600"}}>Download Holdings</span> button.
                    This will open an excel of all the holdings, including the SEDOL, company name, ticker, the active weight, and the sector.
                    <br/><br/>
                    <img src={"https://res.cloudinary.com/indexpro/image/upload/Help%20Section%20Images/Strategy-Excel.png"}
                         alt="strategy-excel" width="900px"/>
                         <br/>
                    <span style={{fontSize: "11px", fontStyle: "italic"}}>The above image of the excel file is for illustration purposes only.</span>
                    <br/><br/>
                    The <span style={{fontWeight: "600"}}>Impact Summary</span> shows the improvements of this strategy, based on MSCI ratings, as compared to your selected benchmark.
                    <br/><br/>
                    <img src={"https://res.cloudinary.com/indexpro/image/upload/Help%20Section%20Images/Strategy-Impact.png"}
                         alt="strategy-impact" width="900px"/>
                    <br/><br/>
                    For more help with your <span style={{fontWeight: "600"}}>Strategy</span> please e-mail us at <a href={"mailto: indexpro@atomlp.com"}>indexpro@atomlp.com</a> or use the chat bubble on the bottom right of this page to chat with us.
                </div>
            </Paper>
        </div>
    )
}

export default HelpStrategies