import React from "react";
import {observer} from "mobx-react-lite";
import {Button, Paper} from "@material-ui/core"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import {
    paper_left,
    HeaderCell,
    SpecialCell,
    transparent_button,
    ValueCell,
    card_title,
    card_subtitle, IPTableRow,
    ipGreen, ipRed
} from "../../Styles/styles";
import {useHistory} from "react-router-dom";
import {useCustomStrategies, useTop10IPStrategies} from "../Hooks/Data/Strategies/strategies";
import {useUserRole, useUserTeamFilterInfo} from "../Hooks/Data/User/user";
import {teamDataFilterWrapper} from "../../Utils/team_utils";

//component shows top 10 index pro strategies sorted by ytd performance
function TopPortfolios() {
    const history = useHistory();

    const user_role = useUserRole()
    const teamInfoObjs = useUserTeamFilterInfo()
    const customStrategyItems = useCustomStrategies()
    const top10Strategies = teamDataFilterWrapper(user_role, teamInfoObjs,customStrategyItems)

    const onStrategyClick = (id) => {
        history.push(`/participant/strategies/custom/${id}`)
    }

    return (
        <Paper style={paper_left} elevation={0}>
            <p style={card_title}>Top Strategies</p>
            <p style={card_subtitle}>Top 10 Recent Strategies</p>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <HeaderCell>Strategy Name</HeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {top10Strategies.slice(0,10).map((row) => (
                            <IPTableRow>
                                <SpecialCell component="th" scope="row">
                                    <Button
                                        onClick={() => onStrategyClick(row.id)}
                                        style={Object.assign({}, transparent_button, {
                                            margin: "0px",
                                            padding: "0px",
                                            justifyContent: "flex-start"
                                        })}>
                                        {row.strategy_name}
                                    </Button>
                                </SpecialCell>
                            </IPTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button style={Object.assign({}, transparent_button, {float: "right", margin: "5px"})}
                    onClick={() => history.push('/participant/strategies')}>
                Strategies <NavigateNextIcon fontSize="small"/>
            </Button>
        </Paper>
    )
}


export default observer(TopPortfolios);