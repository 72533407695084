import {Paper} from "@material-ui/core";
import {big_paper, card_subtitle, card_title, HeaderCell, IPTableRow, ValueCell} from "../../Styles/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import {dollarsWithCommas} from "../../Utils/num_utils";
import React from "react";

function AccountSectors(props) {
    return (
        <div className="Portfolio">
            <Paper style={big_paper} elevation={0}>
                <div>
                    <p style={card_title}>Portfolio</p>
                    <p style={card_subtitle}>Sectors</p>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <HeaderCell>Sector</HeaderCell>
                                    <HeaderCell align="right">Market Value</HeaderCell>
                                    <HeaderCell align="right">% of Portfolio</HeaderCell>
                                    <HeaderCell algin="right"/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.account.data['Sector Data'].map((row) => (
                                    <IPTableRow>
                                        <ValueCell>{row.sector}</ValueCell>
                                        <ValueCell
                                            align="right">{dollarsWithCommas(row.market_value)}</ValueCell>
                                        <ValueCell align="right">{row.weight}</ValueCell>
                                        <ValueCell align="right">
                                            <div style={{
                                                width: `${Math.round(parseFloat(row.weight) * 10)}px`,
                                                height: "20px",
                                                backgroundColor: row.color,
                                            }}/>
                                        </ValueCell>
                                    </IPTableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Paper>
        </div>
    )
}

export default AccountSectors