import {useUserLogo} from "../Hooks/Data/User/user";
import React from "react";

export const Contents = (page_numbers, transition_data) => {
    const logo = useUserLogo()
    const pages = Object.assign(transition_data ? ({'Transition':4, 'Portfolio':7}) : ({'Portfolio': 4}), page_numbers);
    const mappings = {
        'Transition': 'Transition Details',
        'Portfolio': 'Portfolio Details',
        'Environmental': 'Environmental Selections',
        'Social': 'Social Selections',
        'Governance': 'Governance Selections',
        'Themes': 'Investment Themes',
        'Exclusions': 'Portfolio Exclusions',
        'Taxes': 'Tax Strategy & Preferences',
        'Performance': 'Active Performance Selections',
    }

    return (
        <div style={{height: "100%", position: "relative", fontDisplay: "swap"}}>
            <img
                src="https://res.cloudinary.com/indexpro/image/upload/Proposal/Contents.jpg"
                width="100%"/>
            <div style={{
                position: "absolute",
                top: "54px",
                right: "60px",
                fontSize: "14px",
                fontWeight: "600",
                color: "black"
            }}>2
            </div>
            <div style={{
                position: "absolute",
                bottom: "400px",
                left: "60px",
                fontSize: "32px",
                fontWeight: "600",
                color: "black"
            }}>Contents
            </div>
            {Object.keys(pages).map(function (key, index) {
                if(index <=4) {
                    return (
                        <>
                            <div style={{
                                position: "absolute",
                                bottom: `${350 - (index) * 60}px`,
                                left: "60px",
                                fontSize: "15px",
                                fontWeight: "600",
                                color: "black"
                            }}>{key}
                            </div>
                            <div style={{
                                position: "absolute",
                                bottom: `${330 - (index) * 60}px`,
                                left: "60px",
                                fontSize: "13px",
                                fontWeight: "500",
                                color: "black"
                            }}>{mappings[key]}
                            </div>
                            <div style={{
                                position: "absolute",
                                bottom: `${330 - (index) * 60}px`,
                                left: "330px",
                                fontSize: "13px",
                                fontWeight: "500",
                                color: "black"
                            }}>{pages[key]}
                            </div>
                        </>
                    )
                }
                else {
                    return (
                        <>
                            <div style={{
                                position: "absolute",
                                bottom: `${350 - (index-5) * 60}px`,
                                left: "460px",
                                fontSize: "15px",
                                fontWeight: "600",
                                color: "black"
                            }}>{key}
                            </div>
                            <div style={{
                                position: "absolute",
                                bottom: `${330 - (index-5) * 60}px`,
                                left: "460px",
                                fontSize: "13px",
                                fontWeight: "500",
                                color: "black"
                            }}>{mappings[key]}
                            </div>
                            <div style={{
                                position: "absolute",
                                bottom: `${330 - (index-5) * 60}px`,
                                left: "730px",
                                fontSize: "13px",
                                fontWeight: "500",
                                color: "black"
                            }}>{pages[key]}
                            </div>
                        </>
                    )
                }
            })}
            <div style={{position: "absolute", bottom: "40px", right: "60px"}}>
                <img
                    src={logo}
                    height="40px"/>
            </div>
        </div>
    )
}